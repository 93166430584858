import { observable, action, computed } from 'mobx';
import { loadDataHelper, saveDateHelper } from './storeHelpers';
import _ from 'lodash';

import StoreBase from './storeBase';
import { t } from 'env/i18n';
class InvoicingStore extends StoreBase {
  constructor({ data, host, cookieString } = {}) {
    super({ data, host, cookieString });
  }

  @observable isLoadingMetrics = false;
  @observable hasLoadedMetrics = false;
  @observable metrics = [];
  @action loadMetrics(teamId) {
    return loadDataHelper({
      store: this,
      agentKey: 'Billing',
      key: 'Metrics',
      params: teamId,
    });
  }

  @observable productFilterTag = null;
  @action setProductFilterTag(tag) {
    this.productFilterTag = tag;
  }

  @computed get selectedProducts() {
    return {
      products: this.storeProducts?.products.filter((p) => p.Quantity > 0),
      discountCode: this.storeProducts.discountCode,
    };
  }

  @computed get filteredProducts() {
    return {
      products: this.storeProducts?.products.filter(
        (p) =>
          !(this.productFilterTag?.length > 0) ||
          p.Tags?.indexOf(this.productFilterTag) > -1
      ),
      discountCode: this.storeProducts.discountCode,
    };
  }

  getProductsFromList = (list) => {
    const allTags = list.reduce((acc, p) => {
      if (p.Tags) {
        acc = acc.concat(p.Tags?.split(','));
      }

      acc = _.uniq(acc);
      return acc;
    }, []);

    if(allTags.length === 0 && list.length) {
      return [{
        name: 'no-tag',
        products: list
      }];
    }

    const products = allTags.reduce((acc, tag) => {
      acc.push({
        name: tag,
        products: list.filter((x) => x.Tags?.indexOf(tag) > -1),
      });
      return acc;
    }, []);

    const noTagProducts = {
      name: 'no-tag',
      products: list.filter((x) => !x.Tags)
    };

    if(noTagProducts.products.length) {
      return [...products, noTagProducts];
    }

    return products;
  };

  @computed get allProductsByGroup() {
    return this.getProductsFromList(this.storeProducts.products);
  }

  @computed get productsByGroup() {
    return this.getProductsFromList(this.filteredProducts.products);
  }

  @observable isLoadingStoreProducts = false;
  @observable hasLoadedStoreProducts = false;
  @observable storeProducts = {
    products: [],
    discountCode: {},
  };
  @action loadStoreProducts({ selectedPlans } = {}) {
    return loadDataHelper({
      store: this,
      agentKey: 'Billing',
      key: 'StoreProducts',
      params: { selectedPlans },
    });
  }

  @observable isLoadingPricePlanGroups = false;
  @observable hasLoadedPricePlanGroups = false;
  @observable pricePlanGroups = [];
  @action loadPricePlanGroups() {
    loadDataHelper({
      store: this,
      agentKey: 'Billing',
      key: 'PricePlanGroups',
    }).then((data) =>
      data.map((group) => (group == 'Price Plans' ? null : group))
    );
  }

  @observable isLoadingPricePlans = false;
  @observable hasLoadedPricePlans = false;
  @observable pricePlans = {
    PricePlans: [],
    PricePlanGroups: [],
  };
  @action loadPricePlans({ tariffGuid, customer }) {
    return loadDataHelper({
      store: this,
      agentKey: 'Billing',
      key: 'PricePlans',
      params: { tariffGuid, customer },
    }).then(
      action((data) => {
        data.PricePlanGroups = data.PricePlanGroups.map((group) =>
          group == 'Price Plans' ? null : group
        );
        this.pricePlans = data;
        return data;
      })
    );
  }

  @observable isLoadingContract = false;
  @observable hasLoadedContract = false;
  @observable contract = null;
  @action loadContract(contractId) {
    this.contract = null;
    return loadDataHelper({
      store: this,
      agentKey: 'Billing',
      key: 'Contract',
      path: 'Contract',
      params: contractId,
    });
  }

  @observable isLoadingActiveContracts = false;
  @observable hasLoadedActiveContracts = false;
  @observable activeContracts = [];
  @action loadActiveContracts() {
    return loadDataHelper({
      store: this,
      agentKey: 'Billing',
      key: 'ActiveContracts',
      path: 'ActiveContracts',
    });
  }

  @observable isLoadingNonCancelledContracts = false;
  @observable hasLoadedNonCancelledContracts = false;
  @observable nonCancelledContracts = [];
  @action loadNonCancelledContracts() {
    return loadDataHelper({
      store: this,
      agentKey: 'Billing',
      key: 'NonCancelledContracts',
      path: 'NonCancelledContracts',
    });
  }

  @observable isLoadingBenefits = false;
  @observable hasLoadedBenefits = false;
  @observable benefits = {
    TimePasses: [],
    BookingCredits: [],
    ExtraServices: [],
  };
  @action loadBenefits() {
    return loadDataHelper({
      store: this,
      agentKey: 'Billing',
      key: 'Benefits',
    });
  }

  @observable isLoadingTeamBenefits = false;
  @observable hasLoadedTeamBenefits = false;
  @observable teamBenefits = [];
  @action loadTeamBenefits() {
    return loadDataHelper({
      store: this,
      agentKey: 'Billing',
      key: 'TeamBenefits',
    }).then(
      action((data) => {
        data.forEach((team) => {
          team.KPI.forEach((benefit) => {
            benefit.TotalRemainingTimePassesMinutes =
              benefit.RemainingTimePassesMinutes +
              benefit.RemainingTimePassesCount * 24;

            benefit.TotalRemainingBookingCredit =
              benefit.RemainingBookingCredit +
              benefit.RemainingTimeCreditMinutes;

            benefit.TotalUninvoiced =
              benefit.UninvoicedProducts +
              benefit.UninvoicedExtraServices +
              benefit.UnivoicedEventAttendees +
              benefit.UnivoicedTimepasses;
          });
        });
        data.forEach((team) => {
          team.TotalUninvoiced = _.sumBy(team.KPI, 'TotalUninvoiced');
          team.BookedTimeThisMonth = _.sumBy(team.KPI, 'BookedTimeThisMonth');
          team.BookedTimeTotal = _.sumBy(team.KPI, 'BookedTimeTotal');
          team.RemainingBookingCredit = _.sumBy(
            team.KPI,
            'RemainingBookingCredit'
          );
          team.RemainingTimeCreditMinutes = _.sumBy(
            team.KPI,
            'RemainingTimeCreditMinutes'
          );
          team.CheckedTimeThisMonth = _.sumBy(team.KPI, 'CheckedTimeThisMonth');
          team.CheckedTimeTotal = _.sumBy(team.KPI, 'CheckedTimeTotal');
          team.RemainingTimePassesMinutes = _.sumBy(
            team.KPI,
            'RemainingTimePassesMinutes'
          );
          team.RemainingTimePassesCount = _.sumBy(
            team.KPI,
            'RemainingTimePassesCount'
          );
          team.TotalRemainingTimePassesMinutes = _.sumBy(
            team.KPI,
            'TotalRemainingTimePassesMinutes'
          );
        });
        this.teamBenefits = data;
        return data;
      })
    );
  }

  @observable isLoadingProducts = false;
  @observable hasLoadedProducts = false;
  @observable products = [];
  @action loadProducts() {
    return loadDataHelper({
      store: this,
      agentKey: 'Billing',
      key: 'Products',
      path: 'Products',
    });
  }

  @observable isLoadingInvoices = false;
  @observable hasLoadedInvoices = false;
  @observable invoices = [];
  @action loadInvoices() {
    return loadDataHelper({
      store: this,
      agentKey: 'Billing',
      key: 'Invoices',
      path: 'Invoices',
    });
  }

  @observable isLoadingInvoicesPage = false;
  @observable hasLoadedInvoicesPage = false;
  @observable invoicesPage = {};
  @action async loadInvoicesPage() {
    return loadDataHelper({
      store: this,
      agentKey: 'Billing',
      key: 'InvoicesPage'
    });
  }

  @observable isSavingNewContract = false;
  @action saveNewContract({ tariffGuid, startDate, businessGuid }) {
    return saveDateHelper({
      store: this,
      agentKey: 'Billing',
      key: 'NewContract',
      data: { tariffGuid, startDate, businessGuid },
    });
  }

  @observable isSavingContract = false;
  @action saveContract({ contractId, tariffId }) {
    return saveDateHelper({
      store: this,
      agentKey: 'Billing',
      key: 'Contract',
      data: { contractId, tariffId },
    }).then(
      action(() => {
        return this.loadContract(contractId);
      })
    );
  }

  @observable isCancellingContract = false;
  @action cancelContract() {
    this.isCancellingContract = true;
    return this.getAgent()
      .Billing.cancelContract(this.contract)
      .then(
        action(() => {
          return this.loadContract(this.contract.Id);
        })
      )
      .finally(
        action((contract) => {
          this.isCancellingContract = false;
          return contract;
        })
      );
  }

  @observable isLoadingAcceptProposal = false;
  @observable hasLoadedAcceptProposal = false;
  @observable acceptProposal = null;
  @action loadAcceptProposal(proposal_guid) {
    return loadDataHelper({
      store: this,
      agentKey: 'Billing',
      key: 'AcceptProposal',
      params: proposal_guid,
    });
  }

  @observable isLoadingSignaturePage = false;
  @observable hasLoadedSignaturePage = false;
  @observable signaturePage = [];
  @action loadSignaturePage(document_id) {
    return loadDataHelper({
      store: this,
      agentKey: 'Billing',
      key: 'SignaturePage',
      params: document_id,
    });
  }

  @observable isLoadingProposalView = false;
  @observable hasLoadedProposalView = false;
  @observable proposalView = {};
  @action loadProposalView(proposal_guid) {
    this.proposalView = {};
    return loadDataHelper({
      store: this,
      agentKey: 'Billing',
      key: 'ProposalView',
      params: proposal_guid,
    });
  }

  @computed get paidInvoices() {
    const invoices = this.invoices.length > 0 ? this.invoices : this.invoicesPage.Invoices;
    return _.filter(invoices, (i) => i.Paid);
  }

  @computed get unpaidInvoices() {
    const invoices = this.invoices.length > 0 ? this.invoices : this.invoicesPage.Invoices;
    return _.filter(invoices, (i) => !i.Paid);
  }

  @computed get hasBenefits() {
    return (
      this.benefits.TimePasses.length > 0 ||
      this.benefits.BookingCredits.length > 0 ||
      this.benefits.ExtraServices.length > 0
    );
  }

  @computed get earliestRenewingContract() {
    const min = _.minBy(this.activeContracts, function (o) {
      return o.RenewalDate;
    });
    return min ? min.RenewalDate : null;
  }
}

export default InvoicingStore;
