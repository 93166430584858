import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import dynamic from 'next/dynamic';
import _ from 'lodash';
import config from 'env/config';
import LoadableComponent from 'env/LoadableComponent/LoadableComponent';

export const withCustomComponent = (name) => (WrappedComponent) => {
  @inject('appStore')
  @observer
  class WithCustomComponent extends Component {
    componentDidMount() {
      if (window.componentDidMount) window.componentDidMount(name);
    }

    componentWillUpdate(nextProps, nextState) {
      if (window.componentWillUpdate)
        window.componentWillUpdate(nextProps, nextState, name);
    }

    render() {
      const { appStore } = this.props;
      const foundPage = _.find(
        appStore.customPages,
        (page) => page.Name == `${name}.js` || page.Name == `${name}.jsx`
      );
      
      return foundPage ? (
        <LoadableComponent
          {...this.props}
          url={`${config.apiRoot ? config.apiRoot + '/en' : '/en'}/page/${
            foundPage.Name
          }?raw=true`}
        >
          {this.props.children}
        </LoadableComponent>
      ) : (
        <>
          <WrappedComponent componentName={name} {...this.props}>
            {this.props.children}
          </WrappedComponent>
        </>
      );
    }
  }

  return WithCustomComponent;
};

export default withCustomComponent;
