import {
  LinkableLinkRecordFragment,
  LinkableArticleCategoryRecordFragment,
  LinkableArticleRecordFragment,
  LinkableBlogHomepageRecordFragment,
  LinkableCustomPageRecordFragment,
  LinkableLocationSiteRecordFragment,
  LinkableSolutionTypeRecordFragment,
  LinkableNexudusLinkRecordFragment,
  NavbarQuery,
  LinkableWorkspaceRecordFragment,
} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {RunwayEastButton} from '../button/RunwayEastButton';
import {RunwayEastLink} from './RunwayEastLink';

export type LinkableDatoModel =
  | LinkableCustomPageRecordFragment
  | NavbarQuery['allFooterItems'][number]
  | LinkableLocationSiteRecordFragment
  | LinkableSolutionTypeRecordFragment
  | NavbarQuery['allNavbarItems'][number]
  | LinkableArticleCategoryRecordFragment
  | LinkableBlogHomepageRecordFragment
  | LinkableArticleRecordFragment
  | LinkableWorkspaceRecordFragment
  | LinkableLinkRecordFragment
  | LinkableNexudusLinkRecordFragment;

export const datoModelToHref = (
  datoModel: LinkableDatoModel,
): string | null => {
  // eslint-disable-next-line no-underscore-dangle
  if (!datoModel._isValid) return null;

  // eslint-disable-next-line no-underscore-dangle
  switch (datoModel.__typename) {
    case 'CustomPageRecord':
      return datoModel.urlPath || null;
    case 'FooterItemRecord': {
      const {link} = datoModel;
      return link ? datoModelToHref(link) : null;
    }
    case 'LocationSiteRecord': {
      const {urlPath} = datoModel;
      return urlPath || '/404';
    }
    case 'SolutionTypeRecord': {
      const {urlPath} = datoModel;
      return urlPath || '/404';
    }
    case 'NavbarItemRecord': {
      const {link} = datoModel;
      return link ? datoModelToHref(link) : null;
    }
    case 'ArticleCategoryRecord':
      return datoModel.pagePath || null;
    case 'ArticleRecord':
      return datoModel.pagePath || null;
    case 'BlogHomepageRecord':
      return datoModel.pagePath || null;
    case 'NexudusLinkRecord':
      return datoModel.urlPath || null;
    case 'WorkspaceRecord':
      return datoModel.urlPath || null;
    default:
      return '#';
  }
};

interface LinkFields {
  link?: LinkableDatoModel | null;
  externalLink?: string | null;
}

export const resolveHrefFromLinkFields = ({
  link,
  externalLink,
}: LinkFields): string | undefined => {
  if (link) return datoModelToHref(link) || undefined;

  return externalLink || undefined;
};

export interface DatoLinkProps {
  datoModel: LinkableDatoModel;
  children: React.ReactNode;
}

export const DatoLink = React.memo(({datoModel, children}: DatoLinkProps) => {
  const resolvedHref = useMemo(
    (): string | null => datoModelToHref(datoModel),
    [datoModel],
  );

  return (
    <RunwayEastLink href={resolvedHref || undefined}>{children}</RunwayEastLink>
  );
});

type DatoButtonLinkProps = {
  data: LinkableLinkRecordFragment;
};

export const DatoButtonLink = React.memo(({data}: DatoButtonLinkProps) => {
  if (!data.name) return null;
  const htmlId = data.externalElementId ? data.externalElementId : '';
  const variant =
    data.buttonStyle && data.buttonStyle === 'white' ? 'secondary' : 'primary';

  // eslint-disable-next-line no-nested-ternary
  return data.link ? (
    <DatoLink datoModel={data.link}>
      <RunwayEastButton variant={variant} passedHref>
        {data.name}
      </RunwayEastButton>
    </DatoLink>
  ) : data.externalLink ? (
    <RunwayEastButton variant={variant} href={data.externalLink} htmlId={htmlId}>
      {data.name}
    </RunwayEastButton>
  ) : null;
});
