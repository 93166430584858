import {inject} from 'mobx-react';
import React from 'react';
import {AppStore, AuthStore, Business} from 'ui/components/withAppWrapper';
import {LocationItem} from './LocationItem';

export interface LocationsMenuProps {
  appStore: AppStore;
  authStore: AuthStore;
  showCurrent?: boolean;
  btnClassName?: string;
  hideCaret?: boolean;
}

export const LocationsMenu: React.FC<LocationsMenuProps> = inject(
  'appStore',
  'authStore',
)(
  ({
    appStore,
    authStore,
    showCurrent = true,
    btnClassName = 'btn btn-white-black btn-icon',
    hideCaret,
  }: LocationsMenuProps) => {
    const myBusinessIds: number[] = [
      ...((authStore as any)?.me?.Businesses || []),
    ];
    const allBusinesses: Business[] = [...(appStore?.businesses || [])];
    const myBusinesses: Business[] = allBusinesses.filter(
      (business: Business) =>
        myBusinessIds.includes(business.Id) &&
        !!business.RootBusinessId &&
        business.RootBusinessId !== business.Id,
    );

    if (myBusinesses.length <= 1)
      return showCurrent ? (
        <div className="locations-menu">
          <span className={btnClassName}>
            <i className="icon-marker-pin-circle" />
            <span className="text">{appStore.business.Name}</span>
          </span>
        </div>
      ) : null;

    const opts = myBusinesses.map((business) => (
      <LocationItem
        key={business.Name}
        authStore={authStore}
        location={business}
        currentLocation={appStore.business}
      />
    ));

    return (
      <div className="locations-menu dropdown">
        <button
          type="button"
          className={btnClassName.concat(' dropdown-toggle').trim()}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="icon-marker-pin-circle" />
          <span className="text">
            {showCurrent ? appStore.business.Name : 'Locations'}
          </span>
          {hideCaret ? null : <span className="caret" />}
        </button>
        {myBusinesses.length > 1 && (
          <div className="dropdown-menu" role="menu">
            {opts}
          </div>
        )}
      </div>
    );
  },
);

export default LocationsMenu;
