import {inject} from 'mobx-react';
import React from 'react';
// eslint-disable-next-line import/no-cycle
import {DefaultLayout} from 'ui/layouts/DefaultLayout';
import {RunwayEastLayout, RunwayEastLayoutProps} from './RunwayEastLayout';

export type NexudusLayoutProps = RunwayEastLayoutProps;

export const NexudusLayout = inject(
  'authStore',
  'checkoutStore',
)(
  React.memo<NexudusLayoutProps>((props) => {
    if (props.authStore.accessToken) return <DefaultLayout {...props} />;

    return <RunwayEastLayout {...props} />;
  }),
);
