import React, {useCallback, useMemo} from 'react';
import {useElementInteraction} from '../../../../hooks/use-element-interaction';

export interface TextInputProps {
  placeholder?: string;
  value: string;
  onValueChange: (value: string) => any;
  insetComponentOnRight?: React.ReactNode;
  insetComponentOnLeft?: React.ReactNode;
  hasError?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
  bottomText?: string;
}

export const TextInput = React.memo(
  ({
    placeholder,
    value,
    onValueChange,
    insetComponentOnRight,
    insetComponentOnLeft,
    hasError,
    backgroundColor,
    borderColor,
    textColor,
    bottomText,
  }: TextInputProps) => {
    const {
      hasFocus,
      hasMouse,
      onBlur,
      onFocus,
      onMouseEnter,
      onMouseLeave,
    } = useElementInteraction();

    const inputContainerClassNames = useMemo((): string => {
      const classNames = ['rwe-input', 'rwe-input--text'];

      if (hasError) {
        classNames.push('rwe-input--error');

        return classNames.join(' ');
      }

      if (hasFocus || hasMouse) {
        classNames.push('rwe-input--active');
      }
      return classNames.join(' ');
    }, [hasError, hasFocus, hasMouse]);

    const inputContainerStyle = useMemo(
      (): React.CSSProperties | undefined =>
        backgroundColor || borderColor
          ? {
              backgroundColor,
              borderColor:
                !hasFocus && !hasError && !hasMouse ? borderColor : undefined,
            }
          : undefined,
      [backgroundColor, borderColor, hasError, hasFocus, hasMouse],
    );

    const inputStyle = useMemo(
      (): React.CSSProperties | undefined =>
        textColor
          ? {
              color: textColor,
            }
          : undefined,
      [textColor],
    );

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onValueChange(event.target.value);
      },
      [onValueChange],
    );

    return (
      <div className="rwe-input-with-message">
        <div className={inputContainerClassNames} style={inputContainerStyle}>
          {insetComponentOnLeft ? (
            <div className="rwe-input__inset-component rwe-input__inset-component--on-left">
              {insetComponentOnLeft}
            </div>
          ) : null}
          <div className="rwe-input__input">
            <input
              type="text"
              style={inputStyle}
              placeholder={placeholder}
              value={value}
              onChange={handleChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            />
          </div>
          {insetComponentOnRight ? (
            <div className="rwe-input__inset-component rwe-input__inset-component--on-right">
              {insetComponentOnRight}
            </div>
          ) : null}
        </div>
        {bottomText ? (
          <div className="rwe-input-with-message__message">
            <span className="rwe-caption">{bottomText}</span>
          </div>
        ) : null}
      </div>
    );
  },
);
