import { routes } from 'env/routes';

export const isServer = () => typeof window === 'undefined';

export const handServerRequest = async ({ appContext, mobxStore }) => {
  const { router } = appContext;

  //Home
  if (router.pathname == routes.home)
    await mobxStore.appStore.loadHomePage().catch((ex) => null);

  //Event by ID
  if (router.pathname == routes.event_by_id('[event_id]'))
    await mobxStore.eventsStore
      .loadEventPage(router.query.event_id)
      .catch((ex) => null);

  //Events listing

  if (router.pathname == routes.events) {
    await mobxStore.eventsStore
      .loadEventsPage({ ...router.query })
      .catch((ex) => null);
  }
  //Blog by ID
  if (router.pathname == routes.post_by_id('[post_id]'))
    await mobxStore.blogStore
      .loadBlogPostPage(router.query.post_id)
      .catch((ex) => null);

  //BLog listing
  if (router.pathname == routes.blog)
    await mobxStore.blogStore
      .loadBlogPosts({ ...router.query })
      .catch((ex) => null);

  //Custom pages
  if (router.pathname == routes.custom_page('[...page_slug]')) {
    const params = {
      name: router.query.page_slug[0],
      customerController:
        router.query.page_slug.length > 1 ? router.query.page_slug[1] : null,
      customerAction:
        router.query.page_slug.length > 2 ? router.query.page_slug[2] : 'index',
    };
    await mobxStore.appStore.loadCustomPage(params).catch((ex) => null);
  }
};
