import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import config from 'env/config';

export const AdminNotice = withCustomComponent('NoAccessNotice')(
  inject(
    'authStore',
    'appStore'
  )(
    withRouter(
      observer(({ authStore, appStore, componentName, onClose }) => {
        const { t } = useTranslation();
        const { business } = appStore;
        return (
          <div data-component-name={componentName}>
            <span>
              {t('Wow! You are also an administrator for {{Name}}', {
                Name: business.Name,
              })}
            </span>{' '}
            <a
              href={`${config.dashboardUrl}/auth/sign-in?t=${authStore.me?.AccessToken}`}
            >
              {t('Access dashboard')}
            </a>
          </div>
        );
      })
    )
  )
);
