import {useRouter} from 'next/router';
import React, {useContext, useEffect, useMemo, useState} from 'react';

interface AppHistoryContextValue {
  lastRoute: string | null;
}

const AppHistoryContext = React.createContext<AppHistoryContextValue>({
  lastRoute: null,
});

export const useAppHistory = (): AppHistoryContextValue =>
  useContext(AppHistoryContext);

export const AppHistoryProvider = React.memo(({children}) => {
  const router = useRouter();
  const [lastRoute, setLastRoute] = useState<string | null>(null);

  const value = useMemo(() => ({lastRoute}), [lastRoute]);

  useEffect(() => {
    const handleRouteChangeStart = (route: string) => {
      setLastRoute(route);
    };
    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router]);

  return (
    <AppHistoryContext.Provider value={value}>
      {children}
    </AppHistoryContext.Provider>
  );
});
