import Link from 'next/link';
import {useRouter} from 'next/router';
import React, {useMemo} from 'react';

export interface RunwayEastLinkProps {
  href?: string;
  htmlId?: string;
  children: React.ReactNode;
  wrapChildrenWithAnchorElementForInternalLinks?: boolean;
  className?: string;
  style?: React.CSSProperties;
  prefetch?: boolean;
}

export const RunwayEastLink = React.memo(
  ({
    href,
    htmlId,
    children,
    prefetch,
    wrapChildrenWithAnchorElementForInternalLinks = true,
    ...rest
  }: RunwayEastLinkProps) => {
    const router = useRouter();

    const isExternal = useMemo(
      (): boolean => typeof href === 'string' && !href.startsWith('/'),
      [href],
    );

    if (!href) {
      return <>{children}</>;
    }
    const openPopUpBtn = () => {
      const formPopUp = document.getElementById('popUpFormBlock')
      const meetPopUp = document.getElementById('popUpFormBlockMeeting')
      const formPopUp1 = document.getElementById('popUpFormBlock1')
      const meetPopUp1 = document.getElementById('popUpFormBlockMeeting1')
      if(htmlId == 'openPopUp'){
        if(formPopUp != null){
          formPopUp.style.display = 'block';
        }
        else if (meetPopUp != null){
          meetPopUp.style.display = 'block';
        }
      }
      else if(htmlId == 'openPopUp1'){
        if(formPopUp1 != null){
          formPopUp1.style.display = 'block';
        }
        else if (meetPopUp1 != null){
          meetPopUp1.style.display = 'block';
        }
      }
      // if(htmlId=='openPopUp' && formPopUp != null)
      // {
        
      // }
      // else{
      //   if(htmlId=='openPopUp' && meetPopUp != null){
          
      //   }
      // }
    }
    // eslint-disable-next-line no-nested-ternary
    return isExternal || !router ? (
      <a id={htmlId} {...rest} href={href} rel="noreferrer" onClick={openPopUpBtn}>
        {children}
      </a>
    ) : (
      <Link
        href={{
          pathname: href,
          query: {...router.query},
        }}
        passHref
        prefetch={prefetch ?? false}
        as={href}
      >
        {wrapChildrenWithAnchorElementForInternalLinks ? (
          /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
          <a id={htmlId} {...rest}>{children}</a>
        ) : (
          children
        )}
      </Link>
    );
  },
);