import { useRouter } from 'next/router'
import Link from 'next/link'
import React, { Children } from 'react'

const ActiveLink = ({ children, activeClassName = 'active', activeOnRootPath = false, activeIfChildRoute = false, ...props }) => {
  const { pathname, asPath } = useRouter()
  const path = activeOnRootPath ? pathname : asPath;

  const child = Children.only(children)
  const childClassName = child.props.className || ''

  let className;

  if(activeIfChildRoute) {
    className = path.startsWith(props.href) || path.startsWith(props.as)
    ? `${childClassName} ${activeClassName}`.trim()
    : childClassName
  } else {
    className = path === props.href || path === props.as
      ? `${childClassName} ${activeClassName}`.trim()
      : childClassName
  }

  return (
    <Link {...props}>
      {React.cloneElement(child, {
        className: className || null,
      })}
    </Link>
  )
}

export default ActiveLink