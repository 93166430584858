import React, {useCallback, useMemo, useState} from 'react';
import {RunwayEastLink} from '../link/RunwayEastLink';

export interface FooterLink {
  text?: string;
  href?: string;
}

export interface FooterColumnProps {
  title?: FooterLink;
  items?: FooterLink[];
}

export const FooterColumn: React.FC<FooterColumnProps> = React.memo(
  ({title, items}) => {
    const [collapsed, setCollapsed] = useState(true);

    const handleClick = useCallback(() => {
      setCollapsed((value) => !value);
    }, []);

    const columnClassNames = useMemo(() => {
      const classNames = ['rwe-footer__column'];

      if (collapsed) {
        classNames.push('rwe-footer__column--collapsed');
      }
      return classNames.join(' ');
    }, [collapsed]);

    const iconClassNames = useMemo(() => {
      const classNames = ['rwe-footer__column-heading-icon'];

      if (collapsed) {
        classNames.push('rwe-footer__column--collapsed');
      }
      return classNames.join(' ');
    }, [collapsed]);

    return (
      <div
        key={title ? title.text || title.href : undefined}
        className={columnClassNames}
      >
        {title && title.text ? (
          // TODO: figure out the correct way to do onClick
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div className="rwe-footer__column-heading" onClick={handleClick}>
            <div className="rwe-p1">
              <RunwayEastLink href={title.href}>{title.text}</RunwayEastLink>
            </div>
            <i className={iconClassNames} />
          </div>
        ) : null}
        {items && items.length ? (
          <div className="rwe-footer__column-links-container">
            {items.map((item) =>
              item.text ? (
                <div className="rwe-footer__column-link">
                  <div className="rwe-caption">
                    <RunwayEastLink href={item.href}>
                      {item.text}
                    </RunwayEastLink>
                  </div>
                </div>
              ) : null,
            )}
          </div>
        ) : null}
      </div>
    );
  },
);
