export const getErrorDescription = (errorCode, message, t) => {
  if (errorCode == 'CUSTOM_MESSAGE') return t(message);
  if (errorCode == 'INVALID DISCOUNT') return t('Invalid discount code');
  if (errorCode == 'NO_RESOURCE') return t('Select a resource');
  if (errorCode == 'NOT_REGISTERED')
    return t('You must be registered with this location to place a booking');
  if (errorCode == 'INVALID_INTERVAL')
    return t('You cannot make a booking of this length.');
  if (errorCode == 'BOOKING_CONFLICT')
    return t('One or more resources in your basket are not available.');
  if (errorCode == 'EVENT_CONFLICT')
    return t(
      'One or more resources in your basket are being used by an event.'
    );
  if (errorCode == 'RESOURCE_FULL')
    return t('One or more resources in your basket is fully booked.');
  if (errorCode == 'AREA_FULL')
    return t(
      'One or more resources in your basket are in an area which is fully booked.'
    );
  if (errorCode == 'TOO_MANY_VISITORS')
    return t('You cannot add this many attendees to this booking');
  if (errorCode == 'INVALID_SHIFT')
    return t(
      'The start and end time for one or more of your bookings are not valid.'
    );
  if (errorCode == 'NO_PAST_BOOKINGS')
    return t('You cannot request a booking in the past.');
  if (errorCode == 'HARD_LIMIT_WEEK')
    return t('You are over the weekly booking limit.');
  if (errorCode == 'HARD_LIMIT_MONTH')
    return t('You are over the monthly booking limit.');
  if (errorCode == 'INVALID_TARIFF')
    return t('You cannot book one or more of the resources in your basket.');
  if (errorCode == 'INVALID_TEAM')
    return t('Your team cannot book this resource.');
  if (errorCode == 'NOT_AVAILABLE')
    return t(
      'You cannot book one or more of the resources in your basket at the selected time.'
    );
  if (errorCode == 'START_TIME_TOO_EARLY')
    return t(
      'You cannot book one or more resources in your basket this far in advance.'
    );
  if (errorCode == 'START_TIME_TOO_LATE')
    return t(
      'You cannot book one or more resources in your basket this close to the start of the booking.'
    );
  if (errorCode == 'TOO_LONG')
    return t(
      'You cannot book one or more resources in your basket for this long.'
    );
  if (errorCode == 'TOO_SHORT')
    return t(
      'You cannot book one or more resources in your basket for this short time.'
    );
  if (errorCode == 'NO_RETURN_POLICY')
    return t(
      'You cannot book one or more resources in your basket again this soon.'
    );
  if (errorCode == 'NO_CREDIT')
    return t(
      'You cannot book one or more resources in your basket because you do not have enough credit.'
    );
  if (errorCode == 'CUSTOM_MESSAGE')
    return t('You cannot book one or more resources in your basket.');

  return (
    t(
      'Sorry, we cannot process this order at this time. Please contact us for more information'
    ) +
    ': ' +
    t(errorCode)
  );
};
