import {useState, useCallback} from 'react';

type UseElementInteractionReturnType = {
  hasFocus: boolean;
  hasMouse: boolean;
  onFocus: () => void;
  onBlur: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

export const useElementInteraction = (): UseElementInteractionReturnType => {
  const [hasFocus, setHasFocus] = useState(false);
  const [hasMouse, setHasMouse] = useState(false);

  const onFocus = useCallback(() => {
    setHasFocus(true);
    setHasMouse(true);
  }, []);
  const onBlur = useCallback(() => {
    setHasFocus(false);
    setHasMouse(false);
  }, []);
  const onMouseEnter = useCallback(() => setHasMouse(true), []);
  const onMouseLeave = useCallback(() => setHasMouse(false), []);

  return {
    hasFocus,
    hasMouse,
    onFocus,
    onBlur,
    onMouseEnter,
    onMouseLeave,
  };
};
