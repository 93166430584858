class MockAgent {
    constructor() {
      this.requests = {
        del: this.mockRequest,
        get: this.mockRequest,
        getBlob: this.mockRequest,
        put: this.mockRequest,
        send: this.mockRequest,
        post: this.mockRequest,
      };
  
      this.Auth = {
        exchange: this.mockRequest,
        exists: this.mockRequest,
        getMe: this.mockRequest,
        getNotifications: this.mockRequest,
        clearNotifications: this.mockRequest,
        DismissNotifications: this.mockRequest,
        getSignupPage: this.mockRequest,
        tour: this.mockRequest,
        getProfilePage: this.mockRequest,
        getCustomer: this.mockRequest,
        getTeams: this.mockRequest,
        getTeamProfiles: this.mockRequest,
        saveTeamProfile: this.mockRequest,
        restore: this.mockRequest,
        getPayingMember: this.mockRequest,
        saveCustomer: this.mockRequest,
        saveCustomerWithUser: this.mockRequest,
        getResetPassword: this.mockRequest,
        setPassword: this.mockRequest,
        login: this.mockRequest,
        refreshToken: this.mockRequest,
        getTeamMemberAccessToken: this.mockRequest,
      };
  
      this.Business = {
        getHomePage: this.mockRequest,
        getLanguages: this.mockRequest,
        getLegalStatus: this.mockRequest,
        getVisitorTerms: this.mockRequest,
        getVisitorTermsAccept: this.mockRequest,
        getAcceptTerms: this.mockRequest,
        getConfiguration: this.mockRequest,
        getReferralDiscounts: this.mockRequest,
        getCustomPages: this.mockRequest,
        getSendReferralInvite: this.mockRequest,
        getCustomPage: this.mockRequest,
        getTimezones: this.mockRequest,
        getCountries: this.mockRequest,
        getBusiness: this.mockRequest,
        getMessages: this.mockRequest,
        getBusinesses: this.mockRequest,
        getNetworks: this.mockRequest,
        getCustomFields: this.mockRequest,
        getSignupCustomFields: this.mockRequest,
        getTourCustomFields: this.mockRequest,
        getProfileTags: this.mockRequest,
        getTileAction: this.mockRequest,
      };
  
      this.Support = {
        getDepartments: this.mockRequest,
        getMessages: this.mockRequest,
        getMessage: this.mockRequest,
        submitReply: this.mockRequest,
        submitNewContactMessage: this.mockRequest,
        submitNewMessage: this.mockRequest,
        closeRequest: this.mockRequest,
      };
  
      this.Billing = {
        getPricePlanGroups: this.mockRequest,
        getPricePlans: this.mockRequest,
        getInvoices: this.mockRequest,
        getInvoicesPage: this.mockRequest,
        getBenefits: this.mockRequest,
        getTeamBenefits: this.mockRequest,
        getProducts: this.mockRequest,
        getStoreProducts: this.mockRequest,
        purchase: this.mockRequest,
        useExistingSpreedlyToken: this.mockRequest,
        getNonCancelledContracts: this.mockRequest,
        getActiveContracts: this.mockRequest,
        getProposalView: this.mockRequest,
        getSignaturePage: this.mockRequest,
        getAcceptProposal: this.mockRequest,
        getContract: this.mockRequest,
        saveNewContract: this.mockRequest,
        saveContract: this.mockRequest,
        cancelContract: this.mockRequest,
        getMetrics: this.mockRequest,
      };
  
      this.Bookings = {
        getUpcomingBookings: this.mockRequest,
        getTeamBookings: this.mockRequest,
        getBooking: this.mockRequest,
        getBookingPrice: this.mockRequest,
        saveBookingToUpdate: this.mockRequest,
        saveBookingToCreate: this.mockRequest,
        deleteBooking: this.mockRequest,
        getBookings: this.mockRequest,
        getResourceSearch: this.mockRequest,
        getResources: this.mockRequest,
        getResourceGroups: this.mockRequest,
        getMyVisitors: this.mockRequest,
        getPreviousVisitors: this.mockRequest,
        saveVisitorToCreate: this.mockRequest,
        deleteVisitor: this.mockRequest,
        getResourceProducts: this.mockRequest,
        getAvailabilityAt: this.mockRequest,
      };
  
      this.Directory = {
        getMembersDirectory: this.mockRequest,
        getTeamsDirectory: this.mockRequest,
        getSimpleSearch: this.mockRequest,
        getCustomerPage: this.mockRequest,
        getTeamPage: this.mockRequest,
        getCheckedInMetrics: this.mockRequest,
      };
  
      this.Deliveries = {
        getMyDeliveries: this.mockRequest,
      };
  
      this.Files = {
        getMyFiles: this.mockRequest,
        getFile: this.mockRequest,
      };
  
      this.Blog = {
        deleteComment: this.mockRequest,
        saveNewComment: this.mockRequest,
        getBannerBlogPosts: this.mockRequest,
        getBlogPostPage: this.mockRequest,
        getBlogPosts: this.mockRequest,
      };
  
      this.Courses = {
        getCoursesPage: this.mockRequest,
        getCoursePage: this.mockRequest,
        getMyCourses: this.mockRequest,
        getSignUp: this.mockRequest,
        getLessonsPage: this.mockRequest,
        getLessonPage: this.mockRequest,
        putCompleteLesson: this.mockRequest,
        saveNewComment: this.mockRequest,
        deleteComment: this.mockRequest,
        saveNewLessonComment: this.mockRequest,
        deleteLessonComment: this.mockRequest,
      };
  
      this.Community = {
        getVideoRooms: this.mockRequest,
        getAccessRoom: this.mockRequest,
        getAccessTeamRoom: this.mockRequest,
        getAccessMyRoom: this.mockRequest,
        followThread: this.mockRequest,
        unFollowThread: this.mockRequest,
        muteThread: this.mockRequest,
        unMuteThread: this.mockRequest,
        likeThread: this.mockRequest,
        unLikeThread: this.mockRequest,
        likeMessage: this.mockRequest,
        unLikeMessage: this.mockRequest,
        deleteMessage: this.mockRequest,
        deleteThread: this.mockRequest,
        getCommunityStartPage: this.mockRequest,
        getCommunityPage: this.mockRequest,
        getThreadPage: this.mockRequest,
        getThreads: this.mockRequest,
        saveNewCommunityBoardReply: this.mockRequest,
        saveSurveyPage: this.mockRequest,
        saveFormPagePage: this.mockRequest,
        saveNewCommunityBoardMessage: this.mockRequest,
        getSurveyPage: this.mockRequest,
        getSurveyPreviewPage: this.mockRequest,
        getFormPagePage: this.mockRequest,
        getFormPagePreviewPage: this.mockRequest,
      };
  
      this.Events = {
        getEventsPage: this.mockRequest,
        getEventPage: this.mockRequest,
        getEventsCalendar: this.mockRequest,
        getEventTicketsPage: this.mockRequest,
        getBannerEvents: this.mockRequest,
        getUpcomingEvents: this.mockRequest,
        getMyEvents: this.mockRequest,
        deleteComment: this.mockRequest,
        cancelTicket: this.mockRequest,
        sendTicket: this.mockRequest,
        saveNewComment: this.mockRequest,
      };
  
      this.Content = {
        getFaqArticles: this.mockRequest,
        getCommunityPerks: this.mockRequest,
      };
  
      this.Basket = {
        getItems: this.mockRequest,
        getInvoicePreview: this.mockRequest,
        getBasketInvoice: this.mockRequest,
        getPostItems: this.mockRequest,
      };
  
      this.FloorPlans = {
        getItems: this.mockRequest,
      };
    }
  
    mockRequest() {
      return new Promise((resolve) => {
        resolve({ body: 'mocked response' });
      });
    }
  }
  
  export default MockAgent;
  