import React from 'react';
import {Menu, MenuProps} from '../menu/Menu';
import {Dropdown, DropdownProps} from './Dropdown';

export interface DropdownMenuItem {
  text: string;
  href?: string;
  leftAccessory?: React.ReactElement;
  items?: DropdownMenuItem[];
}

export interface DropdownMenuProps
  extends Omit<DropdownProps, 'children'>,
    MenuProps {}

export const DropdownMenu: React.FC<DropdownMenuProps> = React.memo(
  ({items, ...rest}) => {
    return (
      <Dropdown {...rest}>
        <Menu items={items} />
      </Dropdown>
    );
  },
);
