//import config from 'env/config';
import {Maybe, ResponsiveImageFieldsFragment} from 'graphql/generated/graphql';
import Head from 'next/head';
import React, {useContext, useMemo} from 'react';
import {Image} from 'react-datocms';

export type ImageRendererConfig =
  | string
  | {
      aspectRatio: number;
      width: number;
      srcSet?: string;
      src: string;
      placeholderImageBlob?: string;
      alt?: Maybe<string>;
      lazyLoad?: boolean;
      shouldPreload?: boolean;
      sizes?: string;
      title?: Maybe<string>;
    };

export type ImageRenderer = (
  config: ImageRendererConfig,
  lazyLoad?: boolean,
) => React.ReactNode;

export const renderDatoImage: ImageRenderer = (config, lazyLoad) => {
  if (!config) return null;
  return typeof config === 'string' ? (
    <>
      <Image
        data={{
          aspectRatio: 1,
          width: 1,
          alt: config,
          src: config,
          title: config,
        }}
        style={{height: '100%'}}
        // lazyLoad
        intersectionThreshold={1}
      />
    </>
  ) : (
    <>
      {config.shouldPreload ? (
        <Head>
          <link
            rel="preload"
            as="image"
            href={config.src}
            {...{imagesrcset: config.srcSet, imagesizes: config.sizes}}
          />
        </Head>
      ) : null}
      <Image
        data={{
          aspectRatio: config.aspectRatio,
          width: config.width,
          alt: config.alt || config.src,
          base64: config.placeholderImageBlob,
          src: config.src,
          webpSrcSet: config.srcSet,
          sizes: config.sizes,
        }}
        style={{height: '100%'}}
        lazyLoad={config.lazyLoad ?? lazyLoad}
        fadeInDuration={config.lazyLoad && lazyLoad ? undefined : 0}
      />
      {config.title != undefined && config.title != 'empty' ? <div className='image-caption'>{config.title || undefined}</div> : undefined}  
    </>
  );
};

export const renderHtmlImgImage: ImageRenderer = (config, lazyLoad = true) =>
  typeof config === 'string' ? (
    <img
      src={config}
      alt={config}
      title={config}
      loading={lazyLoad ? 'lazy' : 'eager'}
      style={{width: '100%', height: '100%', objectFit: 'contain'}}
    />
  ) : (
    <div
      style={{
        position: 'relative',
        backgroundImage: config.placeholderImageBlob
          ? `url(${config.placeholderImageBlob})`
          : 'none',
        backgroundSize: 'cover',
      }}
    >
      <div style={{paddingTop: `${100.0 / config.aspectRatio}%`}}>
        <picture style={{position: 'absolute', left: 0, top: 0, width: '100%'}}>
          {config.srcSet ? (
            <source srcSet={config.srcSet} type="image/webp" />
          ) : null}
          <img
            src={config.src}
            alt={config.alt || config.src}
            title={config.title || undefined}
            loading={lazyLoad || config.lazyLoad ? 'lazy' : 'eager'}
            style={{width: '100%', height: '100%', objectFit: 'cover'}}
          />
        </picture>
      </div>
    </div>
  );

export const transformDatoResponsiveImageFragment = (
  {
    responsiveImage,
    url,
  }: {
    responsiveImage?: ResponsiveImageFieldsFragment | null;
    url: string;
  },
  lazyLoad = true,
): ImageRendererConfig => {
  return responsiveImage
    ? {
        aspectRatio: responsiveImage.aspectRatio,
        src: responsiveImage.src,
        srcSet: responsiveImage.webpSrcSet,
        width: responsiveImage.width,
        alt: responsiveImage.alt || responsiveImage.src,
        title: responsiveImage.title || undefined,
        placeholderImageBlob: responsiveImage.base64 || undefined,
        lazyLoad,
        sizes: responsiveImage.sizes,
      }
    : url;
};

interface ImageRendererContextValue {
  renderImage: ImageRenderer;
}

const ImageRendererContext = React.createContext<ImageRendererContextValue | null>(
  null,
);

interface ImageRendererProviderProps {
  renderImage: ImageRenderer;
  children: React.ReactNode;
}

const ImageRendererProvider = React.memo(
  ({renderImage, children}: ImageRendererProviderProps) => (
    <ImageRendererContext.Provider value={{renderImage}}>
      {children}
    </ImageRendererContext.Provider>
  ),
);

export interface DatoImageRendererProviderProps {
  children: React.ReactNode;
}

export const DatoImageRendererProvider = React.memo(
  (props: DatoImageRendererProviderProps) => (
    <>
      <Head>
        <link rel="preconnect" href="https://www.datocms-assets.com" />
        <link rel="dns-prefetch" href="https://www.datocms-assets.com" />
      </Head>
      <ImageRendererProvider {...props} renderImage={renderDatoImage} />
    </>
  ),
);

export interface StorybookImageRendererProviderProps {
  children: React.ReactNode;
}

export const StorybookImageRendererProvider = React.memo(
  (props: StorybookImageRendererProviderProps) => (
    <ImageRendererProvider {...props} renderImage={renderHtmlImgImage} />
  ),
);

export const useImageRenderer = (): ImageRendererContextValue => {
  const context = useContext(ImageRendererContext);

  if (!context) throw new Error('Missing ImageRenderer Context');

  return context;
};

export interface OptimizedImageProps {
  data: ImageRendererConfig;
  lazyLoad?: boolean;
  shouldPreload?: boolean;
}

export const OptimizedImage = React.memo(
  ({data, lazyLoad = true, shouldPreload}: OptimizedImageProps) => {
    const {renderImage} = useImageRenderer();

    const renderedImage = useMemo(
      () =>
        renderImage(
          typeof data === 'string' ? data : {...data, shouldPreload},
          lazyLoad,
        ),
      [data, renderImage, lazyLoad, shouldPreload],
    );

    return <div className="rwe-image">{renderedImage}</div>;
  },
);
