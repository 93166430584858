import { observable, action, reaction, toJS } from 'mobx';
import { loadDataHelper } from './storeHelpers';
import { validationContext } from 'validx';
import { supportMessageReplyValidationSchema } from './validationSchemas/supportMessageReplyValidationSchema';
import { supportMessageValidationSchema } from './validationSchemas/supportMessageValidationSchema';
import { contactMessageValidationSchema } from './validationSchemas/contactMessageValidationSchema';

import StoreBase from './storeBase';
const replyValidation = validationContext();
const messageValidation = validationContext();
const contactMessageValidation = validationContext();
class SupportStore extends StoreBase {
  constructor({ data, host, cookieString } = {}) {
    super({ data, host, cookieString });
    reaction(
      () => toJS(this.reply),
      (reply) => {
        this.replyValidation = replyValidation
          .reset()
          .validate(reply, supportMessageReplyValidationSchema(reply));
      }
    );

    reaction(
      () => toJS(this.newMessage),
      (newMessage) => {
        this.messageValidation = messageValidation
          .reset()
          .validate(newMessage, supportMessageValidationSchema(newMessage));
      }
    );

    reaction(
      () => toJS(this.newContactMessage),
      (newContactMessage) => {
        if(this.isSubmittingNewContactMessage) return;
        
        this.newContactMessageValidation = contactMessageValidation
          .reset()
          .validate(
            newContactMessage,
            contactMessageValidationSchema(newContactMessage)
          );
      }
    );
  }

  @observable newContactMessageValidation = { errors: [] };
  @observable newContactMessage = {};

  @observable isSubmittingNewContactMessage = false;
  @action submitNewContactMessage({
    recaptcha,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_term,
  }) {
    this.isSubmittingNewContactMessage = true;
    return this.getAgent()
      .Support.submitNewContactMessage({
        recaptcha,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term,
        ...this.newContactMessage,
      })
      .then(
        action((data) => {
          if (data && data.ErrorMessage) {
            var err = new Error();
            err.data = data;
            throw err;
          }
          this.newContactMessage = {};
          return data;
        })
      )
      .finally(
        action(() => {
          this.isSubmittingNewContactMessage = false;
        })
      );
  }

  @observable newMessage = {};
  @action createNewMessage() {
    this.newMessage = {};
  }

  @observable isSubmittingNewMessage = false;
  @action submitNewMessage() {
    this.isSubmittingNewMessage = true;
    return this.getAgent()
      .Support.submitNewMessage(this.newMessage)
      .then((data) => {
        if (data && data.ErrorMessage) {
          var err = new Error();
          err.data = data;
          throw err;
        }
        return data;
      })
      .finally(
        action(() => {
          this.isSubmittingNewMessage = false;
        })
      );
  }

  @observable reply = {};
  @observable replyValidation = { errors: [] };
  @observable messageValidation = { errors: [] };

  @observable isLoadingDepartments = false;
  @observable hasLoadedDepartments = false;
  @observable departments = [];
  @action loadDepartments() {
    return loadDataHelper({
      store: this,
      agentKey: 'Support',
      key: 'Departments',
      path: 'Departments',
    });
  }

  @observable isLoadingMessages = false;
  @observable hasLoadedMessages = false;
  @observable messages = [];
  @action loadMessages() {
    return loadDataHelper({
      store: this,
      agentKey: 'Support',
      key: 'Messages',
      path: 'Messages',
    });
  }

  @observable isLoadingMessage = false;
  @observable hasLoadedMessage = false;
  @observable message = null;
  @action loadMessage(id) {
    return loadDataHelper({
      store: this,
      agentKey: 'Support',
      key: 'Message',
      params: id,
    });
  }

  @observable isClosingRequest = false;
  @action closeRequest(id) {
    this.isClosingRequest = true;
    return this.getAgent()
      .Support.closeRequest(id)
      .then((data) => {
        if (data && data.ErrorMessage) {
          var err = new Error();
          err.data = data;
          throw err;
        }
        return data;
      })
      .finally(
        action(() => {
          this.isClosingRequest = false;
        })
      );
  }

  @observable isSubmittingReply = false;
  @action submitReply() {
    this.isSubmittingReply = true;
    this.reply.HelpDeskMessage = this.message.Message.Id;
    return this.getAgent()
      .Support.submitReply(this.reply)
      .then((data) => {
        if (data && data.ErrorMessage) {
          var err = new Error();
          err.data = data;
          throw err;
        }
        action(() => {
          this.reply.MessageText = null;
        });
        return data;
      })
      .finally(
        action(() => {
          this.isSubmittingReply = false;
        })
      );
  }
}
export default SupportStore;
