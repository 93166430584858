import { required, pattern } from 'validx';
import global from 'global';

export const communityMessageReplyValidationSchema = () => {
  const t = global.t;

  return {
    Message: [required({ msg: t('Message is required') })],
  };
};
