import { observable, action, toJS, reaction, computed, trace } from 'mobx';
import { loadDataHelper, saveDateHelper } from './storeHelpers';
import { validationContext } from 'validx';
import { customerValidationSchema } from './validationSchemas/customerValidationSchema';
import _ from 'lodash';
import Cookies from 'universal-cookie';
import { reactionWithOldValue } from 'env/utils/reactionWithOldValue';
import StoreBase from './storeBase';
import { generatePassword } from 'env/utils/password';
import Agent from 'env/agent';
import { addTagManagerEvent } from 'env/TagManager';
import { getStores } from './stores';

export const TOKEN_NAME = 'nexudus.auth.public.v4.token';
export const IMPERSONATION_TOKEN_NAME = 'nexudus.auth.public.v4.imp.token';
export const REFRESH_TOKEN_NAME = 'nexudus.auth.public.v4.token.refresh';
export const LOCATION_CHOICE_COOKIE_NAME = 'runwayeast.nexudus.currentLocationId';
const validation = validationContext();

class AuthStore extends StoreBase {
  @observable registrableDomain = null;
  @observable accessToken = null;
  @observable refreshToken = null;
  @observable me = null;
  @observable customer = null;
  @observable customFields = [];
  @observable teams = [];
  @observable payingMember = [];
  @observable team = null;
  
  @observable customerValidation = { errors: {} };
  
  @observable isLoggingIn = false;
  @observable isSavingCustomerWithUser = false;
  @observable isSavingCustomer = false;
  @observable isLoadingCustomer = false;
  @observable isLoadingPayingMember = false;
  @observable isLoadingTeams = false;
  @observable hasLoadedCustomFields = false;
  @observable hasLoadedCustomer = false;
  @observable isLoadingMe = false;
  @observable hasLoadedMe = false;
  @observable hasLoadedTeams = false;
  @observable hasLoadedPayingMember = false;
  
  constructor({ data, host, cookieString } = {}) {
    super({ data, host, cookieString });
    this.cookies = new Cookies(cookieString);
    this.accessToken = this.cookies.get(TOKEN_NAME);
    this.impersonationToken = this.cookies.get(IMPERSONATION_TOKEN_NAME);
    this.refreshToken = this.cookies.get(REFRESH_TOKEN_NAME);

    const updateToken = (tokenName, tokenValue) => {
      if (tokenValue) {
        this.cookies.set(tokenName, tokenValue, {
          secure: true,
          sameSite: 'none',
          domain:
            process.env.NODE_ENV === 'production'
              ? this.registrableDomain
              : null,
          path: '/',
        });
      } else {
        this.cookies.remove(tokenName, {
          domain:
            process.env.NODE_ENV === 'production'
              ? this.registrableDomain
              : null,
          path: '/',
        });
      }
    };

    reaction(
      () => this.accessToken,
      (accessToken) => updateToken(TOKEN_NAME, accessToken)
    );

    reaction(
      () => this.impersonationToken,
      (impersonationToken) =>
        updateToken(IMPERSONATION_TOKEN_NAME, impersonationToken)
    );

    reaction(
      () => this.refreshToken,
      (refreshToken) => updateToken(REFRESH_TOKEN_NAME, refreshToken)
    );

    reactionWithOldValue(
      () => toJS(this.customer),
      (customer, oldCustomer) => {
        if (
          customer &&
          oldCustomer &&
          customer.FullName &&
          customer.FullName != oldCustomer.FullName
        ) {
          this.customer.Salutation = customer.FullName.split(' ')[0];
        }
      }
    );

    reactionWithOldValue(
      () => toJS(this.customer),
      (customer, oldCustomer) => {
        if (oldCustomer) {
          this.customerValidation = validation
            .reset()
            .validate(customer, customerValidationSchema(customer));
        }
      }
    );
  }

  @observable impersonationToken = null;
  @action setImpersonationToken(token) {
    this.impersonationToken = token;
  }

  @action setRegistrableDomain(registrableDomain) {
    this.registrableDomain = registrableDomain;
  }

  @action validateCustomer() {
    this.customerValidation = validation
      .reset()
      .validate(this.customer, customerValidationSchema(this.customer));
  }

  @observable isGettingTeamMemberAccessToken = false;
  @action getTeamMemberAccessToken(coworkerId) {
    this.isGettingTeamMemberAccessToken = true;
    return this.getAgent()
      .Auth.getTeamMemberAccessToken(coworkerId)
      .then(
        action((data) => {
          this.isGettingTeamMemberAccessToken = false;
          return data;
        })
      );
  }

  @observable isLoadingRestore = false;
  @action restore(userGuid) {
    this.isLoadingRestore = true;
    return this.getAgent()
      .Auth.restore(userGuid)
      .then(
        action((data) => {
          this.isLoadingRestore = false;
          return data;
        })
      );
  }

  @observable isLoadingResetPassword = false;
  @observable hasLoadedResetPassword = false;
  @observable resetPassword = null;
  @action loadResetPassword(email, businessId) {
    return loadDataHelper({
      store: this,
      agentKey: 'Auth',
      key: 'ResetPassword',
      params: { email, businessId },
    });
  }

  @observable isLoadingSignupPage = false;
  @observable hasLoadedSignupPage = false;
  @observable signupPage = [];
  @action loadSignupPage({ t, team_guid, tariff_guid }) {
    return loadDataHelper({
      store: this,
      agentKey: 'Auth',
      key: 'SignupPage',
      params: { t, teamGuid: team_guid, tariffGuid: tariff_guid },
    });
  }

  @observable isLoadingTour = false;
  @action tour() {
    this.isLoadingTour = true;
    return this.getAgent()
      .Auth.tour()
      .then(
        action((data) => {
          this.isLoadingTour = false;
          return data;
        })
      );
  }

  @observable userAlreadyExists = false;
  @action exists(email) {
    if (!email) this.userAlreadyExists = false;
    return this.getAgent()
      .Auth.exists(email)
      .then(
        action((data) => {
          this.userAlreadyExists = data;
          return data;
        })
      );
  }

  @action logout() {
    this.accessToken = null;
    this.refreshToken = null;
    this.impersonationToken = null;

    this.cookies.remove(LOCATION_CHOICE_COOKIE_NAME, {
      domain: this.registrableDomain,
      path: '/',
    });

    this.getAgent()
      .requests.get('/login/logout')
      .catch(() => null);

    //We use window.location to force a browser reload
    //This ensures we clear all mobx states after a user logs out.
    setTimeout(() => window.location.reload(true), 500);
  }

  @action exchange(token) {
    this.isLoggingIn = true;
    return this.getAgent()
      .Auth.exchange(token)
      .then(
        action((tokenResponse) => {
          if (tokenResponse.token) {
            this.accessToken = tokenResponse.token;
          }
          this.isLoggingIn = false;
          return tokenResponse;
        })
      );
  }

  @action setPassword(email, token, password) {
    this.isLoggingIn = true;
    return this.getAgent()
      .Auth.setPassword(token, password)
      .then(
        action((res) => {
          this.isLoggingIn = false;
          return res;
        })
      );
  }

  @action login(email, password, totp) {
    this.isLoggingIn = true;
    return this.getAgent()
      .Auth.login(email, password, totp)
      .then(
        action((data) => {
          this.accessToken = data.access_token;
          this.refreshToken = data.refresh_token;
          return data;
        })
      )
      .finally(
        action((data) => {
          this.isLoggingIn = false;
          return data;
        })
      );
  }

  @action refreshAccessToken() {
    return this.getAgent()
      .Auth.login()
      .then(
        action(({ access_token, refresh_token }) => {
          this.accessToken = access_token;
          this.refreshToken = refresh_token;
        })
      );
  }

  @action loadMe() {
    return loadDataHelper({
      store: this,
      agentKey: 'Auth',
      key: 'Me',
    })
      .then((me) => {
        if (!me) {
          this.refreshToken = null;
          this.accessToken = null;
          this.me = null;
          this.hasLoadedMe = false;
        }
        return me;
      })
      .catch(
        action((err) => {
          if (err) {
            this.refreshToken = null;
            this.accessToken = null;
            this.me = null;
            this.hasLoadedMe = false;
          }
          return null;
        })
      );
  }

  @action createCustomer({ business, newsletter, tourDate, createPassword }) {
    addTagManagerEvent({
      name: 'nexudus:signup:new',
      customer: getStores().authStore.customer,
    });

    var newPassword = createPassword ? generatePassword() : null;
    this.customer = {
      Password: newPassword,
      PasswordConfirm: newPassword,
      SignUpToNewsletter: newsletter,
      ProfileTags: '',
      ProfileTagsList: [],
      InvoicingSpaceName: business.Name,
      InvoicingBusinessId: business.Id,
      GeneralTermsAcceptedOnline: false,
      SimpleTimeZoneId: business.SimpleTimeZone.Id,
      CountryId: business.Country.Id,
      BillingCountryId: business.Country.Id,
      InvoicingBusiness: { Id: business.Id },
      SimpleTimeZone: { Id: business.SimpleTimeZone.Id },
      Country: { Id: business.Country.Id },
      TourDate: tourDate,
    };

    this.me = {
      FullName: this.customer.FullName,
    };
  }
  @action loadCustomer() {
    return loadDataHelper({
      store: this,
      agentKey: 'Auth',
      key: 'Customer',
    });
  }

  @action loadPayingMember() {
    return loadDataHelper({
      store: this,
      agentKey: 'Auth',
      key: 'PayingMember',
    });
  }

  @observable isLoadingProfilePage = false;
  @observable hasLoadedProfilePage = false;
  @observable profilePage = {};
  @action loadProfilePage() {
    return loadDataHelper({
      store: this,
      agentKey: 'Auth',
      key: 'ProfilePage',
    });
  }

  @action loadCustomFields() {
    return loadDataHelper({
      store: this,
      agentKey: 'Business',
      key: 'CustomFields',
      path: 'CustomFields',
    });
  }

  @observable isLoadingSignupCustomFields = false;
  @observable hasLoadedSignupCustomFields = false;
  @observable signupCustomFields = [];
  @action loadSignupCustomFields({ signupToken, team_guid, tariff_guid } = {}) {
    return loadDataHelper({
      store: this,
      agentKey: 'Business',
      key: 'SignupCustomFields',
      path: 'CustomFields',
      params: {
        t: signupToken,
        teamGuid: team_guid,
        tariffGuid: tariff_guid,
      },
    });
  }

  @observable isLoadingTourCustomFields = false;
  @observable hasLoadedTourCustomFields = false;
  @observable tourCustomFields = [];
  @action loadTourCustomFields() {
    return loadDataHelper({
      store: this,
      agentKey: 'Business',
      key: 'TourCustomFields',
      path: 'CustomFields',
    });
  }

  @action loadTeams() {
    return loadDataHelper({
      store: this,
      agentKey: 'Auth',
      key: 'Teams',
    });
  }

  @observable isLoadingTeamProfiles = false;
  @observable hasLoadedTeamProfiles = false;
  @observable teamProfiles = [];
  @action loadTeamProfiles() {
    return loadDataHelper({
      store: this,
      agentKey: 'Auth',
      key: 'TeamProfiles',
    });
  }

  @observable TeamProfileValidation = null;
  @observable isSavingTeamProfile = false;

  @action saveTeamProfile(team) {
    return saveDateHelper({
      store: this,
      agentKey: 'Auth',
      key: `TeamProfile`,
      data: team,
    });
  }

  @computed get isLoggedIn() {
    return this.customer?.Id > 0;
  }

  @computed get signupCustomFieldsGroups() {
    let signupCustomFields = _(this.signupCustomFields)
      .groupBy('GroupName')
      .map((fields, group) => {
        return {
          group,
          fields,
        };
      })
      .value();

    return signupCustomFields;
  }

  @computed get customFieldsGroups() {
    let customFieldsGroups = _(this.customFields)
      .groupBy('GroupName')
      .map((fields, group) => {
        return {
          group,
          fields,
        };
      })
      .value();

    return customFieldsGroups;
  }

  @action saveCustomer({
    createUser = true,
    tariffGuid,
    teamGuid,
    recaptcha,
    returnUrl,
    refererGuid,
    discountCode,
    quickForm,
    isEvent,
    isTour,
    askBuyerAddress,
    signupToken,
    proposalId,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_term,
  } = {}) {
    this.customer.isTour = isTour;
    this.customer.quickForm = quickForm;
    this.customer.askBuyerAddress = askBuyerAddress;
    this.customer.isEvent = isEvent;

    this.customer.BillingCountry = {
      Id: this.customer.BillingCountryId,
    };
    this.customer.Country = {
      Id: this.customer.CountryId,
    };
    this.customer.SimpleTimeZone = {
      Id: this.customer.SimpleTimeZoneId,
    };
    this.customer.InvoicingBusiness = {
      Id: this.customer.InvoicingBusinessId,
    };
    this.customer.ProfileTags = this.customer.ProfileTagsList.join(',');
    this.customer.UpdateSocialNetworks = true;
    this.customer.UpdateBillingDetails = true;

    this.customerWithUser = {
      createUser: createUser,
      base64avatar: this.customer.base64avatar,
      base64banner: this.customer.base64banner,
      Coworker: { ...this.customer, refererGuid, discountCode },
      Team: this.team,
      TeamGuid: teamGuid,
      TariffGuid: tariffGuid,
      User: {
        ...this.me,
        NewPassword: this.customer.NewPassword,
        RepeatNewPassword: this.customer.NewPassword,
        OldPassword: this.customer.OldPassword,
      },
    };

    //The public API expects local times
    if (this.customerWithUser.Coworker.TourDate) {
      const tourDate = new Date(this.customerWithUser.Coworker.TourDate);
      const tourDateHours = tourDate.getHours();
      const tourDateOffset = tourDate.getTimezoneOffset() / 60.0;
      tourDate.setHours(tourDateHours - tourDateOffset);

      this.customerWithUser.Coworker.TourDate = tourDate;
    }

    return (
      saveDateHelper({
        store: this,
        agentKey: 'Auth',
        key: 'CustomerWithUser',
        validation: validation,
        validationSchema: customerValidationSchema(this.customer),
        modelToValidate: this.customer,
        data: {
          ...this.customerWithUser,
          t: signupToken,
          recaptcha,
          utm_source,
          utm_medium,
          utm_campaign,
          utm_content,
          utm_term,
          returnUrl,
          proposalId,
        },
      })
        .then((data) => {
          if (data?.RedirectTo?.indexOf('/signup/restore') > -1) {
            const err = new Error();
            err.data = data;
            throw err;
          }
          return data;
        })

        .then(
          action(async (data) => {
            this.isSavingCustomerWithUser = true;

            if (createUser && this.customer.Password)
              await this.login(this.customer.Email, this.customer.Password);

            return data;
          })
        )
        .then(async (data) => {
          //Sign-ups with default plans
          if (data?.RedirectTo?.indexOf('NewContract') > -1) {
            await this.getAgent().requests.get(data.RedirectTo.replace('/en', ''));
            const err = new Error();
            err.data = 'NewContract';
            throw err;
          }
        })
        //reload the customer after saving it
        .then(action(() => createUser && this.loadCustomer()))
        .then(action(() => createUser && this.loadMe()))
        .then(
          action(() => {
            this.isSavingCustomerWithUser = false;
            this.customer.OldPassword = null;
            this.customer.NewPassword = null;
            this.customer.Password = null;
            this.customer.PasswordConfirm = null;
            this.customer.RepeatNewPassword = null;
            this.customer.DeleteAvatar = false;
            this.customer.DeleteBanner = false;
            this.customer.base64avatar = null;
            this.customer.base64banner = null;
            return this.customer;
          })
        )
    );
  }
}

export default AuthStore;
