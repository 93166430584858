import React from 'react';
import {RunwayEastLink} from '../link/RunwayEastLink';
import {Menu} from './Menu';

export interface MenuItemProps {
  href?: string;
  leftAccessory?: React.ReactNode;
  text: string;
  items?: MenuItemProps[];
}

export const MenuItem = React.memo(
  ({href, text, items, leftAccessory}: MenuItemProps) =>
    items && items.length ? (
      <div key={text} className="rwe-dropdown-menu-item-group">
        {text ? (
          <div className="rwe-dropdown-menu-item-header">
            <RunwayEastLink href={href}>
              {leftAccessory ? (
                <div className="rwe-dropdown-menu-item-header-left-accessory-container">
                  {leftAccessory}
                </div>
              ) : null}
              <div className="rwe-caption">{text}</div>
            </RunwayEastLink>
          </div>
        ) : null}
        <Menu items={items} />
      </div>
    ) : (
      <div key={text} className="rwe-dropdown-menu-item">
        <RunwayEastLink href={href}>
          {leftAccessory ? (
            <div className="rwe-dropdown-menu-item-left-accessory-container">
              {leftAccessory}
            </div>
          ) : null}
          <div className="rwe-p1">{text}</div>
        </RunwayEastLink>
      </div>
    ),
);
