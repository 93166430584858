import React from 'react';
import {MediumNavbar, MediumNavbarProps} from './Navbar.md';
import {SmallNavbar, SmallNavbarProps} from './Navbar.sm';

export type ResponsiveNavbarProps = Omit<MediumNavbarProps, 'hideNavbar'> &
  Omit<SmallNavbarProps, 'hideNavbar'>;

export const ResponsiveNavbar: React.FC<ResponsiveNavbarProps> = React.memo(
  (props) => (
    <div className="rwe-responsive-navbar">
      <MediumNavbar {...props} />
      <SmallNavbar {...props} />
    </div>
  ),
);
