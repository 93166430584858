import React, {useMemo} from 'react';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
} from '@apollo/client';

import merge from 'deepmerge';
import isEqual from 'lodash.isequal';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

function createDatoCMSApolloClient(previewMode: boolean) {
  if (!process.env.DATOCMS_API_TOKEN)
    throw new Error('Missing DATOCMS_API_TOKEN from environment variables');

  const uri = `https://graphql.datocms.com${previewMode ? '/preview' : ''}`;

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: new HttpLink({
      uri,
      headers: {
        Authorization: `Bearer ${process.env.DATOCMS_API_TOKEN}`,
        // "X-Environment": "development"
      },
    }),
    cache: new InMemoryCache(),
  });
}

let sharedApolloClient: ReturnType<typeof createDatoCMSApolloClient>;

export function initializeApollo(
  initialState: any = null,
  previewMode: boolean,
): typeof sharedApolloClient {
  // eslint-disable-next-line no-underscore-dangle
  const _apolloClient =
    sharedApolloClient ?? createDatoCMSApolloClient(previewMode);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!sharedApolloClient) sharedApolloClient = _apolloClient;

  return _apolloClient;
}

export function getApolloStateProps(
  client: ApolloClient<any>,
): {[APOLLO_STATE_PROP_NAME]: any} {
  return {
    [APOLLO_STATE_PROP_NAME]: client.cache.extract(),
  };
}

interface PageProps {
  [APOLLO_STATE_PROP_NAME]: Record<string, Record<string, unknown>>;
}

export function useApollo(
  pageProps: PageProps,
  previewMode: boolean,
): ReturnType<typeof initializeApollo> {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  // eslint-disable-next-line no-underscore-dangle
  const store = useMemo(() => initializeApollo(state, previewMode), [
    previewMode,
    state,
  ]);
  return store;
}

interface DatoApolloProviderProps {
  pageProps: PageProps;
  previewMode: boolean;
  children: React.ReactNode;
}

export const DatoApolloProvider: React.FC<DatoApolloProviderProps> = ({
  pageProps,
  previewMode,
  ...rest
}) => {
  const apolloClient = useApollo(pageProps, previewMode);

  return <ApolloProvider {...rest} client={apolloClient} />;
};
