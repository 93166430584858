import { required, pattern } from 'validx';
import global from 'global';

export const contactMessageValidationSchema = () => {
  const t = global.t;

  return {
    // The schema maps to the fields we are validating.
    FromName: [
      pattern({
        pattern: 'email',
        msg: t('This email address does not seem to be valid.'),
      }),
      required({ msg: t('Email is required') }),
    ],
    Header: [required({ msg: t('Subject is required') })],
    Body: [required({ msg: t('Message is required') })],
  };
};
