import React, {useMemo} from 'react';
import {
  RunwayEastButton,
  RunwayEastButtonProps,
} from '../button/RunwayEastButton';
import {TextInput, TextInputProps} from './TextInput';

type SomeTextInputProps = Omit<TextInputProps, 'insetComponentOnRight'>;

export interface TextInputWithButtonOnRightProps extends SomeTextInputProps {
  buttonProps: RunwayEastButtonProps;
}

export const TextInputWithButtonOnRight = React.memo(
  ({buttonProps, ...rest}: TextInputWithButtonOnRightProps) => {
    const embeddedButtonFragment = useMemo(
      () => (
        <div className="rwe-input__embedded-button-on-right">
          <RunwayEastButton {...buttonProps} blockButton={false} />
        </div>
      ),
      [buttonProps],
    );
    return (
      <TextInput {...rest} insetComponentOnRight={embeddedButtonFragment} />
    );
  },
);
