import Head from 'next/head';
import {useRouter} from 'next/router';
import Script from 'next/script';
import React, {useEffect} from 'react';
import ReactComment from 'ui/components/ReactComment';

export interface GoogleTagManagerProps {
  id: string;
}

export const GoogleTagManager = React.memo(({id}: GoogleTagManagerProps) => {
  if (!id) throw new Error('Missing GTM id');

  const router = useRouter();

  useEffect(() => {
    const handleRouteChangeComplete = (url: string) => {
      if (!(window as any).dataLayer) return;

      (window as any).dataLayer.push({
        event: 'pageview',
        page: url,
      });
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <script
          id="GTM"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${id}');`,
          }}
        />
      </Head>

      <ReactComment text="Google Tag Manager (noscript)" />
      <noscript>
        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${id}`}
          height="0"
          width="0"
          style={{display: 'none', visibility: 'hidden'}}
        />
      </noscript>
      <ReactComment text="End Google Tag Manager (noscript)" />
    </>
  );
});
