import { required, pattern } from 'validx';
import global from 'global';

export const surveyValidationSchema = (question) => {
  const t = global.t;

  var validationObj = {};
  validationObj[`Custom${question.Id}`] = [
    required({
      msg: t('Please provide a response for {{questionText}}', {
        questionText: question.Text,
      }),
    }),
  ];

  return validationObj;
};
