import { required } from 'validx';
import global from 'global';

export const supportMessageValidationSchema = () => {
  const t = global.t;

  return {
    // The schema maps to the fields we are validating.
    Subject: [required({ msg: t('Subject is required') })],
    MessageText: [required({ msg: t('Message is required') })],
  };
};
