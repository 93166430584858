import { reaction } from 'mobx';
import { isEqual } from 'lodash';

export const reactionWithOldValue = (expression, effect, options) => {
  let oldValue;
  return reaction(
    expression,
    (v) => {
      if (!isEqual(v, oldValue)) {
        effect(v, oldValue);
        oldValue = v;
      }
    },
    options
  );
};
