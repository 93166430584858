import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Represents `true` or `false` values. */
  BooleanType: boolean;
  CustomData: any;
  /** A ISO 8601 compliant date value */
  Date: any;
  /** A ISO 8601 compliant datetime value */
  DateTime: any;
  /** Represents signed double-precision fractional values as specified by [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point). */
  FloatType: number;
  /** Represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1. */
  IntType: number;
  ItemId: any;
  JsonField: any;
  MetaTagAttributes: any;
  UploadId: any;
};

export type ArticleCategoryModelBreadcrumbField = ArticleCategoryRecord | ArticleRecord | BlogHomepageRecord | CustomPageRecord | LocationSiteRecord | SolutionTypeRecord;

export type ArticleCategoryModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  position?: Maybe<PositionFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  categoryColour?: Maybe<ColorFilter>;
  showBreadcrumb?: Maybe<BooleanFilter>;
  metaTags?: Maybe<SeoFilter>;
  seoStructuredData?: Maybe<JsonFilter>;
  breadcrumb?: Maybe<LinksFilter>;
  hideOnBlogHomepage?: Maybe<BooleanFilter>;
  categoryName?: Maybe<StringFilter>;
  pagePath?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<ArticleCategoryModelFilter>>>;
  AND?: Maybe<Array<Maybe<ArticleCategoryModelFilter>>>;
};

export enum ArticleCategoryModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  POSITION_ASC = 'position_ASC',
  POSITION_DESC = 'position_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  SHOW_BREADCRUMB_ASC = 'showBreadcrumb_ASC',
  SHOW_BREADCRUMB_DESC = 'showBreadcrumb_DESC',
  HIDE_ON_BLOG_HOMEPAGE_ASC = 'hideOnBlogHomepage_ASC',
  HIDE_ON_BLOG_HOMEPAGE_DESC = 'hideOnBlogHomepage_DESC',
  CATEGORY_NAME_ASC = 'categoryName_ASC',
  CATEGORY_NAME_DESC = 'categoryName_DESC',
  PAGE_PATH_ASC = 'pagePath_ASC',
  PAGE_PATH_DESC = 'pagePath_DESC'
}

/** Record of type Article Category (article_category) */
export type ArticleCategoryRecord = RecordInterface & {
  __typename?: 'ArticleCategoryRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  breadcrumb: Array<ArticleCategoryModelBreadcrumbField>;
  categoryColour?: Maybe<ColorField>;
  categoryName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  hideOnBlogHomepage?: Maybe<Scalars['BooleanType']>;
  id: Scalars['ItemId'];
  metaTags?: Maybe<SeoField>;
  pagePath?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['IntType']>;
  seoStructuredData?: Maybe<Scalars['JsonField']>;
  showBreadcrumb?: Maybe<Scalars['BooleanType']>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type Article Category (article_category) */
export type ArticleCategoryRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type ArticleModelBreadcrumbField = ArticleCategoryRecord | ArticleRecord | BlogHomepageRecord | CustomPageRecord | LocationSiteRecord | SolutionTypeRecord;

export type ArticleModelContentBlocksField = CustomHtmlBlockRecord | ECtaBlockRecord | FindASpaceButtonRecord | ImageBlockRecord | NewsletterSignupBlockRecord | QuoteBlockRecord;

export type ArticleModelContentField = {
  __typename?: 'ArticleModelContentField';
  blocks: Array<ArticleModelContentBlocksField>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

export type ArticleModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  seoStructuredData?: Maybe<JsonFilter>;
  image?: Maybe<FileFilter>;
  date?: Maybe<DateFilter>;
  hideOnBlogHomepage?: Maybe<BooleanFilter>;
  showBreadcrumb?: Maybe<BooleanFilter>;
  cardImage?: Maybe<FileFilter>;
  metaTags?: Maybe<SeoFilter>;
  breadcrumb?: Maybe<LinksFilter>;
  content?: Maybe<StructuredTextFilter>;
  pagePath?: Maybe<StringFilter>;
  cardByline?: Maybe<StringFilter>;
  reference?: Maybe<StringFilter>;
  cardTitle?: Maybe<StringFilter>;
  author?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  cardExcerpt?: Maybe<StringFilter>;
  categories?: Maybe<LinksFilter>;
  OR?: Maybe<Array<Maybe<ArticleModelFilter>>>;
  AND?: Maybe<Array<Maybe<ArticleModelFilter>>>;
};

export enum ArticleModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  DATE_ASC = 'date_ASC',
  DATE_DESC = 'date_DESC',
  HIDE_ON_BLOG_HOMEPAGE_ASC = 'hideOnBlogHomepage_ASC',
  HIDE_ON_BLOG_HOMEPAGE_DESC = 'hideOnBlogHomepage_DESC',
  SHOW_BREADCRUMB_ASC = 'showBreadcrumb_ASC',
  SHOW_BREADCRUMB_DESC = 'showBreadcrumb_DESC',
  PAGE_PATH_ASC = 'pagePath_ASC',
  PAGE_PATH_DESC = 'pagePath_DESC',
  CARD_BYLINE_ASC = 'cardByline_ASC',
  CARD_BYLINE_DESC = 'cardByline_DESC',
  REFERENCE_ASC = 'reference_ASC',
  REFERENCE_DESC = 'reference_DESC',
  CARD_TITLE_ASC = 'cardTitle_ASC',
  CARD_TITLE_DESC = 'cardTitle_DESC',
  AUTHOR_ASC = 'author_ASC',
  AUTHOR_DESC = 'author_DESC',
  TITLE_ASC = 'title_ASC',
  TITLE_DESC = 'title_DESC',
  CARD_EXCERPT_ASC = 'cardExcerpt_ASC',
  CARD_EXCERPT_DESC = 'cardExcerpt_DESC'
}

/** Record of type Article (article) */
export type ArticleRecord = RecordInterface & {
  __typename?: 'ArticleRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  author?: Maybe<Scalars['String']>;
  breadcrumb: Array<ArticleModelBreadcrumbField>;
  cardByline?: Maybe<Scalars['String']>;
  cardExcerpt?: Maybe<Scalars['String']>;
  cardImage?: Maybe<FileField>;
  cardTitle?: Maybe<Scalars['String']>;
  categories: Array<ArticleCategoryRecord>;
  content?: Maybe<ArticleModelContentField>;
  createdAt: Scalars['DateTime'];
  date?: Maybe<Scalars['Date']>;
  hideOnBlogHomepage?: Maybe<Scalars['BooleanType']>;
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  metaTags?: Maybe<SeoField>;
  pagePath?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  seoStructuredData?: Maybe<Scalars['JsonField']>;
  showBreadcrumb?: Maybe<Scalars['BooleanType']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type Article (article) */
export type ArticleRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type BlogHomepageModelBreadcrumbField = ArticleCategoryRecord | ArticleRecord | BlogHomepageRecord | CustomPageRecord | LocationSiteRecord | SolutionTypeRecord;

/** Record of type Blog Homepage (blog_homepage) */
export type BlogHomepageRecord = RecordInterface & {
  __typename?: 'BlogHomepageRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  breadcrumb: Array<BlogHomepageModelBreadcrumbField>;
  categoryColour?: Maybe<ColorField>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  metaTags?: Maybe<SeoField>;
  pagePath?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  seoStructuredData?: Maybe<Scalars['JsonField']>;
  showBreadcrumb?: Maybe<Scalars['BooleanType']>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type Blog Homepage (blog_homepage) */
export type BlogHomepageRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Specifies how to filter Boolean fields */
export type BooleanFilter = {
  /** Search for records with an exact match */
  eq?: Maybe<Scalars['BooleanType']>;
};


export type CardGridModelCardsField = ArticleRecord | GenericCardRecord | LocationSiteRecord | MemberCardRecord | SolutionTypeRecord | WorkspaceRecord;

export type CardGridModelHeadingField = {
  __typename?: 'CardGridModelHeadingField';
  blocks: Array<Scalars['String']>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

/** Block of type Card Grid (card_grid) */
export type CardGridRecord = RecordInterface & {
  __typename?: 'CardGridRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColour?: Maybe<ColorField>;
  blockCardIdentify?: Maybe<Scalars['String']>;
  blockMenuName?: Maybe<Scalars['String']>;
  cards: Array<CardGridModelCardsField>;
  createdAt: Scalars['DateTime'];
  heading?: Maybe<CardGridModelHeadingField>;
  hideSeparatorLine?: Maybe<Scalars['BooleanType']>;
  id: Scalars['ItemId'];
  link?: Maybe<LinkRecord>;
  numberOfColumns?: Maybe<Scalars['String']>;
  onLaptop?: Maybe<Scalars['String']>;
  onMobile?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** Block of type Card Grid (card_grid) */
export type CardGridRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type CityModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  position?: Maybe<PositionFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  reference?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<CityModelFilter>>>;
  AND?: Maybe<Array<Maybe<CityModelFilter>>>;
};

export enum CityModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  POSITION_ASC = 'position_ASC',
  POSITION_DESC = 'position_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  REFERENCE_ASC = 'reference_ASC',
  REFERENCE_DESC = 'reference_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC'
}

/** Record of type City (city) */
export type CityRecord = RecordInterface & {
  __typename?: 'CityRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['IntType']>;
  reference?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type City (city) */
export type CityRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type CollectionMetadata = {
  __typename?: 'CollectionMetadata';
  count: Scalars['IntType'];
};

export enum ColorBucketType {
  RED = 'red',
  ORANGE = 'orange',
  PINK = 'pink',
  CYAN = 'cyan',
  PURPLE = 'purple',
  BLUE = 'blue',
  YELLOW = 'yellow',
  GREEN = 'green',
  BROWN = 'brown',
  GREY = 'grey',
  WHITE = 'white',
  BLACK = 'black'
}

export type ColorField = {
  __typename?: 'ColorField';
  alpha: Scalars['IntType'];
  blue: Scalars['IntType'];
  cssRgb: Scalars['String'];
  green: Scalars['IntType'];
  hex: Scalars['String'];
  red: Scalars['IntType'];
};

/** Specifies how to filter Color fields */
export type ColorFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars['BooleanType']>;
};

export type ContactEmbedModelAddressField = {
  __typename?: 'ContactEmbedModelAddressField';
  blocks: Array<Scalars['String']>;
  links: Array<Scalars['String']>;
  value: Scalars['JsonField'];
};

export type ContactEmbedModelHeadingField = {
  __typename?: 'ContactEmbedModelHeadingField';
  blocks: Array<Scalars['String']>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

/** Block of type Contact & Embed (contact_embed) */
export type ContactEmbedRecord = RecordInterface & {
  __typename?: 'ContactEmbedRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  address?: Maybe<ContactEmbedModelAddressField>;
  createdAt: Scalars['DateTime'];
  emailAddress?: Maybe<Scalars['String']>;
  embedLink?: Maybe<Scalars['String']>;
  heading?: Maybe<ContactEmbedModelHeadingField>;
  hideSeparatorLine?: Maybe<Scalars['BooleanType']>;
  id: Scalars['ItemId'];
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** Block of type Contact & Embed (contact_embed) */
export type ContactEmbedRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Specifies how to filter by creation datetime */
export type CreatedAtFilter = {
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: Maybe<Scalars['DateTime']>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: Maybe<Scalars['DateTime']>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: Maybe<Scalars['DateTime']>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: Maybe<Scalars['DateTime']>;
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: Maybe<Scalars['DateTime']>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: Maybe<Scalars['DateTime']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars['BooleanType']>;
};


/** Block of type Custom HTML Block (custom_html_block) */
export type CustomHtmlBlockRecord = RecordInterface & {
  __typename?: 'CustomHtmlBlockRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColour?: Maybe<ColorField>;
  blockCardIdentify?: Maybe<Scalars['String']>;
  blockMenuName?: Maybe<Scalars['String']>;
  containerWidth?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customHtml?: Maybe<Scalars['String']>;
  hideSeparatorLine?: Maybe<Scalars['BooleanType']>;
  id: Scalars['ItemId'];
  onLaptop?: Maybe<Scalars['String']>;
  onMobile?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** Block of type Custom HTML Block (custom_html_block) */
export type CustomHtmlBlockRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};


/** Block of type Custom HTML Block (custom_html_block) */
export type CustomHtmlBlockRecordCustomHtmlArgs = {
  markdown?: Maybe<Scalars['Boolean']>;
};

export type CustomPageModelBreadcrumbField = ArticleCategoryRecord | ArticleRecord | BlogHomepageRecord | CustomPageRecord | LocationSiteRecord | SolutionTypeRecord;

export type CustomPageModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  seoStructuredData?: Maybe<JsonFilter>;
  metaTags?: Maybe<SeoFilter>;
  showBreadcrumb?: Maybe<BooleanFilter>;
  breadcrumb?: Maybe<LinksFilter>;
  pageContent?: Maybe<LinksFilter>;
  pageName?: Maybe<StringFilter>;
  reference?: Maybe<StringFilter>;
  urlSlug?: Maybe<SlugFilter>;
  urlPath?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<CustomPageModelFilter>>>;
  AND?: Maybe<Array<Maybe<CustomPageModelFilter>>>;
};

export enum CustomPageModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  SHOW_BREADCRUMB_ASC = 'showBreadcrumb_ASC',
  SHOW_BREADCRUMB_DESC = 'showBreadcrumb_DESC',
  PAGE_NAME_ASC = 'pageName_ASC',
  PAGE_NAME_DESC = 'pageName_DESC',
  REFERENCE_ASC = 'reference_ASC',
  REFERENCE_DESC = 'reference_DESC',
  URL_PATH_ASC = 'urlPath_ASC',
  URL_PATH_DESC = 'urlPath_DESC'
}

/** Record of type Custom Page (custom_page) */
export type CustomPageRecord = RecordInterface & {
  __typename?: 'CustomPageRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  breadcrumb: Array<CustomPageModelBreadcrumbField>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  metaTags?: Maybe<SeoField>;
  pageContent: Array<PageContentRecord>;
  pageName?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  seoStructuredData?: Maybe<Scalars['JsonField']>;
  showBreadcrumb?: Maybe<Scalars['BooleanType']>;
  updatedAt: Scalars['DateTime'];
  urlPath?: Maybe<Scalars['String']>;
  urlSlug?: Maybe<Scalars['String']>;
};


/** Record of type Custom Page (custom_page) */
export type CustomPageRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};


/** Specifies how to filter Date fields */
export type DateFilter = {
  /** Filter records with a value that's strictly greater than the one specified */
  gt?: Maybe<Scalars['Date']>;
  /** Filter records with a value that's less than the one specified */
  lt?: Maybe<Scalars['Date']>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte?: Maybe<Scalars['Date']>;
  /** Filter records with a value that's less or equal than the one specified */
  lte?: Maybe<Scalars['Date']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars['BooleanType']>;
  /** Search for records with an exact match */
  eq?: Maybe<Scalars['Date']>;
  /** Exclude records with an exact match */
  neq?: Maybe<Scalars['Date']>;
};


export type ECtaBlockModelStructuredTextField = {
  __typename?: 'ECtaBlockModelStructuredTextField';
  blocks: Array<Scalars['String']>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

/** Block of type E CTA Block (e_cta_block) */
export type ECtaBlockRecord = RecordInterface & {
  __typename?: 'ECtaBlockRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColour?: Maybe<ColorField>;
  blockCardIdentify?: Maybe<Scalars['String']>;
  blockMenuName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  cta?: Maybe<LinkRecord>;
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  onLaptop?: Maybe<Scalars['String']>;
  onMobile?: Maybe<Scalars['String']>;
  structuredText?: Maybe<ECtaBlockModelStructuredTextField>;
  updatedAt: Scalars['DateTime'];
};


/** Block of type E CTA Block (e_cta_block) */
export type ECtaBlockRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type FaqModelAnswerField = {
  __typename?: 'FaqModelAnswerField';
  blocks: Array<Scalars['String']>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

export type FaqModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  question?: Maybe<StructuredTextFilter>;
  answer?: Maybe<StructuredTextFilter>;
  reference?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<FaqModelFilter>>>;
  AND?: Maybe<Array<Maybe<FaqModelFilter>>>;
};

export enum FaqModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  REFERENCE_ASC = 'reference_ASC',
  REFERENCE_DESC = 'reference_DESC'
}

export type FaqModelQuestionField = {
  __typename?: 'FaqModelQuestionField';
  blocks: Array<Scalars['String']>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

/** Record of type FAQ (faq) */
export type FaqRecord = RecordInterface & {
  __typename?: 'FaqRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  answer?: Maybe<FaqModelAnswerField>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  question?: Maybe<FaqModelQuestionField>;
  reference?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type FAQ (faq) */
export type FaqRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type FaqsBlockModelHeadingField = {
  __typename?: 'FaqsBlockModelHeadingField';
  blocks: Array<Scalars['String']>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

/** Block of type FAQs Block (faqs_block) */
export type FaqsBlockRecord = RecordInterface & {
  __typename?: 'FaqsBlockRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColor?: Maybe<ColorField>;
  blockCardIdentify?: Maybe<Scalars['String']>;
  blockMenuName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  faqs: Array<FaqRecord>;
  heading?: Maybe<FaqsBlockModelHeadingField>;
  hideSeparatorLine?: Maybe<Scalars['BooleanType']>;
  id: Scalars['ItemId'];
  onLaptop?: Maybe<Scalars['String']>;
  onMobile?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** Block of type FAQs Block (faqs_block) */
export type FaqsBlockRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export enum FaviconType {
  ICON = 'icon',
  APPLE_TOUCH_ICON = 'appleTouchIcon',
  MS_APPLICATION = 'msApplication'
}

export type FeatureBlockModelHeadingField = {
  __typename?: 'FeatureBlockModelHeadingField';
  blocks: Array<Scalars['String']>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

/** Block of type Feature Block (feature_block) */
export type FeatureBlockRecord = RecordInterface & {
  __typename?: 'FeatureBlockRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColor?: Maybe<ColorField>;
  blockCardIdentify?: Maybe<Scalars['String']>;
  blockMenuName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  cta?: Maybe<LinkRecord>;
  features: Array<FeatureRecord>;
  heading?: Maybe<FeatureBlockModelHeadingField>;
  hideSeparatorLine?: Maybe<Scalars['BooleanType']>;
  id: Scalars['ItemId'];
  onLaptop?: Maybe<Scalars['String']>;
  onMobile?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** Block of type Feature Block (feature_block) */
export type FeatureBlockRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type FeatureModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  backgroundColor?: Maybe<ColorFilter>;
  title?: Maybe<StringFilter>;
  reference?: Maybe<StringFilter>;
  icon?: Maybe<FileFilter>;
  OR?: Maybe<Array<Maybe<FeatureModelFilter>>>;
  AND?: Maybe<Array<Maybe<FeatureModelFilter>>>;
};

export enum FeatureModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  TITLE_ASC = 'title_ASC',
  TITLE_DESC = 'title_DESC',
  REFERENCE_ASC = 'reference_ASC',
  REFERENCE_DESC = 'reference_DESC'
}

/** Record of type Feature (feature) */
export type FeatureRecord = RecordInterface & {
  __typename?: 'FeatureRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColor?: Maybe<ColorField>;
  createdAt: Scalars['DateTime'];
  icon?: Maybe<FileField>;
  id: Scalars['ItemId'];
  reference?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type Feature (feature) */
export type FeatureRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type FileField = FileFieldInterface & {
  __typename?: 'FileField';
  _createdAt: Scalars['DateTime'];
  /** The DatoCMS URL where you can edit this entity. To use this field, you need to set a X-Base-Editing-Url header in the request */
  _editingUrl?: Maybe<Scalars['String']>;
  _updatedAt: Scalars['DateTime'];
  alt?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  basename: Scalars['String'];
  blurUpThumb?: Maybe<Scalars['String']>;
  blurhash?: Maybe<Scalars['String']>;
  colors: Array<ColorField>;
  copyright?: Maybe<Scalars['String']>;
  customData: Scalars['CustomData'];
  exifInfo: Scalars['CustomData'];
  filename: Scalars['String'];
  focalPoint?: Maybe<FocalPoint>;
  format: Scalars['String'];
  height?: Maybe<Scalars['IntType']>;
  id: Scalars['UploadId'];
  md5: Scalars['String'];
  mimeType: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  responsiveImage?: Maybe<ResponsiveImage>;
  size: Scalars['IntType'];
  smartTags: Array<Scalars['String']>;
  tags: Array<Scalars['String']>;
  thumbhash?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  video?: Maybe<UploadVideoField>;
  width?: Maybe<Scalars['IntType']>;
};


export type FileFieldAltArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


export type FileFieldBlurUpThumbArgs = {
  punch?: Scalars['Float'];
  size?: Scalars['Int'];
  quality?: Scalars['Int'];
  imgixParams?: Maybe<ImgixParams>;
};


export type FileFieldCustomDataArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


export type FileFieldFocalPointArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


export type FileFieldResponsiveImageArgs = {
  imgixParams?: Maybe<ImgixParams>;
  sizes?: Maybe<Scalars['String']>;
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


export type FileFieldTitleArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


export type FileFieldUrlArgs = {
  imgixParams?: Maybe<ImgixParams>;
};

export type FileFieldInterface = {
  _createdAt: Scalars['DateTime'];
  /** The DatoCMS URL where you can edit this entity. To use this field, you need to set a X-Base-Editing-Url header in the request */
  _editingUrl?: Maybe<Scalars['String']>;
  _updatedAt: Scalars['DateTime'];
  alt?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  basename: Scalars['String'];
  blurUpThumb?: Maybe<Scalars['String']>;
  blurhash?: Maybe<Scalars['String']>;
  colors: Array<ColorField>;
  copyright?: Maybe<Scalars['String']>;
  customData: Scalars['CustomData'];
  exifInfo: Scalars['CustomData'];
  filename: Scalars['String'];
  focalPoint?: Maybe<FocalPoint>;
  format: Scalars['String'];
  height?: Maybe<Scalars['IntType']>;
  id: Scalars['UploadId'];
  md5: Scalars['String'];
  mimeType: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  responsiveImage?: Maybe<ResponsiveImage>;
  size: Scalars['IntType'];
  smartTags: Array<Scalars['String']>;
  tags: Array<Scalars['String']>;
  thumbhash?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  video?: Maybe<UploadVideoField>;
  width?: Maybe<Scalars['IntType']>;
};


export type FileFieldInterfaceAltArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


export type FileFieldInterfaceBlurUpThumbArgs = {
  punch?: Scalars['Float'];
  size?: Scalars['Int'];
  quality?: Scalars['Int'];
  imgixParams?: Maybe<ImgixParams>;
};


export type FileFieldInterfaceCustomDataArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


export type FileFieldInterfaceFocalPointArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


export type FileFieldInterfaceResponsiveImageArgs = {
  imgixParams?: Maybe<ImgixParams>;
  sizes?: Maybe<Scalars['String']>;
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


export type FileFieldInterfaceTitleArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


export type FileFieldInterfaceUrlArgs = {
  imgixParams?: Maybe<ImgixParams>;
};

/** Specifies how to filter Single-file/image fields */
export type FileFilter = {
  /** Search for records with an exact match. The specified value must be an Upload ID */
  eq?: Maybe<Scalars['UploadId']>;
  /** Exclude records with an exact match. The specified value must be an Upload ID */
  neq?: Maybe<Scalars['UploadId']>;
  /** Filter records that have one of the specified uploads */
  in?: Maybe<Array<Maybe<Scalars['UploadId']>>>;
  /** Filter records that do not have one of the specified uploads */
  notIn?: Maybe<Array<Maybe<Scalars['UploadId']>>>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars['BooleanType']>;
};

/** Block of type Find A Space Button (find_a_space_button) */
export type FindASpaceButtonRecord = RecordInterface & {
  __typename?: 'FindASpaceButtonRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  updatedAt: Scalars['DateTime'];
};


/** Block of type Find A Space Button (find_a_space_button) */
export type FindASpaceButtonRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Specifies how to filter Floating-point fields */
export type FloatFilter = {
  /** Filter records with a value that's strictly greater than the one specified */
  gt?: Maybe<Scalars['FloatType']>;
  /** Filter records with a value that's less than the one specified */
  lt?: Maybe<Scalars['FloatType']>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte?: Maybe<Scalars['FloatType']>;
  /** Filter records with a value that's less or equal than the one specified */
  lte?: Maybe<Scalars['FloatType']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars['BooleanType']>;
  /** Search for records with an exact match */
  eq?: Maybe<Scalars['FloatType']>;
  /** Exclude records with an exact match */
  neq?: Maybe<Scalars['FloatType']>;
};


export type FloatingCtaModelBodyField = {
  __typename?: 'FloatingCtaModelBodyField';
  blocks: Array<Scalars['String']>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

export type FloatingCtaModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  links?: Maybe<LinksFilter>;
  body?: Maybe<StructuredTextFilter>;
  reference?: Maybe<StringFilter>;
  heading?: Maybe<StringFilter>;
  pricePrefix?: Maybe<StringFilter>;
  price?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<FloatingCtaModelFilter>>>;
  AND?: Maybe<Array<Maybe<FloatingCtaModelFilter>>>;
};

export enum FloatingCtaModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  REFERENCE_ASC = 'reference_ASC',
  REFERENCE_DESC = 'reference_DESC',
  HEADING_ASC = 'heading_ASC',
  HEADING_DESC = 'heading_DESC',
  PRICE_PREFIX_ASC = 'pricePrefix_ASC',
  PRICE_PREFIX_DESC = 'pricePrefix_DESC',
  PRICE_ASC = 'price_ASC',
  PRICE_DESC = 'price_DESC'
}

/** Record of type Floating CTA (floating_cta) */
export type FloatingCtaRecord = RecordInterface & {
  __typename?: 'FloatingCtaRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  body?: Maybe<FloatingCtaModelBodyField>;
  createdAt: Scalars['DateTime'];
  heading?: Maybe<Scalars['String']>;
  id: Scalars['ItemId'];
  links: Array<LinkRecord>;
  price?: Maybe<Scalars['String']>;
  pricePrefix?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type Floating CTA (floating_cta) */
export type FloatingCtaRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type FooterItemModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  parent?: Maybe<ParentFilter>;
  position?: Maybe<PositionFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  link?: Maybe<LinkFilter>;
  name?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<FooterItemModelFilter>>>;
  AND?: Maybe<Array<Maybe<FooterItemModelFilter>>>;
};

export type FooterItemModelLinkField = ArticleCategoryRecord | ArticleRecord | BlogHomepageRecord | CustomPageRecord | LocationSiteRecord | NexudusLinkRecord | SolutionTypeRecord | WorkspaceRecord;

export enum FooterItemModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  POSITION_ASC = 'position_ASC',
  POSITION_DESC = 'position_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC'
}

/** Record of type Footer Item (footer_item) */
export type FooterItemRecord = RecordInterface & {
  __typename?: 'FooterItemRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  children: Array<FooterItemRecord>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  link?: Maybe<FooterItemModelLinkField>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<FooterItemRecord>;
  position?: Maybe<Scalars['IntType']>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type Footer Item (footer_item) */
export type FooterItemRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Specifies how to filter Multiple files/images field */
export type GalleryFilter = {
  /** Search for records with an exact match. The specified values must be Upload IDs */
  eq?: Maybe<Array<Maybe<Scalars['UploadId']>>>;
  /** Filter records that have all of the specified uploads. The specified values must be Upload IDs */
  allIn?: Maybe<Array<Maybe<Scalars['UploadId']>>>;
  /** Filter records that have one of the specified uploads. The specified values must be Upload IDs */
  anyIn?: Maybe<Array<Maybe<Scalars['UploadId']>>>;
  /** Filter records that do not have any of the specified uploads. The specified values must be Upload IDs */
  notIn?: Maybe<Array<Maybe<Scalars['UploadId']>>>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars['BooleanType']>;
};

export type GenericCardModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  image?: Maybe<FileFilter>;
  link?: Maybe<LinkFilter>;
  subheading?: Maybe<StringFilter>;
  reference?: Maybe<StringFilter>;
  heading?: Maybe<StringFilter>;
  body?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<GenericCardModelFilter>>>;
  AND?: Maybe<Array<Maybe<GenericCardModelFilter>>>;
};

export enum GenericCardModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  SUBHEADING_ASC = 'subheading_ASC',
  SUBHEADING_DESC = 'subheading_DESC',
  REFERENCE_ASC = 'reference_ASC',
  REFERENCE_DESC = 'reference_DESC',
  HEADING_ASC = 'heading_ASC',
  HEADING_DESC = 'heading_DESC',
  BODY_ASC = 'body_ASC',
  BODY_DESC = 'body_DESC'
}

/** Record of type Generic Card (generic_card) */
export type GenericCardRecord = RecordInterface & {
  __typename?: 'GenericCardRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  body?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  heading?: Maybe<Scalars['String']>;
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  link?: Maybe<LinkRecord>;
  reference?: Maybe<Scalars['String']>;
  subheading?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type Generic Card (generic_card) */
export type GenericCardRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type GlobalSeoField = {
  __typename?: 'GlobalSeoField';
  facebookPageUrl?: Maybe<Scalars['String']>;
  fallbackSeo?: Maybe<SeoField>;
  siteName?: Maybe<Scalars['String']>;
  titleSuffix?: Maybe<Scalars['String']>;
  twitterAccount?: Maybe<Scalars['String']>;
};

/** Block of type Header Centre (header_centre) */
export type HeaderCentreRecord = RecordInterface & {
  __typename?: 'HeaderCentreRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColour?: Maybe<ColorField>;
  buttonLink?: Maybe<LinkRecord>;
  buttonType?: Maybe<Scalars['String']>;
  copy?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  heading?: Maybe<Scalars['String']>;
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  textBackgroundImage?: Maybe<FileField>;
  updatedAt: Scalars['DateTime'];
};


/** Block of type Header Centre (header_centre) */
export type HeaderCentreRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type HeaderTextAndImageModelHeadingField = {
  __typename?: 'HeaderTextAndImageModelHeadingField';
  blocks: Array<FindASpaceButtonRecord>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

export type HeaderTextAndImageModelLinkField = LinkRecord | NexudusLinkRecord;

/** Block of type Header Text and Image (header_text_and_image) */
export type HeaderTextAndImageRecord = RecordInterface & {
  __typename?: 'HeaderTextAndImageRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  heading?: Maybe<HeaderTextAndImageModelHeadingField>;
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  imageSide?: Maybe<Scalars['String']>;
  link?: Maybe<HeaderTextAndImageModelLinkField>;
  updatedAt: Scalars['DateTime'];
};


/** Block of type Header Text and Image (header_text_and_image) */
export type HeaderTextAndImageRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type HeaderTextLeftModelHeadingField = {
  __typename?: 'HeaderTextLeftModelHeadingField';
  blocks: Array<Scalars['String']>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

/** Block of type Header Text Left (header_text_left) */
export type HeaderTextLeftRecord = RecordInterface & {
  __typename?: 'HeaderTextLeftRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  heading?: Maybe<HeaderTextLeftModelHeadingField>;
  id: Scalars['ItemId'];
  updatedAt: Scalars['DateTime'];
};


/** Block of type Header Text Left (header_text_left) */
export type HeaderTextLeftRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type HeadingModelCopyField = {
  __typename?: 'HeadingModelCopyField';
  blocks: Array<Scalars['String']>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

export type HeadingModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  copy?: Maybe<StructuredTextFilter>;
  OR?: Maybe<Array<Maybe<HeadingModelFilter>>>;
  AND?: Maybe<Array<Maybe<HeadingModelFilter>>>;
};

export enum HeadingModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC'
}

/** Record of type Heading (heading) */
export type HeadingRecord = RecordInterface & {
  __typename?: 'HeadingRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  copy?: Maybe<HeadingModelCopyField>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  updatedAt: Scalars['DateTime'];
};


/** Record of type Heading (heading) */
export type HeadingRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type HighlightedTextBlockModelStructuredTextField = {
  __typename?: 'HighlightedTextBlockModelStructuredTextField';
  blocks: Array<Scalars['String']>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

/** Block of type Highlighted Text Block (highlighted_text_block) */
export type HighlightedTextBlockRecord = RecordInterface & {
  __typename?: 'HighlightedTextBlockRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColour?: Maybe<ColorField>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  structuredText?: Maybe<HighlightedTextBlockModelStructuredTextField>;
  updatedAt: Scalars['DateTime'];
};


/** Block of type Highlighted Text Block (highlighted_text_block) */
export type HighlightedTextBlockRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Block of type Icon Block (icon_block) */
export type IconBlockRecord = RecordInterface & {
  __typename?: 'IconBlockRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  icon?: Maybe<FileField>;
  id: Scalars['ItemId'];
  updatedAt: Scalars['DateTime'];
};


/** Block of type Icon Block (icon_block) */
export type IconBlockRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Block of type Image Block (image_block) */
export type ImageBlockRecord = RecordInterface & {
  __typename?: 'ImageBlockRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  caption?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  updatedAt: Scalars['DateTime'];
};


/** Block of type Image Block (image_block) */
export type ImageBlockRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type ImageCarouselModelModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  imageCarouselHouse?: Maybe<GalleryFilter>;
  OR?: Maybe<Array<Maybe<ImageCarouselModelModelFilter>>>;
  AND?: Maybe<Array<Maybe<ImageCarouselModelModelFilter>>>;
};

export enum ImageCarouselModelModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC'
}

/** Record of type image carousel model (image_carousel_model) */
export type ImageCarouselModelRecord = RecordInterface & {
  __typename?: 'ImageCarouselModelRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  imageCarouselHouse: Array<FileField>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type image carousel model (image_carousel_model) */
export type ImageCarouselModelRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type ImageModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  image?: Maybe<FileFilter>;
  reference?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<ImageModelFilter>>>;
  AND?: Maybe<Array<Maybe<ImageModelFilter>>>;
};

export enum ImageModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  REFERENCE_ASC = 'reference_ASC',
  REFERENCE_DESC = 'reference_DESC'
}

/** Record of type Image (image) */
export type ImageRecord = RecordInterface & {
  __typename?: 'ImageRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  reference?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type Image (image) */
export type ImageRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type ImgixParams = {
  /**
   * Aspect Ratio
   *
   * Specifies an aspect ratio to maintain when resizing and cropping the image
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/ar)
   */
  ar?: Maybe<Scalars['String']>;
  /**
   * Automatic
   *
   * Applies automatic enhancements to images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/auto)
   */
  auto?: Maybe<Array<ImgixParamsAuto>>;
  /**
   * Background Removal Fallback
   *
   * Overrides default fallback behavior for bg-remove failures.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-remove)
   */
  bgRemoveFallback?: Maybe<Scalars['BooleanType']>;
  /**
   * Background Removal
   *
   * Removes background from image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-remove)
   */
  bgRemove?: Maybe<Scalars['BooleanType']>;
  /**
   * Background Removal Fallback
   *
   * Overrides default fallback behavior for bg-replace failures.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace)
   */
  bgReplaceFallback?: Maybe<Scalars['BooleanType']>;
  /**
   * Background Replacement Negative Prompt
   *
   * Provides a negative text suggestion for background replacement.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace-neg-prompt)
   */
  bgReplaceNegPrompt?: Maybe<Scalars['String']>;
  /**
   * Background Replacement
   *
   * Replaces background from image using a string based prompt.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace)
   */
  bgReplace?: Maybe<Scalars['String']>;
  /**
   * Background Color
   *
   * Colors the background of padded and partially-transparent images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/bg)
   */
  bg?: Maybe<Scalars['String']>;
  /**
   * Blend Align
   *
   * Changes the blend alignment relative to the parent image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-align)
   */
  blendAlign?: Maybe<Array<ImgixParamsBlendAlign>>;
  /**
   * Blend Alpha
   *
   * Changes the alpha of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-alpha)
   */
  blendAlpha?: Maybe<Scalars['IntType']>;
  /**
   * Blend Color
   *
   * Specifies a color to use when applying the blend.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-color)
   */
  blendColor?: Maybe<Scalars['String']>;
  /**
   * Blend Crop
   *
   * Specifies the type of crop for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-crop)
   */
  blendCrop?: Maybe<Array<ImgixParamsBlendCrop>>;
  /**
   * Blend Fit
   *
   * Specifies the fit mode for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-fit)
   */
  blendFit?: Maybe<ImgixParamsBlendFit>;
  /**
   * Blend Height
   *
   * Adjusts the height of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-h)
   */
  blendH?: Maybe<Scalars['FloatType']>;
  /**
   * Blend Mode
   *
   * Sets the blend mode for a blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-mode)
   */
  blendMode?: Maybe<ImgixParamsBlendMode>;
  /**
   * Blend Padding
   *
   * Applies padding to the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-pad)
   */
  blendPad?: Maybe<Scalars['IntType']>;
  /**
   * Blend Size
   *
   * Adjusts the size of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-size)
   */
  blendSize?: Maybe<ImgixParamsBlendSize>;
  /**
   * Blend Width
   *
   * Adjusts the width of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-w)
   */
  blendW?: Maybe<Scalars['FloatType']>;
  /**
   * Blend X Position
   *
   * Adjusts the x-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-x)
   */
  blendX?: Maybe<Scalars['IntType']>;
  /**
   * Blend Y Position
   *
   * Adjusts the y-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-y)
   */
  blendY?: Maybe<Scalars['IntType']>;
  /**
   * Blend
   *
   * Specifies the location of the blend image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend)
   */
  blend?: Maybe<Scalars['String']>;
  /**
   * Gaussian Blur
   *
   * Applies a gaussian blur to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/blur)
   */
  blur?: Maybe<Scalars['IntType']>;
  /**
   * Border Bottom
   *
   * Sets bottom border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-bottom)
   */
  borderBottom?: Maybe<Scalars['IntType']>;
  /**
   * Border Left
   *
   * Sets left border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-left)
   */
  borderLeft?: Maybe<Scalars['IntType']>;
  /**
   * Inner Border Radius
   *
   * Sets the inner radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius-inner)
   */
  borderRadiusInner?: Maybe<Scalars['String']>;
  /**
   * Outer Border Radius
   *
   * Sets the outer radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius)
   */
  borderRadius?: Maybe<Scalars['String']>;
  /**
   * Border Right
   *
   * Sets right border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-right)
   */
  borderRight?: Maybe<Scalars['IntType']>;
  /**
   * Border Top
   *
   * Sets top border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-top)
   */
  borderTop?: Maybe<Scalars['IntType']>;
  /**
   * Border Size & Color
   *
   * Applies a border to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border)
   */
  border?: Maybe<Scalars['String']>;
  /**
   * Brightness
   *
   * Adjusts the brightness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/bri)
   */
  bri?: Maybe<Scalars['IntType']>;
  /**
   * Client Hints
   *
   * Sets one or more Client-Hints headers
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/ch)
   */
  ch?: Maybe<Array<ImgixParamsCh>>;
  /**
   * Chroma Subsampling
   *
   * Specifies the output chroma subsampling rate.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/chromasub)
   */
  chromasub?: Maybe<Scalars['IntType']>;
  /**
   * Color Quantization
   *
   * Limits the number of unique colors in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/colorquant)
   */
  colorquant?: Maybe<Scalars['IntType']>;
  /**
   * Palette Color Count
   *
   * Specifies how many colors to include in a palette-extraction response.
   *
   * Depends on: `palette`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/colors)
   */
  colors?: Maybe<Scalars['IntType']>;
  /**
   * Contrast
   *
   * Adjusts the contrast of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/con)
   */
  con?: Maybe<Scalars['IntType']>;
  /**
   * Mask Corner Radius
   *
   * Specifies the radius value for a rounded corner mask.
   *
   * Depends on: `mask=corners`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/corner-radius)
   */
  cornerRadius?: Maybe<Scalars['String']>;
  /**
   * Crop Mode
   *
   * Specifies how to crop an image.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/crop)
   */
  crop?: Maybe<Array<ImgixParamsCrop>>;
  /**
   * Color Space
   *
   * Specifies the color space of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/cs)
   */
  cs?: Maybe<ImgixParamsCs>;
  /**
   * Download
   *
   * Forces a URL to use send-file in its response.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dl)
   */
  dl?: Maybe<Scalars['String']>;
  /**
   * Dots Per Inch
   *
   * Sets the DPI value in the EXIF header.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dpi)
   */
  dpi?: Maybe<Scalars['IntType']>;
  /**
   * Device Pixel Ratio
   *
   * Adjusts the device-pixel ratio of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/dpr)
   */
  dpr?: Maybe<Scalars['FloatType']>;
  /**
   * Duotone Alpha
   *
   * Changes the alpha of the duotone effect atop the source image.
   *
   * Depends on: `duotone`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone-alpha)
   */
  duotoneAlpha?: Maybe<Scalars['IntType']>;
  /**
   * Duotone
   *
   * Applies a duotone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone)
   */
  duotone?: Maybe<Scalars['String']>;
  /**
   * Exposure
   *
   * Adjusts the exposure of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/exp)
   */
  exp?: Maybe<Scalars['IntType']>;
  /**
   * Url Expiration Timestamp
   *
   * A Unix timestamp specifying a UTC time. Requests made to this URL after that time will output a 404 status code.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/expires)
   */
  expires?: Maybe<Scalars['IntType']>;
  /**
   * Face Index
   *
   * Selects a face to crop to.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faceindex)
   */
  faceindex?: Maybe<Scalars['IntType']>;
  /**
   * Face Padding
   *
   * Adjusts padding around a selected face.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/facepad)
   */
  facepad?: Maybe<Scalars['FloatType']>;
  /**
   * Json Face Data
   *
   * Specifies that face data should be included in output when combined with `fm=json`.
   *
   * Depends on: `fm=json`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faces)
   */
  faces?: Maybe<Scalars['IntType']>;
  /**
   * Fill Color
   *
   * Sets the fill color for images with additional space created by the fit setting
   *
   * Depends on: `fill=solid`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-color)
   */
  fillColor?: Maybe<Scalars['String']>;
  /**
   * Fill Generative Fallback
   *
   * Sets the fallback behavior for generative fill.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-fallback)
   */
  fillGenFallback?: Maybe<Scalars['BooleanType']>;
  /**
   * Fill Generative Negative Prompt
   *
   * Provides a negative text suggestion to the generative fill parameter. Used to reduce the probability of a subject, detail, or object appearing in generative output.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-neg-prompt)
   */
  fillGenNegPrompt?: Maybe<Scalars['String']>;
  /**
   * Fill Generative Position
   *
   * Sets the position of the Origin Image in relation to the generative fill.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-pos)
   */
  fillGenPos?: Maybe<Array<ImgixParamsFillGenPos>>;
  /**
   * Fill Generative Prompt
   *
   * Provides a text suggestion to the generative fill parameter.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-prompt)
   */
  fillGenPrompt?: Maybe<Scalars['String']>;
  /**
   * Fill Generative Seed
   *
   * Sets the generative seed value. Used to generate similar outputs from different prompts.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-seed)
   */
  fillGenSeed?: Maybe<Scalars['IntType']>;
  /**
   * Fill Gradient Color Space
   *
   * Defines the color space as linear, sRGB, Oklab, HSL, or LCH for gradient color interpolation
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-cs)
   */
  fillGradientCs?: Maybe<ImgixParamsFillGradientCs>;
  /**
   * Fill Gradient Linear Direction
   *
   * The fill-gradient-linear-direction specifies the gradient's direction, flowing towards the bottom, top, right, or left
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-linear-direction)
   */
  fillGradientLinearDirection?: Maybe<Array<ImgixParamsFillGradientLinearDirection>>;
  /**
   * Fill Gradient Linear
   *
   * Blends a gradient between two colors, {color1} and {color2}, along a straight path
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-linear)
   */
  fillGradientLinear?: Maybe<Scalars['String']>;
  /**
   * Fill Gradient Radial Radius
   *
   * Parameter defines the radial gradient's radius as pixels or a percentage (0.0-1.0) of the image's smallest dimension
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-radius)
   */
  fillGradientRadialRadius?: Maybe<Scalars['String']>;
  /**
   * Fill Gradient Radial X
   *
   * Specifies the location of the radial gradient's center along the x-axis, using either a pixel value or a floating point percentage (ranging from 0.0 to 1.0) of the image's width
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-x)
   */
  fillGradientRadialX?: Maybe<Scalars['FloatType']>;
  /**
   * Fill Gradient Radial Y
   *
   * Parameter sets the radial gradient's center on the y-axis, using pixels or a 0.0 to 1.0 percentage of the image's height
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-y)
   */
  fillGradientRadialY?: Maybe<Scalars['FloatType']>;
  /**
   * Fill Gradient Radial
   *
   * The fill-gradient-radial parameter creates a circular gradient transitioning from a central color (Color1) to an outer color (Color2)
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial)
   */
  fillGradientRadial?: Maybe<Scalars['String']>;
  /**
   * Fill Gradient Type
   *
   * Specifies if a gradient is radial (circular) or linear (straight)
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-type)
   */
  fillGradientType?: Maybe<ImgixParamsFillGradientType>;
  /**
   * Fill Mode
   *
   * Determines how to fill in additional space created by the fit setting
   *
   * Depends on: `fit`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill)
   */
  fill?: Maybe<ImgixParamsFill>;
  /**
   * Resize Fit Mode
   *
   * Specifies how to map the source image to the output image dimensions.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/fit)
   */
  fit?: Maybe<ImgixParamsFit>;
  /**
   * Flip Axis
   *
   * Flips an image on a specified axis.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/flip)
   */
  flip?: Maybe<ImgixParamsFlip>;
  /**
   * Output Format
   *
   * Changes the format of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/fm)
   */
  fm?: Maybe<ImgixParamsFm>;
  /**
   * Focal Point Debug
   *
   * Displays crosshairs identifying the location of the set focal point
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-debug)
   */
  fpDebug?: Maybe<Scalars['BooleanType']>;
  /**
   * Focal Point X Position
   *
   * Sets the relative horizontal value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-x)
   */
  fpX?: Maybe<Scalars['FloatType']>;
  /**
   * Focal Point Y Position
   *
   * Sets the relative vertical value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-y)
   */
  fpY?: Maybe<Scalars['FloatType']>;
  /**
   * Focal Point Zoom
   *
   * Sets the relative zoom value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-z)
   */
  fpZ?: Maybe<Scalars['FloatType']>;
  /**
   * Frames Per Second
   *
   * Specifies the framerate of the generated image.
   */
  fps?: Maybe<Scalars['IntType']>;
  /**
   * Frame Selection
   *
   * Specifies the frame of an animated image to use.
   */
  frame?: Maybe<Scalars['String']>;
  /**
   * Gamma
   *
   * Adjusts the gamma of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/gam)
   */
  gam?: Maybe<Scalars['IntType']>;
  /**
   * Animated Gif Quality
   *
   * Specifies the quality of the animated gif. The higher the value, the better more compression is applied.
   *
   * Depends on: `fm=gif`
   */
  gifQ?: Maybe<Scalars['IntType']>;
  /**
   * Grid Colors
   *
   * Sets grid colors for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   */
  gridColors?: Maybe<Scalars['String']>;
  /**
   * Grid Size
   *
   * Sets grid size for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   */
  gridSize?: Maybe<Scalars['IntType']>;
  /**
   * Image Height
   *
   * Adjusts the height of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/h)
   */
  h?: Maybe<Scalars['FloatType']>;
  /**
   * Highlight
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/high)
   */
  high?: Maybe<Scalars['IntType']>;
  /**
   * Halftone
   *
   * Applies a half-tone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/htn)
   */
  htn?: Maybe<Scalars['IntType']>;
  /**
   * Hue Shift
   *
   * Adjusts the hue of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/hue)
   */
  hue?: Maybe<Scalars['IntType']>;
  /**
   * Frame Interval
   *
   * Displays every Nth frame starting with the first frame.
   */
  interval?: Maybe<Scalars['IntType']>;
  /**
   * Invert
   *
   * Inverts the colors on the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/invert)
   */
  invert?: Maybe<Scalars['BooleanType']>;
  /**
   * Iptc Passthrough
   *
   * Determine if IPTC data should be passed for JPEG images.
   */
  iptc?: Maybe<ImgixParamsIptc>;
  /**
   * Jpg Progressive
   *
   * Specifies whether or not a jpg/jpeg uses progressive (true) or baseline (false)
   */
  jpgProgressive?: Maybe<Scalars['BooleanType']>;
  /**
   * Animation Loop Count
   *
   * Specifies the number of times an animated image should repeat. A value of 0 means infinite looping.
   */
  loop?: Maybe<Scalars['IntType']>;
  /**
   * Lossless Compression
   *
   * Specifies that the output image should be a lossless variant.
   *
   * Depends on: `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/lossless)
   */
  lossless?: Maybe<Scalars['BooleanType']>;
  /**
   * Watermark Alignment Mode
   *
   * Changes the watermark alignment relative to the parent image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-align)
   */
  markAlign?: Maybe<Array<ImgixParamsMarkAlign>>;
  /**
   * Watermark Alpha
   *
   * Changes the alpha of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-alpha)
   */
  markAlpha?: Maybe<Scalars['IntType']>;
  /**
   * Watermark Base Url
   *
   * Changes base URL of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-base)
   */
  markBase?: Maybe<Scalars['String']>;
  /**
   * Watermark Fit Mode
   *
   * Specifies the fit mode for watermark images.
   *
   * Depends on: `mark`, `markw`, `markh`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-fit)
   */
  markFit?: Maybe<ImgixParamsMarkFit>;
  /**
   * Watermark Height
   *
   * Adjusts the height of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-h)
   */
  markH?: Maybe<Scalars['FloatType']>;
  /**
   * Watermark Padding
   *
   * Applies padding to the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-pad)
   */
  markPad?: Maybe<Scalars['IntType']>;
  /**
   * Watermark Rotation
   *
   * Rotates a watermark or tiled watermarks by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-rot)
   */
  markRot?: Maybe<Scalars['FloatType']>;
  /**
   * Watermark Scale
   *
   * Adjusts the scale of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-scale)
   */
  markScale?: Maybe<Scalars['IntType']>;
  /**
   * Watermark Tile
   *
   * Adds tiled watermark.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-tile)
   */
  markTile?: Maybe<ImgixParamsMarkTile>;
  /**
   * Watermark Width
   *
   * Adjusts the width of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-w)
   */
  markW?: Maybe<Scalars['FloatType']>;
  /**
   * Watermark X Position
   *
   * Adjusts the x-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-x)
   */
  markX?: Maybe<Scalars['IntType']>;
  /**
   * Watermark Y Position
   *
   * Adjusts the y-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-y)
   */
  markY?: Maybe<Scalars['IntType']>;
  /**
   * Watermark Image Url
   *
   * Specifies the location of the watermark image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark)
   */
  mark?: Maybe<Scalars['String']>;
  /**
   * Mask Background Color
   *
   * Colors the background of the transparent mask area of images
   *
   * Depends on: `mask`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/mask-bg)
   */
  maskBg?: Maybe<Scalars['String']>;
  /**
   * Mask Type
   *
   * Defines the type of mask and specifies the URL if that type is selected.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask)
   */
  mask?: Maybe<Scalars['String']>;
  /**
   * Maximum Height
   *
   * Specifies the maximum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-height)
   */
  maxH?: Maybe<Scalars['IntType']>;
  /**
   * Maximum Width
   *
   * Specifies the maximum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-width)
   */
  maxW?: Maybe<Scalars['IntType']>;
  /**
   * Minimum Height
   *
   * Specifies the minimum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-height)
   */
  minH?: Maybe<Scalars['IntType']>;
  /**
   * Minimum Width
   *
   * Specifies the minimum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-width)
   */
  minW?: Maybe<Scalars['IntType']>;
  /**
   * Monochrome
   *
   * Applies a monochrome effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/monochrome)
   */
  monochrome?: Maybe<Scalars['String']>;
  /**
   * Noise Reduction Bound
   *
   * Reduces the noise in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nr)
   */
  nr?: Maybe<Scalars['IntType']>;
  /**
   * Noise Reduction Sharpen
   *
   * Provides a threshold by which to sharpen an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nrs)
   */
  nrs?: Maybe<Scalars['IntType']>;
  /**
   * Orientation
   *
   * Changes the image orientation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/orient)
   */
  orient?: Maybe<Scalars['IntType']>;
  /**
   * Padding Bottom
   *
   * Sets bottom padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-bottom)
   */
  padBottom?: Maybe<Scalars['IntType']>;
  /**
   * Padding Left
   *
   * Sets left padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-left)
   */
  padLeft?: Maybe<Scalars['IntType']>;
  /**
   * Padding Right
   *
   * Sets right padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-right)
   */
  padRight?: Maybe<Scalars['IntType']>;
  /**
   * Padding Top
   *
   * Sets top padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-top)
   */
  padTop?: Maybe<Scalars['IntType']>;
  /**
   * Padding
   *
   * Pads an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad)
   */
  pad?: Maybe<Scalars['IntType']>;
  /**
   * Pdf Page Number
   *
   * Selects a page from a PDF for display.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf/page)
   */
  page?: Maybe<Scalars['IntType']>;
  /**
   * Color Palette Extraction
   *
   * Specifies an output format for palette-extraction.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/palette)
   */
  palette?: Maybe<ImgixParamsPalette>;
  /**
   * Pdf Annotation
   *
   * Enables or disables PDF annotation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf/pdf-annotation)
   */
  pdfAnnotation?: Maybe<Scalars['BooleanType']>;
  /**
   * Css Prefix
   *
   * Specifies a CSS prefix for all classes in palette-extraction.
   *
   * Depends on: `palette=css`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/prefix)
   */
  prefix?: Maybe<Scalars['String']>;
  /**
   * Pixellate
   *
   * Applies a pixelation effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/px)
   */
  px?: Maybe<Scalars['IntType']>;
  /**
   * Output Quality
   *
   * Adjusts the quality of an output image.
   *
   * Depends on: `fm=jpg`, `fm=pjpg`, `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/q)
   */
  q?: Maybe<Scalars['IntType']>;
  /**
   * Source Rectangle Region
   *
   * Crops an image to a specified rectangle.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/rect)
   */
  rect?: Maybe<Scalars['String']>;
  /**
   * Reverse
   *
   * Reverses the frame order on the source animation.
   */
  reverse?: Maybe<Scalars['BooleanType']>;
  /**
   * Rotation
   *
   * Rotates an image by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/rot)
   */
  rot?: Maybe<Scalars['FloatType']>;
  /**
   * Saturation
   *
   * Adjusts the saturation of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sat)
   */
  sat?: Maybe<Scalars['IntType']>;
  /**
   * Sepia Tone
   *
   * Applies a sepia effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/sepia)
   */
  sepia?: Maybe<Scalars['IntType']>;
  /**
   * Shadow
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/shad)
   */
  shad?: Maybe<Scalars['FloatType']>;
  /**
   * Sharpen
   *
   * Adjusts the sharpness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sharp)
   */
  sharp?: Maybe<Scalars['FloatType']>;
  /**
   * Frame Skip
   *
   * Skips every Nth frame starting with the first frame.
   */
  skip?: Maybe<Scalars['IntType']>;
  /**
   * Sanitize Svg
   *
   * Specifies whether to sanitize an SVG.
   */
  svgSanitize?: Maybe<Scalars['BooleanType']>;
  /**
   * Transparency
   *
   * Adds checkerboard behind images which support transparency.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/transparency)
   */
  transparency?: Maybe<ImgixParamsTransparency>;
  /**
   * Trim Color
   *
   * Specifies a trim color on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-color)
   */
  trimColor?: Maybe<Scalars['String']>;
  /**
   * Trim Mean Difference
   *
   * Specifies the mean difference on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-md)
   */
  trimMd?: Maybe<Scalars['FloatType']>;
  /**
   * Trim Padding
   *
   * Pads the area of the source image before trimming.
   *
   * Depends on: `trim`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-pad)
   */
  trimPad?: Maybe<Scalars['IntType']>;
  /**
   * Trim Standard Deviation
   *
   * Specifies the standard deviation on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-sd)
   */
  trimSd?: Maybe<Scalars['FloatType']>;
  /**
   * Trim Tolerance
   *
   * Specifies the tolerance on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-tol)
   */
  trimTol?: Maybe<Scalars['FloatType']>;
  /**
   * Trim Image
   *
   * Trims the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim)
   */
  trim?: Maybe<ImgixParamsTrim>;
  /**
   * Text Align
   *
   * Sets the vertical and horizontal alignment of rendered text relative to the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-align)
   */
  txtAlign?: Maybe<Array<ImgixParamsTxtAlign>>;
  /**
   * Text Clipping Mode
   *
   * Sets the clipping properties of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-clip)
   */
  txtClip?: Maybe<Array<ImgixParamsTxtClip>>;
  /**
   * Text Color
   *
   * Specifies the color of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-color)
   */
  txtColor?: Maybe<Scalars['String']>;
  /**
   * Text Fit Mode
   *
   * Specifies the fit approach for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-fit)
   */
  txtFit?: Maybe<ImgixParamsTxtFit>;
  /**
   * Text Font
   *
   * Selects a font for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-font)
   */
  txtFont?: Maybe<Scalars['String']>;
  /**
   * Text Leading
   *
   * Sets the leading (line spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-lead)
   */
  txtLead?: Maybe<Scalars['IntType']>;
  /**
   * Text Outline Color
   *
   * Specifies a text outline color.
   *
   * Depends on: `txt`, `txtline`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line-color)
   */
  txtLineColor?: Maybe<Scalars['String']>;
  /**
   * Text Outline
   *
   * Outlines the rendered text with a specified color.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line)
   */
  txtLine?: Maybe<Scalars['IntType']>;
  /**
   * Text Padding
   *
   * Specifies the padding (in device-independent pixels) between a textbox and the edges of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-pad)
   */
  txtPad?: Maybe<Scalars['IntType']>;
  /**
   * Text Shadow
   *
   * Applies a shadow to rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-shad)
   */
  txtShad?: Maybe<Scalars['FloatType']>;
  /**
   * Text Font Size
   *
   * Sets the font size of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-size)
   */
  txtSize?: Maybe<Scalars['IntType']>;
  /**
   * Text Tracking
   *
   * Sets the tracking (letter spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-track)
   */
  txtTrack?: Maybe<Scalars['IntType']>;
  /**
   * Text Width
   *
   * Sets the width of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-width)
   */
  txtWidth?: Maybe<Scalars['IntType']>;
  /**
   * Text X Position
   *
   * Sets the horizontal (x) position of the text in pixels relative to the left edge of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-x)
   */
  txtX?: Maybe<Scalars['IntType']>;
  /**
   * Text Y Position
   *
   * Sets the vertical (y) position of the text in pixels relative to the top edge of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-y)
   */
  txtY?: Maybe<Scalars['IntType']>;
  /**
   * Text String
   *
   * Sets the text string to render.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt)
   */
  txt?: Maybe<Scalars['String']>;
  /**
   * Super Resolution Fallback
   *
   * Overrides default fallback behavior for super resolution failures
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/super-resolution/upscale-fallback)
   */
  upscaleFallback?: Maybe<Scalars['BooleanType']>;
  /**
   * Super Resolution
   *
   * Uses generative AI fill to upscale low resolution images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/super-resolution/upscale)
   */
  upscale?: Maybe<Scalars['BooleanType']>;
  /**
   * Unsharp Mask
   *
   * Sharpens the source image using an unsharp mask.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usm)
   */
  usm?: Maybe<Scalars['IntType']>;
  /**
   * Unsharp Mask Radius
   *
   * Specifies the radius for an unsharp mask operation.
   *
   * Depends on: `usm`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usmrad)
   */
  usmrad?: Maybe<Scalars['FloatType']>;
  /**
   * Vibrance
   *
   * Adjusts the vibrance of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/vib)
   */
  vib?: Maybe<Scalars['IntType']>;
  /**
   * Image Width
   *
   * Adjusts the width of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/w)
   */
  w?: Maybe<Scalars['FloatType']>;
  /**
   * Bypasses any [DatoCMS Automatic Image Optimization](https://www.datocms.com/docs/cdn-settings/advanced-asset-settings) that might be set up for the project.
   *
   * Exercise caution when using this parameter, as it could significantly increase your bandwidth costs.
   */
  skipDefaultOptimizations?: Maybe<Scalars['BooleanType']>;
};

export enum ImgixParamsAuto {
  ENHANCE = 'enhance',
  FORMAT = 'format',
  REDEYE = 'redeye',
  COMPRESS = 'compress'
}

export enum ImgixParamsBlendAlign {
  TOP = 'top',
  BOTTOM = 'bottom',
  MIDDLE = 'middle',
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center'
}

export enum ImgixParamsBlendCrop {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  FACES = 'faces'
}

export enum ImgixParamsBlendFit {
  CLAMP = 'clamp',
  CLIP = 'clip',
  CROP = 'crop',
  SCALE = 'scale',
  MAX = 'max'
}

export enum ImgixParamsBlendMode {
  COLOR = 'color',
  BURN = 'burn',
  DODGE = 'dodge',
  DARKEN = 'darken',
  DIFFERENCE = 'difference',
  EXCLUSION = 'exclusion',
  HARDLIGHT = 'hardlight',
  HUE = 'hue',
  LIGHTEN = 'lighten',
  LUMINOSITY = 'luminosity',
  MULTIPLY = 'multiply',
  OVERLAY = 'overlay',
  SATURATION = 'saturation',
  SCREEN = 'screen',
  SOFTLIGHT = 'softlight',
  NORMAL = 'normal'
}

export enum ImgixParamsBlendSize {
  INHERIT = 'inherit'
}

export enum ImgixParamsCh {
  WIDTH = 'width',
  DPR = 'dpr',
  SAVE_DATA = 'saveData'
}

export enum ImgixParamsCrop {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  FACES = 'faces',
  ENTROPY = 'entropy',
  EDGES = 'edges',
  FOCALPOINT = 'focalpoint'
}

export enum ImgixParamsCs {
  SRGB = 'srgb',
  ADOBERGB1998 = 'adobergb1998',
  TINYSRGB = 'tinysrgb',
  STRIP = 'strip'
}

export enum ImgixParamsFill {
  SOLID = 'solid',
  BLUR = 'blur',
  GEN = 'gen',
  GENERATIVE = 'generative',
  GRADIENT = 'gradient'
}

export enum ImgixParamsFillGenPos {
  TOP = 'top',
  BOTTOM = 'bottom',
  MIDDLE = 'middle',
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center'
}

export enum ImgixParamsFillGradientCs {
  LINEAR = 'linear',
  SRGB = 'srgb',
  OKLAB = 'oklab',
  HSL = 'hsl',
  LCH = 'lch'
}

export enum ImgixParamsFillGradientLinearDirection {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right'
}

export enum ImgixParamsFillGradientType {
  LINEAR = 'linear',
  RADIAL = 'radial'
}

export enum ImgixParamsFit {
  CLAMP = 'clamp',
  CLIP = 'clip',
  CROP = 'crop',
  FACEAREA = 'facearea',
  FILL = 'fill',
  FILLMAX = 'fillmax',
  MAX = 'max',
  MIN = 'min',
  SCALE = 'scale'
}

export enum ImgixParamsFlip {
  H = 'h',
  V = 'v',
  HV = 'hv'
}

export enum ImgixParamsFm {
  GIF = 'gif',
  JPG = 'jpg',
  JP2 = 'jp2',
  JSON = 'json',
  JXR = 'jxr',
  PJPG = 'pjpg',
  MP4 = 'mp4',
  PNG = 'png',
  PNG8 = 'png8',
  PNG32 = 'png32',
  WEBP = 'webp',
  WEBM = 'webm',
  BLURHASH = 'blurhash',
  AVIF = 'avif'
}

export enum ImgixParamsIptc {
  ALLOW = 'allow',
  BLOCK = 'block'
}

export enum ImgixParamsMarkAlign {
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom',
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right'
}

export enum ImgixParamsMarkFit {
  CLIP = 'clip',
  CROP = 'crop',
  FILL = 'fill',
  MAX = 'max',
  SCALE = 'scale'
}

export enum ImgixParamsMarkTile {
  GRID = 'grid'
}

export enum ImgixParamsPalette {
  CSS = 'css',
  JSON = 'json'
}

export enum ImgixParamsTransparency {
  GRID = 'grid'
}

export enum ImgixParamsTrim {
  AUTO = 'auto',
  COLOR = 'color'
}

export enum ImgixParamsTxtAlign {
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom',
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right'
}

export enum ImgixParamsTxtClip {
  START = 'start',
  MIDDLE = 'middle',
  END = 'end',
  ELLIPSIS = 'ellipsis'
}

export enum ImgixParamsTxtFit {
  MAX = 'max'
}

/** Specifies how to filter by usage */
export type InUseFilter = {
  /** Search uploads that are currently used by some record or not */
  eq?: Maybe<Scalars['BooleanType']>;
};

/** Block of type inline Image Carousel (inline_image_carousel) */
export type InlineImageCarouselRecord = RecordInterface & {
  __typename?: 'InlineImageCarouselRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  imagehouse: Array<FileField>;
  updatedAt: Scalars['DateTime'];
};


/** Block of type inline Image Carousel (inline_image_carousel) */
export type InlineImageCarouselRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};


/** Specifies how to filter Integer fields */
export type IntegerFilter = {
  /** Filter records with a value that's strictly greater than the one specified */
  gt?: Maybe<Scalars['IntType']>;
  /** Filter records with a value that's less than the one specified */
  lt?: Maybe<Scalars['IntType']>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte?: Maybe<Scalars['IntType']>;
  /** Filter records with a value that's less or equal than the one specified */
  lte?: Maybe<Scalars['IntType']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars['BooleanType']>;
  /** Search for records with an exact match */
  eq?: Maybe<Scalars['IntType']>;
  /** Exclude records with an exact match */
  neq?: Maybe<Scalars['IntType']>;
};


/** Specifies how to filter by ID */
export type ItemIdFilter = {
  /** Search the record with the specified ID */
  eq?: Maybe<Scalars['ItemId']>;
  /** Exclude the record with the specified ID */
  neq?: Maybe<Scalars['ItemId']>;
  /** Search records with the specified IDs */
  in?: Maybe<Array<Maybe<Scalars['ItemId']>>>;
  /** Search records that do not have the specified IDs */
  notIn?: Maybe<Array<Maybe<Scalars['ItemId']>>>;
};

export enum ItemStatus {
  DRAFT = 'draft',
  UPDATED = 'updated',
  PUBLISHED = 'published'
}


/** Specifies how to filter JSON fields */
export type JsonFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars['BooleanType']>;
};

export type LatLonField = {
  __typename?: 'LatLonField';
  latitude: Scalars['FloatType'];
  longitude: Scalars['FloatType'];
};

/** Specifies how to filter Geolocation fields */
export type LatLonFilter = {
  /** Filter records within the specified radius in meters */
  near?: Maybe<LatLonNearFilter>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars['BooleanType']>;
};

export type LatLonNearFilter = {
  latitude: Scalars['FloatType'];
  longitude: Scalars['FloatType'];
  radius: Scalars['FloatType'];
};

/** Specifies how to filter Single-link fields */
export type LinkFilter = {
  /** Search for records with an exact match. The specified value must be a Record ID */
  eq?: Maybe<Scalars['ItemId']>;
  /** Exclude records with an exact match. The specified value must be a Record ID */
  neq?: Maybe<Scalars['ItemId']>;
  /** Filter records linked to one of the specified records */
  in?: Maybe<Array<Maybe<Scalars['ItemId']>>>;
  /** Filter records not linked to one of the specified records */
  notIn?: Maybe<Array<Maybe<Scalars['ItemId']>>>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars['BooleanType']>;
};

export type LinkModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  link?: Maybe<LinkFilter>;
  externalElementId?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  externalLink?: Maybe<StringFilter>;
  buttonStyle?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<LinkModelFilter>>>;
  AND?: Maybe<Array<Maybe<LinkModelFilter>>>;
};

export type LinkModelLinkField = ArticleCategoryRecord | ArticleRecord | BlogHomepageRecord | CustomPageRecord | LocationSiteRecord | NexudusLinkRecord | SolutionTypeRecord | WorkspaceRecord;

export enum LinkModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  EXTERNAL_ELEMENT_ID_ASC = 'externalElementId_ASC',
  EXTERNAL_ELEMENT_ID_DESC = 'externalElementId_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  EXTERNAL_LINK_ASC = 'externalLink_ASC',
  EXTERNAL_LINK_DESC = 'externalLink_DESC',
  BUTTON_STYLE_ASC = 'buttonStyle_ASC',
  BUTTON_STYLE_DESC = 'buttonStyle_DESC'
}

/** Record of type Link (link) */
export type LinkRecord = RecordInterface & {
  __typename?: 'LinkRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  buttonStyle?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  externalElementId?: Maybe<Scalars['String']>;
  externalLink?: Maybe<Scalars['String']>;
  id: Scalars['ItemId'];
  link?: Maybe<LinkModelLinkField>;
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type Link (link) */
export type LinkRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Specifies how to filter Multiple-links fields */
export type LinksFilter = {
  /** Search for records with an exact match. The specified values must be Record IDs */
  eq?: Maybe<Array<Maybe<Scalars['ItemId']>>>;
  /** Filter records linked to all of the specified records. The specified values must be Record IDs */
  allIn?: Maybe<Array<Maybe<Scalars['ItemId']>>>;
  /** Filter records linked to at least one of the specified records. The specified values must be Record IDs */
  anyIn?: Maybe<Array<Maybe<Scalars['ItemId']>>>;
  /** Filter records not linked to any of the specified records. The specified values must be Record IDs */
  notIn?: Maybe<Array<Maybe<Scalars['ItemId']>>>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars['BooleanType']>;
};

export type LocationSiteModelAssetsField = ImageRecord | VimeoVideoRecord;

export type LocationSiteModelBreadcrumbField = ArticleCategoryRecord | ArticleRecord | BlogHomepageRecord | CustomPageRecord | LocationSiteRecord | SolutionTypeRecord;

export type LocationSiteModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  position?: Maybe<PositionFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  cardLocationOffice?: Maybe<StringFilter>;
  reusableComponents?: Maybe<LinkFilter>;
  metaTags?: Maybe<SeoFilter>;
  seoStructuredData?: Maybe<JsonFilter>;
  showBreadcrumb?: Maybe<BooleanFilter>;
  cardImage?: Maybe<FileFilter>;
  city?: Maybe<LinkFilter>;
  transportLinkImage2?: Maybe<FileFilter>;
  transportLinkImage1?: Maybe<FileFilter>;
  textBackgroundImage?: Maybe<FileFilter>;
  floatingCta?: Maybe<LinkFilter>;
  assets?: Maybe<LinksFilter>;
  breadcrumb?: Maybe<LinksFilter>;
  coordinates?: Maybe<LatLonFilter>;
  addressLocation?: Maybe<StringFilter>;
  emailAddress?: Maybe<StringFilter>;
  cardDistance?: Maybe<StringFilter>;
  cardPrice?: Maybe<StringFilter>;
  cardOptionalText?: Maybe<StringFilter>;
  cardAddress?: Maybe<StringFilter>;
  cardPhoneNumber?: Maybe<StringFilter>;
  cardHeading?: Maybe<StringFilter>;
  urlPath?: Maybe<StringFilter>;
  transportLinkCopy1?: Maybe<StringFilter>;
  transportLinkCopy2?: Maybe<StringFilter>;
  reference?: Maybe<StringFilter>;
  address?: Maybe<StringFilter>;
  siteName?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<LocationSiteModelFilter>>>;
  AND?: Maybe<Array<Maybe<LocationSiteModelFilter>>>;
};

export enum LocationSiteModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  POSITION_ASC = 'position_ASC',
  POSITION_DESC = 'position_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  CARD_LOCATION_OFFICE_ASC = 'cardLocationOffice_ASC',
  CARD_LOCATION_OFFICE_DESC = 'cardLocationOffice_DESC',
  SHOW_BREADCRUMB_ASC = 'showBreadcrumb_ASC',
  SHOW_BREADCRUMB_DESC = 'showBreadcrumb_DESC',
  ADDRESS_LOCATION_ASC = 'addressLocation_ASC',
  ADDRESS_LOCATION_DESC = 'addressLocation_DESC',
  EMAIL_ADDRESS_ASC = 'emailAddress_ASC',
  EMAIL_ADDRESS_DESC = 'emailAddress_DESC',
  CARD_DISTANCE_ASC = 'cardDistance_ASC',
  CARD_DISTANCE_DESC = 'cardDistance_DESC',
  CARD_PRICE_ASC = 'cardPrice_ASC',
  CARD_PRICE_DESC = 'cardPrice_DESC',
  CARD_OPTIONAL_TEXT_ASC = 'cardOptionalText_ASC',
  CARD_OPTIONAL_TEXT_DESC = 'cardOptionalText_DESC',
  CARD_ADDRESS_ASC = 'cardAddress_ASC',
  CARD_ADDRESS_DESC = 'cardAddress_DESC',
  CARD_PHONE_NUMBER_ASC = 'cardPhoneNumber_ASC',
  CARD_PHONE_NUMBER_DESC = 'cardPhoneNumber_DESC',
  CARD_HEADING_ASC = 'cardHeading_ASC',
  CARD_HEADING_DESC = 'cardHeading_DESC',
  URL_PATH_ASC = 'urlPath_ASC',
  URL_PATH_DESC = 'urlPath_DESC',
  TRANSPORT_LINK_COPY1_ASC = 'transportLinkCopy1_ASC',
  TRANSPORT_LINK_COPY1_DESC = 'transportLinkCopy1_DESC',
  TRANSPORT_LINK_COPY2_ASC = 'transportLinkCopy2_ASC',
  TRANSPORT_LINK_COPY2_DESC = 'transportLinkCopy2_DESC',
  REFERENCE_ASC = 'reference_ASC',
  REFERENCE_DESC = 'reference_DESC',
  ADDRESS_ASC = 'address_ASC',
  ADDRESS_DESC = 'address_DESC',
  SITE_NAME_ASC = 'siteName_ASC',
  SITE_NAME_DESC = 'siteName_DESC',
  PHONE_NUMBER_ASC = 'phoneNumber_ASC',
  PHONE_NUMBER_DESC = 'phoneNumber_DESC'
}

/** Record of type Location Site (location_site) */
export type LocationSiteRecord = RecordInterface & {
  __typename?: 'LocationSiteRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  address?: Maybe<Scalars['String']>;
  addressLocation?: Maybe<Scalars['String']>;
  assets: Array<LocationSiteModelAssetsField>;
  breadcrumb: Array<LocationSiteModelBreadcrumbField>;
  cardAddress?: Maybe<Scalars['String']>;
  cardDistance?: Maybe<Scalars['String']>;
  cardHeading?: Maybe<Scalars['String']>;
  cardImage?: Maybe<FileField>;
  cardLocationOffice?: Maybe<Scalars['String']>;
  cardOptionalText?: Maybe<Scalars['String']>;
  cardPhoneNumber?: Maybe<Scalars['String']>;
  cardPrice?: Maybe<Scalars['String']>;
  city?: Maybe<CityRecord>;
  coordinates?: Maybe<LatLonField>;
  createdAt: Scalars['DateTime'];
  emailAddress?: Maybe<Scalars['String']>;
  floatingCta?: Maybe<FloatingCtaRecord>;
  id: Scalars['ItemId'];
  metaTags?: Maybe<SeoField>;
  phoneNumber?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['IntType']>;
  reference?: Maybe<Scalars['String']>;
  reusableComponents?: Maybe<PageContentRecord>;
  seoStructuredData?: Maybe<Scalars['JsonField']>;
  showBreadcrumb?: Maybe<Scalars['BooleanType']>;
  siteName?: Maybe<Scalars['String']>;
  textBackgroundImage?: Maybe<FileField>;
  transportLinkCopy1?: Maybe<Scalars['String']>;
  transportLinkCopy2?: Maybe<Scalars['String']>;
  transportLinkImage1?: Maybe<FileField>;
  transportLinkImage2?: Maybe<FileField>;
  updatedAt: Scalars['DateTime'];
  urlPath?: Maybe<Scalars['String']>;
};


/** Record of type Location Site (location_site) */
export type LocationSiteRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type LogoWallModelHeadingField = {
  __typename?: 'LogoWallModelHeadingField';
  blocks: Array<Scalars['String']>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

/** Block of type Logo Wall (logo_wall) */
export type LogoWallRecord = RecordInterface & {
  __typename?: 'LogoWallRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColour?: Maybe<ColorField>;
  blockCardIdentify?: Maybe<Scalars['String']>;
  blockMenuName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  heading?: Maybe<LogoWallModelHeadingField>;
  hideSeparatorLine?: Maybe<Scalars['BooleanType']>;
  id: Scalars['ItemId'];
  logos: Array<FileField>;
  onLaptop?: Maybe<Scalars['String']>;
  onMobile?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** Block of type Logo Wall (logo_wall) */
export type LogoWallRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type MapModelHeadingField = {
  __typename?: 'MapModelHeadingField';
  blocks: Array<Scalars['String']>;
  links: Array<Scalars['String']>;
  value: Scalars['JsonField'];
};

export type MapPinModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  pinCopy?: Maybe<StringFilter>;
  pinTitle?: Maybe<StringFilter>;
  reference?: Maybe<StringFilter>;
  pinDestination?: Maybe<StringFilter>;
  latitude?: Maybe<FloatFilter>;
  longitude?: Maybe<FloatFilter>;
  OR?: Maybe<Array<Maybe<MapPinModelFilter>>>;
  AND?: Maybe<Array<Maybe<MapPinModelFilter>>>;
};

export enum MapPinModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  PIN_COPY_ASC = 'pinCopy_ASC',
  PIN_COPY_DESC = 'pinCopy_DESC',
  PIN_TITLE_ASC = 'pinTitle_ASC',
  PIN_TITLE_DESC = 'pinTitle_DESC',
  REFERENCE_ASC = 'reference_ASC',
  REFERENCE_DESC = 'reference_DESC',
  PIN_DESTINATION_ASC = 'pinDestination_ASC',
  PIN_DESTINATION_DESC = 'pinDestination_DESC',
  LATITUDE_ASC = 'latitude_ASC',
  LATITUDE_DESC = 'latitude_DESC',
  LONGITUDE_ASC = 'longitude_ASC',
  LONGITUDE_DESC = 'longitude_DESC'
}

/** Record of type Map Pin (map_pin) */
export type MapPinRecord = RecordInterface & {
  __typename?: 'MapPinRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  latitude?: Maybe<Scalars['FloatType']>;
  longitude?: Maybe<Scalars['FloatType']>;
  pinCopy?: Maybe<Scalars['String']>;
  pinDestination?: Maybe<Scalars['String']>;
  pinTitle?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type Map Pin (map_pin) */
export type MapPinRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Block of type Map (map) */
export type MapRecord = RecordInterface & {
  __typename?: 'MapRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  blockCardIdentify?: Maybe<Scalars['String']>;
  blockMenuName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  heading?: Maybe<MapModelHeadingField>;
  id: Scalars['ItemId'];
  mapCentreLatitude?: Maybe<Scalars['FloatType']>;
  mapCentreLongitude?: Maybe<Scalars['FloatType']>;
  mapPins: Array<MapPinRecord>;
  onLaptop?: Maybe<Scalars['String']>;
  onMobile?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  zoomLevel?: Maybe<Scalars['FloatType']>;
};


/** Block of type Map (map) */
export type MapRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type MemberCardModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  logo?: Maybe<FileFilter>;
  image?: Maybe<FileFilter>;
  bodyCopy?: Maybe<StringFilter>;
  memberTitle?: Maybe<StringFilter>;
  member?: Maybe<StringFilter>;
  reference?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<MemberCardModelFilter>>>;
  AND?: Maybe<Array<Maybe<MemberCardModelFilter>>>;
};

export enum MemberCardModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  BODY_COPY_ASC = 'bodyCopy_ASC',
  BODY_COPY_DESC = 'bodyCopy_DESC',
  MEMBER_TITLE_ASC = 'memberTitle_ASC',
  MEMBER_TITLE_DESC = 'memberTitle_DESC',
  MEMBER_ASC = 'member_ASC',
  MEMBER_DESC = 'member_DESC',
  REFERENCE_ASC = 'reference_ASC',
  REFERENCE_DESC = 'reference_DESC'
}

/** Record of type Person Card (member_card) */
export type MemberCardRecord = RecordInterface & {
  __typename?: 'MemberCardRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  bodyCopy?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  logo?: Maybe<FileField>;
  member?: Maybe<Scalars['String']>;
  memberTitle?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type Person Card (member_card) */
export type MemberCardRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};


export type MultiColumnBlockModelHeadingField = {
  __typename?: 'MultiColumnBlockModelHeadingField';
  blocks: Array<Scalars['String']>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

/** Block of type Multi Column Block (multi_column_block) */
export type MultiColumnBlockRecord = RecordInterface & {
  __typename?: 'MultiColumnBlockRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColour?: Maybe<ColorField>;
  blockCardIdentify?: Maybe<Scalars['String']>;
  blockMenuName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  heading?: Maybe<MultiColumnBlockModelHeadingField>;
  hideSeparatorLine?: Maybe<Scalars['BooleanType']>;
  id: Scalars['ItemId'];
  numberOfColumns?: Maybe<Scalars['String']>;
  onLaptop?: Maybe<Scalars['String']>;
  onMobile?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  textBlocks: Array<StructuredTextRecord>;
  theme?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** Block of type Multi Column Block (multi_column_block) */
export type MultiColumnBlockRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export enum MuxThumbnailFormatType {
  JPG = 'jpg',
  PNG = 'png',
  GIF = 'gif'
}

/** Record of type Navbar CTA (navbar_cta) */
export type NavbarCtaRecord = RecordInterface & {
  __typename?: 'NavbarCtaRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  link?: Maybe<LinkRecord>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type Navbar CTA (navbar_cta) */
export type NavbarCtaRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type NavbarItemModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  parent?: Maybe<ParentFilter>;
  position?: Maybe<PositionFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  name?: Maybe<StringFilter>;
  link?: Maybe<LinkFilter>;
  OR?: Maybe<Array<Maybe<NavbarItemModelFilter>>>;
  AND?: Maybe<Array<Maybe<NavbarItemModelFilter>>>;
};

export type NavbarItemModelLinkField = ArticleCategoryRecord | ArticleRecord | BlogHomepageRecord | CustomPageRecord | LocationSiteRecord | NexudusLinkRecord | SolutionTypeRecord | WorkspaceRecord;

export enum NavbarItemModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  POSITION_ASC = 'position_ASC',
  POSITION_DESC = 'position_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC'
}

/** Record of type Navbar Item (navbar_item) */
export type NavbarItemRecord = RecordInterface & {
  __typename?: 'NavbarItemRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  children: Array<NavbarItemRecord>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  link?: Maybe<NavbarItemModelLinkField>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<NavbarItemRecord>;
  position?: Maybe<Scalars['IntType']>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type Navbar Item (navbar_item) */
export type NavbarItemRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type NewsletterSignupBlockModelMainCopyField = {
  __typename?: 'NewsletterSignupBlockModelMainCopyField';
  blocks: Array<Scalars['String']>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

export type NewsletterSignupBlockModelSmallprintCopyField = {
  __typename?: 'NewsletterSignupBlockModelSmallprintCopyField';
  blocks: Array<Scalars['String']>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

/** Block of type Newsletter Signup Block (newsletter_signup_block) */
export type NewsletterSignupBlockRecord = RecordInterface & {
  __typename?: 'NewsletterSignupBlockRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  buttonCopy?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  mainCopy?: Maybe<NewsletterSignupBlockModelMainCopyField>;
  placeholderCopy?: Maybe<Scalars['String']>;
  smallprintCopy?: Maybe<NewsletterSignupBlockModelSmallprintCopyField>;
  updatedAt: Scalars['DateTime'];
};


/** Block of type Newsletter Signup Block (newsletter_signup_block) */
export type NewsletterSignupBlockRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type NewsletterSignupGdprCopyModelTextField = {
  __typename?: 'NewsletterSignupGdprCopyModelTextField';
  blocks: Array<Scalars['String']>;
  links: Array<Scalars['String']>;
  value: Scalars['JsonField'];
};

/** Record of type Newsletter Signup GDPR Copy (newsletter_signup_gdpr_copy) */
export type NewsletterSignupGdprCopyRecord = RecordInterface & {
  __typename?: 'NewsletterSignupGdprCopyRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  text?: Maybe<NewsletterSignupGdprCopyModelTextField>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type Newsletter Signup GDPR Copy (newsletter_signup_gdpr_copy) */
export type NewsletterSignupGdprCopyRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type NexudusLinkModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  reference?: Maybe<StringFilter>;
  urlPath?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<NexudusLinkModelFilter>>>;
  AND?: Maybe<Array<Maybe<NexudusLinkModelFilter>>>;
};

export enum NexudusLinkModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  REFERENCE_ASC = 'reference_ASC',
  REFERENCE_DESC = 'reference_DESC',
  URL_PATH_ASC = 'urlPath_ASC',
  URL_PATH_DESC = 'urlPath_DESC'
}

/** Record of type Nexudus Link (nexudus_link) */
export type NexudusLinkRecord = RecordInterface & {
  __typename?: 'NexudusLinkRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  reference?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  urlPath?: Maybe<Scalars['String']>;
};


/** Record of type Nexudus Link (nexudus_link) */
export type NexudusLinkRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Specifies how to filter by image orientation */
export type OrientationFilter = {
  /** Search uploads with the specified orientation */
  eq?: Maybe<UploadOrientation>;
  /** Exclude uploads with the specified orientation */
  neq?: Maybe<UploadOrientation>;
};

export type PageContentModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  injectScript?: Maybe<TextFilter>;
  reference?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<PageContentModelFilter>>>;
  AND?: Maybe<Array<Maybe<PageContentModelFilter>>>;
};

export enum PageContentModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  REFERENCE_ASC = 'reference_ASC',
  REFERENCE_DESC = 'reference_DESC'
}

export type PageContentModelReusableComponentsField = CardGridRecord | ContactEmbedRecord | CustomHtmlBlockRecord | ECtaBlockRecord | FaqsBlockRecord | FeatureBlockRecord | HeaderCentreRecord | HeaderTextAndImageRecord | HeaderTextLeftRecord | LogoWallRecord | MapRecord | MultiColumnBlockRecord | SimpleCtaBlockRecord | TestimonialGridRecord | TestimonialSingleRecord | TestimonialsCarouselRecord;

/** Record of type Page Content (page_content) */
export type PageContentRecord = RecordInterface & {
  __typename?: 'PageContentRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  injectScript?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  reusableComponents: Array<PageContentModelReusableComponentsField>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type Page Content (page_content) */
export type PageContentRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};


/** Record of type Page Content (page_content) */
export type PageContentRecordInjectScriptArgs = {
  markdown?: Maybe<Scalars['Boolean']>;
};

/** Specifies how to filter by parent (tree-like collections only) */
export type ParentFilter = {
  /** Filter records children of the specified record. Value must be a Record ID */
  eq?: Maybe<Scalars['ItemId']>;
  /** Filter records with a parent record or not */
  exists?: Maybe<Scalars['BooleanType']>;
};

/** Specifies how to filter by position (sorted and tree-like collections) */
export type PositionFilter = {
  /** Filter records with a value that's strictly greater than the one specified */
  gt?: Maybe<Scalars['IntType']>;
  /** Filter records with a value that's less than the one specified */
  lt?: Maybe<Scalars['IntType']>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte?: Maybe<Scalars['IntType']>;
  /** Filter records with a value that's less or equal than the one specified */
  lte?: Maybe<Scalars['IntType']>;
  /** Search for records with an exact match */
  eq?: Maybe<Scalars['IntType']>;
  /** Exclude records with an exact match */
  neq?: Maybe<Scalars['IntType']>;
};

/** Specifies how to filter by publication datetime */
export type PublishedAtFilter = {
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: Maybe<Scalars['DateTime']>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: Maybe<Scalars['DateTime']>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: Maybe<Scalars['DateTime']>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: Maybe<Scalars['DateTime']>;
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: Maybe<Scalars['DateTime']>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: Maybe<Scalars['DateTime']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars['BooleanType']>;
};

/** The query root for this schema */
export type Query = {
  __typename?: 'Query';
  /** Returns meta information regarding a record collection */
  _allArticleCategoriesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allArticlesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allCitiesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allCustomPagesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allFaqsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allFeaturesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allFloatingCtasMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allFooterItemsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allGenericCardsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allHeadingsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allImageCarouselModelsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allImagesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allLinksMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allLocationSitesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allMapPinsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allMemberCardsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allNavbarItemsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allNexudusLinksMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allPageContentsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allSolutionTypesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allStructuredTextsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allTestimonialsMeta: CollectionMetadata;
  /** Returns meta information regarding an assets collection */
  _allUploadsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allVimeoVideosMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allWorkspacesMeta: CollectionMetadata;
  /** Returns the single instance record */
  _site: Site;
  /** Returns a collection of records */
  allArticleCategories: Array<ArticleCategoryRecord>;
  /** Returns a collection of records */
  allArticles: Array<ArticleRecord>;
  /** Returns a collection of records */
  allCities: Array<CityRecord>;
  /** Returns a collection of records */
  allCustomPages: Array<CustomPageRecord>;
  /** Returns a collection of records */
  allFaqs: Array<FaqRecord>;
  /** Returns a collection of records */
  allFeatures: Array<FeatureRecord>;
  /** Returns a collection of records */
  allFloatingCtas: Array<FloatingCtaRecord>;
  /** Returns a collection of records */
  allFooterItems: Array<FooterItemRecord>;
  /** Returns a collection of records */
  allGenericCards: Array<GenericCardRecord>;
  /** Returns a collection of records */
  allHeadings: Array<HeadingRecord>;
  /** Returns a collection of records */
  allImageCarouselModels: Array<ImageCarouselModelRecord>;
  /** Returns a collection of records */
  allImages: Array<ImageRecord>;
  /** Returns a collection of records */
  allLinks: Array<LinkRecord>;
  /** Returns a collection of records */
  allLocationSites: Array<LocationSiteRecord>;
  /** Returns a collection of records */
  allMapPins: Array<MapPinRecord>;
  /** Returns a collection of records */
  allMemberCards: Array<MemberCardRecord>;
  /** Returns a collection of records */
  allNavbarItems: Array<NavbarItemRecord>;
  /** Returns a collection of records */
  allNexudusLinks: Array<NexudusLinkRecord>;
  /** Returns a collection of records */
  allPageContents: Array<PageContentRecord>;
  /** Returns a collection of records */
  allSolutionTypes: Array<SolutionTypeRecord>;
  /** Returns a collection of records */
  allStructuredTexts: Array<StructuredTextRecord>;
  /** Returns a collection of records */
  allTestimonials: Array<TestimonialRecord>;
  /** Returns a collection of assets */
  allUploads: Array<FileField>;
  /** Returns a collection of records */
  allVimeoVideos: Array<VimeoVideoRecord>;
  /** Returns a collection of records */
  allWorkspaces: Array<WorkspaceRecord>;
  /** Returns a specific record */
  article?: Maybe<ArticleRecord>;
  /** Returns a specific record */
  articleCategory?: Maybe<ArticleCategoryRecord>;
  /** Returns the single instance record */
  blogHomepage?: Maybe<BlogHomepageRecord>;
  /** Returns a specific record */
  city?: Maybe<CityRecord>;
  /** Returns a specific record */
  customPage?: Maybe<CustomPageRecord>;
  /** Returns a specific record */
  faq?: Maybe<FaqRecord>;
  /** Returns a specific record */
  feature?: Maybe<FeatureRecord>;
  /** Returns a specific record */
  floatingCta?: Maybe<FloatingCtaRecord>;
  /** Returns a specific record */
  footerItem?: Maybe<FooterItemRecord>;
  /** Returns a specific record */
  genericCard?: Maybe<GenericCardRecord>;
  /** Returns a specific record */
  heading?: Maybe<HeadingRecord>;
  /** Returns a specific record */
  image?: Maybe<ImageRecord>;
  /** Returns a specific record */
  imageCarouselModel?: Maybe<ImageCarouselModelRecord>;
  /** Returns a specific record */
  link?: Maybe<LinkRecord>;
  /** Returns a specific record */
  locationSite?: Maybe<LocationSiteRecord>;
  /** Returns a specific record */
  mapPin?: Maybe<MapPinRecord>;
  /** Returns a specific record */
  memberCard?: Maybe<MemberCardRecord>;
  /** Returns the single instance record */
  navbarCta?: Maybe<NavbarCtaRecord>;
  /** Returns a specific record */
  navbarItem?: Maybe<NavbarItemRecord>;
  /** Returns the single instance record */
  newsletterSignupGdprCopy?: Maybe<NewsletterSignupGdprCopyRecord>;
  /** Returns a specific record */
  nexudusLink?: Maybe<NexudusLinkRecord>;
  /** Returns a specific record */
  pageContent?: Maybe<PageContentRecord>;
  /** Returns the single instance record */
  robotsTxt?: Maybe<RobotsTxtRecord>;
  /** Returns the single instance record */
  siteLogo?: Maybe<SiteLogoRecord>;
  /** Returns a specific record */
  solutionType?: Maybe<SolutionTypeRecord>;
  /** Returns a specific record */
  structuredText?: Maybe<StructuredTextRecord>;
  /** Returns a specific record */
  testimonial?: Maybe<TestimonialRecord>;
  /** Returns a specific asset */
  upload?: Maybe<FileField>;
  /** Returns a specific record */
  vimeoVideo?: Maybe<VimeoVideoRecord>;
  /** Returns a specific record */
  workspace?: Maybe<WorkspaceRecord>;
  /** Returns the single instance record */
  xmlSitemap?: Maybe<XmlSitemapRecord>;
};


/** The query root for this schema */
export type Query_AllArticleCategoriesMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<ArticleCategoryModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllArticlesMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<ArticleModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllCitiesMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<CityModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllCustomPagesMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<CustomPageModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllFaqsMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<FaqModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllFeaturesMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<FeatureModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllFloatingCtasMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<FloatingCtaModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllFooterItemsMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<FooterItemModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllGenericCardsMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<GenericCardModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllHeadingsMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<HeadingModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllImageCarouselModelsMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<ImageCarouselModelModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllImagesMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<ImageModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllLinksMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<LinkModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllLocationSitesMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<LocationSiteModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllMapPinsMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<MapPinModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllMemberCardsMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<MemberCardModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllNavbarItemsMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<NavbarItemModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllNexudusLinksMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<NexudusLinkModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllPageContentsMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<PageContentModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllSolutionTypesMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<SolutionTypeModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllStructuredTextsMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<StructuredTextModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllTestimonialsMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<TestimonialModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllUploadsMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<UploadFilter>;
};


/** The query root for this schema */
export type Query_AllVimeoVideosMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<VimeoVideoModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_AllWorkspacesMetaArgs = {
  locale?: Maybe<SiteLocale>;
  filter?: Maybe<WorkspaceModelFilter>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type Query_SiteArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type QueryAllArticleCategoriesArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<ArticleCategoryModelFilter>;
  orderBy?: Maybe<Array<Maybe<ArticleCategoryModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllArticlesArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<ArticleModelFilter>;
  orderBy?: Maybe<Array<Maybe<ArticleModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllCitiesArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<CityModelFilter>;
  orderBy?: Maybe<Array<Maybe<CityModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllCustomPagesArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<CustomPageModelFilter>;
  orderBy?: Maybe<Array<Maybe<CustomPageModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllFaqsArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<FaqModelFilter>;
  orderBy?: Maybe<Array<Maybe<FaqModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllFeaturesArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<FeatureModelFilter>;
  orderBy?: Maybe<Array<Maybe<FeatureModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllFloatingCtasArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<FloatingCtaModelFilter>;
  orderBy?: Maybe<Array<Maybe<FloatingCtaModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllFooterItemsArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<FooterItemModelFilter>;
  orderBy?: Maybe<Array<Maybe<FooterItemModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllGenericCardsArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<GenericCardModelFilter>;
  orderBy?: Maybe<Array<Maybe<GenericCardModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllHeadingsArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<HeadingModelFilter>;
  orderBy?: Maybe<Array<Maybe<HeadingModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllImageCarouselModelsArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<ImageCarouselModelModelFilter>;
  orderBy?: Maybe<Array<Maybe<ImageCarouselModelModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllImagesArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<ImageModelFilter>;
  orderBy?: Maybe<Array<Maybe<ImageModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllLinksArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<LinkModelFilter>;
  orderBy?: Maybe<Array<Maybe<LinkModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllLocationSitesArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<LocationSiteModelFilter>;
  orderBy?: Maybe<Array<Maybe<LocationSiteModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllMapPinsArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<MapPinModelFilter>;
  orderBy?: Maybe<Array<Maybe<MapPinModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllMemberCardsArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<MemberCardModelFilter>;
  orderBy?: Maybe<Array<Maybe<MemberCardModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllNavbarItemsArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<NavbarItemModelFilter>;
  orderBy?: Maybe<Array<Maybe<NavbarItemModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllNexudusLinksArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<NexudusLinkModelFilter>;
  orderBy?: Maybe<Array<Maybe<NexudusLinkModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllPageContentsArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<PageContentModelFilter>;
  orderBy?: Maybe<Array<Maybe<PageContentModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllSolutionTypesArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<SolutionTypeModelFilter>;
  orderBy?: Maybe<Array<Maybe<SolutionTypeModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllStructuredTextsArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<StructuredTextModelFilter>;
  orderBy?: Maybe<Array<Maybe<StructuredTextModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllTestimonialsArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<TestimonialModelFilter>;
  orderBy?: Maybe<Array<Maybe<TestimonialModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllUploadsArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<UploadFilter>;
  orderBy?: Maybe<Array<Maybe<UploadOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllVimeoVideosArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<VimeoVideoModelFilter>;
  orderBy?: Maybe<Array<Maybe<VimeoVideoModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryAllWorkspacesArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  skip?: Maybe<Scalars['IntType']>;
  first?: Maybe<Scalars['IntType']>;
  filter?: Maybe<WorkspaceModelFilter>;
  orderBy?: Maybe<Array<Maybe<WorkspaceModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryArticleArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<ArticleModelFilter>;
  orderBy?: Maybe<Array<Maybe<ArticleModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryArticleCategoryArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<ArticleCategoryModelFilter>;
  orderBy?: Maybe<Array<Maybe<ArticleCategoryModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryBlogHomepageArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type QueryCityArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<CityModelFilter>;
  orderBy?: Maybe<Array<Maybe<CityModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryCustomPageArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<CustomPageModelFilter>;
  orderBy?: Maybe<Array<Maybe<CustomPageModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryFaqArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<FaqModelFilter>;
  orderBy?: Maybe<Array<Maybe<FaqModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryFeatureArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<FeatureModelFilter>;
  orderBy?: Maybe<Array<Maybe<FeatureModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryFloatingCtaArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<FloatingCtaModelFilter>;
  orderBy?: Maybe<Array<Maybe<FloatingCtaModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryFooterItemArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<FooterItemModelFilter>;
  orderBy?: Maybe<Array<Maybe<FooterItemModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryGenericCardArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<GenericCardModelFilter>;
  orderBy?: Maybe<Array<Maybe<GenericCardModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryHeadingArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<HeadingModelFilter>;
  orderBy?: Maybe<Array<Maybe<HeadingModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryImageArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<ImageModelFilter>;
  orderBy?: Maybe<Array<Maybe<ImageModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryImageCarouselModelArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<ImageCarouselModelModelFilter>;
  orderBy?: Maybe<Array<Maybe<ImageCarouselModelModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryLinkArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<LinkModelFilter>;
  orderBy?: Maybe<Array<Maybe<LinkModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryLocationSiteArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<LocationSiteModelFilter>;
  orderBy?: Maybe<Array<Maybe<LocationSiteModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryMapPinArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<MapPinModelFilter>;
  orderBy?: Maybe<Array<Maybe<MapPinModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryMemberCardArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<MemberCardModelFilter>;
  orderBy?: Maybe<Array<Maybe<MemberCardModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryNavbarCtaArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type QueryNavbarItemArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<NavbarItemModelFilter>;
  orderBy?: Maybe<Array<Maybe<NavbarItemModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryNewsletterSignupGdprCopyArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type QueryNexudusLinkArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<NexudusLinkModelFilter>;
  orderBy?: Maybe<Array<Maybe<NexudusLinkModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryPageContentArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<PageContentModelFilter>;
  orderBy?: Maybe<Array<Maybe<PageContentModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryRobotsTxtArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type QuerySiteLogoArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


/** The query root for this schema */
export type QuerySolutionTypeArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<SolutionTypeModelFilter>;
  orderBy?: Maybe<Array<Maybe<SolutionTypeModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryStructuredTextArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<StructuredTextModelFilter>;
  orderBy?: Maybe<Array<Maybe<StructuredTextModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryTestimonialArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<TestimonialModelFilter>;
  orderBy?: Maybe<Array<Maybe<TestimonialModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryUploadArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<UploadFilter>;
  orderBy?: Maybe<Array<Maybe<UploadOrderBy>>>;
};


/** The query root for this schema */
export type QueryVimeoVideoArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<VimeoVideoModelFilter>;
  orderBy?: Maybe<Array<Maybe<VimeoVideoModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryWorkspaceArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
  filter?: Maybe<WorkspaceModelFilter>;
  orderBy?: Maybe<Array<Maybe<WorkspaceModelOrderBy>>>;
};


/** The query root for this schema */
export type QueryXmlSitemapArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};

/** Block of type Quote Block (quote_block) */
export type QuoteBlockRecord = RecordInterface & {
  __typename?: 'QuoteBlockRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  quote?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** Block of type Quote Block (quote_block) */
export type QuoteBlockRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type RecordInterface = {
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
};


export type RecordInterface_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Specifies how to filter by upload type */
export type ResolutionFilter = {
  /** Search uploads with the specified resolution */
  eq?: Maybe<ResolutionType>;
  /** Exclude uploads with the specified resolution */
  neq?: Maybe<ResolutionType>;
  /** Search uploads with the specified resolutions */
  in?: Maybe<Array<Maybe<ResolutionType>>>;
  /** Search uploads without the specified resolutions */
  notIn?: Maybe<Array<Maybe<ResolutionType>>>;
};

export enum ResolutionType {
  ICON = 'icon',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

export type ResponsiveImage = {
  __typename?: 'ResponsiveImage';
  alt?: Maybe<Scalars['String']>;
  aspectRatio: Scalars['FloatType'];
  base64?: Maybe<Scalars['String']>;
  bgColor?: Maybe<Scalars['String']>;
  height: Scalars['IntType'];
  sizes: Scalars['String'];
  src: Scalars['String'];
  srcSet: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  webpSrcSet: Scalars['String'];
  width: Scalars['IntType'];
};

export type RichTextModelCopyField = {
  __typename?: 'RichTextModelCopyField';
  blocks: Array<Scalars['String']>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

/** Block of type Rich Text (rich_text) */
export type RichTextRecord = RecordInterface & {
  __typename?: 'RichTextRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  copy?: Maybe<RichTextModelCopyField>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  updatedAt: Scalars['DateTime'];
};


/** Block of type Rich Text (rich_text) */
export type RichTextRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type Robots.txt (robots_txt) */
export type RobotsTxtRecord = RecordInterface & {
  __typename?: 'RobotsTxtRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  robotsTxtFile?: Maybe<FileField>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type Robots.txt (robots_txt) */
export type RobotsTxtRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type SeoField = {
  __typename?: 'SeoField';
  description?: Maybe<Scalars['String']>;
  image?: Maybe<FileField>;
  noIndex?: Maybe<Scalars['BooleanType']>;
  title?: Maybe<Scalars['String']>;
  twitterCard?: Maybe<Scalars['String']>;
};

/** Specifies how to filter SEO meta tags fields */
export type SeoFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars['BooleanType']>;
};

export type SimpleCtaBlockModelStructuredTextField = {
  __typename?: 'SimpleCtaBlockModelStructuredTextField';
  blocks: Array<FindASpaceButtonRecord>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

/** Block of type Simple CTA Block (simple_cta_block) */
export type SimpleCtaBlockRecord = RecordInterface & {
  __typename?: 'SimpleCtaBlockRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColour?: Maybe<ColorField>;
  blockCardIdentify?: Maybe<Scalars['String']>;
  blockMenuName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  ctaOne?: Maybe<LinkRecord>;
  ctaTwo?: Maybe<LinkRecord>;
  hideSeparatorLine?: Maybe<Scalars['BooleanType']>;
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  imageSide?: Maybe<Scalars['String']>;
  onLaptop?: Maybe<Scalars['String']>;
  onMobile?: Maybe<Scalars['String']>;
  searchForm?: Maybe<Scalars['String']>;
  structuredText?: Maybe<SimpleCtaBlockModelStructuredTextField>;
  updatedAt: Scalars['DateTime'];
};


/** Block of type Simple CTA Block (simple_cta_block) */
export type SimpleCtaBlockRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};


/** Block of type Simple CTA Block (simple_cta_block) */
export type SimpleCtaBlockRecordSearchFormArgs = {
  markdown?: Maybe<Scalars['Boolean']>;
};

export type Site = {
  __typename?: 'Site';
  favicon?: Maybe<FileField>;
  faviconMetaTags: Array<Tag>;
  globalSeo?: Maybe<GlobalSeoField>;
  locales: Array<SiteLocale>;
  noIndex?: Maybe<Scalars['BooleanType']>;
};


export type SiteFaviconMetaTagsArgs = {
  variants?: Maybe<Array<Maybe<FaviconType>>>;
};


export type SiteGlobalSeoArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};

export enum SiteLocale {
  EN = 'en'
}

/** Record of type Site Logo (site_logo) */
export type SiteLogoRecord = RecordInterface & {
  __typename?: 'SiteLogoRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  footerImage?: Maybe<FileField>;
  headerImage?: Maybe<FileField>;
  id: Scalars['ItemId'];
  updatedAt: Scalars['DateTime'];
};


/** Record of type Site Logo (site_logo) */
export type SiteLogoRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Specifies how to filter Slug fields */
export type SlugFilter = {
  /** Search for records with an exact match */
  eq?: Maybe<Scalars['String']>;
  /** Exclude records with an exact match */
  neq?: Maybe<Scalars['String']>;
  /** Filter records that have one of the specified slugs */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filter records that do have one of the specified slugs */
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SolutionTypeModelAssetsField = ImageRecord | VimeoVideoRecord;

export type SolutionTypeModelBreadcrumbField = ArticleCategoryRecord | ArticleRecord | BlogHomepageRecord | CustomPageRecord | LocationSiteRecord | SolutionTypeRecord;

export type SolutionTypeModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  seoStructuredData?: Maybe<JsonFilter>;
  metaTags?: Maybe<SeoFilter>;
  assets?: Maybe<LinksFilter>;
  floatingCta?: Maybe<LinkFilter>;
  cardImage?: Maybe<FileFilter>;
  breadcrumb?: Maybe<LinksFilter>;
  showBreadcrumb?: Maybe<BooleanFilter>;
  reusableComponents?: Maybe<LinkFilter>;
  urlPath?: Maybe<StringFilter>;
  reference?: Maybe<StringFilter>;
  solutionType?: Maybe<StringFilter>;
  cardSubheading?: Maybe<StringFilter>;
  cardHeading?: Maybe<StringFilter>;
  cardCopy?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<SolutionTypeModelFilter>>>;
  AND?: Maybe<Array<Maybe<SolutionTypeModelFilter>>>;
};

export enum SolutionTypeModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  SHOW_BREADCRUMB_ASC = 'showBreadcrumb_ASC',
  SHOW_BREADCRUMB_DESC = 'showBreadcrumb_DESC',
  URL_PATH_ASC = 'urlPath_ASC',
  URL_PATH_DESC = 'urlPath_DESC',
  REFERENCE_ASC = 'reference_ASC',
  REFERENCE_DESC = 'reference_DESC',
  SOLUTION_TYPE_ASC = 'solutionType_ASC',
  SOLUTION_TYPE_DESC = 'solutionType_DESC',
  CARD_SUBHEADING_ASC = 'cardSubheading_ASC',
  CARD_SUBHEADING_DESC = 'cardSubheading_DESC',
  CARD_HEADING_ASC = 'cardHeading_ASC',
  CARD_HEADING_DESC = 'cardHeading_DESC',
  CARD_COPY_ASC = 'cardCopy_ASC',
  CARD_COPY_DESC = 'cardCopy_DESC'
}

/** Record of type Solution Type (solution_type) */
export type SolutionTypeRecord = RecordInterface & {
  __typename?: 'SolutionTypeRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  assets: Array<SolutionTypeModelAssetsField>;
  breadcrumb: Array<SolutionTypeModelBreadcrumbField>;
  cardCopy?: Maybe<Scalars['String']>;
  cardHeading?: Maybe<Scalars['String']>;
  cardImage?: Maybe<FileField>;
  cardSubheading?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  floatingCta?: Maybe<FloatingCtaRecord>;
  id: Scalars['ItemId'];
  metaTags?: Maybe<SeoField>;
  reference?: Maybe<Scalars['String']>;
  reusableComponents?: Maybe<PageContentRecord>;
  seoStructuredData?: Maybe<Scalars['JsonField']>;
  showBreadcrumb?: Maybe<Scalars['BooleanType']>;
  solutionType?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  urlPath?: Maybe<Scalars['String']>;
};


/** Record of type Solution Type (solution_type) */
export type SolutionTypeRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Specifies how to filter by status */
export type StatusFilter = {
  /** Search the record with the specified status */
  eq?: Maybe<ItemStatus>;
  /** Exclude the record with the specified status */
  neq?: Maybe<ItemStatus>;
  /** Search records with the specified statuses */
  in?: Maybe<Array<Maybe<ItemStatus>>>;
  /** Search records without the specified statuses */
  notIn?: Maybe<Array<Maybe<ItemStatus>>>;
};

/** Specifies how to filter Single-line string fields */
export type StringFilter = {
  /** Filter records based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude records based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank?: Maybe<Scalars['BooleanType']>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: Maybe<Scalars['BooleanType']>;
  /** Search for records with an exact match */
  eq?: Maybe<Scalars['String']>;
  /** Exclude records with an exact match */
  neq?: Maybe<Scalars['String']>;
  /** Filter records that equal one of the specified values */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filter records that do not equal one of the specified values */
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: Maybe<Scalars['BooleanType']>;
};

export type StringMatchesFilter = {
  pattern: Scalars['String'];
  caseSensitive?: Maybe<Scalars['BooleanType']>;
  regexp?: Maybe<Scalars['BooleanType']>;
};

/** Specifies how to filter Structured Text fields values */
export type StructuredTextFilter = {
  /** Filter records based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude records based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
  /** Filter records with the specified field set as blank (null or single empty paragraph) */
  isBlank?: Maybe<Scalars['BooleanType']>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: Maybe<Scalars['BooleanType']>;
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: Maybe<Scalars['BooleanType']>;
};

export type StructuredTextModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  structuredText?: Maybe<StructuredTextFilter>;
  reference?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<StructuredTextModelFilter>>>;
  AND?: Maybe<Array<Maybe<StructuredTextModelFilter>>>;
};

export enum StructuredTextModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  REFERENCE_ASC = 'reference_ASC',
  REFERENCE_DESC = 'reference_DESC'
}

export type StructuredTextModelStructuredTextBlocksField = CustomHtmlBlockRecord | FindASpaceButtonRecord | HighlightedTextBlockRecord | IconBlockRecord | ImageBlockRecord;

export type StructuredTextModelStructuredTextField = {
  __typename?: 'StructuredTextModelStructuredTextField';
  blocks: Array<StructuredTextModelStructuredTextBlocksField>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

/** Record of type Structured Text (structured_text) */
export type StructuredTextRecord = RecordInterface & {
  __typename?: 'StructuredTextRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  reference?: Maybe<Scalars['String']>;
  structuredText?: Maybe<StructuredTextModelStructuredTextField>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type Structured Text (structured_text) */
export type StructuredTextRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type Tag = {
  __typename?: 'Tag';
  attributes?: Maybe<Scalars['MetaTagAttributes']>;
  content?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
};

export type TestimonialGridModelHeadingField = {
  __typename?: 'TestimonialGridModelHeadingField';
  blocks: Array<Scalars['String']>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

/** Block of type Testimonial Grid (testimonial_grid) */
export type TestimonialGridRecord = RecordInterface & {
  __typename?: 'TestimonialGridRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  blockCardIdentify?: Maybe<Scalars['String']>;
  blockMenuName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  heading?: Maybe<TestimonialGridModelHeadingField>;
  id: Scalars['ItemId'];
  onLaptop?: Maybe<Scalars['String']>;
  onMobile?: Maybe<Scalars['String']>;
  testimonials: Array<TestimonialRecord>;
  updatedAt: Scalars['DateTime'];
};


/** Block of type Testimonial Grid (testimonial_grid) */
export type TestimonialGridRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type TestimonialModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  backgroundColor?: Maybe<ColorFilter>;
  logo?: Maybe<FileFilter>;
  image?: Maybe<FileFilter>;
  quote?: Maybe<StructuredTextFilter>;
  quotee?: Maybe<StringFilter>;
  reference?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<TestimonialModelFilter>>>;
  AND?: Maybe<Array<Maybe<TestimonialModelFilter>>>;
};

export enum TestimonialModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  QUOTEE_ASC = 'quotee_ASC',
  QUOTEE_DESC = 'quotee_DESC',
  REFERENCE_ASC = 'reference_ASC',
  REFERENCE_DESC = 'reference_DESC'
}

export type TestimonialModelQuoteField = {
  __typename?: 'TestimonialModelQuoteField';
  blocks: Array<Scalars['String']>;
  links: Array<Scalars['String']>;
  value: Scalars['JsonField'];
};

/** Record of type Testimonial (testimonial) */
export type TestimonialRecord = RecordInterface & {
  __typename?: 'TestimonialRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColor?: Maybe<ColorField>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  logo?: Maybe<FileField>;
  quote?: Maybe<TestimonialModelQuoteField>;
  quotee?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** Record of type Testimonial (testimonial) */
export type TestimonialRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Block of type Testimonial Single (testimonial_single) */
export type TestimonialSingleRecord = RecordInterface & {
  __typename?: 'TestimonialSingleRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColour?: Maybe<ColorField>;
  blockCardIdentify?: Maybe<Scalars['String']>;
  blockMenuName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  image?: Maybe<FileField>;
  logo?: Maybe<FileField>;
  onLaptop?: Maybe<Scalars['String']>;
  onMobile?: Maybe<Scalars['String']>;
  quote?: Maybe<Scalars['String']>;
  quotee?: Maybe<Scalars['String']>;
  textBoxColour?: Maybe<ColorField>;
  updatedAt: Scalars['DateTime'];
};


/** Block of type Testimonial Single (testimonial_single) */
export type TestimonialSingleRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type TestimonialsCarouselModelHeadingField = {
  __typename?: 'TestimonialsCarouselModelHeadingField';
  blocks: Array<Scalars['String']>;
  links: Array<LinkRecord>;
  value: Scalars['JsonField'];
};

/** Block of type Testimonials Carousel (testimonials_carousel) */
export type TestimonialsCarouselRecord = RecordInterface & {
  __typename?: 'TestimonialsCarouselRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  backgroundColour?: Maybe<ColorField>;
  blockCardIdentify?: Maybe<Scalars['String']>;
  blockMenuName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  heading?: Maybe<TestimonialsCarouselModelHeadingField>;
  hideSeparatorLine?: Maybe<Scalars['BooleanType']>;
  id: Scalars['ItemId'];
  onLaptop?: Maybe<Scalars['String']>;
  onMobile?: Maybe<Scalars['String']>;
  testimonials: Array<TestimonialRecord>;
  updatedAt: Scalars['DateTime'];
};


/** Block of type Testimonials Carousel (testimonials_carousel) */
export type TestimonialsCarouselRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Specifies how to filter text fields */
export type TextFilter = {
  /** Filter records based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude records based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank?: Maybe<Scalars['BooleanType']>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: Maybe<Scalars['BooleanType']>;
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: Maybe<Scalars['BooleanType']>;
};

/** Specifies how to filter by upload type */
export type TypeFilter = {
  /** Search uploads with the specified type */
  eq?: Maybe<UploadType>;
  /** Exclude uploads with the specified type */
  neq?: Maybe<UploadType>;
  /** Search uploads with the specified types */
  in?: Maybe<Array<Maybe<UploadType>>>;
  /** Search uploads without the specified types */
  notIn?: Maybe<Array<Maybe<UploadType>>>;
};

/** Specifies how to filter by update datetime */
export type UpdatedAtFilter = {
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: Maybe<Scalars['DateTime']>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: Maybe<Scalars['DateTime']>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: Maybe<Scalars['DateTime']>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: Maybe<Scalars['DateTime']>;
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: Maybe<Scalars['DateTime']>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: Maybe<Scalars['DateTime']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars['BooleanType']>;
};

/** Specifies how to filter by default alt */
export type UploadAltFilter = {
  /** Filter uploads based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
  /** Search the uploads with the specified alt */
  eq?: Maybe<Scalars['String']>;
  /** Exclude the uploads with the specified alt */
  neq?: Maybe<Scalars['String']>;
  /** Search uploads with the specified values as default alt */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Search uploads that do not have the specified values as default alt */
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars['BooleanType']>;
};

/** Specifies how to filter by auhtor */
export type UploadAuthorFilter = {
  /** Filter uploads based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars['BooleanType']>;
};

/** Specifies how to filter by basename */
export type UploadBasenameFilter = {
  /** Filter uploads based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
};

/** Specifies how to filter by colors */
export type UploadColorsFilter = {
  /** Filter uploads that have the specified colors */
  contains?: Maybe<ColorBucketType>;
  /** Filter uploads that have all of the specified colors */
  allIn?: Maybe<Array<Maybe<ColorBucketType>>>;
  /** Filter uploads that have at least one of the specified colors */
  anyIn?: Maybe<Array<Maybe<ColorBucketType>>>;
  /** Filter uploads that do not have any of the specified colors */
  notIn?: Maybe<Array<Maybe<ColorBucketType>>>;
  /** Search for uploads with an exact match */
  eq?: Maybe<Array<Maybe<ColorBucketType>>>;
};

/** Specifies how to filter by copyright */
export type UploadCopyrightFilter = {
  /** Filter uploads based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars['BooleanType']>;
};

/** Specifies how to filter by creation datetime */
export type UploadCreatedAtFilter = {
  /** Search for uploads with an exact match */
  eq?: Maybe<Scalars['DateTime']>;
  /** Exclude uploads with an exact match */
  neq?: Maybe<Scalars['DateTime']>;
  /** Filter uploads with a value that's less than the one specified */
  lt?: Maybe<Scalars['DateTime']>;
  /** Filter uploads with a value that's less or equal than the one specified */
  lte?: Maybe<Scalars['DateTime']>;
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt?: Maybe<Scalars['DateTime']>;
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte?: Maybe<Scalars['DateTime']>;
};

/** Specifies how to filter by filename */
export type UploadFilenameFilter = {
  /** Filter uploads based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
};

export type UploadFilter = {
  type?: Maybe<TypeFilter>;
  inUse?: Maybe<InUseFilter>;
  resolution?: Maybe<ResolutionFilter>;
  size?: Maybe<UploadSizeFilter>;
  tags?: Maybe<UploadTagsFilter>;
  smartTags?: Maybe<UploadTagsFilter>;
  colors?: Maybe<UploadColorsFilter>;
  orientation?: Maybe<OrientationFilter>;
  id?: Maybe<UploadIdFilter>;
  mimeType?: Maybe<UploadMimeTypeFilter>;
  format?: Maybe<UploadFormatFilter>;
  height?: Maybe<UploadHeightFilter>;
  width?: Maybe<UploadWidthFilter>;
  alt?: Maybe<UploadAltFilter>;
  title?: Maybe<UploadTitleFilter>;
  notes?: Maybe<UploadNotesFilter>;
  md5?: Maybe<UploadMd5Filter>;
  author?: Maybe<UploadAuthorFilter>;
  copyright?: Maybe<UploadCopyrightFilter>;
  basename?: Maybe<UploadBasenameFilter>;
  filename?: Maybe<UploadFilenameFilter>;
  _createdAt?: Maybe<UploadCreatedAtFilter>;
  _updatedAt?: Maybe<UploadUpdatedAtFilter>;
  OR?: Maybe<Array<Maybe<UploadFilter>>>;
  AND?: Maybe<Array<Maybe<UploadFilter>>>;
};

/** Specifies how to filter by format */
export type UploadFormatFilter = {
  /** Search the asset with the specified format */
  eq?: Maybe<Scalars['String']>;
  /** Exclude the asset with the specified format */
  neq?: Maybe<Scalars['String']>;
  /** Search assets with the specified formats */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Search assets that do not have the specified formats */
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Specifies how to filter by height */
export type UploadHeightFilter = {
  /** Search all assets larger than the specified height */
  gt?: Maybe<Scalars['IntType']>;
  /** Search all assets smaller than the specified height */
  lt?: Maybe<Scalars['IntType']>;
  /** Search all assets larger or equal to the specified height */
  gte?: Maybe<Scalars['IntType']>;
  /** Search all assets larger or equal to the specified height */
  lte?: Maybe<Scalars['IntType']>;
  /** Search assets with the specified height */
  eq?: Maybe<Scalars['IntType']>;
  /** Search assets that do not have the specified height */
  neq?: Maybe<Scalars['IntType']>;
};


/** Specifies how to filter by ID */
export type UploadIdFilter = {
  /** Search the asset with the specified ID */
  eq?: Maybe<Scalars['UploadId']>;
  /** Exclude the asset with the specified ID */
  neq?: Maybe<Scalars['UploadId']>;
  /** Search assets with the specified IDs */
  in?: Maybe<Array<Maybe<Scalars['UploadId']>>>;
  /** Search assets that do not have the specified IDs */
  notIn?: Maybe<Array<Maybe<Scalars['UploadId']>>>;
};

/** Specifies how to filter by MD5 */
export type UploadMd5Filter = {
  /** Search the asset with the specified MD5 */
  eq?: Maybe<Scalars['String']>;
  /** Exclude the asset with the specified MD5 */
  neq?: Maybe<Scalars['String']>;
  /** Search assets with the specified MD5s */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Search assets that do not have the specified MD5s */
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Specifies how to filter by mime type */
export type UploadMimeTypeFilter = {
  /** Filter uploads based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
  /** Search the asset with the specified mime type */
  eq?: Maybe<Scalars['String']>;
  /** Exclude the asset with the specified mime type */
  neq?: Maybe<Scalars['String']>;
  /** Search assets with the specified mime types */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Search assets that do not have the specified mime types */
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Specifies how to filter by notes */
export type UploadNotesFilter = {
  /** Filter uploads based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars['BooleanType']>;
};

export enum UploadOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  SIZE_ASC = 'size_ASC',
  SIZE_DESC = 'size_DESC',
  RESOLUTION_ASC = 'resolution_ASC',
  RESOLUTION_DESC = 'resolution_DESC',
  FILENAME_ASC = 'filename_ASC',
  FILENAME_DESC = 'filename_DESC',
  BASENAME_ASC = 'basename_ASC',
  BASENAME_DESC = 'basename_DESC',
  MIME_TYPE_ASC = 'mimeType_ASC',
  MIME_TYPE_DESC = 'mimeType_DESC',
  FORMAT_ASC = 'format_ASC',
  FORMAT_DESC = 'format_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC'
}

export enum UploadOrientation {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
  SQUARE = 'square'
}

/** Specifies how to filter by size */
export type UploadSizeFilter = {
  /** Search all assets larger than the specified size (in bytes) */
  gt?: Maybe<Scalars['IntType']>;
  /** Search all assets smaller than the specified size (in bytes) */
  lt?: Maybe<Scalars['IntType']>;
  /** Search all assets larger or equal to the specified size (in bytes) */
  gte?: Maybe<Scalars['IntType']>;
  /** Search all assets larger or equal to the specified size (in bytes) */
  lte?: Maybe<Scalars['IntType']>;
  /** Search assets with the specified size (in bytes) */
  eq?: Maybe<Scalars['IntType']>;
  /** Search assets that do not have the specified size (in bytes) */
  neq?: Maybe<Scalars['IntType']>;
};

/** Specifies how to filter by tags */
export type UploadTagsFilter = {
  /** Filter uploads linked to the specified tag */
  contains?: Maybe<Scalars['String']>;
  /** Filter uploads linked to all of the specified tags */
  allIn?: Maybe<Array<Scalars['String']>>;
  /** Filter uploads linked to at least one of the specified tags */
  anyIn?: Maybe<Array<Scalars['String']>>;
  /** Filter uploads not linked to any of the specified tags */
  notIn?: Maybe<Array<Scalars['String']>>;
  /** Search for uploads with an exact match */
  eq?: Maybe<Array<Scalars['String']>>;
};

/** Specifies how to filter by default title */
export type UploadTitleFilter = {
  /** Filter uploads based on a regular expression */
  matches?: Maybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: Maybe<StringMatchesFilter>;
  /** Search the asset with the specified title */
  eq?: Maybe<Scalars['String']>;
  /** Exclude the asset with the specified title */
  neq?: Maybe<Scalars['String']>;
  /** Search assets with the specified as default title */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Search assets that do not have the specified as default title */
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filter assets with the specified field defined (i.e. with any value) or not */
  exists?: Maybe<Scalars['BooleanType']>;
};

export enum UploadType {
  IMAGE = 'image',
  AUDIO = 'audio',
  VIDEO = 'video',
  RICHTEXT = 'richtext',
  PRESENTATION = 'presentation',
  SPREADSHEET = 'spreadsheet',
  PDFDOCUMENT = 'pdfdocument',
  ARCHIVE = 'archive'
}

/** Specifies how to filter by update datetime */
export type UploadUpdatedAtFilter = {
  /** Search for uploads with an exact match */
  eq?: Maybe<Scalars['DateTime']>;
  /** Exclude uploads with an exact match */
  neq?: Maybe<Scalars['DateTime']>;
  /** Filter uploads with a value that's less than the one specified */
  lt?: Maybe<Scalars['DateTime']>;
  /** Filter uploads with a value that's less or equal than the one specified */
  lte?: Maybe<Scalars['DateTime']>;
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt?: Maybe<Scalars['DateTime']>;
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte?: Maybe<Scalars['DateTime']>;
};

export type UploadVideoField = {
  __typename?: 'UploadVideoField';
  alt?: Maybe<Scalars['String']>;
  blurUpThumb?: Maybe<Scalars['String']>;
  blurhash?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  framerate?: Maybe<Scalars['Int']>;
  height: Scalars['IntType'];
  mp4Url?: Maybe<Scalars['String']>;
  muxAssetId: Scalars['String'];
  muxPlaybackId: Scalars['String'];
  streamingUrl: Scalars['String'];
  thumbhash?: Maybe<Scalars['String']>;
  thumbnailUrl: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  width: Scalars['IntType'];
};


export type UploadVideoFieldAltArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};


export type UploadVideoFieldBlurUpThumbArgs = {
  punch?: Scalars['Float'];
  size?: Scalars['Int'];
  quality?: Scalars['Int'];
  imgixParams?: Maybe<ImgixParams>;
};


export type UploadVideoFieldMp4UrlArgs = {
  res?: Maybe<VideoMp4Res>;
  exactRes?: Maybe<VideoMp4Res>;
};


export type UploadVideoFieldThumbnailUrlArgs = {
  format?: Maybe<MuxThumbnailFormatType>;
};


export type UploadVideoFieldTitleArgs = {
  locale?: Maybe<SiteLocale>;
  fallbackLocales?: Maybe<Array<SiteLocale>>;
};

/** Specifies how to filter by width */
export type UploadWidthFilter = {
  /** Search all assets larger than the specified width */
  gt?: Maybe<Scalars['IntType']>;
  /** Search all assets smaller than the specified width */
  lt?: Maybe<Scalars['IntType']>;
  /** Search all assets larger or equal to the specified width */
  gte?: Maybe<Scalars['IntType']>;
  /** Search all assets larger or equal to the specified width */
  lte?: Maybe<Scalars['IntType']>;
  /** Search assets with the specified width */
  eq?: Maybe<Scalars['IntType']>;
  /** Search assets that do not have the specified width */
  neq?: Maybe<Scalars['IntType']>;
};

export enum VideoMp4Res {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high'
}

export type VimeoVideoModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  reference?: Maybe<StringFilter>;
  vimeoUrl?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<VimeoVideoModelFilter>>>;
  AND?: Maybe<Array<Maybe<VimeoVideoModelFilter>>>;
};

export enum VimeoVideoModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  REFERENCE_ASC = 'reference_ASC',
  REFERENCE_DESC = 'reference_DESC',
  VIMEO_URL_ASC = 'vimeoUrl_ASC',
  VIMEO_URL_DESC = 'vimeoUrl_DESC'
}

/** Record of type Vimeo Video (vimeo_video) */
export type VimeoVideoRecord = RecordInterface & {
  __typename?: 'VimeoVideoRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  reference?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  vimeoUrl?: Maybe<Scalars['String']>;
};


/** Record of type Vimeo Video (vimeo_video) */
export type VimeoVideoRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type WorkspaceModelAssetsField = ImageRecord | VimeoVideoRecord;

export type WorkspaceModelBreadcrumbField = ArticleCategoryRecord | ArticleRecord | BlogHomepageRecord | CustomPageRecord | LocationSiteRecord | SolutionTypeRecord | WorkspaceRecord;

export type WorkspaceModelFilter = {
  _createdAt?: Maybe<CreatedAtFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  id?: Maybe<ItemIdFilter>;
  _firstPublishedAt?: Maybe<PublishedAtFilter>;
  _publicationScheduledAt?: Maybe<PublishedAtFilter>;
  _unpublishingScheduledAt?: Maybe<PublishedAtFilter>;
  _publishedAt?: Maybe<PublishedAtFilter>;
  _status?: Maybe<StatusFilter>;
  _updatedAt?: Maybe<UpdatedAtFilter>;
  updatedAt?: Maybe<UpdatedAtFilter>;
  _isValid?: Maybe<BooleanFilter>;
  assets?: Maybe<LinksFilter>;
  name?: Maybe<StringFilter>;
  cardLabelColour?: Maybe<ColorFilter>;
  cardImage?: Maybe<FileFilter>;
  site?: Maybe<LinkFilter>;
  breadcrumb?: Maybe<LinksFilter>;
  hideOnWorkspacesPage?: Maybe<BooleanFilter>;
  metaTags?: Maybe<SeoFilter>;
  reusableComponents?: Maybe<LinksFilter>;
  seoStructuredData?: Maybe<JsonFilter>;
  showBreadcrumb?: Maybe<BooleanFilter>;
  floatingCta?: Maybe<LinkFilter>;
  reference?: Maybe<StringFilter>;
  cardLabel?: Maybe<StringFilter>;
  capacity?: Maybe<StringFilter>;
  rank?: Maybe<IntegerFilter>;
  price?: Maybe<StringFilter>;
  urlPath?: Maybe<StringFilter>;
  OR?: Maybe<Array<Maybe<WorkspaceModelFilter>>>;
  AND?: Maybe<Array<Maybe<WorkspaceModelFilter>>>;
};

export enum WorkspaceModelOrderBy {
  _CREATED_AT_ASC = '_createdAt_ASC',
  _CREATED_AT_DESC = '_createdAt_DESC',
  CREATED_AT_ASC = 'createdAt_ASC',
  CREATED_AT_DESC = 'createdAt_DESC',
  ID_ASC = 'id_ASC',
  ID_DESC = 'id_DESC',
  _FIRST_PUBLISHED_AT_ASC = '_firstPublishedAt_ASC',
  _FIRST_PUBLISHED_AT_DESC = '_firstPublishedAt_DESC',
  _PUBLICATION_SCHEDULED_AT_ASC = '_publicationScheduledAt_ASC',
  _PUBLICATION_SCHEDULED_AT_DESC = '_publicationScheduledAt_DESC',
  _UNPUBLISHING_SCHEDULED_AT_ASC = '_unpublishingScheduledAt_ASC',
  _UNPUBLISHING_SCHEDULED_AT_DESC = '_unpublishingScheduledAt_DESC',
  _PUBLISHED_AT_ASC = '_publishedAt_ASC',
  _PUBLISHED_AT_DESC = '_publishedAt_DESC',
  _STATUS_ASC = '_status_ASC',
  _STATUS_DESC = '_status_DESC',
  _UPDATED_AT_ASC = '_updatedAt_ASC',
  _UPDATED_AT_DESC = '_updatedAt_DESC',
  UPDATED_AT_ASC = 'updatedAt_ASC',
  UPDATED_AT_DESC = 'updatedAt_DESC',
  _IS_VALID_ASC = '_isValid_ASC',
  _IS_VALID_DESC = '_isValid_DESC',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  HIDE_ON_WORKSPACES_PAGE_ASC = 'hideOnWorkspacesPage_ASC',
  HIDE_ON_WORKSPACES_PAGE_DESC = 'hideOnWorkspacesPage_DESC',
  SHOW_BREADCRUMB_ASC = 'showBreadcrumb_ASC',
  SHOW_BREADCRUMB_DESC = 'showBreadcrumb_DESC',
  REFERENCE_ASC = 'reference_ASC',
  REFERENCE_DESC = 'reference_DESC',
  CARD_LABEL_ASC = 'cardLabel_ASC',
  CARD_LABEL_DESC = 'cardLabel_DESC',
  CAPACITY_ASC = 'capacity_ASC',
  CAPACITY_DESC = 'capacity_DESC',
  RANK_ASC = 'rank_ASC',
  RANK_DESC = 'rank_DESC',
  PRICE_ASC = 'price_ASC',
  PRICE_DESC = 'price_DESC',
  URL_PATH_ASC = 'urlPath_ASC',
  URL_PATH_DESC = 'urlPath_DESC'
}

/** Record of type Workspace (workspace) */
export type WorkspaceRecord = RecordInterface & {
  __typename?: 'WorkspaceRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  assets: Array<WorkspaceModelAssetsField>;
  breadcrumb: Array<WorkspaceModelBreadcrumbField>;
  capacity?: Maybe<Scalars['String']>;
  cardImage?: Maybe<FileField>;
  cardLabel?: Maybe<Scalars['String']>;
  cardLabelColour?: Maybe<ColorField>;
  createdAt: Scalars['DateTime'];
  floatingCta?: Maybe<FloatingCtaRecord>;
  hideOnWorkspacesPage?: Maybe<Scalars['BooleanType']>;
  id: Scalars['ItemId'];
  metaTags?: Maybe<SeoField>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  rank?: Maybe<Scalars['IntType']>;
  reference?: Maybe<Scalars['String']>;
  reusableComponents: Array<PageContentRecord>;
  seoStructuredData?: Maybe<Scalars['JsonField']>;
  showBreadcrumb?: Maybe<Scalars['BooleanType']>;
  site?: Maybe<LocationSiteRecord>;
  updatedAt: Scalars['DateTime'];
  urlPath?: Maybe<Scalars['String']>;
};


/** Record of type Workspace (workspace) */
export type WorkspaceRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

/** Record of type XML Sitemap (xml_sitemap) */
export type XmlSitemapRecord = RecordInterface & {
  __typename?: 'XmlSitemapRecord';
  _createdAt: Scalars['DateTime'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']>;
  _isValid: Scalars['BooleanType'];
  _modelApiKey: Scalars['String'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']>;
  _publishedAt?: Maybe<Scalars['DateTime']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']>;
  _updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ItemId'];
  updatedAt: Scalars['DateTime'];
  xmlFile?: Maybe<FileField>;
};


/** Record of type XML Sitemap (xml_sitemap) */
export type XmlSitemapRecord_SeoMetaTagsArgs = {
  locale?: Maybe<SiteLocale>;
};

export type FocalPoint = {
  __typename?: 'focalPoint';
  x: Scalars['FloatType'];
  y: Scalars['FloatType'];
};

export type CustomPageRecordBreadcrumbFragment = (
  { __typename?: 'CustomPageRecord' }
  & { breadcrumb: Array<(
    { __typename: 'ArticleCategoryRecord' }
    & LinkableArticleCategoryRecordFragment
  ) | (
    { __typename: 'ArticleRecord' }
    & LinkableArticleRecordFragment
  ) | (
    { __typename: 'BlogHomepageRecord' }
    & LinkableBlogHomepageRecordFragment
  ) | (
    { __typename: 'CustomPageRecord' }
    & LinkableCustomPageRecordFragment
  ) | (
    { __typename: 'LocationSiteRecord' }
    & LinkableLocationSiteRecordFragment
  ) | (
    { __typename: 'SolutionTypeRecord' }
    & LinkableSolutionTypeRecordFragment
  )> }
);

export type LocationSiteRecordBreadcrumbFragment = (
  { __typename?: 'LocationSiteRecord' }
  & { breadcrumb: Array<(
    { __typename: 'ArticleCategoryRecord' }
    & LinkableArticleCategoryRecordFragment
  ) | (
    { __typename: 'ArticleRecord' }
    & LinkableArticleRecordFragment
  ) | (
    { __typename: 'BlogHomepageRecord' }
    & LinkableBlogHomepageRecordFragment
  ) | (
    { __typename: 'CustomPageRecord' }
    & LinkableCustomPageRecordFragment
  ) | (
    { __typename: 'LocationSiteRecord' }
    & LinkableLocationSiteRecordFragment
  ) | (
    { __typename: 'SolutionTypeRecord' }
    & LinkableSolutionTypeRecordFragment
  )> }
);

export type SolutionTypeRecordBreadcrumbFragment = (
  { __typename?: 'SolutionTypeRecord' }
  & { breadcrumb: Array<(
    { __typename: 'ArticleCategoryRecord' }
    & LinkableArticleCategoryRecordFragment
  ) | (
    { __typename: 'ArticleRecord' }
    & LinkableArticleRecordFragment
  ) | (
    { __typename: 'BlogHomepageRecord' }
    & LinkableBlogHomepageRecordFragment
  ) | (
    { __typename: 'CustomPageRecord' }
    & LinkableCustomPageRecordFragment
  ) | (
    { __typename: 'LocationSiteRecord' }
    & LinkableLocationSiteRecordFragment
  ) | (
    { __typename: 'SolutionTypeRecord' }
    & LinkableSolutionTypeRecordFragment
  )> }
);

export type WorkspaceRecordBreadcrumbFragment = (
  { __typename?: 'WorkspaceRecord' }
  & { breadcrumb: Array<(
    { __typename: 'ArticleCategoryRecord' }
    & LinkableArticleCategoryRecordFragment
  ) | (
    { __typename: 'ArticleRecord' }
    & LinkableArticleRecordFragment
  ) | (
    { __typename: 'BlogHomepageRecord' }
    & LinkableBlogHomepageRecordFragment
  ) | (
    { __typename: 'CustomPageRecord' }
    & LinkableCustomPageRecordFragment
  ) | (
    { __typename: 'LocationSiteRecord' }
    & LinkableLocationSiteRecordFragment
  ) | (
    { __typename: 'SolutionTypeRecord' }
    & LinkableSolutionTypeRecordFragment
  ) | (
    { __typename: 'WorkspaceRecord' }
    & LinkableWorkspaceRecordFragment
  )> }
);

export type ArticleRecordBreadcrumbFragment = (
  { __typename?: 'ArticleRecord' }
  & { breadcrumb: Array<(
    { __typename: 'ArticleCategoryRecord' }
    & LinkableArticleCategoryRecordFragment
  ) | (
    { __typename: 'ArticleRecord' }
    & LinkableArticleRecordFragment
  ) | (
    { __typename: 'BlogHomepageRecord' }
    & LinkableBlogHomepageRecordFragment
  ) | (
    { __typename: 'CustomPageRecord' }
    & LinkableCustomPageRecordFragment
  ) | (
    { __typename: 'LocationSiteRecord' }
    & LinkableLocationSiteRecordFragment
  ) | (
    { __typename: 'SolutionTypeRecord' }
    & LinkableSolutionTypeRecordFragment
  )> }
);

export type ArticleCategoryRecordBreadcrumbFragment = (
  { __typename?: 'ArticleCategoryRecord' }
  & { breadcrumb: Array<(
    { __typename: 'ArticleCategoryRecord' }
    & LinkableArticleCategoryRecordFragment
  ) | (
    { __typename: 'ArticleRecord' }
    & LinkableArticleRecordFragment
  ) | (
    { __typename: 'BlogHomepageRecord' }
    & LinkableBlogHomepageRecordFragment
  ) | (
    { __typename: 'CustomPageRecord' }
    & LinkableCustomPageRecordFragment
  ) | (
    { __typename: 'LocationSiteRecord' }
    & LinkableLocationSiteRecordFragment
  ) | (
    { __typename: 'SolutionTypeRecord' }
    & LinkableSolutionTypeRecordFragment
  )> }
);

export type BlogHomePageRecordBreadcrumbFragment = (
  { __typename?: 'BlogHomepageRecord' }
  & { breadcrumb: Array<(
    { __typename: 'ArticleCategoryRecord' }
    & LinkableArticleCategoryRecordFragment
  ) | (
    { __typename: 'ArticleRecord' }
    & LinkableArticleRecordFragment
  ) | (
    { __typename: 'BlogHomepageRecord' }
    & LinkableBlogHomepageRecordFragment
  ) | (
    { __typename: 'CustomPageRecord' }
    & LinkableCustomPageRecordFragment
  ) | (
    { __typename: 'LocationSiteRecord' }
    & LinkableLocationSiteRecordFragment
  ) | (
    { __typename: 'SolutionTypeRecord' }
    & LinkableSolutionTypeRecordFragment
  )> }
);

export type ColorFragment = (
  { __typename?: 'ColorField' }
  & Pick<ColorField, 'hex'>
);

export type ImageFileFragment = (
  { __typename?: 'FileField' }
  & Pick<FileField, 'id' | 'url' | 'alt'>
);

export type ResponsiveImageFieldsFragment = (
  { __typename?: 'ResponsiveImage' }
  & Pick<ResponsiveImage, 'webpSrcSet' | 'width' | 'aspectRatio' | 'base64' | 'alt' | 'src' | 'sizes' | 'title'>
);

export type LinkableCustomPageRecordFragment = (
  { __typename?: 'CustomPageRecord' }
  & Pick<CustomPageRecord, 'id' | '_isValid' | 'urlPath' | 'pageName'>
);

export type LinkableLocationSiteRecordFragment = (
  { __typename?: 'LocationSiteRecord' }
  & Pick<LocationSiteRecord, 'id' | '_isValid' | 'urlPath' | 'siteName'>
);

export type LinkableSolutionTypeRecordFragment = (
  { __typename?: 'SolutionTypeRecord' }
  & Pick<SolutionTypeRecord, 'id' | '_isValid' | 'urlPath' | 'solutionType'>
);

export type LinkableArticleRecordFragment = (
  { __typename?: 'ArticleRecord' }
  & Pick<ArticleRecord, 'id' | '_isValid' | 'pagePath' | 'title'>
);

export type LinkableArticleCategoryRecordFragment = (
  { __typename?: 'ArticleCategoryRecord' }
  & Pick<ArticleCategoryRecord, 'id' | '_isValid' | 'categoryName' | 'pagePath'>
  & { categoryColour?: Maybe<(
    { __typename?: 'ColorField' }
    & ColorFragment
  )> }
);

export type LinkableBlogHomepageRecordFragment = (
  { __typename?: 'BlogHomepageRecord' }
  & Pick<BlogHomepageRecord, 'id' | '_isValid' | 'pageTitle' | 'pagePath'>
  & { categoryColour?: Maybe<(
    { __typename?: 'ColorField' }
    & ColorFragment
  )> }
);

export type LinkableNexudusLinkRecordFragment = (
  { __typename?: 'NexudusLinkRecord' }
  & Pick<NexudusLinkRecord, 'id' | '_isValid' | 'urlPath'>
);

export type LinkableWorkspaceRecordFragment = (
  { __typename?: 'WorkspaceRecord' }
  & Pick<WorkspaceRecord, 'id' | '_isValid' | 'urlPath'>
);

export type ArticleCardFragment = (
  { __typename?: 'ArticleRecord' }
  & Pick<ArticleRecord, 'id' | 'cardTitle' | 'cardByline' | 'cardExcerpt' | 'pagePath'>
  & { cardImage?: Maybe<(
    { __typename?: 'FileField' }
    & Pick<FileField, 'url'>
    & { responsiveImage?: Maybe<(
      { __typename?: 'ResponsiveImage' }
      & ResponsiveImageFieldsFragment
    )> }
  )>, categories: Array<(
    { __typename?: 'ArticleCategoryRecord' }
    & Pick<ArticleCategoryRecord, 'categoryName'>
    & { categoryColour?: Maybe<(
      { __typename?: 'ColorField' }
      & ColorFragment
    )> }
  )> }
);

export type WorkspaceCardFragment = (
  { __typename?: 'WorkspaceRecord' }
  & Pick<WorkspaceRecord, 'name' | 'capacity' | 'price' | 'cardLabel'>
  & { site?: Maybe<(
    { __typename?: 'LocationSiteRecord' }
    & Pick<LocationSiteRecord, 'address' | 'siteName' | 'transportLinkCopy1' | 'transportLinkCopy2'>
    & { coordinates?: Maybe<(
      { __typename?: 'LatLonField' }
      & Pick<LatLonField, 'latitude' | 'longitude'>
    )>, transportLinkImage1?: Maybe<(
      { __typename?: 'FileField' }
      & ImageFileFragment
    )>, transportLinkImage2?: Maybe<(
      { __typename?: 'FileField' }
      & ImageFileFragment
    )> }
  )>, cardImage?: Maybe<(
    { __typename?: 'FileField' }
    & Pick<FileField, 'url'>
    & { responsiveImage?: Maybe<(
      { __typename?: 'ResponsiveImage' }
      & ResponsiveImageFieldsFragment
    )> }
  )>, cardLabelColour?: Maybe<(
    { __typename?: 'ColorField' }
    & ColorFragment
  )> }
  & LinkableWorkspaceRecordFragment
);

export type MetaTagsFragment = (
  { __typename?: 'SeoField' }
  & Pick<SeoField, 'description' | 'title' | 'twitterCard'>
  & { image?: Maybe<(
    { __typename?: 'FileField' }
    & Pick<FileField, 'url'>
  )> }
);

export type StructuredTextFragment = (
  { __typename?: 'StructuredTextModelStructuredTextField' }
  & Pick<StructuredTextModelStructuredTextField, 'value'>
  & { blocks: Array<(
    { __typename?: 'CustomHtmlBlockRecord' }
    & CustomHtmlBlockFragment
  ) | { __typename?: 'FindASpaceButtonRecord' } | (
    { __typename?: 'HighlightedTextBlockRecord' }
    & Pick<HighlightedTextBlockRecord, 'id'>
    & { backgroundColour?: Maybe<(
      { __typename?: 'ColorField' }
      & ColorFragment
    )>, structuredText?: Maybe<(
      { __typename?: 'HighlightedTextBlockModelStructuredTextField' }
      & Pick<HighlightedTextBlockModelStructuredTextField, 'value'>
      & { links: Array<(
        { __typename?: 'LinkRecord' }
        & LinkableLinkRecordFragment
      )> }
    )> }
  ) | (
    { __typename?: 'IconBlockRecord' }
    & Pick<IconBlockRecord, 'id'>
    & { icon?: Maybe<(
      { __typename?: 'FileField' }
      & ImageFileFragment
    )> }
  ) | (
    { __typename?: 'ImageBlockRecord' }
    & ImageBlockFragment
  )>, links: Array<(
    { __typename?: 'LinkRecord' }
    & LinkableLinkRecordFragment
  )> }
);

export type LinkableLinkRecordFragment = (
  { __typename: 'LinkRecord' }
  & Pick<LinkRecord, 'id' | '_isValid' | 'name' | 'buttonStyle' | 'externalLink' | 'externalElementId'>
  & { link?: Maybe<(
    { __typename: 'ArticleCategoryRecord' }
    & LinkableArticleCategoryRecordFragment
  ) | (
    { __typename: 'ArticleRecord' }
    & LinkableArticleRecordFragment
  ) | (
    { __typename: 'BlogHomepageRecord' }
    & LinkableBlogHomepageRecordFragment
  ) | (
    { __typename: 'CustomPageRecord' }
    & LinkableCustomPageRecordFragment
  ) | (
    { __typename: 'LocationSiteRecord' }
    & LinkableLocationSiteRecordFragment
  ) | (
    { __typename: 'NexudusLinkRecord' }
    & LinkableNexudusLinkRecordFragment
  ) | (
    { __typename: 'SolutionTypeRecord' }
    & LinkableSolutionTypeRecordFragment
  ) | (
    { __typename: 'WorkspaceRecord' }
    & LinkableWorkspaceRecordFragment
  )> }
);

export type NavbarQueryVariables = Exact<{ [key: string]: never; }>;


export type NavbarQuery = (
  { __typename?: 'Query' }
  & { navbarCta?: Maybe<(
    { __typename?: 'NavbarCtaRecord' }
    & { link?: Maybe<(
      { __typename?: 'LinkRecord' }
      & LinkableLinkRecordFragment
    )> }
  )>, allNavbarItems: Array<(
    { __typename: 'NavbarItemRecord' }
    & Pick<NavbarItemRecord, 'id' | '_isValid' | 'name'>
    & { link?: Maybe<(
      { __typename: 'ArticleCategoryRecord' }
      & LinkableArticleCategoryRecordFragment
    ) | (
      { __typename: 'ArticleRecord' }
      & LinkableArticleRecordFragment
    ) | (
      { __typename: 'BlogHomepageRecord' }
      & LinkableBlogHomepageRecordFragment
    ) | (
      { __typename: 'CustomPageRecord' }
      & LinkableCustomPageRecordFragment
    ) | (
      { __typename: 'LocationSiteRecord' }
      & LinkableLocationSiteRecordFragment
    ) | (
      { __typename: 'NexudusLinkRecord' }
      & LinkableNexudusLinkRecordFragment
    ) | (
      { __typename: 'SolutionTypeRecord' }
      & LinkableSolutionTypeRecordFragment
    ) | (
      { __typename: 'WorkspaceRecord' }
      & LinkableWorkspaceRecordFragment
    )>, children: Array<(
      { __typename: 'NavbarItemRecord' }
      & Pick<NavbarItemRecord, 'id' | '_isValid' | 'name'>
      & { link?: Maybe<(
        { __typename: 'ArticleCategoryRecord' }
        & LinkableArticleCategoryRecordFragment
      ) | (
        { __typename: 'ArticleRecord' }
        & LinkableArticleRecordFragment
      ) | (
        { __typename: 'BlogHomepageRecord' }
        & LinkableBlogHomepageRecordFragment
      ) | (
        { __typename: 'CustomPageRecord' }
        & LinkableCustomPageRecordFragment
      ) | (
        { __typename: 'LocationSiteRecord' }
        & LinkableLocationSiteRecordFragment
      ) | (
        { __typename: 'NexudusLinkRecord' }
        & LinkableNexudusLinkRecordFragment
      ) | (
        { __typename: 'SolutionTypeRecord' }
        & LinkableSolutionTypeRecordFragment
      ) | (
        { __typename: 'WorkspaceRecord' }
        & LinkableWorkspaceRecordFragment
      )>, children: Array<(
        { __typename: 'NavbarItemRecord' }
        & Pick<NavbarItemRecord, 'id' | '_isValid' | 'name'>
        & { link?: Maybe<(
          { __typename: 'ArticleCategoryRecord' }
          & LinkableArticleCategoryRecordFragment
        ) | (
          { __typename: 'ArticleRecord' }
          & LinkableArticleRecordFragment
        ) | (
          { __typename: 'BlogHomepageRecord' }
          & LinkableBlogHomepageRecordFragment
        ) | (
          { __typename: 'CustomPageRecord' }
          & LinkableCustomPageRecordFragment
        ) | (
          { __typename: 'LocationSiteRecord' }
          & LinkableLocationSiteRecordFragment
        ) | (
          { __typename: 'NexudusLinkRecord' }
          & LinkableNexudusLinkRecordFragment
        ) | (
          { __typename: 'SolutionTypeRecord' }
          & LinkableSolutionTypeRecordFragment
        ) | (
          { __typename: 'WorkspaceRecord' }
          & LinkableWorkspaceRecordFragment
        )>, children: Array<(
          { __typename: 'NavbarItemRecord' }
          & Pick<NavbarItemRecord, 'id' | '_isValid' | 'name'>
          & { link?: Maybe<(
            { __typename: 'ArticleCategoryRecord' }
            & LinkableArticleCategoryRecordFragment
          ) | (
            { __typename: 'ArticleRecord' }
            & LinkableArticleRecordFragment
          ) | (
            { __typename: 'BlogHomepageRecord' }
            & LinkableBlogHomepageRecordFragment
          ) | (
            { __typename: 'CustomPageRecord' }
            & LinkableCustomPageRecordFragment
          ) | (
            { __typename: 'LocationSiteRecord' }
            & LinkableLocationSiteRecordFragment
          ) | (
            { __typename: 'NexudusLinkRecord' }
            & LinkableNexudusLinkRecordFragment
          ) | (
            { __typename: 'SolutionTypeRecord' }
            & LinkableSolutionTypeRecordFragment
          ) | (
            { __typename: 'WorkspaceRecord' }
            & LinkableWorkspaceRecordFragment
          )>, children: Array<(
            { __typename: 'NavbarItemRecord' }
            & Pick<NavbarItemRecord, 'id' | '_isValid' | 'name'>
            & { link?: Maybe<(
              { __typename: 'ArticleCategoryRecord' }
              & LinkableArticleCategoryRecordFragment
            ) | (
              { __typename: 'ArticleRecord' }
              & LinkableArticleRecordFragment
            ) | (
              { __typename: 'BlogHomepageRecord' }
              & LinkableBlogHomepageRecordFragment
            ) | (
              { __typename: 'CustomPageRecord' }
              & LinkableCustomPageRecordFragment
            ) | (
              { __typename: 'LocationSiteRecord' }
              & LinkableLocationSiteRecordFragment
            ) | (
              { __typename: 'NexudusLinkRecord' }
              & LinkableNexudusLinkRecordFragment
            ) | (
              { __typename: 'SolutionTypeRecord' }
              & LinkableSolutionTypeRecordFragment
            ) | (
              { __typename: 'WorkspaceRecord' }
              & LinkableWorkspaceRecordFragment
            )>, children: Array<(
              { __typename: 'NavbarItemRecord' }
              & Pick<NavbarItemRecord, 'id' | '_isValid' | 'name'>
              & { link?: Maybe<(
                { __typename: 'ArticleCategoryRecord' }
                & LinkableArticleCategoryRecordFragment
              ) | (
                { __typename: 'ArticleRecord' }
                & LinkableArticleRecordFragment
              ) | (
                { __typename: 'BlogHomepageRecord' }
                & LinkableBlogHomepageRecordFragment
              ) | (
                { __typename: 'CustomPageRecord' }
                & LinkableCustomPageRecordFragment
              ) | (
                { __typename: 'LocationSiteRecord' }
                & LinkableLocationSiteRecordFragment
              ) | (
                { __typename: 'NexudusLinkRecord' }
                & LinkableNexudusLinkRecordFragment
              ) | (
                { __typename: 'SolutionTypeRecord' }
                & LinkableSolutionTypeRecordFragment
              ) | (
                { __typename: 'WorkspaceRecord' }
                & LinkableWorkspaceRecordFragment
              )>, children: Array<(
                { __typename: 'NavbarItemRecord' }
                & Pick<NavbarItemRecord, 'id' | '_isValid' | 'name'>
                & { link?: Maybe<(
                  { __typename: 'ArticleCategoryRecord' }
                  & LinkableArticleCategoryRecordFragment
                ) | (
                  { __typename: 'ArticleRecord' }
                  & LinkableArticleRecordFragment
                ) | (
                  { __typename: 'BlogHomepageRecord' }
                  & LinkableBlogHomepageRecordFragment
                ) | (
                  { __typename: 'CustomPageRecord' }
                  & LinkableCustomPageRecordFragment
                ) | (
                  { __typename: 'LocationSiteRecord' }
                  & LinkableLocationSiteRecordFragment
                ) | (
                  { __typename: 'NexudusLinkRecord' }
                  & LinkableNexudusLinkRecordFragment
                ) | (
                  { __typename: 'SolutionTypeRecord' }
                  & LinkableSolutionTypeRecordFragment
                ) | (
                  { __typename: 'WorkspaceRecord' }
                  & LinkableWorkspaceRecordFragment
                )>, children: Array<(
                  { __typename: 'NavbarItemRecord' }
                  & Pick<NavbarItemRecord, 'id' | '_isValid' | 'name'>
                  & { link?: Maybe<(
                    { __typename: 'ArticleCategoryRecord' }
                    & LinkableArticleCategoryRecordFragment
                  ) | (
                    { __typename: 'ArticleRecord' }
                    & LinkableArticleRecordFragment
                  ) | (
                    { __typename: 'BlogHomepageRecord' }
                    & LinkableBlogHomepageRecordFragment
                  ) | (
                    { __typename: 'CustomPageRecord' }
                    & LinkableCustomPageRecordFragment
                  ) | (
                    { __typename: 'LocationSiteRecord' }
                    & LinkableLocationSiteRecordFragment
                  ) | (
                    { __typename: 'NexudusLinkRecord' }
                    & LinkableNexudusLinkRecordFragment
                  ) | (
                    { __typename: 'SolutionTypeRecord' }
                    & LinkableSolutionTypeRecordFragment
                  ) | (
                    { __typename: 'WorkspaceRecord' }
                    & LinkableWorkspaceRecordFragment
                  )>, children: Array<(
                    { __typename: 'NavbarItemRecord' }
                    & Pick<NavbarItemRecord, 'id' | '_isValid' | 'name'>
                    & { link?: Maybe<(
                      { __typename: 'ArticleCategoryRecord' }
                      & LinkableArticleCategoryRecordFragment
                    ) | (
                      { __typename: 'ArticleRecord' }
                      & LinkableArticleRecordFragment
                    ) | (
                      { __typename: 'BlogHomepageRecord' }
                      & LinkableBlogHomepageRecordFragment
                    ) | (
                      { __typename: 'CustomPageRecord' }
                      & LinkableCustomPageRecordFragment
                    ) | (
                      { __typename: 'LocationSiteRecord' }
                      & LinkableLocationSiteRecordFragment
                    ) | (
                      { __typename: 'NexudusLinkRecord' }
                      & LinkableNexudusLinkRecordFragment
                    ) | (
                      { __typename: 'SolutionTypeRecord' }
                      & LinkableSolutionTypeRecordFragment
                    ) | (
                      { __typename: 'WorkspaceRecord' }
                      & LinkableWorkspaceRecordFragment
                    )>, children: Array<(
                      { __typename: 'NavbarItemRecord' }
                      & Pick<NavbarItemRecord, 'id' | '_isValid' | 'name'>
                      & { link?: Maybe<(
                        { __typename: 'ArticleCategoryRecord' }
                        & LinkableArticleCategoryRecordFragment
                      ) | (
                        { __typename: 'ArticleRecord' }
                        & LinkableArticleRecordFragment
                      ) | (
                        { __typename: 'BlogHomepageRecord' }
                        & LinkableBlogHomepageRecordFragment
                      ) | (
                        { __typename: 'CustomPageRecord' }
                        & LinkableCustomPageRecordFragment
                      ) | (
                        { __typename: 'LocationSiteRecord' }
                        & LinkableLocationSiteRecordFragment
                      ) | (
                        { __typename: 'NexudusLinkRecord' }
                        & LinkableNexudusLinkRecordFragment
                      ) | (
                        { __typename: 'SolutionTypeRecord' }
                        & LinkableSolutionTypeRecordFragment
                      ) | (
                        { __typename: 'WorkspaceRecord' }
                        & LinkableWorkspaceRecordFragment
                      )>, children: Array<(
                        { __typename: 'NavbarItemRecord' }
                        & Pick<NavbarItemRecord, 'id' | '_isValid' | 'name'>
                        & { link?: Maybe<(
                          { __typename: 'ArticleCategoryRecord' }
                          & LinkableArticleCategoryRecordFragment
                        ) | (
                          { __typename: 'ArticleRecord' }
                          & LinkableArticleRecordFragment
                        ) | (
                          { __typename: 'BlogHomepageRecord' }
                          & LinkableBlogHomepageRecordFragment
                        ) | (
                          { __typename: 'CustomPageRecord' }
                          & LinkableCustomPageRecordFragment
                        ) | (
                          { __typename: 'LocationSiteRecord' }
                          & LinkableLocationSiteRecordFragment
                        ) | (
                          { __typename: 'NexudusLinkRecord' }
                          & LinkableNexudusLinkRecordFragment
                        ) | (
                          { __typename: 'SolutionTypeRecord' }
                          & LinkableSolutionTypeRecordFragment
                        ) | (
                          { __typename: 'WorkspaceRecord' }
                          & LinkableWorkspaceRecordFragment
                        )>, children: Array<(
                          { __typename: 'NavbarItemRecord' }
                          & Pick<NavbarItemRecord, 'id' | '_isValid' | 'name'>
                          & { link?: Maybe<(
                            { __typename: 'ArticleCategoryRecord' }
                            & LinkableArticleCategoryRecordFragment
                          ) | (
                            { __typename: 'ArticleRecord' }
                            & LinkableArticleRecordFragment
                          ) | (
                            { __typename: 'BlogHomepageRecord' }
                            & LinkableBlogHomepageRecordFragment
                          ) | (
                            { __typename: 'CustomPageRecord' }
                            & LinkableCustomPageRecordFragment
                          ) | (
                            { __typename: 'LocationSiteRecord' }
                            & LinkableLocationSiteRecordFragment
                          ) | (
                            { __typename: 'NexudusLinkRecord' }
                            & LinkableNexudusLinkRecordFragment
                          ) | (
                            { __typename: 'SolutionTypeRecord' }
                            & LinkableSolutionTypeRecordFragment
                          ) | (
                            { __typename: 'WorkspaceRecord' }
                            & LinkableWorkspaceRecordFragment
                          )>, children: Array<(
                            { __typename: 'NavbarItemRecord' }
                            & Pick<NavbarItemRecord, 'id' | '_isValid' | 'name'>
                            & { link?: Maybe<(
                              { __typename: 'ArticleCategoryRecord' }
                              & LinkableArticleCategoryRecordFragment
                            ) | (
                              { __typename: 'ArticleRecord' }
                              & LinkableArticleRecordFragment
                            ) | (
                              { __typename: 'BlogHomepageRecord' }
                              & LinkableBlogHomepageRecordFragment
                            ) | (
                              { __typename: 'CustomPageRecord' }
                              & LinkableCustomPageRecordFragment
                            ) | (
                              { __typename: 'LocationSiteRecord' }
                              & LinkableLocationSiteRecordFragment
                            ) | (
                              { __typename: 'NexudusLinkRecord' }
                              & LinkableNexudusLinkRecordFragment
                            ) | (
                              { __typename: 'SolutionTypeRecord' }
                              & LinkableSolutionTypeRecordFragment
                            ) | (
                              { __typename: 'WorkspaceRecord' }
                              & LinkableWorkspaceRecordFragment
                            )>, children: Array<(
                              { __typename: 'NavbarItemRecord' }
                              & Pick<NavbarItemRecord, 'id' | '_isValid' | 'name'>
                              & { link?: Maybe<(
                                { __typename: 'ArticleCategoryRecord' }
                                & LinkableArticleCategoryRecordFragment
                              ) | (
                                { __typename: 'ArticleRecord' }
                                & LinkableArticleRecordFragment
                              ) | (
                                { __typename: 'BlogHomepageRecord' }
                                & LinkableBlogHomepageRecordFragment
                              ) | (
                                { __typename: 'CustomPageRecord' }
                                & LinkableCustomPageRecordFragment
                              ) | (
                                { __typename: 'LocationSiteRecord' }
                                & LinkableLocationSiteRecordFragment
                              ) | (
                                { __typename: 'NexudusLinkRecord' }
                                & LinkableNexudusLinkRecordFragment
                              ) | (
                                { __typename: 'SolutionTypeRecord' }
                                & LinkableSolutionTypeRecordFragment
                              ) | (
                                { __typename: 'WorkspaceRecord' }
                                & LinkableWorkspaceRecordFragment
                              )>, children: Array<(
                                { __typename: 'NavbarItemRecord' }
                                & Pick<NavbarItemRecord, 'id' | '_isValid' | 'name'>
                                & { link?: Maybe<(
                                  { __typename: 'ArticleCategoryRecord' }
                                  & LinkableArticleCategoryRecordFragment
                                ) | (
                                  { __typename: 'ArticleRecord' }
                                  & LinkableArticleRecordFragment
                                ) | (
                                  { __typename: 'BlogHomepageRecord' }
                                  & LinkableBlogHomepageRecordFragment
                                ) | (
                                  { __typename: 'CustomPageRecord' }
                                  & LinkableCustomPageRecordFragment
                                ) | (
                                  { __typename: 'LocationSiteRecord' }
                                  & LinkableLocationSiteRecordFragment
                                ) | (
                                  { __typename: 'NexudusLinkRecord' }
                                  & LinkableNexudusLinkRecordFragment
                                ) | (
                                  { __typename: 'SolutionTypeRecord' }
                                  & LinkableSolutionTypeRecordFragment
                                ) | (
                                  { __typename: 'WorkspaceRecord' }
                                  & LinkableWorkspaceRecordFragment
                                )>, children: Array<(
                                  { __typename: 'NavbarItemRecord' }
                                  & Pick<NavbarItemRecord, 'id' | '_isValid' | 'name'>
                                  & { link?: Maybe<(
                                    { __typename: 'ArticleCategoryRecord' }
                                    & LinkableArticleCategoryRecordFragment
                                  ) | (
                                    { __typename: 'ArticleRecord' }
                                    & LinkableArticleRecordFragment
                                  ) | (
                                    { __typename: 'BlogHomepageRecord' }
                                    & LinkableBlogHomepageRecordFragment
                                  ) | (
                                    { __typename: 'CustomPageRecord' }
                                    & LinkableCustomPageRecordFragment
                                  ) | (
                                    { __typename: 'LocationSiteRecord' }
                                    & LinkableLocationSiteRecordFragment
                                  ) | (
                                    { __typename: 'NexudusLinkRecord' }
                                    & LinkableNexudusLinkRecordFragment
                                  ) | (
                                    { __typename: 'SolutionTypeRecord' }
                                    & LinkableSolutionTypeRecordFragment
                                  ) | (
                                    { __typename: 'WorkspaceRecord' }
                                    & LinkableWorkspaceRecordFragment
                                  )>, children: Array<(
                                    { __typename: 'NavbarItemRecord' }
                                    & Pick<NavbarItemRecord, 'id' | '_isValid' | 'name'>
                                    & { link?: Maybe<(
                                      { __typename: 'ArticleCategoryRecord' }
                                      & LinkableArticleCategoryRecordFragment
                                    ) | (
                                      { __typename: 'ArticleRecord' }
                                      & LinkableArticleRecordFragment
                                    ) | (
                                      { __typename: 'BlogHomepageRecord' }
                                      & LinkableBlogHomepageRecordFragment
                                    ) | (
                                      { __typename: 'CustomPageRecord' }
                                      & LinkableCustomPageRecordFragment
                                    ) | (
                                      { __typename: 'LocationSiteRecord' }
                                      & LinkableLocationSiteRecordFragment
                                    ) | (
                                      { __typename: 'NexudusLinkRecord' }
                                      & LinkableNexudusLinkRecordFragment
                                    ) | (
                                      { __typename: 'SolutionTypeRecord' }
                                      & LinkableSolutionTypeRecordFragment
                                    ) | (
                                      { __typename: 'WorkspaceRecord' }
                                      & LinkableWorkspaceRecordFragment
                                    )>, children: Array<(
                                      { __typename: 'NavbarItemRecord' }
                                      & Pick<NavbarItemRecord, 'id' | '_isValid' | 'name'>
                                      & { link?: Maybe<(
                                        { __typename: 'ArticleCategoryRecord' }
                                        & LinkableArticleCategoryRecordFragment
                                      ) | (
                                        { __typename: 'ArticleRecord' }
                                        & LinkableArticleRecordFragment
                                      ) | (
                                        { __typename: 'BlogHomepageRecord' }
                                        & LinkableBlogHomepageRecordFragment
                                      ) | (
                                        { __typename: 'CustomPageRecord' }
                                        & LinkableCustomPageRecordFragment
                                      ) | (
                                        { __typename: 'LocationSiteRecord' }
                                        & LinkableLocationSiteRecordFragment
                                      ) | (
                                        { __typename: 'NexudusLinkRecord' }
                                        & LinkableNexudusLinkRecordFragment
                                      ) | (
                                        { __typename: 'SolutionTypeRecord' }
                                        & LinkableSolutionTypeRecordFragment
                                      ) | (
                                        { __typename: 'WorkspaceRecord' }
                                        & LinkableWorkspaceRecordFragment
                                      )>, children: Array<(
                                        { __typename: 'NavbarItemRecord' }
                                        & Pick<NavbarItemRecord, 'id' | '_isValid' | 'name'>
                                        & { link?: Maybe<(
                                          { __typename: 'ArticleCategoryRecord' }
                                          & LinkableArticleCategoryRecordFragment
                                        ) | (
                                          { __typename: 'ArticleRecord' }
                                          & LinkableArticleRecordFragment
                                        ) | (
                                          { __typename: 'BlogHomepageRecord' }
                                          & LinkableBlogHomepageRecordFragment
                                        ) | (
                                          { __typename: 'CustomPageRecord' }
                                          & LinkableCustomPageRecordFragment
                                        ) | (
                                          { __typename: 'LocationSiteRecord' }
                                          & LinkableLocationSiteRecordFragment
                                        ) | (
                                          { __typename: 'NexudusLinkRecord' }
                                          & LinkableNexudusLinkRecordFragment
                                        ) | (
                                          { __typename: 'SolutionTypeRecord' }
                                          & LinkableSolutionTypeRecordFragment
                                        ) | (
                                          { __typename: 'WorkspaceRecord' }
                                          & LinkableWorkspaceRecordFragment
                                        )>, children: Array<(
                                          { __typename: 'NavbarItemRecord' }
                                          & Pick<NavbarItemRecord, 'id' | '_isValid' | 'name'>
                                          & { link?: Maybe<(
                                            { __typename: 'ArticleCategoryRecord' }
                                            & LinkableArticleCategoryRecordFragment
                                          ) | (
                                            { __typename: 'ArticleRecord' }
                                            & LinkableArticleRecordFragment
                                          ) | (
                                            { __typename: 'BlogHomepageRecord' }
                                            & LinkableBlogHomepageRecordFragment
                                          ) | (
                                            { __typename: 'CustomPageRecord' }
                                            & LinkableCustomPageRecordFragment
                                          ) | (
                                            { __typename: 'LocationSiteRecord' }
                                            & LinkableLocationSiteRecordFragment
                                          ) | (
                                            { __typename: 'NexudusLinkRecord' }
                                            & LinkableNexudusLinkRecordFragment
                                          ) | (
                                            { __typename: 'SolutionTypeRecord' }
                                            & LinkableSolutionTypeRecordFragment
                                          ) | (
                                            { __typename: 'WorkspaceRecord' }
                                            & LinkableWorkspaceRecordFragment
                                          )> }
                                        )> }
                                      )> }
                                    )> }
                                  )> }
                                )> }
                              )> }
                            )> }
                          )> }
                        )> }
                      )> }
                    )> }
                  )> }
                )> }
              )> }
            )> }
          )> }
        )> }
      )> }
    )> }
  )>, allFooterItems: Array<(
    { __typename: 'FooterItemRecord' }
    & Pick<FooterItemRecord, 'id' | '_isValid' | 'name'>
    & { link?: Maybe<(
      { __typename: 'ArticleCategoryRecord' }
      & LinkableArticleCategoryRecordFragment
    ) | (
      { __typename: 'ArticleRecord' }
      & LinkableArticleRecordFragment
    ) | (
      { __typename: 'BlogHomepageRecord' }
      & LinkableBlogHomepageRecordFragment
    ) | (
      { __typename: 'CustomPageRecord' }
      & LinkableCustomPageRecordFragment
    ) | (
      { __typename: 'LocationSiteRecord' }
      & LinkableLocationSiteRecordFragment
    ) | (
      { __typename: 'NexudusLinkRecord' }
      & LinkableNexudusLinkRecordFragment
    ) | (
      { __typename: 'SolutionTypeRecord' }
      & LinkableSolutionTypeRecordFragment
    ) | (
      { __typename: 'WorkspaceRecord' }
      & LinkableWorkspaceRecordFragment
    )>, children: Array<(
      { __typename: 'FooterItemRecord' }
      & Pick<FooterItemRecord, 'id' | '_isValid' | 'name'>
      & { link?: Maybe<(
        { __typename: 'ArticleCategoryRecord' }
        & LinkableArticleCategoryRecordFragment
      ) | (
        { __typename: 'ArticleRecord' }
        & LinkableArticleRecordFragment
      ) | (
        { __typename: 'BlogHomepageRecord' }
        & LinkableBlogHomepageRecordFragment
      ) | (
        { __typename: 'CustomPageRecord' }
        & LinkableCustomPageRecordFragment
      ) | (
        { __typename: 'LocationSiteRecord' }
        & LinkableLocationSiteRecordFragment
      ) | (
        { __typename: 'NexudusLinkRecord' }
        & LinkableNexudusLinkRecordFragment
      ) | (
        { __typename: 'SolutionTypeRecord' }
        & LinkableSolutionTypeRecordFragment
      ) | (
        { __typename: 'WorkspaceRecord' }
        & LinkableWorkspaceRecordFragment
      )>, children: Array<(
        { __typename: 'FooterItemRecord' }
        & Pick<FooterItemRecord, 'id' | '_isValid' | 'name'>
        & { link?: Maybe<(
          { __typename: 'ArticleCategoryRecord' }
          & LinkableArticleCategoryRecordFragment
        ) | (
          { __typename: 'ArticleRecord' }
          & LinkableArticleRecordFragment
        ) | (
          { __typename: 'BlogHomepageRecord' }
          & LinkableBlogHomepageRecordFragment
        ) | (
          { __typename: 'CustomPageRecord' }
          & LinkableCustomPageRecordFragment
        ) | (
          { __typename: 'LocationSiteRecord' }
          & LinkableLocationSiteRecordFragment
        ) | (
          { __typename: 'NexudusLinkRecord' }
          & LinkableNexudusLinkRecordFragment
        ) | (
          { __typename: 'SolutionTypeRecord' }
          & LinkableSolutionTypeRecordFragment
        ) | (
          { __typename: 'WorkspaceRecord' }
          & LinkableWorkspaceRecordFragment
        )>, children: Array<(
          { __typename: 'FooterItemRecord' }
          & Pick<FooterItemRecord, 'id' | '_isValid' | 'name'>
          & { link?: Maybe<(
            { __typename: 'ArticleCategoryRecord' }
            & LinkableArticleCategoryRecordFragment
          ) | (
            { __typename: 'ArticleRecord' }
            & LinkableArticleRecordFragment
          ) | (
            { __typename: 'BlogHomepageRecord' }
            & LinkableBlogHomepageRecordFragment
          ) | (
            { __typename: 'CustomPageRecord' }
            & LinkableCustomPageRecordFragment
          ) | (
            { __typename: 'LocationSiteRecord' }
            & LinkableLocationSiteRecordFragment
          ) | (
            { __typename: 'NexudusLinkRecord' }
            & LinkableNexudusLinkRecordFragment
          ) | (
            { __typename: 'SolutionTypeRecord' }
            & LinkableSolutionTypeRecordFragment
          ) | (
            { __typename: 'WorkspaceRecord' }
            & LinkableWorkspaceRecordFragment
          )>, children: Array<(
            { __typename: 'FooterItemRecord' }
            & Pick<FooterItemRecord, 'id' | '_isValid' | 'name'>
            & { link?: Maybe<(
              { __typename: 'ArticleCategoryRecord' }
              & LinkableArticleCategoryRecordFragment
            ) | (
              { __typename: 'ArticleRecord' }
              & LinkableArticleRecordFragment
            ) | (
              { __typename: 'BlogHomepageRecord' }
              & LinkableBlogHomepageRecordFragment
            ) | (
              { __typename: 'CustomPageRecord' }
              & LinkableCustomPageRecordFragment
            ) | (
              { __typename: 'LocationSiteRecord' }
              & LinkableLocationSiteRecordFragment
            ) | (
              { __typename: 'NexudusLinkRecord' }
              & LinkableNexudusLinkRecordFragment
            ) | (
              { __typename: 'SolutionTypeRecord' }
              & LinkableSolutionTypeRecordFragment
            ) | (
              { __typename: 'WorkspaceRecord' }
              & LinkableWorkspaceRecordFragment
            )>, children: Array<(
              { __typename: 'FooterItemRecord' }
              & Pick<FooterItemRecord, 'id' | '_isValid' | 'name'>
              & { link?: Maybe<(
                { __typename: 'ArticleCategoryRecord' }
                & LinkableArticleCategoryRecordFragment
              ) | (
                { __typename: 'ArticleRecord' }
                & LinkableArticleRecordFragment
              ) | (
                { __typename: 'BlogHomepageRecord' }
                & LinkableBlogHomepageRecordFragment
              ) | (
                { __typename: 'CustomPageRecord' }
                & LinkableCustomPageRecordFragment
              ) | (
                { __typename: 'LocationSiteRecord' }
                & LinkableLocationSiteRecordFragment
              ) | (
                { __typename: 'NexudusLinkRecord' }
                & LinkableNexudusLinkRecordFragment
              ) | (
                { __typename: 'SolutionTypeRecord' }
                & LinkableSolutionTypeRecordFragment
              ) | (
                { __typename: 'WorkspaceRecord' }
                & LinkableWorkspaceRecordFragment
              )>, children: Array<(
                { __typename: 'FooterItemRecord' }
                & Pick<FooterItemRecord, 'id' | '_isValid' | 'name'>
                & { link?: Maybe<(
                  { __typename: 'ArticleCategoryRecord' }
                  & LinkableArticleCategoryRecordFragment
                ) | (
                  { __typename: 'ArticleRecord' }
                  & LinkableArticleRecordFragment
                ) | (
                  { __typename: 'BlogHomepageRecord' }
                  & LinkableBlogHomepageRecordFragment
                ) | (
                  { __typename: 'CustomPageRecord' }
                  & LinkableCustomPageRecordFragment
                ) | (
                  { __typename: 'LocationSiteRecord' }
                  & LinkableLocationSiteRecordFragment
                ) | (
                  { __typename: 'NexudusLinkRecord' }
                  & LinkableNexudusLinkRecordFragment
                ) | (
                  { __typename: 'SolutionTypeRecord' }
                  & LinkableSolutionTypeRecordFragment
                ) | (
                  { __typename: 'WorkspaceRecord' }
                  & LinkableWorkspaceRecordFragment
                )>, children: Array<(
                  { __typename: 'FooterItemRecord' }
                  & Pick<FooterItemRecord, 'id' | '_isValid' | 'name'>
                  & { link?: Maybe<(
                    { __typename: 'ArticleCategoryRecord' }
                    & LinkableArticleCategoryRecordFragment
                  ) | (
                    { __typename: 'ArticleRecord' }
                    & LinkableArticleRecordFragment
                  ) | (
                    { __typename: 'BlogHomepageRecord' }
                    & LinkableBlogHomepageRecordFragment
                  ) | (
                    { __typename: 'CustomPageRecord' }
                    & LinkableCustomPageRecordFragment
                  ) | (
                    { __typename: 'LocationSiteRecord' }
                    & LinkableLocationSiteRecordFragment
                  ) | (
                    { __typename: 'NexudusLinkRecord' }
                    & LinkableNexudusLinkRecordFragment
                  ) | (
                    { __typename: 'SolutionTypeRecord' }
                    & LinkableSolutionTypeRecordFragment
                  ) | (
                    { __typename: 'WorkspaceRecord' }
                    & LinkableWorkspaceRecordFragment
                  )>, children: Array<(
                    { __typename: 'FooterItemRecord' }
                    & Pick<FooterItemRecord, 'id' | '_isValid' | 'name'>
                    & { link?: Maybe<(
                      { __typename: 'ArticleCategoryRecord' }
                      & LinkableArticleCategoryRecordFragment
                    ) | (
                      { __typename: 'ArticleRecord' }
                      & LinkableArticleRecordFragment
                    ) | (
                      { __typename: 'BlogHomepageRecord' }
                      & LinkableBlogHomepageRecordFragment
                    ) | (
                      { __typename: 'CustomPageRecord' }
                      & LinkableCustomPageRecordFragment
                    ) | (
                      { __typename: 'LocationSiteRecord' }
                      & LinkableLocationSiteRecordFragment
                    ) | (
                      { __typename: 'NexudusLinkRecord' }
                      & LinkableNexudusLinkRecordFragment
                    ) | (
                      { __typename: 'SolutionTypeRecord' }
                      & LinkableSolutionTypeRecordFragment
                    ) | (
                      { __typename: 'WorkspaceRecord' }
                      & LinkableWorkspaceRecordFragment
                    )>, children: Array<(
                      { __typename: 'FooterItemRecord' }
                      & Pick<FooterItemRecord, 'id' | '_isValid' | 'name'>
                      & { link?: Maybe<(
                        { __typename: 'ArticleCategoryRecord' }
                        & LinkableArticleCategoryRecordFragment
                      ) | (
                        { __typename: 'ArticleRecord' }
                        & LinkableArticleRecordFragment
                      ) | (
                        { __typename: 'BlogHomepageRecord' }
                        & LinkableBlogHomepageRecordFragment
                      ) | (
                        { __typename: 'CustomPageRecord' }
                        & LinkableCustomPageRecordFragment
                      ) | (
                        { __typename: 'LocationSiteRecord' }
                        & LinkableLocationSiteRecordFragment
                      ) | (
                        { __typename: 'NexudusLinkRecord' }
                        & LinkableNexudusLinkRecordFragment
                      ) | (
                        { __typename: 'SolutionTypeRecord' }
                        & LinkableSolutionTypeRecordFragment
                      ) | (
                        { __typename: 'WorkspaceRecord' }
                        & LinkableWorkspaceRecordFragment
                      )>, children: Array<(
                        { __typename: 'FooterItemRecord' }
                        & Pick<FooterItemRecord, 'id' | '_isValid' | 'name'>
                        & { link?: Maybe<(
                          { __typename: 'ArticleCategoryRecord' }
                          & LinkableArticleCategoryRecordFragment
                        ) | (
                          { __typename: 'ArticleRecord' }
                          & LinkableArticleRecordFragment
                        ) | (
                          { __typename: 'BlogHomepageRecord' }
                          & LinkableBlogHomepageRecordFragment
                        ) | (
                          { __typename: 'CustomPageRecord' }
                          & LinkableCustomPageRecordFragment
                        ) | (
                          { __typename: 'LocationSiteRecord' }
                          & LinkableLocationSiteRecordFragment
                        ) | (
                          { __typename: 'NexudusLinkRecord' }
                          & LinkableNexudusLinkRecordFragment
                        ) | (
                          { __typename: 'SolutionTypeRecord' }
                          & LinkableSolutionTypeRecordFragment
                        ) | (
                          { __typename: 'WorkspaceRecord' }
                          & LinkableWorkspaceRecordFragment
                        )>, children: Array<(
                          { __typename: 'FooterItemRecord' }
                          & Pick<FooterItemRecord, 'id' | '_isValid' | 'name'>
                          & { link?: Maybe<(
                            { __typename: 'ArticleCategoryRecord' }
                            & LinkableArticleCategoryRecordFragment
                          ) | (
                            { __typename: 'ArticleRecord' }
                            & LinkableArticleRecordFragment
                          ) | (
                            { __typename: 'BlogHomepageRecord' }
                            & LinkableBlogHomepageRecordFragment
                          ) | (
                            { __typename: 'CustomPageRecord' }
                            & LinkableCustomPageRecordFragment
                          ) | (
                            { __typename: 'LocationSiteRecord' }
                            & LinkableLocationSiteRecordFragment
                          ) | (
                            { __typename: 'NexudusLinkRecord' }
                            & LinkableNexudusLinkRecordFragment
                          ) | (
                            { __typename: 'SolutionTypeRecord' }
                            & LinkableSolutionTypeRecordFragment
                          ) | (
                            { __typename: 'WorkspaceRecord' }
                            & LinkableWorkspaceRecordFragment
                          )>, children: Array<(
                            { __typename: 'FooterItemRecord' }
                            & Pick<FooterItemRecord, 'id' | '_isValid' | 'name'>
                            & { link?: Maybe<(
                              { __typename: 'ArticleCategoryRecord' }
                              & LinkableArticleCategoryRecordFragment
                            ) | (
                              { __typename: 'ArticleRecord' }
                              & LinkableArticleRecordFragment
                            ) | (
                              { __typename: 'BlogHomepageRecord' }
                              & LinkableBlogHomepageRecordFragment
                            ) | (
                              { __typename: 'CustomPageRecord' }
                              & LinkableCustomPageRecordFragment
                            ) | (
                              { __typename: 'LocationSiteRecord' }
                              & LinkableLocationSiteRecordFragment
                            ) | (
                              { __typename: 'NexudusLinkRecord' }
                              & LinkableNexudusLinkRecordFragment
                            ) | (
                              { __typename: 'SolutionTypeRecord' }
                              & LinkableSolutionTypeRecordFragment
                            ) | (
                              { __typename: 'WorkspaceRecord' }
                              & LinkableWorkspaceRecordFragment
                            )>, children: Array<(
                              { __typename: 'FooterItemRecord' }
                              & Pick<FooterItemRecord, 'id' | '_isValid' | 'name'>
                              & { link?: Maybe<(
                                { __typename: 'ArticleCategoryRecord' }
                                & LinkableArticleCategoryRecordFragment
                              ) | (
                                { __typename: 'ArticleRecord' }
                                & LinkableArticleRecordFragment
                              ) | (
                                { __typename: 'BlogHomepageRecord' }
                                & LinkableBlogHomepageRecordFragment
                              ) | (
                                { __typename: 'CustomPageRecord' }
                                & LinkableCustomPageRecordFragment
                              ) | (
                                { __typename: 'LocationSiteRecord' }
                                & LinkableLocationSiteRecordFragment
                              ) | (
                                { __typename: 'NexudusLinkRecord' }
                                & LinkableNexudusLinkRecordFragment
                              ) | (
                                { __typename: 'SolutionTypeRecord' }
                                & LinkableSolutionTypeRecordFragment
                              ) | (
                                { __typename: 'WorkspaceRecord' }
                                & LinkableWorkspaceRecordFragment
                              )>, children: Array<(
                                { __typename: 'FooterItemRecord' }
                                & Pick<FooterItemRecord, 'id' | '_isValid' | 'name'>
                                & { link?: Maybe<(
                                  { __typename: 'ArticleCategoryRecord' }
                                  & LinkableArticleCategoryRecordFragment
                                ) | (
                                  { __typename: 'ArticleRecord' }
                                  & LinkableArticleRecordFragment
                                ) | (
                                  { __typename: 'BlogHomepageRecord' }
                                  & LinkableBlogHomepageRecordFragment
                                ) | (
                                  { __typename: 'CustomPageRecord' }
                                  & LinkableCustomPageRecordFragment
                                ) | (
                                  { __typename: 'LocationSiteRecord' }
                                  & LinkableLocationSiteRecordFragment
                                ) | (
                                  { __typename: 'NexudusLinkRecord' }
                                  & LinkableNexudusLinkRecordFragment
                                ) | (
                                  { __typename: 'SolutionTypeRecord' }
                                  & LinkableSolutionTypeRecordFragment
                                ) | (
                                  { __typename: 'WorkspaceRecord' }
                                  & LinkableWorkspaceRecordFragment
                                )>, children: Array<(
                                  { __typename: 'FooterItemRecord' }
                                  & Pick<FooterItemRecord, 'id' | '_isValid' | 'name'>
                                  & { link?: Maybe<(
                                    { __typename: 'ArticleCategoryRecord' }
                                    & LinkableArticleCategoryRecordFragment
                                  ) | (
                                    { __typename: 'ArticleRecord' }
                                    & LinkableArticleRecordFragment
                                  ) | (
                                    { __typename: 'BlogHomepageRecord' }
                                    & LinkableBlogHomepageRecordFragment
                                  ) | (
                                    { __typename: 'CustomPageRecord' }
                                    & LinkableCustomPageRecordFragment
                                  ) | (
                                    { __typename: 'LocationSiteRecord' }
                                    & LinkableLocationSiteRecordFragment
                                  ) | (
                                    { __typename: 'NexudusLinkRecord' }
                                    & LinkableNexudusLinkRecordFragment
                                  ) | (
                                    { __typename: 'SolutionTypeRecord' }
                                    & LinkableSolutionTypeRecordFragment
                                  ) | (
                                    { __typename: 'WorkspaceRecord' }
                                    & LinkableWorkspaceRecordFragment
                                  )>, children: Array<(
                                    { __typename: 'FooterItemRecord' }
                                    & Pick<FooterItemRecord, 'id' | '_isValid' | 'name'>
                                    & { link?: Maybe<(
                                      { __typename: 'ArticleCategoryRecord' }
                                      & LinkableArticleCategoryRecordFragment
                                    ) | (
                                      { __typename: 'ArticleRecord' }
                                      & LinkableArticleRecordFragment
                                    ) | (
                                      { __typename: 'BlogHomepageRecord' }
                                      & LinkableBlogHomepageRecordFragment
                                    ) | (
                                      { __typename: 'CustomPageRecord' }
                                      & LinkableCustomPageRecordFragment
                                    ) | (
                                      { __typename: 'LocationSiteRecord' }
                                      & LinkableLocationSiteRecordFragment
                                    ) | (
                                      { __typename: 'NexudusLinkRecord' }
                                      & LinkableNexudusLinkRecordFragment
                                    ) | (
                                      { __typename: 'SolutionTypeRecord' }
                                      & LinkableSolutionTypeRecordFragment
                                    ) | (
                                      { __typename: 'WorkspaceRecord' }
                                      & LinkableWorkspaceRecordFragment
                                    )>, children: Array<(
                                      { __typename: 'FooterItemRecord' }
                                      & Pick<FooterItemRecord, 'id' | '_isValid' | 'name'>
                                      & { link?: Maybe<(
                                        { __typename: 'ArticleCategoryRecord' }
                                        & LinkableArticleCategoryRecordFragment
                                      ) | (
                                        { __typename: 'ArticleRecord' }
                                        & LinkableArticleRecordFragment
                                      ) | (
                                        { __typename: 'BlogHomepageRecord' }
                                        & LinkableBlogHomepageRecordFragment
                                      ) | (
                                        { __typename: 'CustomPageRecord' }
                                        & LinkableCustomPageRecordFragment
                                      ) | (
                                        { __typename: 'LocationSiteRecord' }
                                        & LinkableLocationSiteRecordFragment
                                      ) | (
                                        { __typename: 'NexudusLinkRecord' }
                                        & LinkableNexudusLinkRecordFragment
                                      ) | (
                                        { __typename: 'SolutionTypeRecord' }
                                        & LinkableSolutionTypeRecordFragment
                                      ) | (
                                        { __typename: 'WorkspaceRecord' }
                                        & LinkableWorkspaceRecordFragment
                                      )>, children: Array<(
                                        { __typename: 'FooterItemRecord' }
                                        & Pick<FooterItemRecord, 'id' | '_isValid' | 'name'>
                                        & { link?: Maybe<(
                                          { __typename: 'ArticleCategoryRecord' }
                                          & LinkableArticleCategoryRecordFragment
                                        ) | (
                                          { __typename: 'ArticleRecord' }
                                          & LinkableArticleRecordFragment
                                        ) | (
                                          { __typename: 'BlogHomepageRecord' }
                                          & LinkableBlogHomepageRecordFragment
                                        ) | (
                                          { __typename: 'CustomPageRecord' }
                                          & LinkableCustomPageRecordFragment
                                        ) | (
                                          { __typename: 'LocationSiteRecord' }
                                          & LinkableLocationSiteRecordFragment
                                        ) | (
                                          { __typename: 'NexudusLinkRecord' }
                                          & LinkableNexudusLinkRecordFragment
                                        ) | (
                                          { __typename: 'SolutionTypeRecord' }
                                          & LinkableSolutionTypeRecordFragment
                                        ) | (
                                          { __typename: 'WorkspaceRecord' }
                                          & LinkableWorkspaceRecordFragment
                                        )>, children: Array<(
                                          { __typename: 'FooterItemRecord' }
                                          & Pick<FooterItemRecord, 'id' | '_isValid' | 'name'>
                                          & { link?: Maybe<(
                                            { __typename: 'ArticleCategoryRecord' }
                                            & LinkableArticleCategoryRecordFragment
                                          ) | (
                                            { __typename: 'ArticleRecord' }
                                            & LinkableArticleRecordFragment
                                          ) | (
                                            { __typename: 'BlogHomepageRecord' }
                                            & LinkableBlogHomepageRecordFragment
                                          ) | (
                                            { __typename: 'CustomPageRecord' }
                                            & LinkableCustomPageRecordFragment
                                          ) | (
                                            { __typename: 'LocationSiteRecord' }
                                            & LinkableLocationSiteRecordFragment
                                          ) | (
                                            { __typename: 'NexudusLinkRecord' }
                                            & LinkableNexudusLinkRecordFragment
                                          ) | (
                                            { __typename: 'SolutionTypeRecord' }
                                            & LinkableSolutionTypeRecordFragment
                                          ) | (
                                            { __typename: 'WorkspaceRecord' }
                                            & LinkableWorkspaceRecordFragment
                                          )> }
                                        )> }
                                      )> }
                                    )> }
                                  )> }
                                )> }
                              )> }
                            )> }
                          )> }
                        )> }
                      )> }
                    )> }
                  )> }
                )> }
              )> }
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type DatoPagePathsQueryVariables = Exact<{ [key: string]: never; }>;


export type DatoPagePathsQuery = (
  { __typename?: 'Query' }
  & { allCustomPages: Array<(
    { __typename?: 'CustomPageRecord' }
    & Pick<CustomPageRecord, 'urlPath'>
  )>, allLocationSites: Array<(
    { __typename?: 'LocationSiteRecord' }
    & Pick<LocationSiteRecord, 'urlPath'>
  )>, allSolutionTypes: Array<(
    { __typename?: 'SolutionTypeRecord' }
    & Pick<SolutionTypeRecord, 'urlPath'>
  )>, allArticles: Array<(
    { __typename?: 'ArticleRecord' }
    & Pick<ArticleRecord, 'pagePath'>
  )>, allArticleCategories: Array<(
    { __typename?: 'ArticleCategoryRecord' }
    & Pick<ArticleCategoryRecord, 'pagePath'>
  )>, allWorkspaces: Array<(
    { __typename?: 'WorkspaceRecord' }
    & Pick<WorkspaceRecord, 'urlPath'>
  )>, blogHomepage?: Maybe<(
    { __typename?: 'BlogHomepageRecord' }
    & Pick<BlogHomepageRecord, 'pagePath'>
  )> }
);

export type DatoSiteMapFileUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type DatoSiteMapFileUrlQuery = (
  { __typename?: 'Query' }
  & { xmlSitemap?: Maybe<(
    { __typename?: 'XmlSitemapRecord' }
    & { xmlFile?: Maybe<(
      { __typename?: 'FileField' }
      & Pick<FileField, 'url'>
    )> }
  )> }
);

export type DatoRobotsTxtFileUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type DatoRobotsTxtFileUrlQuery = (
  { __typename?: 'Query' }
  & { robotsTxt?: Maybe<(
    { __typename?: 'RobotsTxtRecord' }
    & { robotsTxtFile?: Maybe<(
      { __typename?: 'FileField' }
      & Pick<FileField, 'url'>
    )> }
  )> }
);

export type DatoCustomPageQueryVariables = Exact<{
  path: Scalars['String'];
}>;


export type DatoCustomPageQuery = (
  { __typename?: 'Query' }
  & { customPage?: Maybe<(
    { __typename?: 'CustomPageRecord' }
    & Pick<CustomPageRecord, 'pageName' | 'urlPath' | 'showBreadcrumb' | 'seoStructuredData'>
    & { _seoMetaTags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'attributes' | 'content' | 'tag'>
    )>, metaTags?: Maybe<(
      { __typename?: 'SeoField' }
      & MetaTagsFragment
    )>, pageContent: Array<(
      { __typename?: 'PageContentRecord' }
      & Pick<PageContentRecord, 'injectScript'>
      & PageContentFragment
    )> }
    & CustomPageRecordBreadcrumbFragment
  )> }
);

export type DatoLocationSitePageQueryVariables = Exact<{
  path: Scalars['String'];
}>;


export type DatoLocationSitePageQuery = (
  { __typename?: 'Query' }
  & { locationSite?: Maybe<(
    { __typename?: 'LocationSiteRecord' }
    & Pick<LocationSiteRecord, 'showBreadcrumb' | 'siteName' | 'address' | 'phoneNumber' | 'addressLocation' | 'emailAddress' | 'seoStructuredData'>
    & { textBackgroundImage?: Maybe<(
      { __typename?: 'FileField' }
      & Pick<FileField, 'url'>
      & { responsiveImage?: Maybe<(
        { __typename?: 'ResponsiveImage' }
        & ResponsiveImageFieldsFragment
      )> }
    )>, assets: Array<(
      { __typename?: 'ImageRecord' }
      & { image?: Maybe<(
        { __typename?: 'FileField' }
        & Pick<FileField, 'url'>
        & { responsiveImage?: Maybe<(
          { __typename?: 'ResponsiveImage' }
          & ResponsiveImageFieldsFragment
        )> }
      )> }
    ) | (
      { __typename?: 'VimeoVideoRecord' }
      & Pick<VimeoVideoRecord, 'vimeoUrl'>
    )>, floatingCta?: Maybe<(
      { __typename?: 'FloatingCtaRecord' }
      & Pick<FloatingCtaRecord, 'price' | 'pricePrefix' | 'heading'>
      & { links: Array<(
        { __typename?: 'LinkRecord' }
        & LinkableLinkRecordFragment
      )>, body?: Maybe<(
        { __typename?: 'FloatingCtaModelBodyField' }
        & Pick<FloatingCtaModelBodyField, 'value'>
        & { links: Array<(
          { __typename?: 'LinkRecord' }
          & LinkableLinkRecordFragment
        )> }
      )> }
    )>, _seoMetaTags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'attributes' | 'content' | 'tag'>
    )>, metaTags?: Maybe<(
      { __typename?: 'SeoField' }
      & MetaTagsFragment
    )>, reusableComponents?: Maybe<(
      { __typename?: 'PageContentRecord' }
      & PageContentFragment
    )> }
    & LinkableLocationSiteRecordFragment
    & LocationSiteRecordBreadcrumbFragment
  )> }
);

export type DatoSolutionTypePageQueryVariables = Exact<{
  path: Scalars['String'];
}>;


export type DatoSolutionTypePageQuery = (
  { __typename?: 'Query' }
  & { solutionType?: Maybe<(
    { __typename?: 'SolutionTypeRecord' }
    & Pick<SolutionTypeRecord, 'showBreadcrumb' | 'solutionType' | 'seoStructuredData'>
    & { assets: Array<(
      { __typename?: 'ImageRecord' }
      & { image?: Maybe<(
        { __typename?: 'FileField' }
        & Pick<FileField, 'url'>
        & { responsiveImage?: Maybe<(
          { __typename?: 'ResponsiveImage' }
          & ResponsiveImageFieldsFragment
        )> }
      )> }
    ) | (
      { __typename?: 'VimeoVideoRecord' }
      & Pick<VimeoVideoRecord, 'vimeoUrl'>
    )>, floatingCta?: Maybe<(
      { __typename?: 'FloatingCtaRecord' }
      & Pick<FloatingCtaRecord, 'price' | 'pricePrefix' | 'heading'>
      & { links: Array<(
        { __typename?: 'LinkRecord' }
        & LinkableLinkRecordFragment
      )>, body?: Maybe<(
        { __typename?: 'FloatingCtaModelBodyField' }
        & Pick<FloatingCtaModelBodyField, 'value'>
        & { links: Array<(
          { __typename?: 'LinkRecord' }
          & LinkableLinkRecordFragment
        )> }
      )> }
    )>, _seoMetaTags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'attributes' | 'content' | 'tag'>
    )>, metaTags?: Maybe<(
      { __typename?: 'SeoField' }
      & MetaTagsFragment
    )>, reusableComponents?: Maybe<(
      { __typename?: 'PageContentRecord' }
      & PageContentFragment
    )> }
    & LinkableSolutionTypeRecordFragment
    & SolutionTypeRecordBreadcrumbFragment
  )> }
);

export type DatoWorkspacePageQueryVariables = Exact<{
  path: Scalars['String'];
}>;


export type DatoWorkspacePageQuery = (
  { __typename?: 'Query' }
  & { workspace?: Maybe<(
    { __typename?: 'WorkspaceRecord' }
    & Pick<WorkspaceRecord, 'showBreadcrumb' | 'name' | 'seoStructuredData'>
    & { site?: Maybe<(
      { __typename?: 'LocationSiteRecord' }
      & Pick<LocationSiteRecord, 'siteName' | 'address' | 'phoneNumber' | 'addressLocation' | 'emailAddress'>
    )>, floatingCta?: Maybe<(
      { __typename?: 'FloatingCtaRecord' }
      & Pick<FloatingCtaRecord, 'price' | 'pricePrefix' | 'heading'>
      & { links: Array<(
        { __typename?: 'LinkRecord' }
        & LinkableLinkRecordFragment
      )>, body?: Maybe<(
        { __typename?: 'FloatingCtaModelBodyField' }
        & Pick<FloatingCtaModelBodyField, 'value'>
        & { links: Array<(
          { __typename?: 'LinkRecord' }
          & LinkableLinkRecordFragment
        )> }
      )> }
    )>, assets: Array<(
      { __typename?: 'ImageRecord' }
      & { image?: Maybe<(
        { __typename?: 'FileField' }
        & Pick<FileField, 'url'>
        & { responsiveImage?: Maybe<(
          { __typename?: 'ResponsiveImage' }
          & ResponsiveImageFieldsFragment
        )> }
      )> }
    ) | (
      { __typename?: 'VimeoVideoRecord' }
      & Pick<VimeoVideoRecord, 'vimeoUrl'>
    )>, _seoMetaTags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'attributes' | 'content' | 'tag'>
    )>, metaTags?: Maybe<(
      { __typename?: 'SeoField' }
      & MetaTagsFragment
    )>, reusableComponents: Array<(
      { __typename?: 'PageContentRecord' }
      & PageContentFragment
    )> }
    & LinkableWorkspaceRecordFragment
    & WorkspaceRecordBreadcrumbFragment
  )> }
);

export type DatoArticlePageQueryVariables = Exact<{
  path: Scalars['String'];
}>;


export type DatoArticlePageQuery = (
  { __typename?: 'Query' }
  & { article?: Maybe<(
    { __typename?: 'ArticleRecord' }
    & Pick<ArticleRecord, 'title' | 'date' | 'pagePath' | 'author' | 'showBreadcrumb' | 'cardExcerpt' | '_createdAt' | 'seoStructuredData'>
    & { image?: Maybe<(
      { __typename?: 'FileField' }
      & Pick<FileField, 'url'>
      & { responsiveImage?: Maybe<(
        { __typename?: 'ResponsiveImage' }
        & ResponsiveImageFieldsFragment
      )> }
    )>, categories: Array<(
      { __typename?: 'ArticleCategoryRecord' }
      & LinkableArticleCategoryRecordFragment
    )>, _seoMetaTags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'attributes' | 'content' | 'tag'>
    )>, metaTags?: Maybe<(
      { __typename?: 'SeoField' }
      & MetaTagsFragment
    )>, content?: Maybe<(
      { __typename?: 'ArticleModelContentField' }
      & Pick<ArticleModelContentField, 'value'>
      & { blocks: Array<(
        { __typename?: 'CustomHtmlBlockRecord' }
        & CustomHtmlBlockFragment
      ) | (
        { __typename?: 'ECtaBlockRecord' }
        & ECtaBlockFragment
      ) | { __typename?: 'FindASpaceButtonRecord' } | (
        { __typename?: 'ImageBlockRecord' }
        & ImageBlockFragment
      ) | (
        { __typename?: 'NewsletterSignupBlockRecord' }
        & NewsletterSignupBlockFragment
      ) | (
        { __typename?: 'QuoteBlockRecord' }
        & QuoteBlockFragment
      )>, links: Array<(
        { __typename?: 'LinkRecord' }
        & LinkableLinkRecordFragment
      )> }
    )> }
    & ArticleRecordBreadcrumbFragment
  )> }
);

export type DatoArticleCategoryPageQueryVariables = Exact<{
  path: Scalars['String'];
}>;


export type DatoArticleCategoryPageQuery = (
  { __typename?: 'Query' }
  & { articleCategory?: Maybe<(
    { __typename?: 'ArticleCategoryRecord' }
    & Pick<ArticleCategoryRecord, 'id' | 'categoryName' | 'pagePath' | 'showBreadcrumb' | 'seoStructuredData'>
    & { categoryColour?: Maybe<(
      { __typename?: 'ColorField' }
      & ColorFragment
    )>, _seoMetaTags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'attributes' | 'content' | 'tag'>
    )>, metaTags?: Maybe<(
      { __typename?: 'SeoField' }
      & MetaTagsFragment
    )> }
    & ArticleCategoryRecordBreadcrumbFragment
  )> }
);

export type DatoArticleCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type DatoArticleCategoriesQuery = (
  { __typename?: 'Query' }
  & { allArticleCategories: Array<(
    { __typename?: 'ArticleCategoryRecord' }
    & Pick<ArticleCategoryRecord, 'pagePath' | 'categoryName'>
    & { categoryColour?: Maybe<(
      { __typename?: 'ColorField' }
      & ColorFragment
    )> }
  )> }
);

export type DatoArticlesInCategoryQueryVariables = Exact<{
  categoryId: Scalars['ItemId'];
  excludingIds: Array<Scalars['ItemId']> | Scalars['ItemId'];
}>;


export type DatoArticlesInCategoryQuery = (
  { __typename?: 'Query' }
  & { allArticles: Array<(
    { __typename?: 'ArticleRecord' }
    & ArticleCardFragment
  )> }
);

export type DatoArticleCountInCategoryQueryVariables = Exact<{
  categoryId: Scalars['ItemId'];
}>;


export type DatoArticleCountInCategoryQuery = (
  { __typename?: 'Query' }
  & { _allArticlesMeta: (
    { __typename?: 'CollectionMetadata' }
    & Pick<CollectionMetadata, 'count'>
  ) }
);

export type DatoArticlesInBlogHomePageQueryVariables = Exact<{
  excludingIds: Array<Scalars['ItemId']> | Scalars['ItemId'];
}>;


export type DatoArticlesInBlogHomePageQuery = (
  { __typename?: 'Query' }
  & { allArticles: Array<(
    { __typename?: 'ArticleRecord' }
    & ArticleCardFragment
  )> }
);

export type DatoArticleCountInBlogHomePageQueryVariables = Exact<{ [key: string]: never; }>;


export type DatoArticleCountInBlogHomePageQuery = (
  { __typename?: 'Query' }
  & { _allArticlesMeta: (
    { __typename?: 'CollectionMetadata' }
    & Pick<CollectionMetadata, 'count'>
  ) }
);

export type DatoBlogHomePageQueryVariables = Exact<{ [key: string]: never; }>;


export type DatoBlogHomePageQuery = (
  { __typename?: 'Query' }
  & { blogHomepage?: Maybe<(
    { __typename?: 'BlogHomepageRecord' }
    & Pick<BlogHomepageRecord, 'pageTitle' | 'pagePath' | 'showBreadcrumb' | 'seoStructuredData'>
    & { categoryColour?: Maybe<(
      { __typename?: 'ColorField' }
      & ColorFragment
    )>, _seoMetaTags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'attributes' | 'content' | 'tag'>
    )>, metaTags?: Maybe<(
      { __typename?: 'SeoField' }
      & MetaTagsFragment
    )> }
    & BlogHomePageRecordBreadcrumbFragment
  )> }
);

export type DatoMembershipsSearchPageQueryVariables = Exact<{ [key: string]: never; }>;


export type DatoMembershipsSearchPageQuery = (
  { __typename?: 'Query' }
  & { allCities: Array<(
    { __typename?: 'CityRecord' }
    & Pick<CityRecord, 'name'>
  )>, allLocationSites: Array<(
    { __typename?: 'LocationSiteRecord' }
    & Pick<LocationSiteRecord, 'siteName'>
    & { coordinates?: Maybe<(
      { __typename?: 'LatLonField' }
      & Pick<LatLonField, 'latitude' | 'longitude'>
    )>, city?: Maybe<(
      { __typename?: 'CityRecord' }
      & Pick<CityRecord, 'id' | 'name'>
    )> }
    & LinkableLocationSiteRecordFragment
  )> }
);

export type DatoAllWorkspacesQueryVariables = Exact<{ [key: string]: never; }>;


export type DatoAllWorkspacesQuery = (
  { __typename?: 'Query' }
  & { allWorkspaces: Array<(
    { __typename?: 'WorkspaceRecord' }
    & WorkspaceCardFragment
  )> }
);

export type DatoWorkspacesQueryVariables = Exact<{
  siteIds?: Maybe<Array<Scalars['ItemId']> | Scalars['ItemId']>;
  capacities?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type DatoWorkspacesQuery = (
  { __typename?: 'Query' }
  & { allWorkspaces: Array<(
    { __typename?: 'WorkspaceRecord' }
    & WorkspaceCardFragment
  )> }
);

export type DatoSiteLogoQueryVariables = Exact<{ [key: string]: never; }>;


export type DatoSiteLogoQuery = (
  { __typename?: 'Query' }
  & { siteLogo?: Maybe<(
    { __typename?: 'SiteLogoRecord' }
    & { headerImage?: Maybe<(
      { __typename?: 'FileField' }
      & Pick<FileField, 'url'>
    )>, footerImage?: Maybe<(
      { __typename?: 'FileField' }
      & Pick<FileField, 'url'>
    )> }
  )> }
);

export type HeaderCentreFragment = (
  { __typename?: 'HeaderCentreRecord' }
  & Pick<HeaderCentreRecord, 'id' | '_isValid' | 'buttonType' | 'copy'>
  & { headingString: HeaderCentreRecord['heading'] }
  & { backgroundColour?: Maybe<(
    { __typename?: 'ColorField' }
    & ColorFragment
  )>, buttonLink?: Maybe<(
    { __typename?: 'LinkRecord' }
    & LinkableLinkRecordFragment
  )>, textBackgroundImage?: Maybe<(
    { __typename?: 'FileField' }
    & Pick<FileField, 'url'>
    & { responsiveImage?: Maybe<(
      { __typename?: 'ResponsiveImage' }
      & ResponsiveImageFieldsFragment
    )> }
  )> }
);

export type CardGridFragment = (
  { __typename?: 'CardGridRecord' }
  & Pick<CardGridRecord, 'id' | '_isValid' | 'numberOfColumns' | 'hideSeparatorLine' | 'blockCardIdentify' | 'blockMenuName' | 'onLaptop' | 'onMobile'>
  & { backgroundColour?: Maybe<(
    { __typename?: 'ColorField' }
    & ColorFragment
  )>, heading?: Maybe<(
    { __typename?: 'CardGridModelHeadingField' }
    & Pick<CardGridModelHeadingField, 'value'>
    & { links: Array<(
      { __typename?: 'LinkRecord' }
      & LinkableLinkRecordFragment
    )> }
  )>, cards: Array<(
    { __typename?: 'ArticleRecord' }
    & ArticleCardFragment
  ) | (
    { __typename?: 'GenericCardRecord' }
    & Pick<GenericCardRecord, 'heading' | 'subheading' | 'body'>
    & { link?: Maybe<(
      { __typename?: 'LinkRecord' }
      & LinkableLinkRecordFragment
    )>, image?: Maybe<(
      { __typename?: 'FileField' }
      & Pick<FileField, 'url'>
      & { responsiveImage?: Maybe<(
        { __typename?: 'ResponsiveImage' }
        & ResponsiveImageFieldsFragment
      )> }
    )> }
  ) | (
    { __typename?: 'LocationSiteRecord' }
    & Pick<LocationSiteRecord, 'cardHeading' | 'cardPhoneNumber' | 'cardAddress' | 'cardDistance' | 'cardPrice' | 'cardOptionalText' | 'cardLocationOffice'>
    & { cardImage?: Maybe<(
      { __typename?: 'FileField' }
      & Pick<FileField, 'url'>
      & { responsiveImage?: Maybe<(
        { __typename?: 'ResponsiveImage' }
        & ResponsiveImageFieldsFragment
      )> }
    )> }
    & LinkableLocationSiteRecordFragment
  ) | (
    { __typename?: 'MemberCardRecord' }
    & Pick<MemberCardRecord, 'member' | 'memberTitle' | 'bodyCopy'>
    & { logo?: Maybe<(
      { __typename?: 'FileField' }
      & ImageFileFragment
    )>, image?: Maybe<(
      { __typename?: 'FileField' }
      & Pick<FileField, 'url'>
      & { responsiveImage?: Maybe<(
        { __typename?: 'ResponsiveImage' }
        & ResponsiveImageFieldsFragment
      )> }
    )> }
  ) | (
    { __typename?: 'SolutionTypeRecord' }
    & Pick<SolutionTypeRecord, 'cardHeading' | 'cardSubheading' | 'cardCopy'>
    & { cardImage?: Maybe<(
      { __typename?: 'FileField' }
      & Pick<FileField, 'url'>
      & { responsiveImage?: Maybe<(
        { __typename?: 'ResponsiveImage' }
        & ResponsiveImageFieldsFragment
      )> }
    )> }
    & LinkableSolutionTypeRecordFragment
  ) | (
    { __typename?: 'WorkspaceRecord' }
    & WorkspaceCardFragment
  )>, link?: Maybe<(
    { __typename?: 'LinkRecord' }
    & LinkableLinkRecordFragment
  )> }
);

export type MapFragment = (
  { __typename?: 'MapRecord' }
  & Pick<MapRecord, 'id' | '_isValid' | 'mapCentreLatitude' | 'mapCentreLongitude' | 'zoomLevel' | 'blockCardIdentify' | 'blockMenuName' | 'onLaptop' | 'onMobile'>
  & { mapPins: Array<(
    { __typename?: 'MapPinRecord' }
    & Pick<MapPinRecord, 'id' | '_isValid' | 'latitude' | 'longitude' | 'pinTitle' | 'pinDestination' | 'pinCopy'>
  )> }
);

export type FaqsBlockFragment = (
  { __typename?: 'FaqsBlockRecord' }
  & Pick<FaqsBlockRecord, 'id' | '_isValid' | 'hideSeparatorLine' | 'blockCardIdentify' | 'blockMenuName' | 'onLaptop' | 'onMobile'>
  & { heading?: Maybe<(
    { __typename?: 'FaqsBlockModelHeadingField' }
    & Pick<FaqsBlockModelHeadingField, 'value'>
    & { links: Array<(
      { __typename?: 'LinkRecord' }
      & LinkableLinkRecordFragment
    )> }
  )>, backgroundColor?: Maybe<(
    { __typename?: 'ColorField' }
    & ColorFragment
  )>, faqs: Array<(
    { __typename?: 'FaqRecord' }
    & { question?: Maybe<(
      { __typename?: 'FaqModelQuestionField' }
      & Pick<FaqModelQuestionField, 'value'>
      & { links: Array<(
        { __typename?: 'LinkRecord' }
        & LinkableLinkRecordFragment
      )> }
    )>, answer?: Maybe<(
      { __typename?: 'FaqModelAnswerField' }
      & Pick<FaqModelAnswerField, 'value'>
      & { links: Array<(
        { __typename?: 'LinkRecord' }
        & LinkableLinkRecordFragment
      )> }
    )> }
  )> }
);

export type FeatureBlockFragment = (
  { __typename?: 'FeatureBlockRecord' }
  & Pick<FeatureBlockRecord, 'id' | '_isValid' | 'hideSeparatorLine' | 'blockCardIdentify' | 'blockMenuName' | 'onLaptop' | 'onMobile'>
  & { heading?: Maybe<(
    { __typename?: 'FeatureBlockModelHeadingField' }
    & Pick<FeatureBlockModelHeadingField, 'value'>
    & { links: Array<(
      { __typename?: 'LinkRecord' }
      & LinkableLinkRecordFragment
    )> }
  )>, cta?: Maybe<(
    { __typename?: 'LinkRecord' }
    & LinkableLinkRecordFragment
  )>, backgroundColor?: Maybe<(
    { __typename?: 'ColorField' }
    & ColorFragment
  )>, features: Array<(
    { __typename?: 'FeatureRecord' }
    & Pick<FeatureRecord, 'title'>
    & { backgroundColor?: Maybe<(
      { __typename?: 'ColorField' }
      & ColorFragment
    )>, icon?: Maybe<(
      { __typename?: 'FileField' }
      & ImageFileFragment
    )> }
  )> }
);

export type SimpleCtaBlockFragment = (
  { __typename?: 'SimpleCtaBlockRecord' }
  & Pick<SimpleCtaBlockRecord, 'id' | '_isValid' | 'imageSide' | 'hideSeparatorLine' | 'blockCardIdentify' | 'blockMenuName' | 'onLaptop' | 'onMobile' | 'searchForm'>
  & { structuredText?: Maybe<(
    { __typename?: 'SimpleCtaBlockModelStructuredTextField' }
    & Pick<SimpleCtaBlockModelStructuredTextField, 'value'>
    & { blocks: Array<(
      { __typename: 'FindASpaceButtonRecord' }
      & Pick<FindASpaceButtonRecord, 'id'>
    )>, links: Array<(
      { __typename?: 'LinkRecord' }
      & LinkableLinkRecordFragment
    )> }
  )>, image?: Maybe<(
    { __typename?: 'FileField' }
    & Pick<FileField, 'url'>
    & { responsiveImage?: Maybe<(
      { __typename?: 'ResponsiveImage' }
      & ResponsiveImageFieldsFragment
    )> }
  )>, ctaOne?: Maybe<(
    { __typename?: 'LinkRecord' }
    & LinkableLinkRecordFragment
  )>, ctaTwo?: Maybe<(
    { __typename?: 'LinkRecord' }
    & LinkableLinkRecordFragment
  )>, backgroundColour?: Maybe<(
    { __typename?: 'ColorField' }
    & ColorFragment
  )> }
);

export type MultiColumnBlockFragment = (
  { __typename?: 'MultiColumnBlockRecord' }
  & Pick<MultiColumnBlockRecord, 'id' | '_isValid' | 'theme' | 'numberOfColumns' | 'hideSeparatorLine' | 'blockCardIdentify' | 'blockMenuName' | 'onLaptop' | 'onMobile'>
  & { backgroundColour?: Maybe<(
    { __typename?: 'ColorField' }
    & ColorFragment
  )>, heading?: Maybe<(
    { __typename?: 'MultiColumnBlockModelHeadingField' }
    & Pick<MultiColumnBlockModelHeadingField, 'value'>
    & { links: Array<(
      { __typename?: 'LinkRecord' }
      & LinkableLinkRecordFragment
    )> }
  )>, textBlocks: Array<(
    { __typename?: 'StructuredTextRecord' }
    & { structuredText?: Maybe<(
      { __typename?: 'StructuredTextModelStructuredTextField' }
      & StructuredTextFragment
    )> }
  )> }
);

export type ECtaBlockFragment = (
  { __typename?: 'ECtaBlockRecord' }
  & Pick<ECtaBlockRecord, 'id' | '_isValid' | 'blockCardIdentify' | 'blockMenuName' | 'onLaptop' | 'onMobile'>
  & { structuredText?: Maybe<(
    { __typename?: 'ECtaBlockModelStructuredTextField' }
    & Pick<ECtaBlockModelStructuredTextField, 'value'>
    & { links: Array<(
      { __typename?: 'LinkRecord' }
      & LinkableLinkRecordFragment
    )> }
  )>, image?: Maybe<(
    { __typename?: 'FileField' }
    & Pick<FileField, 'url'>
    & { responsiveImage?: Maybe<(
      { __typename?: 'ResponsiveImage' }
      & ResponsiveImageFieldsFragment
    )> }
  )>, cta?: Maybe<(
    { __typename?: 'LinkRecord' }
    & LinkableLinkRecordFragment
  )>, backgroundColour?: Maybe<(
    { __typename?: 'ColorField' }
    & ColorFragment
  )> }
);

export type TestimonialsCarouselFragment = (
  { __typename?: 'TestimonialsCarouselRecord' }
  & Pick<TestimonialsCarouselRecord, 'id' | '_isValid' | 'hideSeparatorLine' | 'blockCardIdentify' | 'blockMenuName' | 'onLaptop' | 'onMobile'>
  & { heading?: Maybe<(
    { __typename?: 'TestimonialsCarouselModelHeadingField' }
    & Pick<TestimonialsCarouselModelHeadingField, 'value'>
    & { links: Array<(
      { __typename?: 'LinkRecord' }
      & LinkableLinkRecordFragment
    )> }
  )>, testimonials: Array<(
    { __typename?: 'TestimonialRecord' }
    & Pick<TestimonialRecord, 'quotee'>
    & { quote?: Maybe<(
      { __typename?: 'TestimonialModelQuoteField' }
      & Pick<TestimonialModelQuoteField, 'value'>
    )>, logo?: Maybe<(
      { __typename?: 'FileField' }
      & ImageFileFragment
    )>, image?: Maybe<(
      { __typename?: 'FileField' }
      & Pick<FileField, 'url'>
      & { responsiveImage?: Maybe<(
        { __typename?: 'ResponsiveImage' }
        & ResponsiveImageFieldsFragment
      )> }
    )>, backgroundColor?: Maybe<(
      { __typename?: 'ColorField' }
      & ColorFragment
    )> }
  )>, backgroundColour?: Maybe<(
    { __typename?: 'ColorField' }
    & ColorFragment
  )> }
);

export type TestimonialGridFragment = (
  { __typename?: 'TestimonialGridRecord' }
  & Pick<TestimonialGridRecord, 'id' | '_isValid' | 'blockCardIdentify' | 'blockMenuName' | 'onLaptop' | 'onMobile'>
  & { heading?: Maybe<(
    { __typename?: 'TestimonialGridModelHeadingField' }
    & Pick<TestimonialGridModelHeadingField, 'value'>
    & { links: Array<(
      { __typename?: 'LinkRecord' }
      & LinkableLinkRecordFragment
    )> }
  )>, testimonials: Array<(
    { __typename?: 'TestimonialRecord' }
    & Pick<TestimonialRecord, 'quotee'>
    & { quote?: Maybe<(
      { __typename?: 'TestimonialModelQuoteField' }
      & Pick<TestimonialModelQuoteField, 'value'>
    )>, logo?: Maybe<(
      { __typename?: 'FileField' }
      & ImageFileFragment
    )>, image?: Maybe<(
      { __typename?: 'FileField' }
      & Pick<FileField, 'url'>
      & { responsiveImage?: Maybe<(
        { __typename?: 'ResponsiveImage' }
        & ResponsiveImageFieldsFragment
      )> }
    )>, backgroundColor?: Maybe<(
      { __typename?: 'ColorField' }
      & ColorFragment
    )> }
  )> }
);

export type ContactEmbedFragment = (
  { __typename?: 'ContactEmbedRecord' }
  & Pick<ContactEmbedRecord, 'id' | '_isValid' | 'emailAddress' | 'embedLink' | 'phoneNumber'>
  & { heading?: Maybe<(
    { __typename?: 'ContactEmbedModelHeadingField' }
    & Pick<ContactEmbedModelHeadingField, 'value'>
  )>, address?: Maybe<(
    { __typename?: 'ContactEmbedModelAddressField' }
    & Pick<ContactEmbedModelAddressField, 'value'>
  )> }
);

export type HeaderTextLeftFragment = (
  { __typename?: 'HeaderTextLeftRecord' }
  & Pick<HeaderTextLeftRecord, 'id' | '_isValid'>
  & { heading?: Maybe<(
    { __typename?: 'HeaderTextLeftModelHeadingField' }
    & Pick<HeaderTextLeftModelHeadingField, 'value'>
    & { links: Array<(
      { __typename?: 'LinkRecord' }
      & LinkableLinkRecordFragment
    )> }
  )> }
);

export type HeaderTextAndImageFragment = (
  { __typename?: 'HeaderTextAndImageRecord' }
  & Pick<HeaderTextAndImageRecord, 'id' | '_isValid' | 'imageSide'>
  & { heading?: Maybe<(
    { __typename?: 'HeaderTextAndImageModelHeadingField' }
    & Pick<HeaderTextAndImageModelHeadingField, 'value'>
    & { blocks: Array<(
      { __typename: 'FindASpaceButtonRecord' }
      & Pick<FindASpaceButtonRecord, 'id'>
    )>, links: Array<(
      { __typename?: 'LinkRecord' }
      & LinkableLinkRecordFragment
    )> }
  )>, image?: Maybe<(
    { __typename?: 'FileField' }
    & Pick<FileField, 'url'>
    & { responsiveImage?: Maybe<(
      { __typename?: 'ResponsiveImage' }
      & ResponsiveImageFieldsFragment
    )> }
  )>, link?: Maybe<(
    { __typename?: 'LinkRecord' }
    & LinkableLinkRecordFragment
  ) | { __typename?: 'NexudusLinkRecord' }> }
);

export type ImageBlockFragment = (
  { __typename?: 'ImageBlockRecord' }
  & Pick<ImageBlockRecord, 'id' | '_isValid' | 'caption'>
  & { image?: Maybe<(
    { __typename?: 'FileField' }
    & Pick<FileField, 'url'>
    & { responsiveImage?: Maybe<(
      { __typename?: 'ResponsiveImage' }
      & ResponsiveImageFieldsFragment
    )> }
  )> }
);

export type NewsletterSignupBlockFragment = (
  { __typename?: 'NewsletterSignupBlockRecord' }
  & Pick<NewsletterSignupBlockRecord, 'id' | '_isValid' | 'buttonCopy' | 'placeholderCopy'>
  & { smallprintCopy?: Maybe<(
    { __typename?: 'NewsletterSignupBlockModelSmallprintCopyField' }
    & Pick<NewsletterSignupBlockModelSmallprintCopyField, 'value'>
    & { links: Array<(
      { __typename?: 'LinkRecord' }
      & LinkableLinkRecordFragment
    )> }
  )>, mainCopy?: Maybe<(
    { __typename?: 'NewsletterSignupBlockModelMainCopyField' }
    & Pick<NewsletterSignupBlockModelMainCopyField, 'value'>
    & { links: Array<(
      { __typename?: 'LinkRecord' }
      & LinkableLinkRecordFragment
    )> }
  )> }
);

export type QuoteBlockFragment = (
  { __typename?: 'QuoteBlockRecord' }
  & Pick<QuoteBlockRecord, 'id' | '_isValid' | 'quote'>
);

export type LogoWallFragment = (
  { __typename?: 'LogoWallRecord' }
  & Pick<LogoWallRecord, 'id' | '_isValid' | 'hideSeparatorLine' | 'blockCardIdentify' | 'blockMenuName' | 'onLaptop' | 'onMobile'>
  & { heading?: Maybe<(
    { __typename?: 'LogoWallModelHeadingField' }
    & Pick<LogoWallModelHeadingField, 'value'>
    & { links: Array<(
      { __typename?: 'LinkRecord' }
      & LinkableLinkRecordFragment
    )> }
  )>, logos: Array<(
    { __typename?: 'FileField' }
    & Pick<FileField, 'url'>
    & { responsiveImage?: Maybe<(
      { __typename?: 'ResponsiveImage' }
      & ResponsiveImageFieldsFragment
    )> }
  )>, backgroundColour?: Maybe<(
    { __typename?: 'ColorField' }
    & ColorFragment
  )> }
);

export type TestimonialSingleFragment = (
  { __typename?: 'TestimonialSingleRecord' }
  & Pick<TestimonialSingleRecord, 'id' | '_isValid' | 'quotee' | 'quote' | 'blockCardIdentify' | 'blockMenuName' | 'onLaptop' | 'onMobile'>
  & { textBoxColour?: Maybe<(
    { __typename?: 'ColorField' }
    & ColorFragment
  )>, logo?: Maybe<(
    { __typename?: 'FileField' }
    & { responsiveImage?: Maybe<(
      { __typename?: 'ResponsiveImage' }
      & ResponsiveImageFieldsFragment
    )> }
    & ImageFileFragment
  )>, image?: Maybe<(
    { __typename?: 'FileField' }
    & Pick<FileField, 'url'>
    & { responsiveImage?: Maybe<(
      { __typename?: 'ResponsiveImage' }
      & ResponsiveImageFieldsFragment
    )> }
  )>, backgroundColour?: Maybe<(
    { __typename?: 'ColorField' }
    & ColorFragment
  )> }
);

export type CustomHtmlBlockFragment = (
  { __typename?: 'CustomHtmlBlockRecord' }
  & Pick<CustomHtmlBlockRecord, 'id' | '_isValid' | 'customHtml' | 'containerWidth' | 'hideSeparatorLine' | 'blockCardIdentify' | 'blockMenuName' | 'onLaptop' | 'onMobile'>
  & { backgroundColour?: Maybe<(
    { __typename?: 'ColorField' }
    & ColorFragment
  )> }
);

export type PageContentFragment = (
  { __typename?: 'PageContentRecord' }
  & Pick<PageContentRecord, 'id'>
  & { reusableComponents: Array<(
    { __typename: 'CardGridRecord' }
    & CardGridFragment
  ) | (
    { __typename: 'ContactEmbedRecord' }
    & ContactEmbedFragment
  ) | (
    { __typename: 'CustomHtmlBlockRecord' }
    & CustomHtmlBlockFragment
  ) | (
    { __typename: 'ECtaBlockRecord' }
    & ECtaBlockFragment
  ) | (
    { __typename: 'FaqsBlockRecord' }
    & FaqsBlockFragment
  ) | (
    { __typename: 'FeatureBlockRecord' }
    & FeatureBlockFragment
  ) | (
    { __typename: 'HeaderCentreRecord' }
    & HeaderCentreFragment
  ) | (
    { __typename: 'HeaderTextAndImageRecord' }
    & HeaderTextAndImageFragment
  ) | (
    { __typename: 'HeaderTextLeftRecord' }
    & HeaderTextLeftFragment
  ) | (
    { __typename: 'LogoWallRecord' }
    & LogoWallFragment
  ) | (
    { __typename: 'MapRecord' }
    & MapFragment
  ) | (
    { __typename: 'MultiColumnBlockRecord' }
    & MultiColumnBlockFragment
  ) | (
    { __typename: 'SimpleCtaBlockRecord' }
    & SimpleCtaBlockFragment
  ) | (
    { __typename: 'TestimonialGridRecord' }
    & TestimonialGridFragment
  ) | (
    { __typename: 'TestimonialSingleRecord' }
    & TestimonialSingleFragment
  ) | (
    { __typename: 'TestimonialsCarouselRecord' }
    & TestimonialsCarouselFragment
  )> }
);

export type SiteLogoFragment = (
  { __typename?: 'SiteLogoRecord' }
  & { headerImage?: Maybe<(
    { __typename?: 'FileField' }
    & Pick<FileField, 'url'>
  )>, footerImage?: Maybe<(
    { __typename?: 'FileField' }
    & Pick<FileField, 'url'>
  )> }
);

export const LinkableCustomPageRecordFragmentDoc = gql`
    fragment LinkableCustomPageRecord on CustomPageRecord {
  id
  _isValid
  urlPath
  pageName
}
    `;
export const LinkableLocationSiteRecordFragmentDoc = gql`
    fragment LinkableLocationSiteRecord on LocationSiteRecord {
  id
  _isValid
  urlPath
  siteName
}
    `;
export const LinkableSolutionTypeRecordFragmentDoc = gql`
    fragment LinkableSolutionTypeRecord on SolutionTypeRecord {
  id
  _isValid
  urlPath
  solutionType
}
    `;
export const LinkableArticleRecordFragmentDoc = gql`
    fragment LinkableArticleRecord on ArticleRecord {
  id
  _isValid
  pagePath
  title
}
    `;
export const ColorFragmentDoc = gql`
    fragment Color on ColorField {
  hex
}
    `;
export const LinkableArticleCategoryRecordFragmentDoc = gql`
    fragment LinkableArticleCategoryRecord on ArticleCategoryRecord {
  id
  _isValid
  categoryName
  categoryColour {
    ...Color
  }
  pagePath
}
    ${ColorFragmentDoc}`;
export const LinkableBlogHomepageRecordFragmentDoc = gql`
    fragment LinkableBlogHomepageRecord on BlogHomepageRecord {
  id
  _isValid
  pageTitle
  categoryColour {
    ...Color
  }
  pagePath
}
    ${ColorFragmentDoc}`;
export const CustomPageRecordBreadcrumbFragmentDoc = gql`
    fragment CustomPageRecordBreadcrumb on CustomPageRecord {
  breadcrumb {
    __typename
    ... on CustomPageRecord {
      ...LinkableCustomPageRecord
    }
    ... on LocationSiteRecord {
      ...LinkableLocationSiteRecord
    }
    ... on SolutionTypeRecord {
      ...LinkableSolutionTypeRecord
    }
    ... on ArticleRecord {
      ...LinkableArticleRecord
    }
    ... on ArticleCategoryRecord {
      ...LinkableArticleCategoryRecord
    }
    ... on BlogHomepageRecord {
      ...LinkableBlogHomepageRecord
    }
  }
}
    ${LinkableCustomPageRecordFragmentDoc}
${LinkableLocationSiteRecordFragmentDoc}
${LinkableSolutionTypeRecordFragmentDoc}
${LinkableArticleRecordFragmentDoc}
${LinkableArticleCategoryRecordFragmentDoc}
${LinkableBlogHomepageRecordFragmentDoc}`;
export const LocationSiteRecordBreadcrumbFragmentDoc = gql`
    fragment LocationSiteRecordBreadcrumb on LocationSiteRecord {
  breadcrumb {
    __typename
    ... on CustomPageRecord {
      ...LinkableCustomPageRecord
    }
    ... on LocationSiteRecord {
      ...LinkableLocationSiteRecord
    }
    ... on SolutionTypeRecord {
      ...LinkableSolutionTypeRecord
    }
    ... on ArticleRecord {
      ...LinkableArticleRecord
    }
    ... on ArticleCategoryRecord {
      ...LinkableArticleCategoryRecord
    }
    ... on BlogHomepageRecord {
      ...LinkableBlogHomepageRecord
    }
  }
}
    ${LinkableCustomPageRecordFragmentDoc}
${LinkableLocationSiteRecordFragmentDoc}
${LinkableSolutionTypeRecordFragmentDoc}
${LinkableArticleRecordFragmentDoc}
${LinkableArticleCategoryRecordFragmentDoc}
${LinkableBlogHomepageRecordFragmentDoc}`;
export const SolutionTypeRecordBreadcrumbFragmentDoc = gql`
    fragment SolutionTypeRecordBreadcrumb on SolutionTypeRecord {
  breadcrumb {
    __typename
    ... on CustomPageRecord {
      ...LinkableCustomPageRecord
    }
    ... on LocationSiteRecord {
      ...LinkableLocationSiteRecord
    }
    ... on SolutionTypeRecord {
      ...LinkableSolutionTypeRecord
    }
    ... on ArticleRecord {
      ...LinkableArticleRecord
    }
    ... on ArticleCategoryRecord {
      ...LinkableArticleCategoryRecord
    }
    ... on BlogHomepageRecord {
      ...LinkableBlogHomepageRecord
    }
  }
}
    ${LinkableCustomPageRecordFragmentDoc}
${LinkableLocationSiteRecordFragmentDoc}
${LinkableSolutionTypeRecordFragmentDoc}
${LinkableArticleRecordFragmentDoc}
${LinkableArticleCategoryRecordFragmentDoc}
${LinkableBlogHomepageRecordFragmentDoc}`;
export const LinkableWorkspaceRecordFragmentDoc = gql`
    fragment LinkableWorkspaceRecord on WorkspaceRecord {
  id
  _isValid
  urlPath
}
    `;
export const WorkspaceRecordBreadcrumbFragmentDoc = gql`
    fragment WorkspaceRecordBreadcrumb on WorkspaceRecord {
  breadcrumb {
    __typename
    ... on CustomPageRecord {
      ...LinkableCustomPageRecord
    }
    ... on LocationSiteRecord {
      ...LinkableLocationSiteRecord
    }
    ... on SolutionTypeRecord {
      ...LinkableSolutionTypeRecord
    }
    ... on ArticleRecord {
      ...LinkableArticleRecord
    }
    ... on ArticleCategoryRecord {
      ...LinkableArticleCategoryRecord
    }
    ... on BlogHomepageRecord {
      ...LinkableBlogHomepageRecord
    }
    ... on WorkspaceRecord {
      ...LinkableWorkspaceRecord
    }
  }
}
    ${LinkableCustomPageRecordFragmentDoc}
${LinkableLocationSiteRecordFragmentDoc}
${LinkableSolutionTypeRecordFragmentDoc}
${LinkableArticleRecordFragmentDoc}
${LinkableArticleCategoryRecordFragmentDoc}
${LinkableBlogHomepageRecordFragmentDoc}
${LinkableWorkspaceRecordFragmentDoc}`;
export const ArticleRecordBreadcrumbFragmentDoc = gql`
    fragment ArticleRecordBreadcrumb on ArticleRecord {
  breadcrumb {
    __typename
    ... on CustomPageRecord {
      ...LinkableCustomPageRecord
    }
    ... on LocationSiteRecord {
      ...LinkableLocationSiteRecord
    }
    ... on SolutionTypeRecord {
      ...LinkableSolutionTypeRecord
    }
    ... on ArticleRecord {
      ...LinkableArticleRecord
    }
    ... on ArticleCategoryRecord {
      ...LinkableArticleCategoryRecord
    }
    ... on BlogHomepageRecord {
      ...LinkableBlogHomepageRecord
    }
  }
}
    ${LinkableCustomPageRecordFragmentDoc}
${LinkableLocationSiteRecordFragmentDoc}
${LinkableSolutionTypeRecordFragmentDoc}
${LinkableArticleRecordFragmentDoc}
${LinkableArticleCategoryRecordFragmentDoc}
${LinkableBlogHomepageRecordFragmentDoc}`;
export const ArticleCategoryRecordBreadcrumbFragmentDoc = gql`
    fragment ArticleCategoryRecordBreadcrumb on ArticleCategoryRecord {
  breadcrumb {
    __typename
    ... on CustomPageRecord {
      ...LinkableCustomPageRecord
    }
    ... on LocationSiteRecord {
      ...LinkableLocationSiteRecord
    }
    ... on SolutionTypeRecord {
      ...LinkableSolutionTypeRecord
    }
    ... on ArticleRecord {
      ...LinkableArticleRecord
    }
    ... on ArticleCategoryRecord {
      ...LinkableArticleCategoryRecord
    }
    ... on BlogHomepageRecord {
      ...LinkableBlogHomepageRecord
    }
  }
}
    ${LinkableCustomPageRecordFragmentDoc}
${LinkableLocationSiteRecordFragmentDoc}
${LinkableSolutionTypeRecordFragmentDoc}
${LinkableArticleRecordFragmentDoc}
${LinkableArticleCategoryRecordFragmentDoc}
${LinkableBlogHomepageRecordFragmentDoc}`;
export const BlogHomePageRecordBreadcrumbFragmentDoc = gql`
    fragment BlogHomePageRecordBreadcrumb on BlogHomepageRecord {
  breadcrumb {
    __typename
    ... on CustomPageRecord {
      ...LinkableCustomPageRecord
    }
    ... on LocationSiteRecord {
      ...LinkableLocationSiteRecord
    }
    ... on SolutionTypeRecord {
      ...LinkableSolutionTypeRecord
    }
    ... on ArticleRecord {
      ...LinkableArticleRecord
    }
    ... on ArticleCategoryRecord {
      ...LinkableArticleCategoryRecord
    }
    ... on BlogHomepageRecord {
      ...LinkableBlogHomepageRecord
    }
  }
}
    ${LinkableCustomPageRecordFragmentDoc}
${LinkableLocationSiteRecordFragmentDoc}
${LinkableSolutionTypeRecordFragmentDoc}
${LinkableArticleRecordFragmentDoc}
${LinkableArticleCategoryRecordFragmentDoc}
${LinkableBlogHomepageRecordFragmentDoc}`;
export const MetaTagsFragmentDoc = gql`
    fragment MetaTags on SeoField {
  description
  image {
    url
  }
  title
  twitterCard
}
    `;
export const LinkableNexudusLinkRecordFragmentDoc = gql`
    fragment LinkableNexudusLinkRecord on NexudusLinkRecord {
  id
  _isValid
  urlPath
}
    `;
export const LinkableLinkRecordFragmentDoc = gql`
    fragment LinkableLinkRecord on LinkRecord {
  __typename
  id
  _isValid
  name
  buttonStyle
  externalLink
  externalElementId
  link {
    __typename
    ... on CustomPageRecord {
      ...LinkableCustomPageRecord
    }
    ... on LocationSiteRecord {
      ...LinkableLocationSiteRecord
    }
    ... on SolutionTypeRecord {
      ...LinkableSolutionTypeRecord
    }
    ... on ArticleRecord {
      ...LinkableArticleRecord
    }
    ... on ArticleCategoryRecord {
      ...LinkableArticleCategoryRecord
    }
    ... on BlogHomepageRecord {
      ...LinkableBlogHomepageRecord
    }
    ... on NexudusLinkRecord {
      ...LinkableNexudusLinkRecord
    }
    ... on WorkspaceRecord {
      ...LinkableWorkspaceRecord
    }
  }
}
    ${LinkableCustomPageRecordFragmentDoc}
${LinkableLocationSiteRecordFragmentDoc}
${LinkableSolutionTypeRecordFragmentDoc}
${LinkableArticleRecordFragmentDoc}
${LinkableArticleCategoryRecordFragmentDoc}
${LinkableBlogHomepageRecordFragmentDoc}
${LinkableNexudusLinkRecordFragmentDoc}
${LinkableWorkspaceRecordFragmentDoc}`;
export const NewsletterSignupBlockFragmentDoc = gql`
    fragment NewsletterSignupBlock on NewsletterSignupBlockRecord {
  id
  _isValid
  buttonCopy
  smallprintCopy {
    value
    links {
      ...LinkableLinkRecord
    }
  }
  placeholderCopy
  mainCopy {
    value
    links {
      ...LinkableLinkRecord
    }
  }
}
    ${LinkableLinkRecordFragmentDoc}`;
export const QuoteBlockFragmentDoc = gql`
    fragment QuoteBlock on QuoteBlockRecord {
  id
  _isValid
  quote
}
    `;
export const ResponsiveImageFieldsFragmentDoc = gql`
    fragment ResponsiveImageFields on ResponsiveImage {
  webpSrcSet
  width
  aspectRatio
  base64
  alt
  src
  sizes
  title
}
    `;
export const HeaderCentreFragmentDoc = gql`
    fragment HeaderCentre on HeaderCentreRecord {
  id
  _isValid
  backgroundColour {
    ...Color
  }
  buttonLink {
    ...LinkableLinkRecord
  }
  buttonType
  copy
  headingString: heading
  textBackgroundImage {
    url
    responsiveImage(imgixParams: {fit: clip, w: 500, auto: format, q: 10}) {
      ...ResponsiveImageFields
    }
  }
}
    ${ColorFragmentDoc}
${LinkableLinkRecordFragmentDoc}
${ResponsiveImageFieldsFragmentDoc}`;
export const ImageFileFragmentDoc = gql`
    fragment ImageFile on FileField {
  id
  url
  alt
}
    `;
export const ArticleCardFragmentDoc = gql`
    fragment ArticleCard on ArticleRecord {
  id
  cardTitle
  cardByline
  cardExcerpt
  cardImage {
    url
    responsiveImage(imgixParams: {fit: max, w: 735, auto: format, q: 50}) {
      ...ResponsiveImageFields
    }
  }
  categories {
    categoryName
    categoryColour {
      ...Color
    }
  }
  pagePath
}
    ${ResponsiveImageFieldsFragmentDoc}
${ColorFragmentDoc}`;
export const WorkspaceCardFragmentDoc = gql`
    fragment WorkspaceCard on WorkspaceRecord {
  ...LinkableWorkspaceRecord
  name
  capacity
  price
  site {
    address
    coordinates {
      latitude
      longitude
    }
    siteName
    transportLinkCopy1
    transportLinkImage1 {
      ...ImageFile
    }
    transportLinkCopy2
    transportLinkImage2 {
      ...ImageFile
    }
  }
  cardLabel
  cardImage {
    url
    responsiveImage(imgixParams: {fit: max, w: 735, auto: format, q: 50}) {
      ...ResponsiveImageFields
    }
  }
  cardLabelColour {
    ...Color
  }
}
    ${LinkableWorkspaceRecordFragmentDoc}
${ImageFileFragmentDoc}
${ResponsiveImageFieldsFragmentDoc}
${ColorFragmentDoc}`;
export const CardGridFragmentDoc = gql`
    fragment CardGrid on CardGridRecord {
  id
  _isValid
  backgroundColour {
    ...Color
  }
  heading {
    value
    links {
      ...LinkableLinkRecord
    }
  }
  numberOfColumns
  cards {
    ... on LocationSiteRecord {
      ...LinkableLocationSiteRecord
      cardHeading
      cardPhoneNumber
      cardAddress
      cardDistance
      cardPrice
      cardOptionalText
      cardLocationOffice
      cardImage {
        url
        responsiveImage(imgixParams: {fit: max, w: 695, auto: format, q: 50}) {
          ...ResponsiveImageFields
        }
      }
    }
    ... on SolutionTypeRecord {
      ...LinkableSolutionTypeRecord
      cardHeading
      cardSubheading
      cardCopy
      cardImage {
        url
        responsiveImage(imgixParams: {fit: max, w: 972, auto: format, q: 50}) {
          ...ResponsiveImageFields
        }
      }
    }
    ... on GenericCardRecord {
      link {
        ...LinkableLinkRecord
      }
      heading
      subheading
      body
      image {
        url
        responsiveImage(imgixParams: {fit: max, w: 972, auto: format, q: 50}) {
          ...ResponsiveImageFields
        }
      }
    }
    ... on MemberCardRecord {
      member
      memberTitle
      bodyCopy
      logo {
        ...ImageFile
      }
      image {
        url
        responsiveImage(imgixParams: {fit: max, w: 759, auto: format, q: 50}) {
          ...ResponsiveImageFields
        }
      }
    }
    ... on ArticleRecord {
      ...ArticleCard
    }
    ... on WorkspaceRecord {
      ...WorkspaceCard
    }
  }
  link {
    ...LinkableLinkRecord
  }
  hideSeparatorLine
  blockCardIdentify
  blockMenuName
  onLaptop
  onMobile
}
    ${ColorFragmentDoc}
${LinkableLinkRecordFragmentDoc}
${LinkableLocationSiteRecordFragmentDoc}
${ResponsiveImageFieldsFragmentDoc}
${LinkableSolutionTypeRecordFragmentDoc}
${ImageFileFragmentDoc}
${ArticleCardFragmentDoc}
${WorkspaceCardFragmentDoc}`;
export const MapFragmentDoc = gql`
    fragment Map on MapRecord {
  id
  _isValid
  mapCentreLatitude
  mapCentreLongitude
  zoomLevel
  mapPins {
    id
    _isValid
    latitude
    longitude
    pinTitle
    pinDestination
    pinCopy
  }
  blockCardIdentify
  blockMenuName
  onLaptop
  onMobile
}
    `;
export const FeatureBlockFragmentDoc = gql`
    fragment FeatureBlock on FeatureBlockRecord {
  id
  _isValid
  heading {
    value
    links {
      ...LinkableLinkRecord
    }
  }
  cta {
    ...LinkableLinkRecord
  }
  backgroundColor {
    ...Color
  }
  features {
    backgroundColor {
      ...Color
    }
    title
    icon {
      ...ImageFile
    }
  }
  hideSeparatorLine
  blockCardIdentify
  blockMenuName
  onLaptop
  onMobile
}
    ${LinkableLinkRecordFragmentDoc}
${ColorFragmentDoc}
${ImageFileFragmentDoc}`;
export const FaqsBlockFragmentDoc = gql`
    fragment FaqsBlock on FaqsBlockRecord {
  id
  _isValid
  heading {
    value
    links {
      ...LinkableLinkRecord
    }
  }
  backgroundColor {
    ...Color
  }
  faqs {
    question {
      value
      links {
        ...LinkableLinkRecord
      }
    }
    answer {
      value
      links {
        ...LinkableLinkRecord
      }
    }
  }
  hideSeparatorLine
  blockCardIdentify
  blockMenuName
  onLaptop
  onMobile
}
    ${LinkableLinkRecordFragmentDoc}
${ColorFragmentDoc}`;
export const SimpleCtaBlockFragmentDoc = gql`
    fragment SimpleCtaBlock on SimpleCtaBlockRecord {
  id
  _isValid
  structuredText {
    value
    blocks {
      __typename
      id
    }
    links {
      ...LinkableLinkRecord
    }
  }
  image {
    url
    responsiveImage(imgixParams: {fit: max, w: 856, auto: format, q: 50}) {
      ...ResponsiveImageFields
    }
  }
  imageSide
  ctaOne {
    ...LinkableLinkRecord
  }
  ctaTwo {
    ...LinkableLinkRecord
  }
  backgroundColour {
    ...Color
  }
  hideSeparatorLine
  blockCardIdentify
  blockMenuName
  onLaptop
  onMobile
  searchForm
}
    ${LinkableLinkRecordFragmentDoc}
${ResponsiveImageFieldsFragmentDoc}
${ColorFragmentDoc}`;
export const ECtaBlockFragmentDoc = gql`
    fragment ECtaBlock on ECtaBlockRecord {
  id
  _isValid
  structuredText {
    value
    links {
      ...LinkableLinkRecord
    }
  }
  image {
    url
    responsiveImage(imgixParams: {fit: max, w: 1092, auto: format, q: 50}) {
      ...ResponsiveImageFields
    }
  }
  cta {
    ...LinkableLinkRecord
  }
  backgroundColour {
    ...Color
  }
  blockCardIdentify
  blockMenuName
  onLaptop
  onMobile
}
    ${LinkableLinkRecordFragmentDoc}
${ResponsiveImageFieldsFragmentDoc}
${ColorFragmentDoc}`;
export const ImageBlockFragmentDoc = gql`
    fragment ImageBlock on ImageBlockRecord {
  id
  _isValid
  image {
    url
    responsiveImage(imgixParams: {fit: max, w: 636, auto: format, q: 50}) {
      ...ResponsiveImageFields
    }
  }
  caption
}
    ${ResponsiveImageFieldsFragmentDoc}`;
export const CustomHtmlBlockFragmentDoc = gql`
    fragment CustomHtmlBlock on CustomHtmlBlockRecord {
  id
  _isValid
  customHtml
  backgroundColour {
    ...Color
  }
  containerWidth
  hideSeparatorLine
  blockCardIdentify
  blockMenuName
  onLaptop
  onMobile
}
    ${ColorFragmentDoc}`;
export const StructuredTextFragmentDoc = gql`
    fragment StructuredText on StructuredTextModelStructuredTextField {
  value
  blocks {
    ... on HighlightedTextBlockRecord {
      id
      backgroundColour {
        ...Color
      }
      structuredText {
        value
        links {
          ...LinkableLinkRecord
        }
      }
    }
    ... on IconBlockRecord {
      id
      icon {
        ...ImageFile
      }
    }
    ... on ImageBlockRecord {
      ...ImageBlock
    }
    ... on CustomHtmlBlockRecord {
      ...CustomHtmlBlock
    }
  }
  links {
    ...LinkableLinkRecord
  }
}
    ${ColorFragmentDoc}
${LinkableLinkRecordFragmentDoc}
${ImageFileFragmentDoc}
${ImageBlockFragmentDoc}
${CustomHtmlBlockFragmentDoc}`;
export const MultiColumnBlockFragmentDoc = gql`
    fragment MultiColumnBlock on MultiColumnBlockRecord {
  id
  _isValid
  theme
  numberOfColumns
  backgroundColour {
    ...Color
  }
  heading {
    value
    links {
      ...LinkableLinkRecord
    }
  }
  textBlocks {
    structuredText {
      ...StructuredText
    }
  }
  hideSeparatorLine
  blockCardIdentify
  blockMenuName
  onLaptop
  onMobile
}
    ${ColorFragmentDoc}
${LinkableLinkRecordFragmentDoc}
${StructuredTextFragmentDoc}`;
export const TestimonialsCarouselFragmentDoc = gql`
    fragment TestimonialsCarousel on TestimonialsCarouselRecord {
  id
  _isValid
  heading {
    value
    links {
      ...LinkableLinkRecord
    }
  }
  testimonials {
    quote {
      value
    }
    quotee
    logo {
      ...ImageFile
    }
    image {
      url
      responsiveImage(imgixParams: {fit: crop, w: 357, h: 420, auto: format, q: 50}) {
        ...ResponsiveImageFields
      }
    }
    backgroundColor {
      ...Color
    }
  }
  backgroundColour {
    ...Color
  }
  hideSeparatorLine
  blockCardIdentify
  blockMenuName
  onLaptop
  onMobile
}
    ${LinkableLinkRecordFragmentDoc}
${ImageFileFragmentDoc}
${ResponsiveImageFieldsFragmentDoc}
${ColorFragmentDoc}`;
export const TestimonialGridFragmentDoc = gql`
    fragment TestimonialGrid on TestimonialGridRecord {
  id
  _isValid
  heading {
    value
    links {
      ...LinkableLinkRecord
    }
  }
  testimonials {
    quote {
      value
    }
    quotee
    logo {
      ...ImageFile
    }
    image {
      url
      responsiveImage(imgixParams: {fit: crop, w: 357, h: 420, auto: format, q: 50}) {
        ...ResponsiveImageFields
      }
    }
    backgroundColor {
      ...Color
    }
  }
  blockCardIdentify
  blockMenuName
  onLaptop
  onMobile
}
    ${LinkableLinkRecordFragmentDoc}
${ImageFileFragmentDoc}
${ResponsiveImageFieldsFragmentDoc}
${ColorFragmentDoc}`;
export const ContactEmbedFragmentDoc = gql`
    fragment ContactEmbed on ContactEmbedRecord {
  id
  _isValid
  heading {
    value
  }
  address {
    value
  }
  emailAddress
  embedLink
  phoneNumber
}
    `;
export const HeaderTextLeftFragmentDoc = gql`
    fragment HeaderTextLeft on HeaderTextLeftRecord {
  id
  _isValid
  heading {
    value
    links {
      ...LinkableLinkRecord
    }
  }
}
    ${LinkableLinkRecordFragmentDoc}`;
export const HeaderTextAndImageFragmentDoc = gql`
    fragment HeaderTextAndImage on HeaderTextAndImageRecord {
  id
  _isValid
  heading {
    value
    blocks {
      __typename
      id
    }
    links {
      ...LinkableLinkRecord
    }
  }
  image {
    url
    responsiveImage(imgixParams: {fit: max, w: 1006, auto: format, q: 30}) {
      ...ResponsiveImageFields
    }
  }
  imageSide
  link {
    ... on LinkRecord {
      ...LinkableLinkRecord
    }
  }
}
    ${LinkableLinkRecordFragmentDoc}
${ResponsiveImageFieldsFragmentDoc}`;
export const LogoWallFragmentDoc = gql`
    fragment LogoWall on LogoWallRecord {
  id
  _isValid
  heading {
    value
    links {
      ...LinkableLinkRecord
    }
  }
  logos {
    url
    responsiveImage(imgixParams: {fit: max, w: 250, auto: format, q: 75}) {
      ...ResponsiveImageFields
    }
  }
  backgroundColour {
    ...Color
  }
  hideSeparatorLine
  blockCardIdentify
  blockMenuName
  onLaptop
  onMobile
}
    ${LinkableLinkRecordFragmentDoc}
${ResponsiveImageFieldsFragmentDoc}
${ColorFragmentDoc}`;
export const TestimonialSingleFragmentDoc = gql`
    fragment TestimonialSingle on TestimonialSingleRecord {
  id
  _isValid
  textBoxColour {
    ...Color
  }
  quotee
  quote
  logo {
    ...ImageFile
    responsiveImage(imgixParams: {fit: max, w: 82, h: 36, q: 100}) {
      ...ResponsiveImageFields
    }
  }
  image {
    url
    responsiveImage(imgixParams: {fit: max, w: 600, auto: format, q: 50}) {
      ...ResponsiveImageFields
    }
  }
  backgroundColour {
    ...Color
  }
  blockCardIdentify
  blockMenuName
  onLaptop
  onMobile
}
    ${ColorFragmentDoc}
${ImageFileFragmentDoc}
${ResponsiveImageFieldsFragmentDoc}`;
export const PageContentFragmentDoc = gql`
    fragment PageContent on PageContentRecord {
  id
  reusableComponents {
    __typename
    ... on HeaderCentreRecord {
      ...HeaderCentre
    }
    ... on CardGridRecord {
      ...CardGrid
    }
    ... on MapRecord {
      ...Map
    }
    ... on FeatureBlockRecord {
      ...FeatureBlock
    }
    ... on FaqsBlockRecord {
      ...FaqsBlock
    }
    ... on SimpleCtaBlockRecord {
      ...SimpleCtaBlock
    }
    ... on ECtaBlockRecord {
      ...ECtaBlock
    }
    ... on MultiColumnBlockRecord {
      ...MultiColumnBlock
    }
    ... on TestimonialsCarouselRecord {
      ...TestimonialsCarousel
    }
    ... on TestimonialGridRecord {
      ...TestimonialGrid
    }
    ... on ContactEmbedRecord {
      ...ContactEmbed
    }
    ... on HeaderTextLeftRecord {
      ...HeaderTextLeft
    }
    ... on HeaderTextAndImageRecord {
      ...HeaderTextAndImage
    }
    ... on LogoWallRecord {
      ...LogoWall
    }
    ... on TestimonialSingleRecord {
      ...TestimonialSingle
    }
    ... on CustomHtmlBlockRecord {
      ...CustomHtmlBlock
    }
  }
}
    ${HeaderCentreFragmentDoc}
${CardGridFragmentDoc}
${MapFragmentDoc}
${FeatureBlockFragmentDoc}
${FaqsBlockFragmentDoc}
${SimpleCtaBlockFragmentDoc}
${ECtaBlockFragmentDoc}
${MultiColumnBlockFragmentDoc}
${TestimonialsCarouselFragmentDoc}
${TestimonialGridFragmentDoc}
${ContactEmbedFragmentDoc}
${HeaderTextLeftFragmentDoc}
${HeaderTextAndImageFragmentDoc}
${LogoWallFragmentDoc}
${TestimonialSingleFragmentDoc}
${CustomHtmlBlockFragmentDoc}`;
export const SiteLogoFragmentDoc = gql`
    fragment SiteLogo on SiteLogoRecord {
  headerImage {
    url
  }
  footerImage {
    url
  }
}
    `;
export const NavbarDocument = gql`
    query Navbar {
  navbarCta {
    link {
      ...LinkableLinkRecord
    }
  }
  allNavbarItems(filter: {parent: {eq: null}, _isValid: {eq: true}}) {
    __typename
    id
    _isValid
    name
    link {
      __typename
      ... on CustomPageRecord {
        ...LinkableCustomPageRecord
      }
      ... on LocationSiteRecord {
        ...LinkableLocationSiteRecord
      }
      ... on SolutionTypeRecord {
        ...LinkableSolutionTypeRecord
      }
      ... on ArticleRecord {
        ...LinkableArticleRecord
      }
      ... on ArticleCategoryRecord {
        ...LinkableArticleCategoryRecord
      }
      ... on BlogHomepageRecord {
        ...LinkableBlogHomepageRecord
      }
      ... on NexudusLinkRecord {
        ...LinkableNexudusLinkRecord
      }
      ... on WorkspaceRecord {
        ...LinkableWorkspaceRecord
      }
    }
    children {
      __typename
      id
      _isValid
      name
      link {
        __typename
        ... on CustomPageRecord {
          ...LinkableCustomPageRecord
        }
        ... on LocationSiteRecord {
          ...LinkableLocationSiteRecord
        }
        ... on SolutionTypeRecord {
          ...LinkableSolutionTypeRecord
        }
        ... on ArticleRecord {
          ...LinkableArticleRecord
        }
        ... on ArticleCategoryRecord {
          ...LinkableArticleCategoryRecord
        }
        ... on BlogHomepageRecord {
          ...LinkableBlogHomepageRecord
        }
        ... on NexudusLinkRecord {
          ...LinkableNexudusLinkRecord
        }
        ... on WorkspaceRecord {
          ...LinkableWorkspaceRecord
        }
      }
      children {
        __typename
        id
        _isValid
        name
        link {
          __typename
          ... on CustomPageRecord {
            ...LinkableCustomPageRecord
          }
          ... on LocationSiteRecord {
            ...LinkableLocationSiteRecord
          }
          ... on SolutionTypeRecord {
            ...LinkableSolutionTypeRecord
          }
          ... on ArticleRecord {
            ...LinkableArticleRecord
          }
          ... on ArticleCategoryRecord {
            ...LinkableArticleCategoryRecord
          }
          ... on BlogHomepageRecord {
            ...LinkableBlogHomepageRecord
          }
          ... on NexudusLinkRecord {
            ...LinkableNexudusLinkRecord
          }
          ... on WorkspaceRecord {
            ...LinkableWorkspaceRecord
          }
        }
        children {
          __typename
          id
          _isValid
          name
          link {
            __typename
            ... on CustomPageRecord {
              ...LinkableCustomPageRecord
            }
            ... on LocationSiteRecord {
              ...LinkableLocationSiteRecord
            }
            ... on SolutionTypeRecord {
              ...LinkableSolutionTypeRecord
            }
            ... on ArticleRecord {
              ...LinkableArticleRecord
            }
            ... on ArticleCategoryRecord {
              ...LinkableArticleCategoryRecord
            }
            ... on BlogHomepageRecord {
              ...LinkableBlogHomepageRecord
            }
            ... on NexudusLinkRecord {
              ...LinkableNexudusLinkRecord
            }
            ... on WorkspaceRecord {
              ...LinkableWorkspaceRecord
            }
          }
          children {
            __typename
            id
            _isValid
            name
            link {
              __typename
              ... on CustomPageRecord {
                ...LinkableCustomPageRecord
              }
              ... on LocationSiteRecord {
                ...LinkableLocationSiteRecord
              }
              ... on SolutionTypeRecord {
                ...LinkableSolutionTypeRecord
              }
              ... on ArticleRecord {
                ...LinkableArticleRecord
              }
              ... on ArticleCategoryRecord {
                ...LinkableArticleCategoryRecord
              }
              ... on BlogHomepageRecord {
                ...LinkableBlogHomepageRecord
              }
              ... on NexudusLinkRecord {
                ...LinkableNexudusLinkRecord
              }
              ... on WorkspaceRecord {
                ...LinkableWorkspaceRecord
              }
            }
            children {
              __typename
              id
              _isValid
              name
              link {
                __typename
                ... on CustomPageRecord {
                  ...LinkableCustomPageRecord
                }
                ... on LocationSiteRecord {
                  ...LinkableLocationSiteRecord
                }
                ... on SolutionTypeRecord {
                  ...LinkableSolutionTypeRecord
                }
                ... on ArticleRecord {
                  ...LinkableArticleRecord
                }
                ... on ArticleCategoryRecord {
                  ...LinkableArticleCategoryRecord
                }
                ... on BlogHomepageRecord {
                  ...LinkableBlogHomepageRecord
                }
                ... on NexudusLinkRecord {
                  ...LinkableNexudusLinkRecord
                }
                ... on WorkspaceRecord {
                  ...LinkableWorkspaceRecord
                }
              }
              children {
                __typename
                id
                _isValid
                name
                link {
                  __typename
                  ... on CustomPageRecord {
                    ...LinkableCustomPageRecord
                  }
                  ... on LocationSiteRecord {
                    ...LinkableLocationSiteRecord
                  }
                  ... on SolutionTypeRecord {
                    ...LinkableSolutionTypeRecord
                  }
                  ... on ArticleRecord {
                    ...LinkableArticleRecord
                  }
                  ... on ArticleCategoryRecord {
                    ...LinkableArticleCategoryRecord
                  }
                  ... on BlogHomepageRecord {
                    ...LinkableBlogHomepageRecord
                  }
                  ... on NexudusLinkRecord {
                    ...LinkableNexudusLinkRecord
                  }
                  ... on WorkspaceRecord {
                    ...LinkableWorkspaceRecord
                  }
                }
                children {
                  __typename
                  id
                  _isValid
                  name
                  link {
                    __typename
                    ... on CustomPageRecord {
                      ...LinkableCustomPageRecord
                    }
                    ... on LocationSiteRecord {
                      ...LinkableLocationSiteRecord
                    }
                    ... on SolutionTypeRecord {
                      ...LinkableSolutionTypeRecord
                    }
                    ... on ArticleRecord {
                      ...LinkableArticleRecord
                    }
                    ... on ArticleCategoryRecord {
                      ...LinkableArticleCategoryRecord
                    }
                    ... on BlogHomepageRecord {
                      ...LinkableBlogHomepageRecord
                    }
                    ... on NexudusLinkRecord {
                      ...LinkableNexudusLinkRecord
                    }
                    ... on WorkspaceRecord {
                      ...LinkableWorkspaceRecord
                    }
                  }
                  children {
                    __typename
                    id
                    _isValid
                    name
                    link {
                      __typename
                      ... on CustomPageRecord {
                        ...LinkableCustomPageRecord
                      }
                      ... on LocationSiteRecord {
                        ...LinkableLocationSiteRecord
                      }
                      ... on SolutionTypeRecord {
                        ...LinkableSolutionTypeRecord
                      }
                      ... on ArticleRecord {
                        ...LinkableArticleRecord
                      }
                      ... on ArticleCategoryRecord {
                        ...LinkableArticleCategoryRecord
                      }
                      ... on BlogHomepageRecord {
                        ...LinkableBlogHomepageRecord
                      }
                      ... on NexudusLinkRecord {
                        ...LinkableNexudusLinkRecord
                      }
                      ... on WorkspaceRecord {
                        ...LinkableWorkspaceRecord
                      }
                    }
                    children {
                      __typename
                      id
                      _isValid
                      name
                      link {
                        __typename
                        ... on CustomPageRecord {
                          ...LinkableCustomPageRecord
                        }
                        ... on LocationSiteRecord {
                          ...LinkableLocationSiteRecord
                        }
                        ... on SolutionTypeRecord {
                          ...LinkableSolutionTypeRecord
                        }
                        ... on ArticleRecord {
                          ...LinkableArticleRecord
                        }
                        ... on ArticleCategoryRecord {
                          ...LinkableArticleCategoryRecord
                        }
                        ... on BlogHomepageRecord {
                          ...LinkableBlogHomepageRecord
                        }
                        ... on NexudusLinkRecord {
                          ...LinkableNexudusLinkRecord
                        }
                        ... on WorkspaceRecord {
                          ...LinkableWorkspaceRecord
                        }
                      }
                      children {
                        __typename
                        id
                        _isValid
                        name
                        link {
                          __typename
                          ... on CustomPageRecord {
                            ...LinkableCustomPageRecord
                          }
                          ... on LocationSiteRecord {
                            ...LinkableLocationSiteRecord
                          }
                          ... on SolutionTypeRecord {
                            ...LinkableSolutionTypeRecord
                          }
                          ... on ArticleRecord {
                            ...LinkableArticleRecord
                          }
                          ... on ArticleCategoryRecord {
                            ...LinkableArticleCategoryRecord
                          }
                          ... on BlogHomepageRecord {
                            ...LinkableBlogHomepageRecord
                          }
                          ... on NexudusLinkRecord {
                            ...LinkableNexudusLinkRecord
                          }
                          ... on WorkspaceRecord {
                            ...LinkableWorkspaceRecord
                          }
                        }
                        children {
                          __typename
                          id
                          _isValid
                          name
                          link {
                            __typename
                            ... on CustomPageRecord {
                              ...LinkableCustomPageRecord
                            }
                            ... on LocationSiteRecord {
                              ...LinkableLocationSiteRecord
                            }
                            ... on SolutionTypeRecord {
                              ...LinkableSolutionTypeRecord
                            }
                            ... on ArticleRecord {
                              ...LinkableArticleRecord
                            }
                            ... on ArticleCategoryRecord {
                              ...LinkableArticleCategoryRecord
                            }
                            ... on BlogHomepageRecord {
                              ...LinkableBlogHomepageRecord
                            }
                            ... on NexudusLinkRecord {
                              ...LinkableNexudusLinkRecord
                            }
                            ... on WorkspaceRecord {
                              ...LinkableWorkspaceRecord
                            }
                          }
                          children {
                            __typename
                            id
                            _isValid
                            name
                            link {
                              __typename
                              ... on CustomPageRecord {
                                ...LinkableCustomPageRecord
                              }
                              ... on LocationSiteRecord {
                                ...LinkableLocationSiteRecord
                              }
                              ... on SolutionTypeRecord {
                                ...LinkableSolutionTypeRecord
                              }
                              ... on ArticleRecord {
                                ...LinkableArticleRecord
                              }
                              ... on ArticleCategoryRecord {
                                ...LinkableArticleCategoryRecord
                              }
                              ... on BlogHomepageRecord {
                                ...LinkableBlogHomepageRecord
                              }
                              ... on NexudusLinkRecord {
                                ...LinkableNexudusLinkRecord
                              }
                              ... on WorkspaceRecord {
                                ...LinkableWorkspaceRecord
                              }
                            }
                            children {
                              __typename
                              id
                              _isValid
                              name
                              link {
                                __typename
                                ... on CustomPageRecord {
                                  ...LinkableCustomPageRecord
                                }
                                ... on LocationSiteRecord {
                                  ...LinkableLocationSiteRecord
                                }
                                ... on SolutionTypeRecord {
                                  ...LinkableSolutionTypeRecord
                                }
                                ... on ArticleRecord {
                                  ...LinkableArticleRecord
                                }
                                ... on ArticleCategoryRecord {
                                  ...LinkableArticleCategoryRecord
                                }
                                ... on BlogHomepageRecord {
                                  ...LinkableBlogHomepageRecord
                                }
                                ... on NexudusLinkRecord {
                                  ...LinkableNexudusLinkRecord
                                }
                                ... on WorkspaceRecord {
                                  ...LinkableWorkspaceRecord
                                }
                              }
                              children {
                                __typename
                                id
                                _isValid
                                name
                                link {
                                  __typename
                                  ... on CustomPageRecord {
                                    ...LinkableCustomPageRecord
                                  }
                                  ... on LocationSiteRecord {
                                    ...LinkableLocationSiteRecord
                                  }
                                  ... on SolutionTypeRecord {
                                    ...LinkableSolutionTypeRecord
                                  }
                                  ... on ArticleRecord {
                                    ...LinkableArticleRecord
                                  }
                                  ... on ArticleCategoryRecord {
                                    ...LinkableArticleCategoryRecord
                                  }
                                  ... on BlogHomepageRecord {
                                    ...LinkableBlogHomepageRecord
                                  }
                                  ... on NexudusLinkRecord {
                                    ...LinkableNexudusLinkRecord
                                  }
                                  ... on WorkspaceRecord {
                                    ...LinkableWorkspaceRecord
                                  }
                                }
                                children {
                                  __typename
                                  id
                                  _isValid
                                  name
                                  link {
                                    __typename
                                    ... on CustomPageRecord {
                                      ...LinkableCustomPageRecord
                                    }
                                    ... on LocationSiteRecord {
                                      ...LinkableLocationSiteRecord
                                    }
                                    ... on SolutionTypeRecord {
                                      ...LinkableSolutionTypeRecord
                                    }
                                    ... on ArticleRecord {
                                      ...LinkableArticleRecord
                                    }
                                    ... on ArticleCategoryRecord {
                                      ...LinkableArticleCategoryRecord
                                    }
                                    ... on BlogHomepageRecord {
                                      ...LinkableBlogHomepageRecord
                                    }
                                    ... on NexudusLinkRecord {
                                      ...LinkableNexudusLinkRecord
                                    }
                                    ... on WorkspaceRecord {
                                      ...LinkableWorkspaceRecord
                                    }
                                  }
                                  children {
                                    __typename
                                    id
                                    _isValid
                                    name
                                    link {
                                      __typename
                                      ... on CustomPageRecord {
                                        ...LinkableCustomPageRecord
                                      }
                                      ... on LocationSiteRecord {
                                        ...LinkableLocationSiteRecord
                                      }
                                      ... on SolutionTypeRecord {
                                        ...LinkableSolutionTypeRecord
                                      }
                                      ... on ArticleRecord {
                                        ...LinkableArticleRecord
                                      }
                                      ... on ArticleCategoryRecord {
                                        ...LinkableArticleCategoryRecord
                                      }
                                      ... on BlogHomepageRecord {
                                        ...LinkableBlogHomepageRecord
                                      }
                                      ... on NexudusLinkRecord {
                                        ...LinkableNexudusLinkRecord
                                      }
                                      ... on WorkspaceRecord {
                                        ...LinkableWorkspaceRecord
                                      }
                                    }
                                    children {
                                      __typename
                                      id
                                      _isValid
                                      name
                                      link {
                                        __typename
                                        ... on CustomPageRecord {
                                          ...LinkableCustomPageRecord
                                        }
                                        ... on LocationSiteRecord {
                                          ...LinkableLocationSiteRecord
                                        }
                                        ... on SolutionTypeRecord {
                                          ...LinkableSolutionTypeRecord
                                        }
                                        ... on ArticleRecord {
                                          ...LinkableArticleRecord
                                        }
                                        ... on ArticleCategoryRecord {
                                          ...LinkableArticleCategoryRecord
                                        }
                                        ... on BlogHomepageRecord {
                                          ...LinkableBlogHomepageRecord
                                        }
                                        ... on NexudusLinkRecord {
                                          ...LinkableNexudusLinkRecord
                                        }
                                        ... on WorkspaceRecord {
                                          ...LinkableWorkspaceRecord
                                        }
                                      }
                                      children {
                                        __typename
                                        id
                                        _isValid
                                        name
                                        link {
                                          __typename
                                          ... on CustomPageRecord {
                                            ...LinkableCustomPageRecord
                                          }
                                          ... on LocationSiteRecord {
                                            ...LinkableLocationSiteRecord
                                          }
                                          ... on SolutionTypeRecord {
                                            ...LinkableSolutionTypeRecord
                                          }
                                          ... on ArticleRecord {
                                            ...LinkableArticleRecord
                                          }
                                          ... on ArticleCategoryRecord {
                                            ...LinkableArticleCategoryRecord
                                          }
                                          ... on BlogHomepageRecord {
                                            ...LinkableBlogHomepageRecord
                                          }
                                          ... on NexudusLinkRecord {
                                            ...LinkableNexudusLinkRecord
                                          }
                                          ... on WorkspaceRecord {
                                            ...LinkableWorkspaceRecord
                                          }
                                        }
                                        children {
                                          __typename
                                          id
                                          _isValid
                                          name
                                          link {
                                            __typename
                                            ... on CustomPageRecord {
                                              ...LinkableCustomPageRecord
                                            }
                                            ... on LocationSiteRecord {
                                              ...LinkableLocationSiteRecord
                                            }
                                            ... on SolutionTypeRecord {
                                              ...LinkableSolutionTypeRecord
                                            }
                                            ... on ArticleRecord {
                                              ...LinkableArticleRecord
                                            }
                                            ... on ArticleCategoryRecord {
                                              ...LinkableArticleCategoryRecord
                                            }
                                            ... on BlogHomepageRecord {
                                              ...LinkableBlogHomepageRecord
                                            }
                                            ... on NexudusLinkRecord {
                                              ...LinkableNexudusLinkRecord
                                            }
                                            ... on WorkspaceRecord {
                                              ...LinkableWorkspaceRecord
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  allFooterItems(filter: {parent: {eq: null}, _isValid: {eq: true}}) {
    __typename
    id
    _isValid
    name
    link {
      __typename
      ... on CustomPageRecord {
        ...LinkableCustomPageRecord
      }
      ... on LocationSiteRecord {
        ...LinkableLocationSiteRecord
      }
      ... on SolutionTypeRecord {
        ...LinkableSolutionTypeRecord
      }
      ... on ArticleRecord {
        ...LinkableArticleRecord
      }
      ... on ArticleCategoryRecord {
        ...LinkableArticleCategoryRecord
      }
      ... on BlogHomepageRecord {
        ...LinkableBlogHomepageRecord
      }
      ... on NexudusLinkRecord {
        ...LinkableNexudusLinkRecord
      }
      ... on WorkspaceRecord {
        ...LinkableWorkspaceRecord
      }
    }
    children {
      __typename
      id
      _isValid
      name
      link {
        __typename
        ... on CustomPageRecord {
          ...LinkableCustomPageRecord
        }
        ... on LocationSiteRecord {
          ...LinkableLocationSiteRecord
        }
        ... on SolutionTypeRecord {
          ...LinkableSolutionTypeRecord
        }
        ... on ArticleRecord {
          ...LinkableArticleRecord
        }
        ... on ArticleCategoryRecord {
          ...LinkableArticleCategoryRecord
        }
        ... on BlogHomepageRecord {
          ...LinkableBlogHomepageRecord
        }
        ... on NexudusLinkRecord {
          ...LinkableNexudusLinkRecord
        }
        ... on WorkspaceRecord {
          ...LinkableWorkspaceRecord
        }
      }
      children {
        __typename
        id
        _isValid
        name
        link {
          __typename
          ... on CustomPageRecord {
            ...LinkableCustomPageRecord
          }
          ... on LocationSiteRecord {
            ...LinkableLocationSiteRecord
          }
          ... on SolutionTypeRecord {
            ...LinkableSolutionTypeRecord
          }
          ... on ArticleRecord {
            ...LinkableArticleRecord
          }
          ... on ArticleCategoryRecord {
            ...LinkableArticleCategoryRecord
          }
          ... on BlogHomepageRecord {
            ...LinkableBlogHomepageRecord
          }
          ... on NexudusLinkRecord {
            ...LinkableNexudusLinkRecord
          }
          ... on WorkspaceRecord {
            ...LinkableWorkspaceRecord
          }
        }
        children {
          __typename
          id
          _isValid
          name
          link {
            __typename
            ... on CustomPageRecord {
              ...LinkableCustomPageRecord
            }
            ... on LocationSiteRecord {
              ...LinkableLocationSiteRecord
            }
            ... on SolutionTypeRecord {
              ...LinkableSolutionTypeRecord
            }
            ... on ArticleRecord {
              ...LinkableArticleRecord
            }
            ... on ArticleCategoryRecord {
              ...LinkableArticleCategoryRecord
            }
            ... on BlogHomepageRecord {
              ...LinkableBlogHomepageRecord
            }
            ... on NexudusLinkRecord {
              ...LinkableNexudusLinkRecord
            }
            ... on WorkspaceRecord {
              ...LinkableWorkspaceRecord
            }
          }
          children {
            __typename
            id
            _isValid
            name
            link {
              __typename
              ... on CustomPageRecord {
                ...LinkableCustomPageRecord
              }
              ... on LocationSiteRecord {
                ...LinkableLocationSiteRecord
              }
              ... on SolutionTypeRecord {
                ...LinkableSolutionTypeRecord
              }
              ... on ArticleRecord {
                ...LinkableArticleRecord
              }
              ... on ArticleCategoryRecord {
                ...LinkableArticleCategoryRecord
              }
              ... on BlogHomepageRecord {
                ...LinkableBlogHomepageRecord
              }
              ... on NexudusLinkRecord {
                ...LinkableNexudusLinkRecord
              }
              ... on WorkspaceRecord {
                ...LinkableWorkspaceRecord
              }
            }
            children {
              __typename
              id
              _isValid
              name
              link {
                __typename
                ... on CustomPageRecord {
                  ...LinkableCustomPageRecord
                }
                ... on LocationSiteRecord {
                  ...LinkableLocationSiteRecord
                }
                ... on SolutionTypeRecord {
                  ...LinkableSolutionTypeRecord
                }
                ... on ArticleRecord {
                  ...LinkableArticleRecord
                }
                ... on ArticleCategoryRecord {
                  ...LinkableArticleCategoryRecord
                }
                ... on BlogHomepageRecord {
                  ...LinkableBlogHomepageRecord
                }
                ... on NexudusLinkRecord {
                  ...LinkableNexudusLinkRecord
                }
                ... on WorkspaceRecord {
                  ...LinkableWorkspaceRecord
                }
              }
              children {
                __typename
                id
                _isValid
                name
                link {
                  __typename
                  ... on CustomPageRecord {
                    ...LinkableCustomPageRecord
                  }
                  ... on LocationSiteRecord {
                    ...LinkableLocationSiteRecord
                  }
                  ... on SolutionTypeRecord {
                    ...LinkableSolutionTypeRecord
                  }
                  ... on ArticleRecord {
                    ...LinkableArticleRecord
                  }
                  ... on ArticleCategoryRecord {
                    ...LinkableArticleCategoryRecord
                  }
                  ... on BlogHomepageRecord {
                    ...LinkableBlogHomepageRecord
                  }
                  ... on NexudusLinkRecord {
                    ...LinkableNexudusLinkRecord
                  }
                  ... on WorkspaceRecord {
                    ...LinkableWorkspaceRecord
                  }
                }
                children {
                  __typename
                  id
                  _isValid
                  name
                  link {
                    __typename
                    ... on CustomPageRecord {
                      ...LinkableCustomPageRecord
                    }
                    ... on LocationSiteRecord {
                      ...LinkableLocationSiteRecord
                    }
                    ... on SolutionTypeRecord {
                      ...LinkableSolutionTypeRecord
                    }
                    ... on ArticleRecord {
                      ...LinkableArticleRecord
                    }
                    ... on ArticleCategoryRecord {
                      ...LinkableArticleCategoryRecord
                    }
                    ... on BlogHomepageRecord {
                      ...LinkableBlogHomepageRecord
                    }
                    ... on NexudusLinkRecord {
                      ...LinkableNexudusLinkRecord
                    }
                    ... on WorkspaceRecord {
                      ...LinkableWorkspaceRecord
                    }
                  }
                  children {
                    __typename
                    id
                    _isValid
                    name
                    link {
                      __typename
                      ... on CustomPageRecord {
                        ...LinkableCustomPageRecord
                      }
                      ... on LocationSiteRecord {
                        ...LinkableLocationSiteRecord
                      }
                      ... on SolutionTypeRecord {
                        ...LinkableSolutionTypeRecord
                      }
                      ... on ArticleRecord {
                        ...LinkableArticleRecord
                      }
                      ... on ArticleCategoryRecord {
                        ...LinkableArticleCategoryRecord
                      }
                      ... on BlogHomepageRecord {
                        ...LinkableBlogHomepageRecord
                      }
                      ... on NexudusLinkRecord {
                        ...LinkableNexudusLinkRecord
                      }
                      ... on WorkspaceRecord {
                        ...LinkableWorkspaceRecord
                      }
                    }
                    children {
                      __typename
                      id
                      _isValid
                      name
                      link {
                        __typename
                        ... on CustomPageRecord {
                          ...LinkableCustomPageRecord
                        }
                        ... on LocationSiteRecord {
                          ...LinkableLocationSiteRecord
                        }
                        ... on SolutionTypeRecord {
                          ...LinkableSolutionTypeRecord
                        }
                        ... on ArticleRecord {
                          ...LinkableArticleRecord
                        }
                        ... on ArticleCategoryRecord {
                          ...LinkableArticleCategoryRecord
                        }
                        ... on BlogHomepageRecord {
                          ...LinkableBlogHomepageRecord
                        }
                        ... on NexudusLinkRecord {
                          ...LinkableNexudusLinkRecord
                        }
                        ... on WorkspaceRecord {
                          ...LinkableWorkspaceRecord
                        }
                      }
                      children {
                        __typename
                        id
                        _isValid
                        name
                        link {
                          __typename
                          ... on CustomPageRecord {
                            ...LinkableCustomPageRecord
                          }
                          ... on LocationSiteRecord {
                            ...LinkableLocationSiteRecord
                          }
                          ... on SolutionTypeRecord {
                            ...LinkableSolutionTypeRecord
                          }
                          ... on ArticleRecord {
                            ...LinkableArticleRecord
                          }
                          ... on ArticleCategoryRecord {
                            ...LinkableArticleCategoryRecord
                          }
                          ... on BlogHomepageRecord {
                            ...LinkableBlogHomepageRecord
                          }
                          ... on NexudusLinkRecord {
                            ...LinkableNexudusLinkRecord
                          }
                          ... on WorkspaceRecord {
                            ...LinkableWorkspaceRecord
                          }
                        }
                        children {
                          __typename
                          id
                          _isValid
                          name
                          link {
                            __typename
                            ... on CustomPageRecord {
                              ...LinkableCustomPageRecord
                            }
                            ... on LocationSiteRecord {
                              ...LinkableLocationSiteRecord
                            }
                            ... on SolutionTypeRecord {
                              ...LinkableSolutionTypeRecord
                            }
                            ... on ArticleRecord {
                              ...LinkableArticleRecord
                            }
                            ... on ArticleCategoryRecord {
                              ...LinkableArticleCategoryRecord
                            }
                            ... on BlogHomepageRecord {
                              ...LinkableBlogHomepageRecord
                            }
                            ... on NexudusLinkRecord {
                              ...LinkableNexudusLinkRecord
                            }
                            ... on WorkspaceRecord {
                              ...LinkableWorkspaceRecord
                            }
                          }
                          children {
                            __typename
                            id
                            _isValid
                            name
                            link {
                              __typename
                              ... on CustomPageRecord {
                                ...LinkableCustomPageRecord
                              }
                              ... on LocationSiteRecord {
                                ...LinkableLocationSiteRecord
                              }
                              ... on SolutionTypeRecord {
                                ...LinkableSolutionTypeRecord
                              }
                              ... on ArticleRecord {
                                ...LinkableArticleRecord
                              }
                              ... on ArticleCategoryRecord {
                                ...LinkableArticleCategoryRecord
                              }
                              ... on BlogHomepageRecord {
                                ...LinkableBlogHomepageRecord
                              }
                              ... on NexudusLinkRecord {
                                ...LinkableNexudusLinkRecord
                              }
                              ... on WorkspaceRecord {
                                ...LinkableWorkspaceRecord
                              }
                            }
                            children {
                              __typename
                              id
                              _isValid
                              name
                              link {
                                __typename
                                ... on CustomPageRecord {
                                  ...LinkableCustomPageRecord
                                }
                                ... on LocationSiteRecord {
                                  ...LinkableLocationSiteRecord
                                }
                                ... on SolutionTypeRecord {
                                  ...LinkableSolutionTypeRecord
                                }
                                ... on ArticleRecord {
                                  ...LinkableArticleRecord
                                }
                                ... on ArticleCategoryRecord {
                                  ...LinkableArticleCategoryRecord
                                }
                                ... on BlogHomepageRecord {
                                  ...LinkableBlogHomepageRecord
                                }
                                ... on NexudusLinkRecord {
                                  ...LinkableNexudusLinkRecord
                                }
                                ... on WorkspaceRecord {
                                  ...LinkableWorkspaceRecord
                                }
                              }
                              children {
                                __typename
                                id
                                _isValid
                                name
                                link {
                                  __typename
                                  ... on CustomPageRecord {
                                    ...LinkableCustomPageRecord
                                  }
                                  ... on LocationSiteRecord {
                                    ...LinkableLocationSiteRecord
                                  }
                                  ... on SolutionTypeRecord {
                                    ...LinkableSolutionTypeRecord
                                  }
                                  ... on ArticleRecord {
                                    ...LinkableArticleRecord
                                  }
                                  ... on ArticleCategoryRecord {
                                    ...LinkableArticleCategoryRecord
                                  }
                                  ... on BlogHomepageRecord {
                                    ...LinkableBlogHomepageRecord
                                  }
                                  ... on NexudusLinkRecord {
                                    ...LinkableNexudusLinkRecord
                                  }
                                  ... on WorkspaceRecord {
                                    ...LinkableWorkspaceRecord
                                  }
                                }
                                children {
                                  __typename
                                  id
                                  _isValid
                                  name
                                  link {
                                    __typename
                                    ... on CustomPageRecord {
                                      ...LinkableCustomPageRecord
                                    }
                                    ... on LocationSiteRecord {
                                      ...LinkableLocationSiteRecord
                                    }
                                    ... on SolutionTypeRecord {
                                      ...LinkableSolutionTypeRecord
                                    }
                                    ... on ArticleRecord {
                                      ...LinkableArticleRecord
                                    }
                                    ... on ArticleCategoryRecord {
                                      ...LinkableArticleCategoryRecord
                                    }
                                    ... on BlogHomepageRecord {
                                      ...LinkableBlogHomepageRecord
                                    }
                                    ... on NexudusLinkRecord {
                                      ...LinkableNexudusLinkRecord
                                    }
                                    ... on WorkspaceRecord {
                                      ...LinkableWorkspaceRecord
                                    }
                                  }
                                  children {
                                    __typename
                                    id
                                    _isValid
                                    name
                                    link {
                                      __typename
                                      ... on CustomPageRecord {
                                        ...LinkableCustomPageRecord
                                      }
                                      ... on LocationSiteRecord {
                                        ...LinkableLocationSiteRecord
                                      }
                                      ... on SolutionTypeRecord {
                                        ...LinkableSolutionTypeRecord
                                      }
                                      ... on ArticleRecord {
                                        ...LinkableArticleRecord
                                      }
                                      ... on ArticleCategoryRecord {
                                        ...LinkableArticleCategoryRecord
                                      }
                                      ... on BlogHomepageRecord {
                                        ...LinkableBlogHomepageRecord
                                      }
                                      ... on NexudusLinkRecord {
                                        ...LinkableNexudusLinkRecord
                                      }
                                      ... on WorkspaceRecord {
                                        ...LinkableWorkspaceRecord
                                      }
                                    }
                                    children {
                                      __typename
                                      id
                                      _isValid
                                      name
                                      link {
                                        __typename
                                        ... on CustomPageRecord {
                                          ...LinkableCustomPageRecord
                                        }
                                        ... on LocationSiteRecord {
                                          ...LinkableLocationSiteRecord
                                        }
                                        ... on SolutionTypeRecord {
                                          ...LinkableSolutionTypeRecord
                                        }
                                        ... on ArticleRecord {
                                          ...LinkableArticleRecord
                                        }
                                        ... on ArticleCategoryRecord {
                                          ...LinkableArticleCategoryRecord
                                        }
                                        ... on BlogHomepageRecord {
                                          ...LinkableBlogHomepageRecord
                                        }
                                        ... on NexudusLinkRecord {
                                          ...LinkableNexudusLinkRecord
                                        }
                                        ... on WorkspaceRecord {
                                          ...LinkableWorkspaceRecord
                                        }
                                      }
                                      children {
                                        __typename
                                        id
                                        _isValid
                                        name
                                        link {
                                          __typename
                                          ... on CustomPageRecord {
                                            ...LinkableCustomPageRecord
                                          }
                                          ... on LocationSiteRecord {
                                            ...LinkableLocationSiteRecord
                                          }
                                          ... on SolutionTypeRecord {
                                            ...LinkableSolutionTypeRecord
                                          }
                                          ... on ArticleRecord {
                                            ...LinkableArticleRecord
                                          }
                                          ... on ArticleCategoryRecord {
                                            ...LinkableArticleCategoryRecord
                                          }
                                          ... on BlogHomepageRecord {
                                            ...LinkableBlogHomepageRecord
                                          }
                                          ... on NexudusLinkRecord {
                                            ...LinkableNexudusLinkRecord
                                          }
                                          ... on WorkspaceRecord {
                                            ...LinkableWorkspaceRecord
                                          }
                                        }
                                        children {
                                          __typename
                                          id
                                          _isValid
                                          name
                                          link {
                                            __typename
                                            ... on CustomPageRecord {
                                              ...LinkableCustomPageRecord
                                            }
                                            ... on LocationSiteRecord {
                                              ...LinkableLocationSiteRecord
                                            }
                                            ... on SolutionTypeRecord {
                                              ...LinkableSolutionTypeRecord
                                            }
                                            ... on ArticleRecord {
                                              ...LinkableArticleRecord
                                            }
                                            ... on ArticleCategoryRecord {
                                              ...LinkableArticleCategoryRecord
                                            }
                                            ... on BlogHomepageRecord {
                                              ...LinkableBlogHomepageRecord
                                            }
                                            ... on NexudusLinkRecord {
                                              ...LinkableNexudusLinkRecord
                                            }
                                            ... on WorkspaceRecord {
                                              ...LinkableWorkspaceRecord
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${LinkableLinkRecordFragmentDoc}
${LinkableCustomPageRecordFragmentDoc}
${LinkableLocationSiteRecordFragmentDoc}
${LinkableSolutionTypeRecordFragmentDoc}
${LinkableArticleRecordFragmentDoc}
${LinkableArticleCategoryRecordFragmentDoc}
${LinkableBlogHomepageRecordFragmentDoc}
${LinkableNexudusLinkRecordFragmentDoc}
${LinkableWorkspaceRecordFragmentDoc}`;

/**
 * __useNavbarQuery__
 *
 * To run a query within a React component, call `useNavbarQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavbarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavbarQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavbarQuery(baseOptions?: Apollo.QueryHookOptions<NavbarQuery, NavbarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NavbarQuery, NavbarQueryVariables>(NavbarDocument, options);
      }
export function useNavbarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NavbarQuery, NavbarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NavbarQuery, NavbarQueryVariables>(NavbarDocument, options);
        }
export type NavbarQueryHookResult = ReturnType<typeof useNavbarQuery>;
export type NavbarLazyQueryHookResult = ReturnType<typeof useNavbarLazyQuery>;
export type NavbarQueryResult = Apollo.QueryResult<NavbarQuery, NavbarQueryVariables>;
export const DatoPagePathsDocument = gql`
    query datoPagePaths {
  allCustomPages(
    filter: {urlPath: {notIn: ["", "/", "/404"]}, _isValid: {eq: true}}
  ) {
    urlPath
  }
  allLocationSites(filter: {urlPath: {notIn: ["", "/"]}, _isValid: {eq: true}}) {
    urlPath
  }
  allSolutionTypes(filter: {urlPath: {notIn: ["", "/"]}, _isValid: {eq: true}}) {
    urlPath
  }
  allArticles(filter: {pagePath: {notIn: ["", "/"]}, _isValid: {eq: true}}) {
    pagePath
  }
  allArticleCategories(
    filter: {pagePath: {notIn: ["", "/"]}, _isValid: {eq: true}}
  ) {
    pagePath
  }
  allWorkspaces(filter: {urlPath: {notIn: ["", "/"]}, _isValid: {eq: true}}) {
    urlPath
  }
  blogHomepage {
    pagePath
  }
}
    `;

/**
 * __useDatoPagePathsQuery__
 *
 * To run a query within a React component, call `useDatoPagePathsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatoPagePathsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatoPagePathsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDatoPagePathsQuery(baseOptions?: Apollo.QueryHookOptions<DatoPagePathsQuery, DatoPagePathsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatoPagePathsQuery, DatoPagePathsQueryVariables>(DatoPagePathsDocument, options);
      }
export function useDatoPagePathsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatoPagePathsQuery, DatoPagePathsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatoPagePathsQuery, DatoPagePathsQueryVariables>(DatoPagePathsDocument, options);
        }
export type DatoPagePathsQueryHookResult = ReturnType<typeof useDatoPagePathsQuery>;
export type DatoPagePathsLazyQueryHookResult = ReturnType<typeof useDatoPagePathsLazyQuery>;
export type DatoPagePathsQueryResult = Apollo.QueryResult<DatoPagePathsQuery, DatoPagePathsQueryVariables>;
export const DatoSiteMapFileUrlDocument = gql`
    query datoSiteMapFileUrl {
  xmlSitemap {
    xmlFile {
      url
    }
  }
}
    `;

/**
 * __useDatoSiteMapFileUrlQuery__
 *
 * To run a query within a React component, call `useDatoSiteMapFileUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatoSiteMapFileUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatoSiteMapFileUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useDatoSiteMapFileUrlQuery(baseOptions?: Apollo.QueryHookOptions<DatoSiteMapFileUrlQuery, DatoSiteMapFileUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatoSiteMapFileUrlQuery, DatoSiteMapFileUrlQueryVariables>(DatoSiteMapFileUrlDocument, options);
      }
export function useDatoSiteMapFileUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatoSiteMapFileUrlQuery, DatoSiteMapFileUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatoSiteMapFileUrlQuery, DatoSiteMapFileUrlQueryVariables>(DatoSiteMapFileUrlDocument, options);
        }
export type DatoSiteMapFileUrlQueryHookResult = ReturnType<typeof useDatoSiteMapFileUrlQuery>;
export type DatoSiteMapFileUrlLazyQueryHookResult = ReturnType<typeof useDatoSiteMapFileUrlLazyQuery>;
export type DatoSiteMapFileUrlQueryResult = Apollo.QueryResult<DatoSiteMapFileUrlQuery, DatoSiteMapFileUrlQueryVariables>;
export const DatoRobotsTxtFileUrlDocument = gql`
    query datoRobotsTxtFileUrl {
  robotsTxt {
    robotsTxtFile {
      url
    }
  }
}
    `;

/**
 * __useDatoRobotsTxtFileUrlQuery__
 *
 * To run a query within a React component, call `useDatoRobotsTxtFileUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatoRobotsTxtFileUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatoRobotsTxtFileUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useDatoRobotsTxtFileUrlQuery(baseOptions?: Apollo.QueryHookOptions<DatoRobotsTxtFileUrlQuery, DatoRobotsTxtFileUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatoRobotsTxtFileUrlQuery, DatoRobotsTxtFileUrlQueryVariables>(DatoRobotsTxtFileUrlDocument, options);
      }
export function useDatoRobotsTxtFileUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatoRobotsTxtFileUrlQuery, DatoRobotsTxtFileUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatoRobotsTxtFileUrlQuery, DatoRobotsTxtFileUrlQueryVariables>(DatoRobotsTxtFileUrlDocument, options);
        }
export type DatoRobotsTxtFileUrlQueryHookResult = ReturnType<typeof useDatoRobotsTxtFileUrlQuery>;
export type DatoRobotsTxtFileUrlLazyQueryHookResult = ReturnType<typeof useDatoRobotsTxtFileUrlLazyQuery>;
export type DatoRobotsTxtFileUrlQueryResult = Apollo.QueryResult<DatoRobotsTxtFileUrlQuery, DatoRobotsTxtFileUrlQueryVariables>;
export const DatoCustomPageDocument = gql`
    query datoCustomPage($path: String!) {
  customPage(filter: {urlPath: {eq: $path}, _isValid: {eq: true}}) {
    pageName
    urlPath
    showBreadcrumb
    ...CustomPageRecordBreadcrumb
    _seoMetaTags {
      attributes
      content
      tag
    }
    metaTags {
      ...MetaTags
    }
    pageContent {
      injectScript
      ...PageContent
    }
    seoStructuredData
  }
}
    ${CustomPageRecordBreadcrumbFragmentDoc}
${MetaTagsFragmentDoc}
${PageContentFragmentDoc}`;

/**
 * __useDatoCustomPageQuery__
 *
 * To run a query within a React component, call `useDatoCustomPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatoCustomPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatoCustomPageQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useDatoCustomPageQuery(baseOptions: Apollo.QueryHookOptions<DatoCustomPageQuery, DatoCustomPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatoCustomPageQuery, DatoCustomPageQueryVariables>(DatoCustomPageDocument, options);
      }
export function useDatoCustomPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatoCustomPageQuery, DatoCustomPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatoCustomPageQuery, DatoCustomPageQueryVariables>(DatoCustomPageDocument, options);
        }
export type DatoCustomPageQueryHookResult = ReturnType<typeof useDatoCustomPageQuery>;
export type DatoCustomPageLazyQueryHookResult = ReturnType<typeof useDatoCustomPageLazyQuery>;
export type DatoCustomPageQueryResult = Apollo.QueryResult<DatoCustomPageQuery, DatoCustomPageQueryVariables>;
export const DatoLocationSitePageDocument = gql`
    query datoLocationSitePage($path: String!) {
  locationSite(filter: {urlPath: {eq: $path}, _isValid: {eq: true}}) {
    ...LinkableLocationSiteRecord
    textBackgroundImage {
      url
      responsiveImage(imgixParams: {fit: clip, w: 500, auto: format, q: 10}) {
        ...ResponsiveImageFields
      }
    }
    ...LocationSiteRecordBreadcrumb
    showBreadcrumb
    siteName
    address
    phoneNumber
    addressLocation
    emailAddress
    assets {
      ... on ImageRecord {
        image {
          url
          responsiveImage(imgixParams: {fit: max, w: 1092, auto: format, q: 50}) {
            ...ResponsiveImageFields
          }
        }
      }
      ... on VimeoVideoRecord {
        vimeoUrl
      }
    }
    floatingCta {
      price
      pricePrefix
      links {
        ...LinkableLinkRecord
      }
      heading
      body {
        value
        links {
          ...LinkableLinkRecord
        }
      }
    }
    _seoMetaTags {
      attributes
      content
      tag
    }
    metaTags {
      ...MetaTags
    }
    reusableComponents {
      ...PageContent
    }
    seoStructuredData
  }
}
    ${LinkableLocationSiteRecordFragmentDoc}
${ResponsiveImageFieldsFragmentDoc}
${LocationSiteRecordBreadcrumbFragmentDoc}
${LinkableLinkRecordFragmentDoc}
${MetaTagsFragmentDoc}
${PageContentFragmentDoc}`;

/**
 * __useDatoLocationSitePageQuery__
 *
 * To run a query within a React component, call `useDatoLocationSitePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatoLocationSitePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatoLocationSitePageQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useDatoLocationSitePageQuery(baseOptions: Apollo.QueryHookOptions<DatoLocationSitePageQuery, DatoLocationSitePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatoLocationSitePageQuery, DatoLocationSitePageQueryVariables>(DatoLocationSitePageDocument, options);
      }
export function useDatoLocationSitePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatoLocationSitePageQuery, DatoLocationSitePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatoLocationSitePageQuery, DatoLocationSitePageQueryVariables>(DatoLocationSitePageDocument, options);
        }
export type DatoLocationSitePageQueryHookResult = ReturnType<typeof useDatoLocationSitePageQuery>;
export type DatoLocationSitePageLazyQueryHookResult = ReturnType<typeof useDatoLocationSitePageLazyQuery>;
export type DatoLocationSitePageQueryResult = Apollo.QueryResult<DatoLocationSitePageQuery, DatoLocationSitePageQueryVariables>;
export const DatoSolutionTypePageDocument = gql`
    query datoSolutionTypePage($path: String!) {
  solutionType(filter: {urlPath: {eq: $path}, _isValid: {eq: true}}) {
    ...LinkableSolutionTypeRecord
    ...SolutionTypeRecordBreadcrumb
    showBreadcrumb
    solutionType
    assets {
      ... on ImageRecord {
        image {
          url
          responsiveImage(imgixParams: {fit: max, w: 1092, auto: format, q: 50}) {
            ...ResponsiveImageFields
          }
        }
      }
      ... on VimeoVideoRecord {
        vimeoUrl
      }
    }
    floatingCta {
      price
      pricePrefix
      links {
        ...LinkableLinkRecord
      }
      heading
      body {
        value
        links {
          ...LinkableLinkRecord
        }
      }
    }
    _seoMetaTags {
      attributes
      content
      tag
    }
    metaTags {
      ...MetaTags
    }
    reusableComponents {
      ...PageContent
    }
    seoStructuredData
  }
}
    ${LinkableSolutionTypeRecordFragmentDoc}
${SolutionTypeRecordBreadcrumbFragmentDoc}
${ResponsiveImageFieldsFragmentDoc}
${LinkableLinkRecordFragmentDoc}
${MetaTagsFragmentDoc}
${PageContentFragmentDoc}`;

/**
 * __useDatoSolutionTypePageQuery__
 *
 * To run a query within a React component, call `useDatoSolutionTypePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatoSolutionTypePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatoSolutionTypePageQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useDatoSolutionTypePageQuery(baseOptions: Apollo.QueryHookOptions<DatoSolutionTypePageQuery, DatoSolutionTypePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatoSolutionTypePageQuery, DatoSolutionTypePageQueryVariables>(DatoSolutionTypePageDocument, options);
      }
export function useDatoSolutionTypePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatoSolutionTypePageQuery, DatoSolutionTypePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatoSolutionTypePageQuery, DatoSolutionTypePageQueryVariables>(DatoSolutionTypePageDocument, options);
        }
export type DatoSolutionTypePageQueryHookResult = ReturnType<typeof useDatoSolutionTypePageQuery>;
export type DatoSolutionTypePageLazyQueryHookResult = ReturnType<typeof useDatoSolutionTypePageLazyQuery>;
export type DatoSolutionTypePageQueryResult = Apollo.QueryResult<DatoSolutionTypePageQuery, DatoSolutionTypePageQueryVariables>;
export const DatoWorkspacePageDocument = gql`
    query datoWorkspacePage($path: String!) {
  workspace(filter: {urlPath: {eq: $path}, _isValid: {eq: true}}) {
    ...LinkableWorkspaceRecord
    ...WorkspaceRecordBreadcrumb
    showBreadcrumb
    name
    site {
      siteName
      address
      phoneNumber
      addressLocation
      emailAddress
    }
    floatingCta {
      price
      pricePrefix
      links {
        ...LinkableLinkRecord
      }
      heading
      body {
        value
        links {
          ...LinkableLinkRecord
        }
      }
    }
    assets {
      ... on ImageRecord {
        image {
          url
          responsiveImage(imgixParams: {fit: max, w: 1092, auto: format, q: 50}) {
            ...ResponsiveImageFields
          }
        }
      }
      ... on VimeoVideoRecord {
        vimeoUrl
      }
    }
    _seoMetaTags {
      attributes
      content
      tag
    }
    metaTags {
      ...MetaTags
    }
    reusableComponents {
      ...PageContent
    }
    seoStructuredData
  }
}
    ${LinkableWorkspaceRecordFragmentDoc}
${WorkspaceRecordBreadcrumbFragmentDoc}
${LinkableLinkRecordFragmentDoc}
${ResponsiveImageFieldsFragmentDoc}
${MetaTagsFragmentDoc}
${PageContentFragmentDoc}`;

/**
 * __useDatoWorkspacePageQuery__
 *
 * To run a query within a React component, call `useDatoWorkspacePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatoWorkspacePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatoWorkspacePageQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useDatoWorkspacePageQuery(baseOptions: Apollo.QueryHookOptions<DatoWorkspacePageQuery, DatoWorkspacePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatoWorkspacePageQuery, DatoWorkspacePageQueryVariables>(DatoWorkspacePageDocument, options);
      }
export function useDatoWorkspacePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatoWorkspacePageQuery, DatoWorkspacePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatoWorkspacePageQuery, DatoWorkspacePageQueryVariables>(DatoWorkspacePageDocument, options);
        }
export type DatoWorkspacePageQueryHookResult = ReturnType<typeof useDatoWorkspacePageQuery>;
export type DatoWorkspacePageLazyQueryHookResult = ReturnType<typeof useDatoWorkspacePageLazyQuery>;
export type DatoWorkspacePageQueryResult = Apollo.QueryResult<DatoWorkspacePageQuery, DatoWorkspacePageQueryVariables>;
export const DatoArticlePageDocument = gql`
    query datoArticlePage($path: String!) {
  article(filter: {pagePath: {eq: $path}, _isValid: {eq: true}}) {
    ...ArticleRecordBreadcrumb
    title
    date
    pagePath
    author
    showBreadcrumb
    image {
      url
      responsiveImage(imgixParams: {fit: max, w: 868, auto: format, q: 50}) {
        ...ResponsiveImageFields
      }
    }
    categories {
      ...LinkableArticleCategoryRecord
    }
    _seoMetaTags {
      attributes
      content
      tag
    }
    metaTags {
      ...MetaTags
    }
    cardExcerpt
    _createdAt
    content {
      value
      blocks {
        ... on ECtaBlockRecord {
          ...ECtaBlock
        }
        ... on ImageBlockRecord {
          ...ImageBlock
        }
        ... on NewsletterSignupBlockRecord {
          ...NewsletterSignupBlock
        }
        ... on QuoteBlockRecord {
          ...QuoteBlock
        }
        ... on CustomHtmlBlockRecord {
          ...CustomHtmlBlock
        }
      }
      links {
        ...LinkableLinkRecord
      }
    }
    seoStructuredData
  }
}
    ${ArticleRecordBreadcrumbFragmentDoc}
${ResponsiveImageFieldsFragmentDoc}
${LinkableArticleCategoryRecordFragmentDoc}
${MetaTagsFragmentDoc}
${ECtaBlockFragmentDoc}
${ImageBlockFragmentDoc}
${NewsletterSignupBlockFragmentDoc}
${QuoteBlockFragmentDoc}
${CustomHtmlBlockFragmentDoc}
${LinkableLinkRecordFragmentDoc}`;

/**
 * __useDatoArticlePageQuery__
 *
 * To run a query within a React component, call `useDatoArticlePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatoArticlePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatoArticlePageQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useDatoArticlePageQuery(baseOptions: Apollo.QueryHookOptions<DatoArticlePageQuery, DatoArticlePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatoArticlePageQuery, DatoArticlePageQueryVariables>(DatoArticlePageDocument, options);
      }
export function useDatoArticlePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatoArticlePageQuery, DatoArticlePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatoArticlePageQuery, DatoArticlePageQueryVariables>(DatoArticlePageDocument, options);
        }
export type DatoArticlePageQueryHookResult = ReturnType<typeof useDatoArticlePageQuery>;
export type DatoArticlePageLazyQueryHookResult = ReturnType<typeof useDatoArticlePageLazyQuery>;
export type DatoArticlePageQueryResult = Apollo.QueryResult<DatoArticlePageQuery, DatoArticlePageQueryVariables>;
export const DatoArticleCategoryPageDocument = gql`
    query datoArticleCategoryPage($path: String!) {
  articleCategory(filter: {pagePath: {eq: $path}, _isValid: {eq: true}}) {
    ...ArticleCategoryRecordBreadcrumb
    id
    categoryName
    categoryColour {
      ...Color
    }
    pagePath
    showBreadcrumb
    _seoMetaTags {
      attributes
      content
      tag
    }
    metaTags {
      ...MetaTags
    }
    seoStructuredData
  }
}
    ${ArticleCategoryRecordBreadcrumbFragmentDoc}
${ColorFragmentDoc}
${MetaTagsFragmentDoc}`;

/**
 * __useDatoArticleCategoryPageQuery__
 *
 * To run a query within a React component, call `useDatoArticleCategoryPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatoArticleCategoryPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatoArticleCategoryPageQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useDatoArticleCategoryPageQuery(baseOptions: Apollo.QueryHookOptions<DatoArticleCategoryPageQuery, DatoArticleCategoryPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatoArticleCategoryPageQuery, DatoArticleCategoryPageQueryVariables>(DatoArticleCategoryPageDocument, options);
      }
export function useDatoArticleCategoryPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatoArticleCategoryPageQuery, DatoArticleCategoryPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatoArticleCategoryPageQuery, DatoArticleCategoryPageQueryVariables>(DatoArticleCategoryPageDocument, options);
        }
export type DatoArticleCategoryPageQueryHookResult = ReturnType<typeof useDatoArticleCategoryPageQuery>;
export type DatoArticleCategoryPageLazyQueryHookResult = ReturnType<typeof useDatoArticleCategoryPageLazyQuery>;
export type DatoArticleCategoryPageQueryResult = Apollo.QueryResult<DatoArticleCategoryPageQuery, DatoArticleCategoryPageQueryVariables>;
export const DatoArticleCategoriesDocument = gql`
    query datoArticleCategories {
  allArticleCategories(
    orderBy: position_ASC
    filter: {hideOnBlogHomepage: {eq: false}}
  ) {
    pagePath
    categoryName
    categoryColour {
      ...Color
    }
  }
}
    ${ColorFragmentDoc}`;

/**
 * __useDatoArticleCategoriesQuery__
 *
 * To run a query within a React component, call `useDatoArticleCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatoArticleCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatoArticleCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDatoArticleCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<DatoArticleCategoriesQuery, DatoArticleCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatoArticleCategoriesQuery, DatoArticleCategoriesQueryVariables>(DatoArticleCategoriesDocument, options);
      }
export function useDatoArticleCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatoArticleCategoriesQuery, DatoArticleCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatoArticleCategoriesQuery, DatoArticleCategoriesQueryVariables>(DatoArticleCategoriesDocument, options);
        }
export type DatoArticleCategoriesQueryHookResult = ReturnType<typeof useDatoArticleCategoriesQuery>;
export type DatoArticleCategoriesLazyQueryHookResult = ReturnType<typeof useDatoArticleCategoriesLazyQuery>;
export type DatoArticleCategoriesQueryResult = Apollo.QueryResult<DatoArticleCategoriesQuery, DatoArticleCategoriesQueryVariables>;
export const DatoArticlesInCategoryDocument = gql`
    query datoArticlesInCategory($categoryId: ItemId!, $excludingIds: [ItemId!]!) {
  allArticles(
    filter: {categories: {anyIn: [$categoryId]}, id: {notIn: $excludingIds}, _isValid: {eq: true}}
    orderBy: updatedAt_DESC
    first: 12
  ) {
    ...ArticleCard
  }
}
    ${ArticleCardFragmentDoc}`;

/**
 * __useDatoArticlesInCategoryQuery__
 *
 * To run a query within a React component, call `useDatoArticlesInCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatoArticlesInCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatoArticlesInCategoryQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      excludingIds: // value for 'excludingIds'
 *   },
 * });
 */
export function useDatoArticlesInCategoryQuery(baseOptions: Apollo.QueryHookOptions<DatoArticlesInCategoryQuery, DatoArticlesInCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatoArticlesInCategoryQuery, DatoArticlesInCategoryQueryVariables>(DatoArticlesInCategoryDocument, options);
      }
export function useDatoArticlesInCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatoArticlesInCategoryQuery, DatoArticlesInCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatoArticlesInCategoryQuery, DatoArticlesInCategoryQueryVariables>(DatoArticlesInCategoryDocument, options);
        }
export type DatoArticlesInCategoryQueryHookResult = ReturnType<typeof useDatoArticlesInCategoryQuery>;
export type DatoArticlesInCategoryLazyQueryHookResult = ReturnType<typeof useDatoArticlesInCategoryLazyQuery>;
export type DatoArticlesInCategoryQueryResult = Apollo.QueryResult<DatoArticlesInCategoryQuery, DatoArticlesInCategoryQueryVariables>;
export const DatoArticleCountInCategoryDocument = gql`
    query datoArticleCountInCategory($categoryId: ItemId!) {
  _allArticlesMeta(
    filter: {categories: {anyIn: [$categoryId]}, _isValid: {eq: true}}
  ) {
    count
  }
}
    `;

/**
 * __useDatoArticleCountInCategoryQuery__
 *
 * To run a query within a React component, call `useDatoArticleCountInCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatoArticleCountInCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatoArticleCountInCategoryQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useDatoArticleCountInCategoryQuery(baseOptions: Apollo.QueryHookOptions<DatoArticleCountInCategoryQuery, DatoArticleCountInCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatoArticleCountInCategoryQuery, DatoArticleCountInCategoryQueryVariables>(DatoArticleCountInCategoryDocument, options);
      }
export function useDatoArticleCountInCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatoArticleCountInCategoryQuery, DatoArticleCountInCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatoArticleCountInCategoryQuery, DatoArticleCountInCategoryQueryVariables>(DatoArticleCountInCategoryDocument, options);
        }
export type DatoArticleCountInCategoryQueryHookResult = ReturnType<typeof useDatoArticleCountInCategoryQuery>;
export type DatoArticleCountInCategoryLazyQueryHookResult = ReturnType<typeof useDatoArticleCountInCategoryLazyQuery>;
export type DatoArticleCountInCategoryQueryResult = Apollo.QueryResult<DatoArticleCountInCategoryQuery, DatoArticleCountInCategoryQueryVariables>;
export const DatoArticlesInBlogHomePageDocument = gql`
    query datoArticlesInBlogHomePage($excludingIds: [ItemId!]!) {
  allArticles(
    filter: {hideOnBlogHomepage: {eq: false}, id: {notIn: $excludingIds}, _isValid: {eq: true}}
    orderBy: updatedAt_DESC
    first: 12
  ) {
    ...ArticleCard
  }
}
    ${ArticleCardFragmentDoc}`;

/**
 * __useDatoArticlesInBlogHomePageQuery__
 *
 * To run a query within a React component, call `useDatoArticlesInBlogHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatoArticlesInBlogHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatoArticlesInBlogHomePageQuery({
 *   variables: {
 *      excludingIds: // value for 'excludingIds'
 *   },
 * });
 */
export function useDatoArticlesInBlogHomePageQuery(baseOptions: Apollo.QueryHookOptions<DatoArticlesInBlogHomePageQuery, DatoArticlesInBlogHomePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatoArticlesInBlogHomePageQuery, DatoArticlesInBlogHomePageQueryVariables>(DatoArticlesInBlogHomePageDocument, options);
      }
export function useDatoArticlesInBlogHomePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatoArticlesInBlogHomePageQuery, DatoArticlesInBlogHomePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatoArticlesInBlogHomePageQuery, DatoArticlesInBlogHomePageQueryVariables>(DatoArticlesInBlogHomePageDocument, options);
        }
export type DatoArticlesInBlogHomePageQueryHookResult = ReturnType<typeof useDatoArticlesInBlogHomePageQuery>;
export type DatoArticlesInBlogHomePageLazyQueryHookResult = ReturnType<typeof useDatoArticlesInBlogHomePageLazyQuery>;
export type DatoArticlesInBlogHomePageQueryResult = Apollo.QueryResult<DatoArticlesInBlogHomePageQuery, DatoArticlesInBlogHomePageQueryVariables>;
export const DatoArticleCountInBlogHomePageDocument = gql`
    query datoArticleCountInBlogHomePage {
  _allArticlesMeta(
    filter: {hideOnBlogHomepage: {eq: false}, _isValid: {eq: true}}
  ) {
    count
  }
}
    `;

/**
 * __useDatoArticleCountInBlogHomePageQuery__
 *
 * To run a query within a React component, call `useDatoArticleCountInBlogHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatoArticleCountInBlogHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatoArticleCountInBlogHomePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useDatoArticleCountInBlogHomePageQuery(baseOptions?: Apollo.QueryHookOptions<DatoArticleCountInBlogHomePageQuery, DatoArticleCountInBlogHomePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatoArticleCountInBlogHomePageQuery, DatoArticleCountInBlogHomePageQueryVariables>(DatoArticleCountInBlogHomePageDocument, options);
      }
export function useDatoArticleCountInBlogHomePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatoArticleCountInBlogHomePageQuery, DatoArticleCountInBlogHomePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatoArticleCountInBlogHomePageQuery, DatoArticleCountInBlogHomePageQueryVariables>(DatoArticleCountInBlogHomePageDocument, options);
        }
export type DatoArticleCountInBlogHomePageQueryHookResult = ReturnType<typeof useDatoArticleCountInBlogHomePageQuery>;
export type DatoArticleCountInBlogHomePageLazyQueryHookResult = ReturnType<typeof useDatoArticleCountInBlogHomePageLazyQuery>;
export type DatoArticleCountInBlogHomePageQueryResult = Apollo.QueryResult<DatoArticleCountInBlogHomePageQuery, DatoArticleCountInBlogHomePageQueryVariables>;
export const DatoBlogHomePageDocument = gql`
    query datoBlogHomePage {
  blogHomepage {
    ...BlogHomePageRecordBreadcrumb
    pageTitle
    pagePath
    showBreadcrumb
    categoryColour {
      ...Color
    }
    _seoMetaTags {
      attributes
      content
      tag
    }
    metaTags {
      ...MetaTags
    }
    seoStructuredData
  }
}
    ${BlogHomePageRecordBreadcrumbFragmentDoc}
${ColorFragmentDoc}
${MetaTagsFragmentDoc}`;

/**
 * __useDatoBlogHomePageQuery__
 *
 * To run a query within a React component, call `useDatoBlogHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatoBlogHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatoBlogHomePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useDatoBlogHomePageQuery(baseOptions?: Apollo.QueryHookOptions<DatoBlogHomePageQuery, DatoBlogHomePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatoBlogHomePageQuery, DatoBlogHomePageQueryVariables>(DatoBlogHomePageDocument, options);
      }
export function useDatoBlogHomePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatoBlogHomePageQuery, DatoBlogHomePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatoBlogHomePageQuery, DatoBlogHomePageQueryVariables>(DatoBlogHomePageDocument, options);
        }
export type DatoBlogHomePageQueryHookResult = ReturnType<typeof useDatoBlogHomePageQuery>;
export type DatoBlogHomePageLazyQueryHookResult = ReturnType<typeof useDatoBlogHomePageLazyQuery>;
export type DatoBlogHomePageQueryResult = Apollo.QueryResult<DatoBlogHomePageQuery, DatoBlogHomePageQueryVariables>;
export const DatoMembershipsSearchPageDocument = gql`
    query datoMembershipsSearchPage {
  allCities {
    name
  }
  allLocationSites {
    ...LinkableLocationSiteRecord
    coordinates {
      latitude
      longitude
    }
    siteName
    city {
      id
      name
    }
  }
}
    ${LinkableLocationSiteRecordFragmentDoc}`;

/**
 * __useDatoMembershipsSearchPageQuery__
 *
 * To run a query within a React component, call `useDatoMembershipsSearchPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatoMembershipsSearchPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatoMembershipsSearchPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useDatoMembershipsSearchPageQuery(baseOptions?: Apollo.QueryHookOptions<DatoMembershipsSearchPageQuery, DatoMembershipsSearchPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatoMembershipsSearchPageQuery, DatoMembershipsSearchPageQueryVariables>(DatoMembershipsSearchPageDocument, options);
      }
export function useDatoMembershipsSearchPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatoMembershipsSearchPageQuery, DatoMembershipsSearchPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatoMembershipsSearchPageQuery, DatoMembershipsSearchPageQueryVariables>(DatoMembershipsSearchPageDocument, options);
        }
export type DatoMembershipsSearchPageQueryHookResult = ReturnType<typeof useDatoMembershipsSearchPageQuery>;
export type DatoMembershipsSearchPageLazyQueryHookResult = ReturnType<typeof useDatoMembershipsSearchPageLazyQuery>;
export type DatoMembershipsSearchPageQueryResult = Apollo.QueryResult<DatoMembershipsSearchPageQuery, DatoMembershipsSearchPageQueryVariables>;
export const DatoAllWorkspacesDocument = gql`
    query datoAllWorkspaces {
  allWorkspaces(filter: {_isValid: {eq: true}}, orderBy: rank_ASC) {
    ...WorkspaceCard
  }
}
    ${WorkspaceCardFragmentDoc}`;

/**
 * __useDatoAllWorkspacesQuery__
 *
 * To run a query within a React component, call `useDatoAllWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatoAllWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatoAllWorkspacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDatoAllWorkspacesQuery(baseOptions?: Apollo.QueryHookOptions<DatoAllWorkspacesQuery, DatoAllWorkspacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatoAllWorkspacesQuery, DatoAllWorkspacesQueryVariables>(DatoAllWorkspacesDocument, options);
      }
export function useDatoAllWorkspacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatoAllWorkspacesQuery, DatoAllWorkspacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatoAllWorkspacesQuery, DatoAllWorkspacesQueryVariables>(DatoAllWorkspacesDocument, options);
        }
export type DatoAllWorkspacesQueryHookResult = ReturnType<typeof useDatoAllWorkspacesQuery>;
export type DatoAllWorkspacesLazyQueryHookResult = ReturnType<typeof useDatoAllWorkspacesLazyQuery>;
export type DatoAllWorkspacesQueryResult = Apollo.QueryResult<DatoAllWorkspacesQuery, DatoAllWorkspacesQueryVariables>;
export const DatoWorkspacesDocument = gql`
    query datoWorkspaces($siteIds: [ItemId!], $capacities: [String!]) {
  allWorkspaces(
    filter: {site: {in: $siteIds}, capacity: {in: $capacities}, _isValid: {eq: true}, hideOnWorkspacesPage: {eq: false}}
    orderBy: rank_ASC
  ) {
    ...WorkspaceCard
  }
}
    ${WorkspaceCardFragmentDoc}`;

/**
 * __useDatoWorkspacesQuery__
 *
 * To run a query within a React component, call `useDatoWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatoWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatoWorkspacesQuery({
 *   variables: {
 *      siteIds: // value for 'siteIds'
 *      capacities: // value for 'capacities'
 *   },
 * });
 */
export function useDatoWorkspacesQuery(baseOptions?: Apollo.QueryHookOptions<DatoWorkspacesQuery, DatoWorkspacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatoWorkspacesQuery, DatoWorkspacesQueryVariables>(DatoWorkspacesDocument, options);
      }
export function useDatoWorkspacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatoWorkspacesQuery, DatoWorkspacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatoWorkspacesQuery, DatoWorkspacesQueryVariables>(DatoWorkspacesDocument, options);
        }
export type DatoWorkspacesQueryHookResult = ReturnType<typeof useDatoWorkspacesQuery>;
export type DatoWorkspacesLazyQueryHookResult = ReturnType<typeof useDatoWorkspacesLazyQuery>;
export type DatoWorkspacesQueryResult = Apollo.QueryResult<DatoWorkspacesQuery, DatoWorkspacesQueryVariables>;
export const DatoSiteLogoDocument = gql`
    query datoSiteLogo {
  siteLogo {
    headerImage {
      url
    }
    footerImage {
      url
    }
  }
}
    `;

/**
 * __useDatoSiteLogoQuery__
 *
 * To run a query within a React component, call `useDatoSiteLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatoSiteLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatoSiteLogoQuery({
 *   variables: {
 *   },
 * });
 */
export function useDatoSiteLogoQuery(baseOptions?: Apollo.QueryHookOptions<DatoSiteLogoQuery, DatoSiteLogoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatoSiteLogoQuery, DatoSiteLogoQueryVariables>(DatoSiteLogoDocument, options);
      }
export function useDatoSiteLogoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatoSiteLogoQuery, DatoSiteLogoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatoSiteLogoQuery, DatoSiteLogoQueryVariables>(DatoSiteLogoDocument, options);
        }
export type DatoSiteLogoQueryHookResult = ReturnType<typeof useDatoSiteLogoQuery>;
export type DatoSiteLogoLazyQueryHookResult = ReturnType<typeof useDatoSiteLogoLazyQuery>;
export type DatoSiteLogoQueryResult = Apollo.QueryResult<DatoSiteLogoQuery, DatoSiteLogoQueryVariables>;