import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { action } from 'mobx';

export const ReferralNotice = withCustomComponent('ReferalModal')(
  inject('appStore')(
    withRouter(
      observer(({ componentName, router, appStore }) => {
        const { t } = useTranslation();

        const showReferralModal = action((show) => {
          appStore.showReferralModal = show;
        });

        return (
          <div data-component-name={componentName}>
            <Trans>
              <a
                href="#"
                onClick={(ev) => {
                  ev.preventDefault();
                  showReferralModal(true);
                }}
                data-toggle="modal"
                data-target="#modal_referral"
              >
                Click here
                </a>
              <span> to refer a friend and get a discount</span>
            </Trans>
          </div>
        );
      })
    )
  )
);
