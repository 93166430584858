import { required } from 'validx';
import global from 'global';

export const supportMessageReplyValidationSchema = () => {
  const t = global.t;

  return {
    // The schema maps to the fields we are validating.
    TextMessage: [required({ msg: t('Message is required') })],
  };
};
