import React from 'react';

export interface LeftIconProps {
  color?: any;
  width?: number;
  height?: number;
}

export const LeftIcon = React.memo(
  ({color = '#20CAAB', width = 13, height = 20}: LeftIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.35 17.65L4.71667 10L12.35 2.35L10 0L0 10L10 20L12.35 17.65Z" />
    </svg>
  ),
);
