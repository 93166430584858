import { removeNewLines } from 'env/utils/strings';
import { isServer } from 'env/ssr/ServerSideRenderManager';
import { adjustBrightness, isDarkColor } from 'env/utils/cssHelpers';

export const whiteLabelCss = ({ configuration, primaryColor, appStore }) => {

  let hoverColor = primaryColor && adjustBrightness(primaryColor, -15); //Primary Darken by 15%

  //Default values (for lighter color)
  let textColor = "#111111"; //black
  let linkColor = "#0064FA"; //blue

  //Checking if the color is dark enough
  if (primaryColor && isDarkColor(primaryColor)) {
    textColor = "#FFFFFF"; //white
    linkColor = primaryColor;
  }

  let borderRadius = "0.5rem"
  if (appStore.corporateDashboard) {
    borderRadius = "0";
  }

  return removeNewLines(`
    :root {
      --bg-color: ${primaryColor};
      --hover-color: ${hoverColor};
      --text-color: ${textColor};
      --link-color: ${linkColor};
      --border-radius: ${borderRadius};
    }
    ${configuration['Website.CustomCSS']}
    ${!isServer() && '#__next { visibility: visible !important; }'}`);
}
