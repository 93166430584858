import React from 'react';

export interface PreviewBannerProps {
  exitPreviewHref: string;
}

export const PreviewBanner = React.memo(
  ({exitPreviewHref}: PreviewBannerProps) => (
    <div className="rwe-preview-banner">
      <div className="rwe-preview-banner__text">
        <div className="rwe-p1">Preview Mode Enabled</div>
      </div>

      <div className="rwe-preview-banner__link">
        <div className="rwe-p1">
          <a href={exitPreviewHref}>Exit Preview Mode</a>
        </div>
      </div>
    </div>
  ),
);
