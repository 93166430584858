import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { getStores } from 'env/stores/stores';
import global from 'global';
import { isServer } from 'env/ssr/ServerSideRenderManager';

@withTranslation()
@inject('appStore', 'authStore')
@observer
class MobxApp extends Component {
  componentDidMount() {
    const { t, appStore, authStore } = this.props;
    global.t = t;
    
    if (
      process.env.LOCAL_BUILD !== 'true' &&
      process.env.NODE_ENV === 'production'
    ) {
      authStore.setRegistrableDomain(
        // appStore.configuration['System.RegistrableDomain']
        process.env.NEXT_PUBLIC_COOKIE_DOMAIN
      );
    }
  }

  render() {
    return <div className="nx">{this.props.children}</div>;
  }
}

export default MobxApp;
