import React, { Component, useEffect, useState } from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';
import config from 'env/config';
import i18n from 'env/i18n';
import LanguageBar from './LanguageBar';

@withCustomComponent('Footer')
@withTranslation()
@withRouter
@inject('appStore', 'authStore', 'bookingsStore', 'contentStore')
@observer
class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentLanguage: i18n.language,
    };
  }

  componentDidMount() {
    const { appStore, contentStore } = this.props;

    if (!appStore.hasLoadedLanguages) appStore.loadLanguages();

    if (!contentStore.hasLoadedFaqArticles)
      contentStore.loadFaqArticles().catch((ex) => null);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentLanguage != prevState.currentLanguage)
      window.location.href = `?lng=${this.state.currentLanguage}`;
  }

  render() {
    const {
      t,
      router,
      componentName,
      appStore,
      authStore,
      bookingsStore,
      contentStore,
    } = this.props;
    const { business, languages, configuration, corporateDashboard } = appStore;
    const { isLoggedIn } = authStore;
    const { resources } = bookingsStore;
    const { faqArticles } = contentStore;
    return (
      !isLoggedIn && (
        <footer data-component-name={componentName} className="site-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-xl-5 pb-24 pb-lg-0">
                <a href={routes.home}>
                  <img
                    src={`${business.NativeHomeUrlWithLanguage}/business/getlogo?h=96&mode=pad`}
                    alt={business.Name}
                    height="48"
                  />
                </a>
                <p className="fs-14 lh-14 text-gray-600 mt-16 mt-md-24 pr-0 pr-lg-48 pr-xl-72 mb-0">
                  {configuration['Footer.SayingText']}
                </p>
                <span className="fs-14 lh-14 text-gray-600 mt-md-24 pr-0 pr-lg-48 pr-xl-72 mb-0">
                  {configuration['Footer.SayingAuthor']
                    ? `- ${configuration['Footer.SayingAuthor']}`
                    : ''}
                </span>
              </div>
              <div className="col-lg-7 col-xl-5 pt-16 pt-md-24 pt-lg-0">
                <div className="row">
                  <div className="col-sm-6 col-md-4">
                    <h6 className="mb-8">{t('Company')}</h6>
                    <ul className="list-unstyled">
                      {configuration['PublicWebSite.MyAccount'] && (
                        <li>
                          <Link href={routes.profile}>
                            <a>{t('My Account')}</a>
                          </Link>
                        </li>
                      )}
                      {resources.length > 0 && (
                        <li>
                          <Link href={routes.calendar}>
                            <a>{t('Bookings Calendar')}</a>
                          </Link>
                        </li>
                      )}
                      {configuration['PublicWebSite.Contact'] && (
                        <li>
                          <Link href={routes.contact}>
                            <a>{t('Contact')}</a>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <h6 className="mb-8">{t('Explore')}</h6>
                    <ul className="list-unstyled">
                      {!corporateDashboard &&
                        configuration['PublicWebSite.Events'] && (
                          <li>
                            <Link href={routes.events}>
                              <a>{t('Events')}</a>
                            </Link>
                          </li>
                        )}
                      {configuration['PublicWebSite.Directory'] && (
                        <li>
                          <Link href={routes.directory_members}>
                            <a>{t('Directory')}</a>
                          </Link>
                        </li>
                      )}
                      {configuration['PublicWebSite.CommunityBoard'] && (
                        <li>
                          <Link href={routes.forum}>
                            <a>{t('Discussion boards')}</a>
                          </Link>
                        </li>
                      )}
                      {configuration['Jitsi.AlwaysOnRooms.Enabled'] && (
                        <li>
                          <Link href={routes.virtual_rooms}>
                            <a>{t('Virtual rooms')}</a>
                          </Link>
                        </li>
                      )}
                      {configuration['Blog.Enabled'] && (
                        <li>
                          <Link href={routes.posts}>
                            <a>{t('Articles')}</a>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <h6 className="mb-8">{t('Support')}</h6>
                    <ul className="list-unstyled">
                      {faqArticles.length > 0 && (
                        <li>
                          <Link href={routes.faq}>
                            <a>{t('Faq')}</a>
                          </Link>
                        </li>
                      )}
                      {configuration['PublicWebSite.Help'] && (
                        <li>
                          <Link href={routes.support_new}>
                            <a>{t('Help')}</a>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-xl-2 pt-16 pt-xl-0">
                <div className="list-inline-16 justify-content-start justify-content-xl-end">
                  {configuration['Social.Facebook'] && (
                    <a
                      className="text-gray-900 lh-1 tdn"
                      href={configuration['Social.Facebook']}
                      target="_blank"
                    >
                      <i className="icon-social-facebook fs-24"></i>
                    </a>
                  )}
                  {configuration['Social.Twitter'] && (
                    <a
                      className="text-gray-900 lh-1 tdn"
                      href={`https://twitter.com/${configuration['Social.Twitter']}`}
                      target="_blank"
                    >
                      <i className="icon-social-twitter fs-24"></i>
                    </a>
                  )}
                  {configuration['Social.Instagram'] && (
                    <a
                      className="text-gray-900 lh-1 tdn"
                      href={configuration['Social.Instagram']}
                      target="_blank"
                    >
                      <i className="icon-social-instagram fs-24"></i>
                    </a>
                  )}
                  {configuration['Social.LinkedIn'] && (
                    <a
                      className="text-gray-900 lh-1 tdn"
                      href={configuration['Social.LinkedIn']}
                      target="_blank"
                    >
                      <i className="icon-social-linkedin fs-24"></i>
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="row fs-14 text-gray-600 mt-32 mt-md-64">
              <div className="col-lg-6">
                {moment().format('YYYY')} © {business.Name}.{' '}
                {t('All rights reserved.')}
              </div>
              <div className="col-lg-6 text-lg-right pt-4 pt-lg-0">
                {business.TermsAndConditions && (
                  <Link href={routes.legal_terms}>
                    <a className="text-gray-600 mr-12">
                      {t('Terms and conditions')}
                    </a>
                  </Link>
                )}
                {configuration['Legal.Privacy'] && (
                  <Link href={routes.legal_privacy}>
                    <a className="text-gray-600 mr-12">{t('Privacy policy')}</a>
                  </Link>
                )}
                {configuration['Legal.Cookies'] && (
                  <Link href={routes.legal_cookies}>
                    <a className="text-gray-600">{t('Cookies policy')}</a>
                  </Link>
                )}
                
                <LanguageBar classes='ml-20'/>
              </div>
            </div>
          </div>
        </footer>
      )
    );
  }
}
export default Footer;
