import React, {useContext, useMemo, useState} from 'react';
import {
  RweBreakpoint,
  useRweBreakpoint,
} from '../../../../hooks/use-rwe-breakpoint';

interface FloatingCtaContextValue {
  isFloatingCtaPresent: boolean;
  isFloating: boolean;
  setIsFloating: (isFloating: boolean) => any;
  floatingContentWidth: number | undefined;
  setFloatingContentWidth: (floatingContentWidth: number | undefined) => any;
  avoidanceStyle: React.CSSProperties | undefined;
}

const FloatingCtaContext = React.createContext<
  FloatingCtaContextValue | undefined
>(undefined);

export const useFloatingCta = (): FloatingCtaContextValue => {
  const context = useContext(FloatingCtaContext);

  if (!context) throw new Error('Missing FloatingCtaContext Provider');

  return context;
};

export const FloatingCtaContextProvider = React.memo(({children}) => {
  const [isFloating, setIsFloating] = useState(false);
  const [floatingContentWidth, setFloatingContentWidth] = useState<
    number | undefined
  >(undefined);
  const breakpoint = useRweBreakpoint();

  const avoidanceStyle = useMemo(
    (): React.CSSProperties | undefined =>
      floatingContentWidth && breakpoint && breakpoint > RweBreakpoint.Tablet
        ? {marginRight: floatingContentWidth}
        : undefined,
    [floatingContentWidth, breakpoint],
  );

  return (
    <FloatingCtaContext.Provider
      value={{
        isFloatingCtaPresent: !!floatingContentWidth,
        isFloating,
        setIsFloating,
        floatingContentWidth,
        setFloatingContentWidth,
        avoidanceStyle,
      }}
    >
      {children}
    </FloatingCtaContext.Provider>
  );
});
