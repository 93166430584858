import moment from 'moment';
export const getShiftsFromResources = (items, propertyName) => {
  let allShifts = [];

  for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
    const item = items[itemIndex];
    try {
      if (item[propertyName]) {
        const shifts = item[propertyName].split(', ');

        for (let index = 0; index < shifts.length; index++) {
          const shift = shifts[index];
          const title = shift.split(/:(.+)/)[0];
          const withNotTile = shift.split(/:(.+)/)[1].split(' ').join('');
          const start = withNotTile.split('->')[0].split(' ').join('');
          const end = withNotTile.split('->')[1].split(' ').join('');

          const foundShift = allShifts.filter((s) => s.title === title);
          if (foundShift.length == 0) {
            allShifts.push({
              title,
              start: moment(`1900-01-01T${start}`),
              end: moment(`1900-01-01T${end}`),
              id: allShifts.length,
            });
          }
        }
      }
    } catch {
      continue;
    }
  }

  return allShifts;
};
