import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const ImpersonationNotice = withCustomComponent(
  'CustomerInactiveNotice'
)(
  inject(
    'appStore',
    'authStore'
  )(
    withRouter(
      observer(({ componentName, router, authStore }) => {
        const { t } = useTranslation();
        if (!authStore.me) return <div></div>;
        return (
          <div data-component-name={componentName}>
            <span>
              {t('You are currently managing the account for {{FullName}}. ', {
                FullName: authStore.me.FullName,
              })}
            </span>
            <a
              href="#"
              onClick={(ev) => {
                window.location = `/login?t=${
                  authStore.impersonationToken
                  }&returnurl=${encodeURIComponent('/profile/team')}`;
              }}
            >
              {t('Go back to your account')}
            </a>
          </div>
        );
      })
    )
  )
);
