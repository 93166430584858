import React, {useEffect, useMemo, useRef} from 'react';

export interface DropdownProps {
  rightAligned?: boolean;
  children: React.ReactChild;
  variant?: 'find-a-space';
  isShowing: boolean;
}

export const Dropdown: React.FC<DropdownProps> = React.memo(
  ({rightAligned, children, variant, isShowing}) => {
    const dropdownElementRef = useRef<HTMLDivElement | null>(null);

    const dropdownMenuClassNames = useMemo((): string => {
      const classNames = ['rwe-dropdown-menu'];

      if (rightAligned) {
        classNames.push('rwe-dropdown-menu--right-aligned');
      }

      if (variant) {
        classNames.push(`rwe-dropdown-menu--${variant}`);
      }

      return classNames.join(' ');
    }, [rightAligned, variant]);

    useEffect(() => {
      if (!isShowing) return;
      if (!dropdownElementRef.current) return;

      dropdownElementRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }, [isShowing]);

    if (!isShowing) return null;

    return (
      <div ref={dropdownElementRef} className={dropdownMenuClassNames}>
        {children}
      </div>
    );
  },
);
