export const isProduction = (): boolean =>
  process.env.VERCEL_ENV === 'production' ||
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';

export const isDevelopment = (): boolean =>
  !process.env.VERCEL_ENV && !process.env.NEXT_PUBLIC_VERCEL_ENV;

export const HOST =
  (process.env.VERCEL_URL || process.env.NEXT_PUBLIC_VERCEL_URL) &&
  process.env.COOKIE_DOMAIN !== 'vercel.app' &&
  process.env.NEXT_PUBLIC_COOKIE_DOMAIN !== 'vercel.app'
    ? `https://${
        process.env.COOKIE_DOMAIN || process.env.NEXT_PUBLIC_COOKIE_DOMAIN
      }`
    : `http://${
        process.env.VERCEL_URL ||
        process.env.NEXT_PUBLIC_VERCEL_URL ||
        `localhost:${process.env.PORT || 3000}`
      }`;
