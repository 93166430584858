import React, {useCallback, useState} from 'react';
import {RunwayEastLink} from '../link/RunwayEastLink';

export interface NavigationDrawerItem {
  text: string;
  href?: string;
  items?: NavigationDrawerItem[];
}

interface NavigationDrawerItemProps {
  text: string;
  href?: string;
  withArrow?: boolean;
  expanded?: boolean;
  onClick?: () => void;
  leftAccessory?: React.ReactElement;
}

const NavigationDrawerItem: React.FC<NavigationDrawerItemProps> = React.memo(
  ({href, text, withArrow, children, expanded, onClick, leftAccessory}) => (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={`rwe-drawer__item ${
          expanded ? 'rwe-drawer__item--expanded' : ''
        }`}
        onClick={onClick}
      >
        <div
          className={`rwe-drawer__item-text ${
            withArrow ? 'rwe-drawer__item-text--with-arrow-on-right' : ''
          }`}
        >
          <RunwayEastLink href={href}>
            {leftAccessory ? (
              <div className="rwe-drawer__item-left-accessory-container">
                {leftAccessory}
              </div>
            ) : null}
            <div className="rwe-p1">{text}</div>
          </RunwayEastLink>
        </div>
      </div>
      {children ? (
        <div
          className={`rwe-drawer__expandable-menu ${
            expanded ? 'rwe-drawer__expandable-menu--expanded' : ''
          }`}
        >
          {children}
        </div>
      ) : null}
    </>
  ),
);

export interface ExpandableMenuItem {
  text: string;
  href?: string;
  leftAccessory?: React.ReactElement;
  items?: ExpandableMenuItem[];
}

interface ExpandableMenuProps {
  items: ExpandableMenuItem[];
}

const ExpandableMenu = ({items}: ExpandableMenuProps) => (
  <>
    {items.map((item) =>
      item.items && item.items.length ? (
        <React.Fragment key={item.text}>
          <div className="rwe-drawer__expandable-menu-item-header">
            <RunwayEastLink href={item.href}>
              {item.leftAccessory ? (
                <div className="rwe-drawer__expandable-menu-item-header-left-accessory-container">
                  {item.leftAccessory}
                </div>
              ) : null}
              <div className="rwe-p3">{item.text}</div>
            </RunwayEastLink>
          </div>
          <ExpandableMenu items={item.items} />
        </React.Fragment>
      ) : (
        <div key={item.text} className="rwe-drawer__expandable-menu-item">
          <RunwayEastLink href={item.href}>
            {item.leftAccessory ? (
              <div className="rwe-drawer__expandable-menu-item-left-accessory-container">
                {item.leftAccessory}
              </div>
            ) : null}
            <div className="rwe-p1">{item.text}</div>
          </RunwayEastLink>
        </div>
      ),
    )}
  </>
);

interface NavigationDrawerItemWithExpandableMenuProps
  extends NavigationDrawerItemProps {
  items: ExpandableMenuItem[];
}

export const NavigationDrawerItemWithExpandableMenu: React.FC<NavigationDrawerItemWithExpandableMenuProps> = React.memo(
  ({items, ...rest}) => {
    const [isExpandableMenuShowing, setIsExpandableMenuShowing] = useState(
      false,
    );

    const toggleExpandableMenuVisibility = useCallback(() => {
      setIsExpandableMenuShowing((value) => !value);
    }, []);

    return (
      <NavigationDrawerItem
        {...rest}
        withArrow
        expanded={isExpandableMenuShowing}
        onClick={toggleExpandableMenuVisibility}
      >
        <ExpandableMenu items={items} />
      </NavigationDrawerItem>
    );
  },
);

export interface NavigationDrawerItemsProps {
  items: NavigationDrawerItem[];
  TopSectionComponent?: React.ReactElement;
}

export const NavigationDrawerItems = React.memo(
  ({items, TopSectionComponent}: NavigationDrawerItemsProps) => (
    <>
      {TopSectionComponent}
      {items.map((item) =>
        item.items && item.items.length ? (
          <NavigationDrawerItemWithExpandableMenu
            key={item.text}
            text={item.text}
            href={item.href}
            items={item.items}
          />
        ) : (
          <NavigationDrawerItem
            key={item.text}
            text={item.text}
            href={item.href}
            withArrow={false}
          />
        ),
      )}
    </>
  ),
);
