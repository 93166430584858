import React from 'react';
import {RunwayEastLink} from './RunwayEastLink';

interface BaseProps {
  disabled?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

interface AnchorProps extends BaseProps {
  href: string;
  htmlId?: string;
  target?: '_blank' | '_self';
  onClick?: undefined;
  as: 'anchor';
}

interface ButtonProps extends BaseProps {
  href?: undefined;
  target?: undefined;
  onClick: () => any;
  as: 'button';
}

export type AnchorOrButtonProps = AnchorProps | ButtonProps;

export const AnchorOrButton = React.memo((props: AnchorOrButtonProps) => {
  switch (props.as) {
    case 'anchor':
      return (
        <RunwayEastLink
          {...props}
          wrapChildrenWithAnchorElementForInternalLinks
        />
      );
    case 'button':
      return <button type="button" className="rwe-button-reset" {...props} />;
    default:
      return null;
  }
});
