import { observable, action, reaction, toJS, computed } from 'mobx';
import { loadDataHelper } from './storeHelpers';

import StoreBase from './storeBase';
import _ from 'lodash';

class ContentStore extends StoreBase {
  constructor({ data, host, cookieString } = {}) {
    super({ data, host, cookieString });
  }

  @computed get homeFaqArticles() {
    return this.faqArticles.filter((a) => a.ShowInHomePage);
  }

  @computed get articlesByGroup() {
    return _(this.faqArticles)
      .groupBy('GroupName')
      .map((articles, group) => {
        return {
          group,
          articles,
        };
      })
      .value();
  }

  @observable isLoadingFaqArticles = false;
  @observable hasLoadedFaqArticles = false;
  @observable faqArticles = [];
  @action loadFaqArticles() {
    return loadDataHelper({
      store: this,
      agentKey: 'Content',
      key: 'FaqArticles',
    });
  }

  @computed get homeCommunityPerks() {
    return this.communityPerks.filter((a) => a.ShowInHomePage);
  }

  @computed get communityPerksByGroup() {
    return _(this.communityPerks)
      .groupBy('GroupName')
      .map((perks, group) => {
        return {
          group,
          perks,
        };
      })
      .value();
  }

  @observable isLoadingCommunityPerks = false;
  @observable hasLoadedCommunityPerks = false;
  @observable communityPerks = [];
  @action loadCommunityPerks() {
    return loadDataHelper({
      store: this,
      agentKey: 'Content',
      key: 'CommunityPerks',
    });
  }
}

export default ContentStore;
