import { required, pattern } from 'validx';
import global from 'global';

export const commentValidationSchema = () => {
  const t = global.t;

  return {
    // The schema maps to the fields we are validating.
    Title: [required({ msg: t('A subject is required') })],
    Comment: [required({ msg: t('Comment message is required') })],
  };
};
