import React from 'react';
import {LeftIcon, LeftIconProps} from './LeftIcon';

type RightIconProps = LeftIconProps;

const style = {transform: 'scaleX(-1)'};

export const RightIcon = React.memo((props: RightIconProps) => (
  <div style={style}>
    <LeftIcon {...props} />
  </div>
));
