import React from 'react';
import {CloseIcon} from '../icon/CloseIcon';
import {AnchorOrButton} from '../link/AnchorOrButton';
import {RunwayEastLink} from '../link/RunwayEastLink';
import {RunwayEastLogo} from '../RunwayEastLogo';
import {Drawer, DrawerProps} from './Drawer';
import {
  NavigationDrawerItems,
  NavigationDrawerItemsProps,
} from './NavigationDrawerItems';

export interface NavigationDrawerProps
  extends Omit<DrawerProps, 'children'>,
    NavigationDrawerItemsProps {}

export const NavigationDrawer: React.FC<NavigationDrawerProps> = React.memo(
  ({TopSectionComponent, items, onClose, ...rest}) => (
    <Drawer
      {...rest}
      onClose={onClose}
      HeaderComponent={
        <>
          <RunwayEastLink href="/">
            <RunwayEastLogo width={128} />
          </RunwayEastLink>
          <AnchorOrButton as="button" onClick={onClose}>
            <CloseIcon />
          </AnchorOrButton>
        </>
      }
    >
      <NavigationDrawerItems
        TopSectionComponent={TopSectionComponent}
        items={items}
      />
    </Drawer>
  ),
);
