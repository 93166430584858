import { required, pattern } from 'validx';
import global from 'global';

export const communityMessageValidationSchema = () => {
  const t = global.t;

  return {
    // The schema maps to the fields we are validating.
    Subject: [required({ msg: t('Subject is required') })],
    Message: [required({ msg: t('Message is required') })],
  };
};
