module.exports = {
  featureFlags: {
    membershipsList: false
  },
  apiRoot:
    process.env.PUBLIC_URL
      ? process.env.PUBLIC_URL
      : 'https://runwayeast.spaces.nexudus.com',
  storageUrl:
    process.env.STORAGE_URL
      ? process.env.STORAGE_URL
      : 'https://storage.nexudus.com',
  platformRoot:
    process.env.API_URL
      ? process.env.API_URL
      : 'https://spaces.nexudus.com',
  dashboardUrl:
    process.env.DASHBOARD_URL
      ? process.env.DASHBOARD_URL
      : 'https://platform.nexudus.com',
};
