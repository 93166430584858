import {useRouter} from 'next/router';
import React from 'react';
import {PreviewBanner} from './PreviewBanner';

export const PreviewBannerContainer = React.memo(() => {
  const router = useRouter();

  if (!router.isPreview) return null;

  return (
    <div className="rwe-preview-banner-container">
      <PreviewBanner exitPreviewHref="/api/exit-preview" />
    </div>
  );
});
