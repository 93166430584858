import Script from 'next/script';
import React from 'react';

export interface HubSpotProps {
  id: string;
}

export const HubSpot = React.memo(({id}: HubSpotProps) => {
  if (!id) throw new Error('Missing HubSpot id');

  return (
    <Script
      type="text/javascript"
      id="hs-script-loader"
      strategy="lazyOnload"
      async
      defer
      src={`//js.hs-scripts.com/${id}.js`}
    />
  );
});
