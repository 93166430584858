import React from 'react';

export interface AvatarProps {
  text?: string;
}

export const Avatar: React.FC<AvatarProps> = React.memo(({text}) => (
  <div className="rwe-avatar">
    {text ? <div className="rwe-caption">{text}</div> : null}
  </div>
));
