import {useCallback, useEffect, useState} from 'react';

// eslint-disable-next-line no-shadow
export enum RweBreakpoint {
  Mobile,
  Tablet,
  Desktop,
  DesktopLarge,
  DesktopExtraLarge,
}

const breakpoints = Object.values(RweBreakpoint).filter(
  (value) => typeof value === 'number',
) as RweBreakpoint[];

const rweBreakpointMinWidthMap: Record<RweBreakpoint, number> = {
  [RweBreakpoint.Mobile]: 0,
  [RweBreakpoint.Tablet]: 768,
  [RweBreakpoint.Desktop]: 1200,
  [RweBreakpoint.DesktopLarge]: 1440,
  [RweBreakpoint.DesktopExtraLarge]: 1920,
};

const constructMediaQueryString = (breakpoint: RweBreakpoint) => {
  if (breakpoint === 0) {
    return `(max-width: ${
      rweBreakpointMinWidthMap[breakpoints[breakpoint + 1]] - 1
    }px)`;
  }
  if (breakpoint === breakpoints[breakpoints.length - 1]) {
    return `(min-width: ${rweBreakpointMinWidthMap[breakpoint]}px)`;
  }
  return `(min-width: ${
    rweBreakpointMinWidthMap[breakpoint]
  }px) and (max-width: ${
    rweBreakpointMinWidthMap[breakpoints[breakpoint + 1]] - 1
  }px)`;
};

const getBreakpointFromMatchedMedia = () => {
  const mediaQueryMatchResults = breakpoints
    .map((breakpoint) =>
      window.matchMedia(constructMediaQueryString(breakpoint)),
    )
    .map(({matches}) => matches);

  const mediaQueryMatchTrueResultIndex = mediaQueryMatchResults.findIndex(
    (mediaQueryMatchResult) => mediaQueryMatchResult,
  );

  return breakpoints[mediaQueryMatchTrueResultIndex];
};

export const useRweBreakpoint = (): RweBreakpoint | undefined => {
  const [breakpoint, setBreakpoint] = useState<RweBreakpoint | undefined>(
    typeof window === 'undefined' ? undefined : getBreakpointFromMatchedMedia(),
  );

  const measureBodyAndSetBreakpoint = useCallback(() => {
    const newBreakpoint = getBreakpointFromMatchedMedia();

    setBreakpoint(newBreakpoint);
  }, []);

  useEffect(measureBodyAndSetBreakpoint, [measureBodyAndSetBreakpoint]);

  useEffect(() => {
    const handleResize = () => {
      const newBreakpoint = getBreakpointFromMatchedMedia();

      if (breakpoint !== newBreakpoint) {
        setBreakpoint(newBreakpoint);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [breakpoint]);

  return breakpoint;
};
