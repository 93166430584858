import React, {useContext, useMemo, useRef, useState} from 'react';
import {PreviewBannerContainer} from '../preview/PreviewBannerContainer';
import {colors} from '../theme';
import {useHidingNavbar} from './use-hiding-navbar';

export const DEFAULT_BACKGROUND_COLOR = colors.primary.white;
interface NavbarContextValue {
  backgroundColor: string;
  setBackgroundColor: (value: string) => any;
  navbarContainerElementRef: React.RefObject<HTMLDivElement | null> | null;
  footerContainerElementRef: React.RefObject<HTMLDivElement | null> | null;
  isNavbarHiding: boolean;
}

const NavbarContext = React.createContext<NavbarContextValue>({
  backgroundColor: DEFAULT_BACKGROUND_COLOR,
  setBackgroundColor: () => null,
  navbarContainerElementRef: null,
  footerContainerElementRef: null,
  isNavbarHiding: false,
});

export const useNavbarContext = (): NavbarContextValue => {
  const contextValue = useContext(NavbarContext);

  if (!contextValue) throw new Error('Missing NavbarContext Provider');

  return contextValue;
};

interface NavbarContextProviderProps {
  NavbarComponent: React.ReactElement;
  FooterComponent: React.ReactElement;
  initialBackgroundColor?: string;
  shouldShowOverflow?: boolean;
}

export const NavbarContextProvider: React.FC<NavbarContextProviderProps> = React.memo(
  ({
    children,
    NavbarComponent,
    FooterComponent,
    initialBackgroundColor = colors.primary.white,
    shouldShowOverflow,
  }) => {
    const navbarContainerElementRef = useRef<HTMLDivElement | null>(null);
    const footerContainerElementRef = useRef<HTMLDivElement | null>(null);
    const [backgroundColor, setBackgroundColor] = useState(
      initialBackgroundColor,
    );
    const isNavbarHiding = useHidingNavbar();

    const navbarContextValue = useMemo(
      (): NavbarContextValue => ({
        backgroundColor,
        setBackgroundColor,
        navbarContainerElementRef,
        footerContainerElementRef,
        isNavbarHiding,
      }),
      [backgroundColor, isNavbarHiding],
    );

    const navbarContainerClassNames = useMemo((): string => {
      const classNames = ['rwe-navbar-container'];

      if (isNavbarHiding) {
        classNames.push('rwe-navbar-container--hidden');
      }

      return classNames.join(' ');
    }, [isNavbarHiding]);

    return (
      <NavbarContext.Provider value={navbarContextValue}>
        <div
          ref={navbarContainerElementRef}
          className={navbarContainerClassNames}
          style={{backgroundColor}}
        >
          {NavbarComponent}
        </div>
        <div
          className="rwe-page-content"
          style={{
            backgroundColor,
            overflow: shouldShowOverflow ? 'visible' : 'hidden',
          }}
        >
          {children}
        </div>
        <div ref={footerContainerElementRef}>{FooterComponent}</div>
        <PreviewBannerContainer />
      </NavbarContext.Provider>
    );
  },
);
