import * as timeago from 'timeago.js';

import moment from 'moment';
import i18next from 'i18next';

export const setMinutes = (date, minutes) => {
  date.setMinutes(minutes);
  return date;
};

export const setHours = (date, hours) => {
  date.setHours(hours);
  return date;
};

export const initTimeAgo = () => {
  var langShort = i18next.language.split('-')[0].toLocaleLowerCase();
  const nodes = document.querySelectorAll('.timeago');
  if (nodes.length > 0) timeago.render(nodes, langShort);
};

export const isWeekend = (thisDate) =>
  moment(thisDate).day() == 0 || moment(thisDate).day() == 6;

export const toLocalTime = (date) => {
  const fromDate = moment(date).clone();
  const fromOffset = fromDate.toDate().getTimezoneOffset();
  fromDate.add(-fromOffset, 'minutes');
  return fromDate.toDate();
};
