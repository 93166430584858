import Agent from 'env/agent';

class StoreBase {
  constructor({ data = {}, host, cookieString }) {
    this.host = host;
    this.cookieString = cookieString;
    Object.keys(data).forEach((key, i) => {
      this[key] = data[key];
    });

    this.agent = new Agent(host, cookieString, true);
  }

  getAgent = () => this.agent
}

export default StoreBase;
