import {useRef, useState, useEffect} from 'react';

export const useHidingNavbar = (): boolean => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [hideNavbar, setHideNavbar] = useState(false);

  useEffect(() => {
    let isScrolling = false;
    let previousScrollPosition = 0;

    const handleScroll = () => {
      if (isScrolling) return;

      isScrolling = true;

      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        const currentScrollPosition = window.scrollY;

        const hasScrolledDown =
          previousScrollPosition > currentScrollPosition ||
          currentScrollPosition <= 0;

        previousScrollPosition = currentScrollPosition;
        if(hasScrolledDown == false){
          // @ts-ignore  
          window.__rwe_scroll_locked = false 
        }      
        // @ts-ignore
        if(window.__rwe_scroll_locked != true){
          setHideNavbar(!hasScrolledDown);
        }
        isScrolling = false;
      }, 100);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return hideNavbar;
};
