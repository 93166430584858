import React, {useMemo} from 'react';
import {AnchorOrButton} from '../link/AnchorOrButton';

type Variant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'floating'
  | 'primary-dark';

interface PassedHrefOnlyProps {
  /**
   * This is true when the component is wrapped by a Next/Link with passHref = true
   */
  passedHref: true;
}
interface HrefOnlyProps {
  href: string;
  htmlId?: string;
  target?: '_blank' | '_self';
}

interface OnClickOnlyProps {
  onClick: () => any;
}

type HrefOrOnClickProps =
  | PassedHrefOnlyProps
  | HrefOnlyProps
  | OnClickOnlyProps;

type RunwayEastButtonBaseProps = {
  disabled?: boolean;
  variant: Variant;
  blockButton?: boolean;
  isActive?: boolean;
} & HrefOrOnClickProps;

type RunwayEastButtonWithIconAndTextProps = {
  IconComponent: React.ReactChild;
  RightIconComponent?: React.ReactChild;
  children: string;
} & RunwayEastButtonBaseProps;

type RunwayEastButtonWithIconOnlyProps = {
  IconComponent: React.ReactChild;
  RightIconComponent?: React.ReactChild;
  children?: undefined;
} & RunwayEastButtonBaseProps;

type RunwayEastButtonWithTextOnlyProps = {
  IconComponent?: undefined;
  RightIconComponent?: React.ReactChild;
  children: string;
} & RunwayEastButtonBaseProps;

export type RunwayEastButtonProps =
  | RunwayEastButtonWithIconAndTextProps
  | RunwayEastButtonWithIconOnlyProps
  | RunwayEastButtonWithTextOnlyProps;

export const RunwayEastButton = React.memo(
  ({
    children,
    IconComponent,
    RightIconComponent,
    variant,
    blockButton,
    isActive,
    ...rest
  }: RunwayEastButtonProps) => {
    const elementClassNames = useMemo((): string => {
      const classNames = ['rwe-button', `rwe-button--${variant}`];

      if (blockButton) {
        classNames.push('rwe-button--block');
      }

      if (isActive) {
        classNames.push('rwe-button--active');
      }

      return classNames.join(' ');
    }, [blockButton, variant, isActive]);

    const innerFragment = useMemo(
      () => (
        <div className={elementClassNames}>
          {IconComponent ? (
            <div className="rwe-button__icon-container">{IconComponent}</div>
          ) : null}
          {children ? (
            <div className="rwe-button__content">{children}</div>
          ) : null}
          {RightIconComponent ? (
            <div className="rwe-button__icon-container">
              {RightIconComponent}
            </div>
          ) : null}
        </div>
      ),
      [IconComponent, RightIconComponent, children, elementClassNames],
    );

    if ('passedHref' in rest) {
      return innerFragment;
    }
    
    if ('onClick' in rest) {
      return (
        <AnchorOrButton
          as="button"
          style={blockButton ? {width: '100%'} : undefined}
          {...rest}
        >
          {innerFragment}
        </AnchorOrButton>
      );
    }
    return (
      <AnchorOrButton
        as="anchor"
        style={blockButton ? {width: '100%'} : undefined}
        {...rest}
      >
        {innerFragment}
      </AnchorOrButton>
    );
  },
);
