import StoreBase from './storeBase';
import { observable, action, computed, toJS } from 'mobx';
import { loadDataHelper } from './storeHelpers';
import { getBookingToSubmit } from './bookingsStore';
import { getStores } from './stores';
import { toLocalTime } from 'env/utils/dateUtil';
import { isServer } from 'env/ssr/ServerSideRenderManager';
import { getErrorDescription } from 'ui/_pages/bookings/BookingErrorCodes';
import global from 'global';
import { addTagManagerEvent } from 'env/TagManager';
class CheckoutStore extends StoreBase {
  constructor({ data, host, cookieString } = {}) {
    super({ data, host, cookieString });

    if (!isServer() && window.localStorage)
      this.basket = JSON.parse(window.localStorage.getItem('nx-basket')) || [];
  }

  saveBasket() {
    if (!isServer() && window.localStorage)
      window.localStorage.setItem(
        'nx-basket',
        JSON.stringify(toJS(this.basket))
      );
  }

  getBasketForCheckout = () => {
    return this.basket.reduce((acc, item) => {
      if (item.type == 'course') {
        const course = {
          type: item.type,
          Course: {
            Id: item.data.Id,
          },
        };
        acc.push(course);
      }
      if (item.type == 'event') {
        item.data.EventProducts.forEach((product) => {
          if (product.Quantity > 0) {
            product.Attendees.forEach((att) => {
              acc.push({
                Type: item.type,
                EventAttendee: {
                  FullName: att.FullName,
                  Email: att.Email,
                  CalendarEventId: item.data.Id,
                  EventProductId: product.Id,
                },
              });
            });
          }
        });
      }
      if (item.type == 'contract') {
        const contract = {
          type: item.type,
          CoworkerContract: {
            StartDate: toLocalTime(item.data.startDate),
            TariffId: item.data.tariff.Id,
          },
        };
        acc.push(contract);
      }
      if (item.type == 'product') {
        const product = {
          type: item.type,
          CoworkerProduct: {
            ProductId: item.data.Id,
            Quantity: item.data.Quantity,
            RegularCharge: item.data.RegularCharge,
          },
        };
        acc.push(product);
      }
      if (item.type == 'booking') {
        const bookingToSubmit = getBookingToSubmit(item.data);
        const booking = {
          Type: item.type,
          Booking: {
            FromTime: bookingToSubmit.booking.FromTime,
            ToTime: bookingToSubmit.booking.ToTime,
            ResourceId: item.data.ResourceId,
            FloorPlanDeskId: item.data.FloorPlanDeskId,
            DiscountCode: item.data.DiscountCode,
            Notes: item.data.Notes,
            BookingVisitors: item.data.BookingVisitors,
            IncludeZoomInvite: item.data.IncludeZoomInvite,
            Tentative: item.data.Tentative,
            BookingProducts: [],
          },
        };
        item.data.ResourceProducts.forEach((product) => {
          if (product.Quantity > 0) {
            booking.Booking.BookingProducts.push({
              ProductId: product.ProductId,
              Quantity: product.Quantity,
            });
          }
        });
        acc.push(booking);
      }
      return acc;
    }, []);
  };

  @observable isLoadingBasketInvoice = false;
  @observable hasLoadedBasketInvoice = false;
  @observable basketInvoice = [];
  @action loadBasketInvoice({ proposalId } = {}) {
    var basket = this.getBasketForCheckout();
    return loadDataHelper({
      store: this,
      agentKey: 'Basket',
      key: 'BasketInvoice',
      params: {
        basket,
        proposalId,
        discountCode: this.discountCode,
      },
    });
  }

  @observable isLoadingPostItems = false;
  @observable hasLoadedPostItems = false;
  @observable postItems = [];
  @action loadPostItems({ proposalId } = {}) {
    var basket = this.getBasketForCheckout();

    addTagManagerEvent({
      name: 'nexudus:basket:checkout',
      customer: getStores().authStore.customer,
      proposal_id: proposalId,
      basket: basket,
    });

    return loadDataHelper({
      store: this,
      agentKey: 'Basket',
      key: 'PostItems',
      params: {
        basket,
        proposalId,
        discountCode: this.discountCode,
      },
    });
  }

  @observable isLoadingInvoicePreview = false;
  @observable hasLoadedInvoicePreview = false;
  @observable invoicePreview = null;
  @action loadInvoicePreview({
    addNewContracts = false,
    proposalId = null,
  } = {}) {
    this.invoicePreview = null;
    var basket = this.getBasketForCheckout();

    addTagManagerEvent({
      name: 'nexudus:basket:checkout_summary',
      customer: getStores().authStore.customer,
      proposal_id: proposalId,
      basket: basket,
    });

    return loadDataHelper({
      store: this,
      agentKey: 'Basket',
      key: 'InvoicePreview',
      params: {
        proposalId,
        addNewContracts,
        basket,
        discountCode: this.discountCode,
      },
    });
  }

  @action loadPartialInvoicePreview({
    addNewContracts = false,
    proposalId = null,
    basket = [],
  } = {}) {
    this.invoicePreview = null;

    return loadDataHelper({
      store: this,
      agentKey: 'Basket',
      key: 'InvoicePreview',
      params: {
        proposalId,
        addNewContracts,
        basket,
        discountCode: this.discountCode,
      },
    });
  }

  @observable discountCode = null;
  @action applyDiscount(discountCode) {
    this.discountCode = discountCode;
  }

  @observable basket = [];
  @action addToBasket = ({
    type,
    data,
    bookingPrice,
    resourceProducts,
    previewInvoice = true,
  }) => {
    addTagManagerEvent({
      name: 'nexudus:basket:add',
      customer: getStores().authStore.customer,
      item_type: type,
      data: data,
    });

    if (type == 'booking') {
      if (bookingPrice && bookingPrice.Currency) {
        data.BookingPrice = bookingPrice.PriceDecimal;
        data.BookingPriceCurrencyCode = bookingPrice.Currency.Code;
        data.BookingPriceMessage = bookingPrice.Resource?.ErrorCode
          ? getErrorDescription(
              bookingPrice.Resource.ErrorCode,
              bookingPrice.Message,
              global.t
            )
          : bookingPrice.Message;
      }
      data.ResourceProducts = resourceProducts || [];
      data.BookingVisitors = data.BookingVisitors || [];
      data.Id = data.Id || this.basket.length + 1;
      data.inBasket = true;
    }

    const foundItem = this.basket.find(
      (i) => i.type === type && i.data.Id == data.Id
    );
    if (foundItem) {
      foundItem.data = data;
    } else {
      this.basket.push({
        type,
        data,
      });
    }

    if (previewInvoice) this.loadInvoicePreview();

    this.saveBasket();
  };

  @action removeFromBasket({ item, previewInvoice }) {
    addTagManagerEvent({
      name: 'nexudus:basket:remove',
      customer: getStores().authStore.customer,
      item_type: item.type,
      data: item,
    });

    this.basket.remove(item);
    if (previewInvoice) this.loadInvoicePreview();
    this.saveBasket();
  }

  @action clearBasket() {
    addTagManagerEvent({
      name: 'nexudus:basket:clear',
      customer: getStores().authStore.customer,
    });

    this.basket = [];
    this.saveBasket();
  }

  @computed get hasPlan() {
    return this.selectedPlans.length > 0;
  }

  @computed get selectedPlans() {
    return this.basket
      .filter((bi) => bi.type == 'contract')
      .map((bi) => bi.data.tariff.Id);
  }
}

export default CheckoutStore;
