import {NavbarQuery, useNavbarQuery} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {datoModelToHref} from '../link/DatoLink';
import {Footer, FooterProps} from './Footer';
import {FooterColumnProps} from './FooterColumn';

export const transformDatoFooterItems = (
  items: NavbarQuery['allFooterItems'],
): FooterColumnProps[] =>
  items
    ? items.map((item) => ({
        title: {text: item.name || ''},
        items: item.children
          ? item.children.map((child) => ({
              text: child ? child.name || undefined : undefined,
              href: child ? datoModelToHref(child) || undefined : undefined,
            }))
          : [],
      }))
    : [];

export const DatoFooter: React.FC<Omit<FooterProps, 'columns'>> = React.memo(
  (props) => {
    const {data} = useNavbarQuery();

    const footerItems = useMemo((): FooterColumnProps[] => {
      if (!data) return [];

      return transformDatoFooterItems(data.allFooterItems);
    }, [data]);

    return <Footer {...props} columns={footerItems} />;
  },
);
