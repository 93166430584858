import {NavbarQuery, useNavbarQuery, useDatoSiteLogoQuery} from 'graphql/generated/graphql';
import React, {useMemo} from 'react';
import {routes} from 'env/routes';
import {NavbarItem} from './NavbarItems';
import {ResponsiveNavbar, ResponsiveNavbarProps} from './ResponsiveNavbar';
import {datoModelToHref} from '../link/DatoLink';

export const transformDatoNavbarItems = (
  items: NavbarQuery['allNavbarItems'],
): NavbarItem[] =>
  items
    ? items.filter(Boolean).map((item) => ({
        text: item.name || '',
        href: datoModelToHref(item) || undefined,
        items: item.children
          ? item.children.filter(Boolean).map(
              (child) =>
                child
                  ? {
                      text: child.name || '',
                      href: datoModelToHref(child) || undefined,
                      items: child.children
                        ? transformDatoNavbarItems(child.children as any)
                        : undefined,
                    }
                  : (null as any), // this won't be reached because we .filter(Boolean)
            )
          : [],
      }))
    : [];

type DatoResponsiveNavbarProps = Omit<
  ResponsiveNavbarProps,
  'items' | 'dashboardHref' | 'loginHref' | 'checkoutRoute' | 'cta'
>;

export const DatoResponsiveNavbar: React.FC<DatoResponsiveNavbarProps> = React.memo(
  (props) => {
    const {data} = useNavbarQuery();
    const {data:res} = useDatoSiteLogoQuery();
    //console.log("navbar query", data)
    //console.log(res);
    const navbarItems = useMemo((): NavbarItem[] => {
      if (!data) return [];

      return transformDatoNavbarItems(data.allNavbarItems);
    }, [data]);

    if (!data || !data.allNavbarItems) return null;

    return (
      <ResponsiveNavbar
        {...props}
        cta={
          data.navbarCta?.link || {
            __typename: 'LinkRecord',
            id: '',
            _isValid: false,
          }
        }
        dashboardHref="https://hub.runwayea.st/dashboard"
        items={navbarItems}
        loginHref="https://members.runwayea.st/"
        checkoutRoute={(routes as any).checkout}
      />
    );
  },
);
