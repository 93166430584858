import React, { Component, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import ActiveLink from 'ui/components/ActiveLink';
import { useTranslation } from 'react-i18next';
import { routes } from 'env/routes';
import { withRouter } from 'next/router';
import withCustomComponent from 'ui/components/withCustomComponent';
import {LocationsMenu} from 'ui/components/runway-east/locations-menu/LocationsMenu';

export const MainMenu = withCustomComponent('MainMenu')(
  inject(
    'contentStore',
    'coursesStore',
    'floorPlanStore',
    'invoicingStore',
    'appStore',
    'authStore',
    'bookingsStore'
  )(
    withRouter(
      observer(
        ({
          componentName,
          router,
          appStore,
          contentStore,
          coursesStore,
          floorPlanStore,
          invoicingStore,
          bookingsStore,
          authStore,
        }) => {
          const { t } = useTranslation();
          const {
            business,
            mainMenuDocked,
            corporateDashboard,
            virtualDashboard,
            mainMenuExpanded,
            publicCustomPages,
          } = appStore;
          const { accessToken, customer } = authStore;
          const { faqArticles, communityPerks } = contentStore;
          const config = appStore.configuration;

          const isCoursesRoute =
            router.pathname === routes.courses ||
            router.pathname === routes.my_courses;
          const coursesExist =
            coursesStore.coursesPage.Courses.length > 0 ||
            coursesStore.myCourses.length > 0;

          const isDashboardActive =
            router.pathname === routes.home ||
            router.pathname === routes.team_dashboard;

          const isBookingsActive =
            router.pathname === routes.resources ||
            router.pathname === routes.my_bookings ||
            router.pathname === routes.floorplan ||
            router.pathname === routes.floorplan_by_id('[floorplan_id]') ||
            router.pathname === routes.calendar ||
            router.pathname === routes.my_events ||
            router.pathname === routes.visitors ||
            router.pathname === routes.deliveries;

          const isCommunityActive =
            router.pathname === routes.forum ||
            router.pathname === routes.forum_by_group('[group_id]') ||
            router.pathname === routes.forum_start ||
            router.pathname === routes.my_threads ||
            router.pathname === routes.private_threads ||
            router.pathname === routes.virtual_rooms ||
            router.pathname === routes.posts ||
            router.pathname === routes.events ||
            router.pathname === routes.event_by_id('[event_id]') ||
            router.pathname === routes.events_calendar ||
            router.pathname === routes.courses ||
            router.pathname === routes.my_courses ||
            router.pathname === routes.course_by_id('[course_id]') ||
            router.pathname === routes.lesson_by_id('[lesson_id]') ||
            router.pathname === routes.directory_members ||
            router.pathname === routes.directory_member_by_id('[member_id]') ||
            router.pathname === routes.directory_team_by_id('[team_id]') ||
            router.pathname === routes.directory_teams ||
            router.pathname === routes.custom_page('[...page_slug]');

          const isSupportActive =
            router.pathname === routes.faq ||
            router.pathname === routes.support ||
            router.pathname === routes.support_new ||
            router.pathname === routes.support_message('[message_id]');

          const isSettingsActive =
            router.pathname === routes.profile ||
            router.pathname === routes.profile_team ||
            router.pathname === routes.profile_team ||
            router.pathname === routes.profile_team ||
            router.pathname === routes.plan ||
            router.pathname === routes.invoices ||
            router.pathname === routes.integrations ||
            router.pathname === routes.notifications ||
            router.pathname === routes.files ||
            router.pathname === routes.metrics;

          const canAccessHelpDesk = config['Access.Help'] == 2 ||
          (customer?.IsMember && config['Access.Help'] == 3) ||
          (!customer?.IsMember && config['Access.Help'] == 4);

          const initToolTips = () => {
            const $ = window.$;
            $('[data-toggle="tooltip"]').tooltip('dispose');
            $('[data-toggle="tooltip"]').tooltip({
              html: true,
            });
          };

          const updateDimensions = () => {
            if (window.innerWidth < 1024) {
              if (appStore.mainMenuDocked) {
                appStore.toggleMainMenuDocked(false);
                appStore.toggleMainMenuExpanded(false);
              }
            }
          };

          const openDropdown = (shouldHide) => {
            if (isDashboardActive) {
              $('#dashboard-dropdown').off('hide.bs.dropdown');
              $('#dashboard-dropdown-toggle').dropdown('toggle');

              if (mainMenuExpanded) {
                // if menu is expanded, prevent dropdown from closing
                $('#dashboard-dropdown').on('hide.bs.dropdown', function (e) {
                  e.preventDefault();
                });
              }
            } else if (isBookingsActive) {
              $('#bookings-dropdown').off('hide.bs.dropdown');
              $('#bookings-dropdown-toggle').dropdown('toggle');

              if (mainMenuExpanded) {
                $('#bookings-dropdown').on('hide.bs.dropdown', function (e) {
                  e.preventDefault();
                });
              }
            } else if (isCommunityActive) {
              $('#community-dropdown').off('hide.bs.dropdown');
              $('#community-dropdown-toggle').dropdown('toggle');

              if (mainMenuExpanded) {
                $('#community-dropdown').on('hide.bs.dropdown', function (e) {
                  e.preventDefault();
                });
              }
            } else if (isSupportActive) {
              $('#support-dropdown').off('hide.bs.dropdown');
              $('#support-dropdown-toggle').dropdown('toggle');

              if (mainMenuExpanded) {
                $('#support-dropdown').on('hide.bs.dropdown', function (e) {
                  e.preventDefault();
                });
              }
            } else if (isSettingsActive) {
              $('#settings-dropdown').off('hide.bs.dropdown');
              $('#settings-dropdown-toggle').dropdown('toggle');

              if (mainMenuExpanded) {
                $('#settings-dropdown').on('hide.bs.dropdown', function (e) {
                  e.preventDefault();
                });
              }
            }
          };

          const communityMenu = (config['PublicWebSite.CommunityBoard'] ||
            config['Jitsi.AlwaysOnRooms.Enabled'] ||
            config['Jitsi.AlwaysOnRooms.Enabled'] ||
            config['Blog.Enabled'] ||
            publicCustomPages.length > 0 ||
            (!corporateDashboard && config['PublicWebSite.Events']) ||
            coursesStore.coursesPage.Courses.length > 0 ||
            config['PublicWebSite.Directory']) && (
            <div
              className={`dropdown ${virtualDashboard ? 'show' : ''}`}
              id="community-dropdown"
            >
              <button
                data-toggle="dropdown"
                id="community-dropdown-toggle"
                className={isCommunityActive ? 'active' : ''}
              >
                <i
                  className="icon-community"
                  data-toggle="tooltip"
                  title={t('Community')}
                ></i>
                <span>{t('Community')}</span>
              </button>
              <div className="dropdown-menu">
                {config['PublicWebSite.CommunityBoard'] && (
                  <ActiveLink href={routes.forum} activeIfChildRoute>
                    <a>{t('Discussion boards')}</a>
                  </ActiveLink>
                )}
                {config['Jitsi.AlwaysOnRooms.Enabled'] && (
                  <ActiveLink href={routes.virtual_rooms}>
                    <a>{t('Virtual rooms')}</a>
                  </ActiveLink>
                )}
                {config['Blog.Enabled'] && (
                  <ActiveLink href={routes.posts} activeIfChildRoute>
                    <a>{t('Articles')}</a>
                  </ActiveLink>
                )}
                {!corporateDashboard && config['PublicWebSite.Events'] && (
                  <ActiveLink href={routes.events} activeIfChildRoute>
                    <a>{t('Events')}</a>
                  </ActiveLink>
                )}
                {(coursesExist || (isCoursesRoute && router.query?.query)) && (
                  <ActiveLink href={routes.courses} activeIfChildRoute>
                    <a>{t('Courses')}</a>
                  </ActiveLink>
                )}
                {config['PublicWebSite.Directory'] && (
                  <ActiveLink
                    href={
                      config['Directory.DirectoryRecords'] == 2
                        ? routes.directory_teams
                        : routes.directory_members
                    }
                    activeIfChildRoute
                  >
                    <a>
                      {config['Directory.DirectoryRecords'] == 2
                        ? t('Teams')
                        : t('Members')}
                    </a>
                  </ActiveLink>
                )}
                {publicCustomPages.map((page, i) => (
                  <ActiveLink href={routes.custom_page(page.Permalink)}>
                    <a>{t(page.Title || page.Name)}</a>
                  </ActiveLink>
                ))}
              </div>
            </div>
          );

          useEffect(() => {
            initToolTips();
            updateDimensions();

            if (!appStore.hasLoadedReferralDiscounts)
              appStore.loadReferralDiscounts().catch((ex) => null);
            if (!contentStore.hasLoadedFaqArticles)
              contentStore.loadFaqArticles().catch((ex) => null);
            if (!contentStore.hasLoadedCommunityPerks)
              contentStore.loadCommunityPerks().catch((ex) => null);
            if (!bookingsStore.hasLoadedResources)
              bookingsStore.loadResources().catch(() => null);

            if (!coursesStore.hasLoadedCoursesPage)
              coursesStore
                .loadCoursesPage({ ...router.query })
                .catch((ex) => null);

            if (!invoicingStore.hasLoadedStoreProducts)
              invoicingStore
                .loadStoreProducts({ ...router.query })
                .catch((ex) => null);

            if (!appStore.mainMenuDocked)
              appStore.toggleMainMenuExpanded(false);
            if (appStore.mainMenuDocked) openDropdown();

            window.addEventListener('resize', updateDimensions);

            return () => {
              window.removeEventListener('resize', updateDimensions);
            }
          }, []);

          useEffect(() => {
            initToolTips();
          }, []);

          if (!business) return <></>;
          if (!accessToken) return <></>;

          const { resourceGroups } = bookingsStore;
          // move 'All Resources' group to first element
          if(resourceGroups.length && resourceGroups[resourceGroups.length-1].groupName === null) {
            const allResourcesGroup = resourceGroups.pop();
            resourceGroups.unshift(allResourcesGroup);
          }

          return (
            <aside
              data-component-name={componentName}
              className={`site-mainmenu ${
                mainMenuExpanded
                  ? 'site-mainmenu--expanded'
                  : 'site-mainmenu--contracted'
              }
            ${mainMenuExpanded && mainMenuDocked ? 'site-mainmenu--docked' : ''}
            `}
            >
              <div
                onClick={() => appStore.toggleMainMenuExpanded()}
                className="site-mainmenu__shadow"
              ></div>

              <div className="site-mainmenu__content">
                <header className="site-mainmenu__header">
                  <button
                    onClick={() => appStore.toggleMainMenuExpanded(false)}
                    className="toggle-mobile"
                  >
                    <i className="icon-close-large"></i>
                  </button>
                  <ActiveLink href="/">
                    <a className="brand">
                      <img
                        src={`${business.NativeHomeUrlWithLanguage}/business/getlogo?h=192&mode=pad`}
                        alt={business.Name}
                      />
                    </a>
                  </ActiveLink>
                  <button
                    onClick={() => {
                      if (mainMenuExpanded) {
                        appStore.toggleMainMenuDocked();
                        openDropdown();
                      } else {
                        appStore.toggleMainMenuExpanded();
                        openDropdown();
                      }
                    }}
                    className="toggle-desktop"
                  >
                    <i
                      className={`icon-${
                        mainMenuExpanded
                          ? mainMenuDocked
                            ? 'close-sidebar'
                            : 'lock'
                          : 'expand-sidebar'
                      }`}
                      data-toggle="tooltip"
                      title={
                        mainMenuExpanded
                          ? mainMenuDocked
                            ? t('Undock navigation')
                            : t('Dock navigation')
                          : t('Toggle navigation')
                      }
                    ></i>
                  </button>
                </header>
                <nav className="site-mainmenu__nav">
                  {/* Location selector */}
                  <LocationsMenu
                    appStore={appStore}
                    authStore={authStore}
                    btnClassName=" "
                    hideCaret
                  />

                  {/* Dashboard */}
                  {customer?.TeamNames && (
                    <div className="dropdown" id="dashboard-dropdown">
                      <button
                        data-toggle="dropdown"
                        id="dashboard-dropdown-toggle"
                        className={isDashboardActive ? 'active' : ''}
                      >
                        <i
                          className="icon-dashboard"
                          data-toggle="tooltip"
                          title={t('Home')}
                        ></i>

                        <span>{t('Home')}</span>
                      </button>
                      <div className="dropdown-menu">
                        <ActiveLink href={routes.home}>
                          <a>
                            {virtualDashboard
                              ? t('Community Feed')
                              : t('My dashboard')}
                          </a>
                        </ActiveLink>

                        <ActiveLink href={routes.team_dashboard}>
                          <a>{t('My Team')}</a>
                        </ActiveLink>
                      </div>
                    </div>
                  )}
                  {!customer?.TeamNames && (
                    <ActiveLink href={routes.home}>
                      <button>
                        <i
                          className="icon-dashboard"
                          data-toggle="tooltip"
                          title={
                            virtualDashboard
                              ? t('Community Feed')
                              : t('My dashboard')
                          }
                          onClick={initToolTips}
                        ></i>
                        <span>
                          {virtualDashboard
                            ? t('Community Feed')
                            : t('My dashboard')}
                        </span>
                      </button>
                    </ActiveLink>
                  )}
                  {virtualDashboard && communityMenu}
                  {/* Bookings */}
                  <div className="dropdown" id="bookings-dropdown">
                    <button
                      data-toggle="dropdown"
                      id="bookings-dropdown-toggle"
                      className={isBookingsActive ? 'active' : ''}
                    >
                      <i
                        className="icon-bookings"
                        data-toggle="tooltip"
                        title={t('Bookings')}
                      ></i>
                      <span>{t('Bookings')}</span>
                    </button>
                    <div className="dropdown-menu">
                      {resourceGroups.length == 1 && (
                        <ActiveLink href={routes.resources} activeIfChildRoute>
                          <a>{t('Available resources')}</a>
                        </ActiveLink>
                      )}
                      {resourceGroups.length > 1 &&
                        resourceGroups.map((group) => (
                          <li className="nav-item">
                            <ActiveLink
                              href={`${
                                routes.resources
                              }?group=${encodeURIComponent(
                                group.groupName ?? ''
                              )}`}
                            >
                              <a className="nav-link">
                                {group.groupName ?? t('All Resources')}
                              </a>
                            </ActiveLink>
                          </li>
                        ))}
                      <ActiveLink href={routes.calendar} activeIfChildRoute>
                        <a>{t('Calendar')}</a>
                      </ActiveLink>
                      {!virtualDashboard &&
                        floorPlanStore.floorPlans.length > 0 && (
                          <ActiveLink
                            href={routes.floorplan}
                            activeIfChildRoute
                          >
                            <a>{t('Floor plan')}</a>
                          </ActiveLink>
                        )}
                    </div>
                  </div>

                  {/* Products and Services */}
                  {!corporateDashboard &&
                    invoicingStore.storeProducts.products.length > 0 && (
                      <ActiveLink href={routes.store}>
                        <button>
                          <i
                            className="icon-products"
                            data-toggle="tooltip"
                            title={t('Products and services')}
                            onClick={initToolTips}
                          ></i>
                          <span>{t('Products and services')}</span>
                        </button>
                      </ActiveLink>
                    )}

                  {/* Community */}
                  {!virtualDashboard && communityMenu}

                  {/* Perks Benefits */}
                  {communityPerks.length > 0 && (
                    <ActiveLink href={routes.perks}>
                      <button>
                        <i
                          className="icon-perk-benefits"
                          data-toggle="tooltip"
                          title={t('Perks and benefits')}
                          onClick={initToolTips}
                        ></i>
                        <span>{t('Perks and benefits')}</span>
                      </button>
                    </ActiveLink>
                  )}

                  {/* Support */}
                  {(faqArticles.length > 0 || config['PublicWebSite.Help']) && (
                    <div className="dropdown" id="support-dropdown">
                      <button
                        data-toggle="dropdown"
                        id="support-dropdown-toggle"
                        className={isSupportActive ? 'active' : ''}
                      >
                        <i
                          className="icon-support"
                          data-toggle="tooltip"
                          title={t('Support')}
                        ></i>
                        <span>{t('Support')}</span>
                      </button>
                      <div className="dropdown-menu">
                        {faqArticles.length > 0 && (
                          <ActiveLink href={routes.faq}>
                            <a>{t('FAQ')}</a>
                          </ActiveLink>
                        )}
                        {(config['PublicWebSite.Help'] && canAccessHelpDesk) && (
                          <ActiveLink href={routes.support} activeIfChildRoute>
                            <a>{t('Help request')}</a>
                          </ActiveLink>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Settings */}
                  <div className="dropdown" id="settings-dropdown">
                    <button
                      data-toggle="dropdown"
                      id="settings-dropdown-toggle"
                      className={isSettingsActive ? 'active' : ''}
                    >
                      <i
                        className="icon-settings"
                        data-toggle="tooltip"
                        title={t('Settings')}
                      ></i>
                      <span>{t('Settings')}</span>
                    </button>
                    <div className="dropdown-menu">
                      <ActiveLink href={routes.profile}>
                        <a>{t('My Account')}</a>
                      </ActiveLink>
                      {(customer?.TeamNames && !customer?.IsTeamAdministrator) && (
                        <ActiveLink href={routes.profile_team}>
                          <a>{t('My team')}</a>
                        </ActiveLink>
                      )}
                      {customer?.IsTeamAdministrator && (
                        <ActiveLink href={routes.profile_team}>
                          <a>{t('Team Settings')}</a>
                        </ActiveLink>
                      )}
                      {!corporateDashboard && (
                        <ActiveLink href={routes.plan}>
                          <a>{t('Plans and benefits')}</a>
                        </ActiveLink>
                      )}
                      {(!corporateDashboard &&
                        config['Members.ViewInvoices'] &&
                        config['SignupForm.ShowBillingSection'] == 'true') && (
                        <ActiveLink href={routes.invoices}>
                          <a>{t('Billing')}</a>
                        </ActiveLink>
                      )}
                      <ActiveLink href={routes.integrations}>
                        <a>{t('Integrations')}</a>
                      </ActiveLink>
                      <ActiveLink href={routes.notifications}>
                        <a>{t('Notifications')}</a>
                      </ActiveLink>
                      <ActiveLink href={routes.metrics}>
                        <a>{t('Metrics')}</a>
                      </ActiveLink>
                    </div>
                  </div>
                </nav>
                <footer className="site-mainmenu__footer">
                  <div className="customer">
                    {customer ? <><img
                      src={`${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${customer.Id}?t=${customer.UpdatedOn}&h=32&w=32&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
                      id="Customer_Avatar_Img"
                      className="avatar"
                      alt={customer.FullName}
                    />
                    <span>{customer.FullName}</span></> : null}
                  </div>
                  <button onClick={() => authStore.logout()} className="logout">
                    <i className="icon-log-out"></i>
                  </button>
                </footer>
              </div>
            </aside>
          );
        }
      )
    )
  )
);
