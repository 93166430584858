import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

import { configureAnchors } from 'react-scrollable-anchor';
import momentLocalizer from 'react-widgets-moment';
import TurndownService from 'turndown';
import Head from 'next/head';
import Footer from 'ui/components/Footer';
import MainHeader from 'ui/components/MainHeader';
import {MainMenu} from 'ui/components/MainMenu';
import {NexudusLayout} from 'ui/components/runway-east/layout/NexudusLayout';

// We want to use the Nexudus layout in every page. The Nexudus page components
// import the default export from this file. To reduce the chance of merge conflicts
// and increase the change of future compatibility with Nexudus changes, we change
// the default export here to be the Nexudus Layout
export default NexudusLayout;

//Disable markdown escaping in Turndown.
TurndownService.prototype.escape = (x) => x;

//Fix for full calendar ssr
if (typeof Element === 'undefined') {
  global.Element = function () {};
}

//react components localiser
momentLocalizer();

@withCustomComponent('DefaultLayout')
@inject('authStore')
@observer
class DefaultLayout extends Component {
  componentDidMount() {
    configureAnchors({ offset: -100, scrollDuration: 200 });
    this.initBodyClass();
  }

  initBodyClass() {
    const { authStore } = this.props;
    const { isLoggedIn } = authStore;
    const $ = window.$;
    if (isLoggedIn) {
      $('body').removeClass('lo');
      $('body').addClass('li');
    } else {
      $('body').removeClass('li');
      $('body').addClass('lo');
    }
  }

  componentDidUpdate() {
    this.initBodyClass();
  }

  componentWillUnmount() {
    const { authStore } = this.props;
    const $ = window.$;
    $('body').removeClass('lo');
    $('body').removeClass('li');
  }

  render() {
    const { t, appStore, authStore, title } = this.props;

    return (
      <div data-component-name={this.props.componentName}>
        <Head>
          <meta charSet="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <title>{title}</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
            />
        </Head>
        <MainMenu />
        <MainHeader />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

export {DefaultLayout}
