import React, { useEffect } from 'react';
import {useDatoSiteLogoQuery} from 'graphql/generated/graphql';
import {NewsletterSignUpInput} from '../input/NewsletterSignUpInput';
import {RunwayEastLink} from '../link/RunwayEastLink';
import {RunwayEastLogo} from '../RunwayEastLogo';
import {colors} from '../theme';
import {FooterColumn, FooterColumnProps} from './FooterColumn';

export interface FooterProps {
  columns: FooterColumnProps[];
  BottomRowLeftComponent?: React.ReactElement;
  BottomRowRightComponent?: React.ReactElement;
  privacyPolicyHref?: string;
  termsAndConditionsHref?: string;
}

export const Footer = React.memo(
  ({
    columns,
    BottomRowLeftComponent,
    BottomRowRightComponent,
    privacyPolicyHref,
    termsAndConditionsHref,
  }: FooterProps) => {
    const {data} = useDatoSiteLogoQuery();
    return (
      <footer className="rwe-footer">
        <div className="rwe-footer__container">
          <div className="rwe-footer__links-and-sign-up-container">
            <div className="rwe-footer__columns-container">
              {columns.map((column) => (
                <FooterColumn {...column} />
              ))}
            </div>
            <div className="rwe-footer__newsletter-sign-up">
              <div className="rwe-p1" style={{marginBottom: 24}}>
                Sign up to our newsletter
              </div>
              <NewsletterSignUpInput
                placeholder="Enter email..."
                textColor={colors.primary.charcoalBlack}
                backgroundColor={colors.primary.white}
              />
              <div className="rwe-footer__newsletter-sign-up-disclaimer">
                <p className="rwe-p3">
                  By submitting your email address, you agree to our{' '}
                  <RunwayEastLink href={privacyPolicyHref}>
                    Privacy Policy
                  </RunwayEastLink>{' '}
                  and{' '}
                  <RunwayEastLink href={termsAndConditionsHref}>
                    Terms and conditions
                  </RunwayEastLink>
                </p>
              </div>
            </div>
          </div>
          <div className="rwe-footer__bottom-row">
            <div className="rwe-footer__column">
              <div className="rwe-footer__social-links">
                {BottomRowLeftComponent}
              </div>
            </div>
            <div className="rwe-footer__column rwe-footer__copyright rwe-caption">
              <div className="rwe-footer__logo">
                <RunwayEastLogo color={colors.primary.white} height={32} href={data?.siteLogo?.footerImage?.url}/>
              </div>
              {new Date().getFullYear()} © Runway East. All rights reserved.
            </div>
            <div className="rwe-footer__column rwe-caption">
              <div className="rwe-footer__row">{BottomRowRightComponent}</div>
            </div>
          </div>
        </div>
      </footer>
    );
  },
);
