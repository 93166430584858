import Loadable from 'react-loadable';
import config from 'env/config';
import { inject } from 'mobx-react';

let cachedComponent = {};
const LoadableComponent = inject('appStore')(
  ({ url, appStore, loadingComponent, ...rest }) => {
    const relativeUrl = url.toLowerCase().split('/en/')[1].split('.jsx')[0];

    const foundPage = appStore.customPages.find((p) => {
      const thisPageUrl = `page/${p.Name.toLowerCase().replace('.jsx', '')}`;
      return relativeUrl == thisPageUrl;
    });

    const source = foundPage?.source;
    if (source) {
      try {
        const key = `${foundPage.Id}`;
        const WrappedComponent = cachedComponent[key];
        cachedComponent[key] = WrappedComponent;

        return <WrappedComponent {...rest} />;
      } catch (ex) {
        return (
          <div
            style={{ fontFamily: 'monospace', padding: 5, color: 'red' }}
          >{`Error parsing ${url}: ${ex}`}</div>
        );
      }
    } else {
      return (
        <div
          style={{ fontFamily: 'monospace', padding: 5, color: 'red' }}
        >{`Could not find component with path ${relativeUrl}`}</div>
      );
    }
  }
);

export default LoadableComponent;
