import {useCallback, useMemo, useState} from 'react';

interface HubspotField<FieldName = string> {
  name: FieldName;
  value: string;
}

interface HubspotContext {
  hutk?: string;
  pageUri: string;
  pageName: string;
}

interface HubspotLegalConsentOptions {
  consent: {
    consentToProcess: boolean;
    text: string;
    communications?: {
      value: boolean;
      subscriptionTypeId: number;
      text: string;
    }[];
  };
}

interface HubspotFormSubmission<FieldName extends string> {
  submittedAt: Date;
  fields: HubspotField<FieldName>[];
  context: HubspotContext;
  legalConsentOptions: HubspotLegalConsentOptions;
}

interface HubspotFormEndpointInput {
  portalId: string;
  formGuid: string;
}

const getHubspotFormEndpoint = ({
  portalId,
  formGuid,
}: HubspotFormEndpointInput) =>
  `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`;

type HubspotErrorType =
  | 'MAX_NUMBER_OF_SUBMITTED_VALUES_EXCEEDED'
  | 'INVALID_EMAIL'
  | 'BLOCKED_EMAIL'
  | 'REQUIRED_FIELD'
  | 'INVALID_NUMBER'
  | 'INPUT_TOO_LARGE'
  | 'FIELD_NOT_IN_FORM_DEFINITION'
  | 'NUMBER_OUT_OF_RANGE'
  | 'VALUE_NOT_IN_FIELD_DEFINITION'
  | 'INVALID_METADATA'
  | 'INVALID_GOTOWEBINAR_WEBINAR_KEY'
  | 'INVALID_HUTK'
  | 'INVALID_IP_ADDRESS'
  | 'INVALID_PAGE_URI'
  | 'INVALID_LEGAL_OPTION_FORMAT'
  | 'MISSING_PROCESSING_CONSENT'
  | 'MISSING_PROCESSING_CONSENT_TEXT'
  | 'MISSING_COMMUNICATION_CONSENT_TEXT'
  | 'MISSING_LEGITIMATE_INTEREST_TEXT'
  | 'DUPLICATE_SUBSCRIPTION_TYPE_ID'
  | 'FORM_HAS_RECAPTCHA_ENABLED';

export interface HubspotFormSubmissionResponse {
  redirectUri?: string;
  inlineMessage?: string;
  errors?: {
    message: string;
    errorType: HubspotErrorType;
  }[];
}
const submitForm = async <FieldName extends string>(
  formId: HubspotFormEndpointInput,
  {submittedAt, ...formSubmission}: HubspotFormSubmission<FieldName>,
) => {
  const response = await fetch(getHubspotFormEndpoint(formId), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...formSubmission,
      submittedAt: submittedAt.getTime(),
    }),
  });

  const rawTextResponse = await response.text();

  return new Promise<HubspotFormSubmissionResponse | string>(
    (resolve, reject) => {
      try {
        const jsonResponse = JSON.parse(
          rawTextResponse,
        ) as HubspotFormSubmissionResponse;

        if (!response.ok) {
          return reject(jsonResponse);
        }

        return resolve(jsonResponse);
      } catch (e) {
        if (!response.ok) {
          return reject(rawTextResponse);
        }

        return resolve(rawTextResponse);
      }
    },
  );
};

const transformHubspotFormResponseErrorMessages = (
  errors: Required<HubspotFormSubmissionResponse>['errors'],
): Required<HubspotFormSubmissionResponse>['errors'] =>
  errors.map((error) =>
    error.errorType === 'INVALID_EMAIL' ||
    (error.errorType === 'REQUIRED_FIELD' && error.message.includes('email'))
      ? {...error, message: 'Please enter a valid email address'}
      : error,
  );

export const useHubspotForm = <FieldName extends string>(
  form: HubspotFormEndpointInput & {fields?: FieldName[]},
) => {
  const [lastFormResponseMessage, setLastFormResponseMessage] = useState<
    string | undefined
  >(undefined);
  const [lastFormResponseHasError, setLastFormResponseHasError] = useState<
    boolean | undefined
  >(undefined);

  const submit = useCallback(
    async (
      formSubmission: Omit<
        HubspotFormSubmission<FieldName>,
        'submittedAt' | 'context'
      >,
    ) => {
      try {
        const hutk = document.cookie.replace(
          /(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/,
          '$1',
        );
        const response = await submitForm<FieldName>(form, {
          ...formSubmission,
          context: {
            ...(hutk ? {hutk} : {}),
            pageName: document.title,
            pageUri: window.location.href,
          },
          submittedAt: new Date(),
        });
        setLastFormResponseHasError(false);
        setLastFormResponseMessage('Success!');
      } catch (e) {
        setLastFormResponseHasError(true);
        setLastFormResponseMessage(
          (e as HubspotFormSubmissionResponse).errors
            ? transformHubspotFormResponseErrorMessages(
                (e as HubspotFormSubmissionResponse).errors as any,
              )
                .map(({message}) => message)
                .join(', ')
            : 'Something went wrong!',
        );
      }
    },
    [form],
  );

  return useMemo(
    () => ({submit, lastFormResponseMessage, lastFormResponseHasError}),
    [lastFormResponseHasError, lastFormResponseMessage, submit],
  );
};
