export const colors = {
  slate: {
    veryLightSlate: '#F0EFF4',
    midSlate: '#9E9AAB',
    darkSlate: '#565366',
  },
  primary: {
    charcoalBlack: '#1C1510',
    teal: '#20CAAB',
    white: '#FFF',
  },
  secondary: {
    red: '#FF5C5C',
    pink: '#FF7BD1',
    yellow: '#FFBB57',
    bluePurple: '#7770FF',
  },
  other: {
    tealButton: '#17A98E',
  },
};
