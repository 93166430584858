import { required, pattern } from 'validx';
import global from 'global';

export const formPageValidationSchema = (question) => {
  const t = global.t;

  var validationObj = {};
  validationObj[`Custom${question.Id}`] = [
    required({
      msg: t('Please provide a response for {{questionText}}', {
        questionText: question.Text,
      }),
    }),
  ];

  return validationObj;
};

export const formPageCustomerValidationSchema = (customer) => {
  const t = global.t;

  return {
    // The schema maps to the fields we are validating.
    FullName: [required({ msg: t('Full name is required') })],
    Email: [
      required({ msg: t('Email is required') }),
      pattern({
        pattern: 'email',
        msg: t('This email address does not seem to be valid.'),
      }),
    ],
  };
};
