import React, {DOMAttributes, useCallback, useRef, useState} from 'react';
import {DropdownMenu, DropdownMenuProps} from '../dropdown/DropdownMenu';
import {RunwayEastLink} from '../link/RunwayEastLink';

export interface NavbarItem {
  text: string;
  href?: string;
  items?: NavbarItem[];
}

interface NavbarItemProps
  extends Pick<DOMAttributes<HTMLDivElement>, 'onClick' | 'onMouseEnter'> {
  text: string;
  href?: string;
  withArrow?: boolean;
  leftAccessory?: React.ReactElement;
  prefetch?: boolean;
}

export const NavbarItem = React.memo(
  React.forwardRef<HTMLDivElement, React.PropsWithChildren<NavbarItemProps>>(
    (
      {href, text, withArrow, children, leftAccessory, prefetch, ...rest},
      ref,
    ) => (
      <div {...rest} ref={ref} className="rwe-navbar__navigation-item">
        <div
          className={`rwe-navbar__navigation-item-text ${
            withArrow
              ? 'rwe-navbar__navigation-item-text--with-arrow-on-right'
              : ''
          }`}
        >
          {leftAccessory ? (
            <div className="rwe-navbar__navigation-item-left-accessory-container">
              {leftAccessory}
            </div>
          ) : null}
          <div className="rwe-p1">
            <RunwayEastLink prefetch={prefetch} href={href}>
              {text}
            </RunwayEastLink>
          </div>
          <div className="rwe-navbar__navigation-item-arrow" />
        </div>

        {children}
      </div>
    ),
  ),
);

export type NavbarItemWithDropdownMenuProps = NavbarItemProps &
  Omit<DropdownMenuProps, 'isShowing'>;

export const NavbarItemWithDropdownMenu: React.FC<NavbarItemWithDropdownMenuProps> = React.memo(
  ({items, rightAligned, ...rest}) => {
    const navigationItemRef = useRef<HTMLDivElement>(null);
    const [isDropdownMenuShowing, setIsDropdownMenuShowing] = useState(false);

    const toggleDropdownMenuVisibility = useCallback(() => {
      setIsDropdownMenuShowing((value) => !value);
    }, []);

    const hasMouseLeftDropdownMenuAndNavbarItem = (event: MouseEvent) => {
      if (!navigationItemRef.current) return false;
      if (!event.target) return false;

      return !navigationItemRef.current.contains(event.target as Node);
    };

    const showDropdownMenu = useCallback(() => {
      setIsDropdownMenuShowing(true);

      const removeEventListener = () =>
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        window.removeEventListener('mousemove', handleMouseMove);

      const handleMouseMove = (event: MouseEvent) => {
        if (!hasMouseLeftDropdownMenuAndNavbarItem(event)) return;

        removeEventListener();
        setIsDropdownMenuShowing(false);
      };

      window.addEventListener('mousemove', handleMouseMove);
    }, []);

    return (
      <NavbarItem
        {...rest}
        ref={navigationItemRef}
        onClick={toggleDropdownMenuVisibility}
        onMouseEnter={showDropdownMenu}
        withArrow
      >
        <DropdownMenu
          isShowing={isDropdownMenuShowing}
          items={items}
          rightAligned={rightAligned}
        />
      </NavbarItem>
    );
  },
);

export interface NavbarItemsProps {
  items: NavbarItem[];
  prefetch?: boolean;
}

export const NavbarItems = React.memo(({items, prefetch}: NavbarItemsProps) => (
  <>
    {items.map((item) =>
      item.items && item.items.length ? (
        <NavbarItemWithDropdownMenu
          key={item.text}
          text={item.text}
          href={item.href}
          items={item.items}
        />
      ) : (
        <NavbarItem
          prefetch={prefetch}
          key={item.text}
          text={item.text}
          href={item.href}
          withArrow={false}
        />
      ),
    )}
  </>
));
