import { useStaticRendering } from 'mobx-react';
import AppStore from './appStore';
import AuthStore from './authStore';
import BookingsStore from './bookingsStore';
import InvoicingStore from './invoicingStore';
import EventsStore from './eventsStore';
import CoursesStore from './coursesStore';
import BlogStore from './blogStore';
import CommunityStore from './communityStore';
import deliveriesStore from './deliveriesStore';
import FilesStore from './filesStore';
import SupportStore from './supportStore';
import CheckoutStore from './checkoutStore';
import FloorPlanStore from './floorplanStore';
import { isServer } from 'env/ssr/ServerSideRenderManager';
import ContentStore from './contentStore';

useStaticRendering(isServer());

let store = null;
export const getStores = () => store;

export default function initializeStore({ stores, host, cookieString }) {
  if (!stores) {
    store = {
      appStore: new AppStore({ host, cookieString }),
      authStore: new AuthStore({ host, cookieString }),
      bookingsStore: new BookingsStore({ host, cookieString }),
      invoicingStore: new InvoicingStore({ host, cookieString }),
      eventsStore: new EventsStore({ host, cookieString }),
      coursesStore: new CoursesStore({ host, cookieString }),
      blogStore: new BlogStore({ host, cookieString }),
      communityStore: new CommunityStore({ host, cookieString }),
      deliveriesStore: new deliveriesStore({ host, cookieString }),
      filesStore: new FilesStore({ host, cookieString }),
      supportStore: new SupportStore({ host, cookieString }),
      checkoutStore: new CheckoutStore({ host, cookieString }),
      floorPlanStore: new FloorPlanStore({ host, cookieString }),
      contentStore: new ContentStore({ host, cookieString }),
    };

    return store;
  }

  //If running in the client, create new stores and hydrate
  //based on the data passed in from the server.
  if (store === null) {
    store = {
      appStore: new AppStore({ data: stores.appStore, host, cookieString }),
      authStore: new AuthStore({ data: stores.authStore, host, cookieString }),
      bookingsStore: new BookingsStore({ data: stores.bookingsStore, host, cookieString }),
      invoicingStore: new InvoicingStore({
        data: stores.invoicingStore,
        host, cookieString
      }),
      eventsStore: new EventsStore({ data: stores.eventsStore, host, cookieString }),
      coursesStore: new CoursesStore({ data: stores.coursesStore, host, cookieString }),
      blogStore: new BlogStore({ data: stores.blogStore, host, cookieString }),
      communityStore: new CommunityStore({
        data: stores.communityStore,
        host, cookieString
      }),
      deliveriesStore: new deliveriesStore({
        data: stores.deliveriesStore,
        host, cookieString
      }),
      filesStore: new FilesStore({
        data: stores.filesStore,
        host, cookieString
      }),
      supportStore: new SupportStore({ data: stores.supportStore, host, cookieString }),
      checkoutStore: new CheckoutStore({ data: stores.checkoutStore, host, cookieString }),
      floorPlanStore: new FloorPlanStore({
        data: stores.floorPlanStore,
        host, cookieString
      }),
      contentStore: new ContentStore({ data: stores.contentStore, host, cookieString }),
    };
  }

  return store;
}
