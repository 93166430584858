import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const CustomerInactiveNotice = withCustomComponent(
  'CustomerInactiveNotice'
)(
  inject('appStore')(
    withRouter(
      observer(({ componentName, router, appStore }) => {
        const { t } = useTranslation();

        return (
          <div data-component-name={componentName}>
            <b>{t('Heads up!')}</b>{' '}
            {t(
              'Your account is not active, you will not have access to some sections.'
            )}
          </div>
        );
      })
    )
  )
);
