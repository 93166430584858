import React from 'react';

interface RunwayEastLogoProps {
  color?: any;
  width?: number;
  height?: number;
  href?: string;
}

export const RunwayEastLogo: React.FC<RunwayEastLogoProps> = ({
  color = 'black',
  width = 300,
  height,
  href,
}) => {
  return href ? <img src={href} width={width} height={height}/> : null
  //(
    // <svg
    //   width={width}
    //   height={height}
    //   viewBox="0 0 1178 244"
    //   fill={color}
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path d="M530.606 171.643C540.489 171.643 546.07 167.894 548.925 164.997L548.839 205.809C548.839 212.157 546.113 216.971 539.211 216.971C532.31 216.971 529.583 212.157 529.583 205.809L529.498 182.208H501.381V207.258C501.381 229.624 514.204 243.384 539.211 243.384C564.219 243.384 577.042 229.581 577.042 201.847V1.74658H548.925V135.389C548.925 142.801 545.9 147.743 538.7 147.743C531.799 147.743 529.583 142.929 529.583 136.581L529.498 1.74658H501.381V138.711C501.338 163.165 515.227 171.643 530.606 171.643Z" />
    //   <path d="M74.9792 107.655V35.7427C74.9792 12.6099 60.8354 1.78906 39.1937 1.78906H0V241.339H28.1172V144.761C28.2876 144.761 36.0412 144.761 36.0412 144.761C43.6243 144.761 46.862 148.254 46.862 156.008V241.297H74.9792V154.815C74.9792 133.642 55.5528 131.213 55.5528 131.213C55.5528 131.213 74.9792 128.785 74.9792 107.655ZM46.862 106.462C46.862 114.215 41.7498 117.709 34.1667 117.709C34.1667 117.709 28.2876 117.709 28.1172 117.709V26.2851C28.2876 26.2851 36.0412 26.2851 36.0412 26.2851C43.6243 26.2851 46.862 29.7785 46.862 37.532V106.462Z" />
    //   <path d="M593.955 241.297H931.83V215.523H907.462V131.256H929.529V105.482H907.462V27.5633H931.83V1.74658H593.955V241.297Z" />
    //   <path d="M1177.6 1.74658V27.5633H1158.13V241.297H1130.01V27.5633H1110.5V1.74658H1177.6Z" />
    //   <path d="M1080.81 111.532C1064.19 97.3878 1059.42 91.1679 1059.42 77.6631V36.595C1059.42 30.6307 1061.98 26.0723 1068.5 26.0723C1075.01 26.0723 1077.57 30.6307 1077.57 36.595V95.4707H1105.69V35.5299C1105.69 13.5474 1093.08 0 1068.5 0C1043.92 0 1031.31 13.5474 1031.31 35.5299V82.4345C1031.31 102.798 1041.27 114.556 1057.51 128.402C1074.12 142.546 1078.89 148.766 1078.89 162.27L1078.81 205.767C1078.81 212.115 1076.08 216.929 1069.18 216.929C1062.28 216.929 1059.55 212.115 1059.55 205.767L1059.47 144.718H1031.35V207.215C1031.35 229.581 1044.17 243.342 1069.18 243.342C1094.19 243.342 1107.01 229.539 1107.01 207.215V157.499C1107.01 137.135 1097.08 125.377 1080.81 111.532Z" />
    //   <path d="M977.84 0C952.833 0 940.01 13.803 940.01 36.1264V241.297H968.127V144.761H987.596V241.297H1015.71V36.1264C1015.67 13.803 1002.85 0 977.84 0ZM968.127 117.709L968.212 37.5748C968.212 31.2271 970.938 26.4131 977.84 26.4131C984.741 26.4131 987.468 31.2271 987.468 37.5748L987.553 117.709H968.127Z" />
    //   <path d="M438.117 144.761H457.586V241.297H485.703V36.1264C485.703 13.7604 472.88 0 447.873 0C422.866 0 410.043 13.803 410.043 36.1264V241.297H438.16V144.761H438.117ZM438.202 37.5748C438.202 31.2271 440.929 26.4131 447.83 26.4131C454.732 26.4131 457.458 31.2271 457.458 37.5748L457.544 117.709H438.075L438.202 37.5748Z" />
    //   <path d="M235.588 158.862L212.541 1.74658H183.23V241.297H208.451L206.96 115.025L205.682 80.5174H206.065L231.03 241.297H258.508V1.74658H233.288L234.779 128.061L235.972 158.862H235.588Z" />
    //   <path d="M363.734 177.99L349.676 1.74658H321.346L307.33 177.99H306.946L298.639 1.74658H269.925L285.262 241.297H320.025L334.85 65.7771H335.234L350.059 241.297H384.822L400.116 1.74658H372.425L364.118 177.99H363.734Z" />
    //   <path d="M128.487 243.384C153.494 243.384 166.318 229.581 166.318 207.258V2.0874H138.2L138.115 205.809C138.115 212.157 135.389 216.971 128.487 216.971C121.586 216.971 118.859 212.157 118.859 205.809L118.774 2.0874H90.6567V207.258C90.6141 229.581 103.48 243.384 128.487 243.384Z" />
    // </svg>
  //);
};
