export const lowerFirstLetter = (s) => {
  return s.charAt(0).toLowerCase() + s.slice(1);
};

export const isString = (x) => {
  return Object.prototype.toString.call(x) === '[object String]';
};

export const linkTo = (router, params) => {
  const query = { ...router.query, ...params };
  return router.pathname + '?' + getQueryString(query);
};

export const getUrlDomain = (data) => {
  var a = document.createElement('a');
  a.href = data;
  return a.hostname;
};

export const getQueryString = (params) =>
  Object.keys(params)
    .reduce((acc, key) => {
      if (params[key] != undefined && params[key].toString().length > 0)
        acc.push(key + '=' + encodeURIComponent(params[key]));
      return acc;
    }, [])
    .join('&');

export const removeHtml = (str) => str && str.replace(/(<([^>]+)>)/gi, '');

export const removeNewLines = (str) => str && str.replace(/\r?\n|\r/g, '');

export const replaceNewLines = (str, replaceWith = '<br/>') =>
  str && str.replace(/\r?\n|\r/g, replaceWith);

export const shorten = (str, maxLen, separator = ' ', ellipsis = '...') => {
  if (!str) return str;
  str = removeHtml(str);
  if (str.length <= maxLen) return str;
  return str.substr(0, str.lastIndexOf(separator, maxLen)) + ellipsis;
};

export const slugify = (string) => {
  if (!string) return '';
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  var result = string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

  if (result.length == 0) return 'view';
  return result;
};
