import { observable, action, reaction, toJS, computed } from 'mobx';
import { loadDataHelper, saveDateHelper } from './storeHelpers';

import StoreBase from './storeBase';
import { commentValidationSchema } from './validationSchemas/commentValidationSchema';
import { validationContext } from 'validx';
const validation = validationContext();
class BlogStore extends StoreBase {
  constructor({ data, host, cookieString } = {}) {
    super({ data, host, cookieString });
    reaction(
      () => toJS(this.newVisitor),
      (newVisitor) => {
        this.newVisitorValidation = validation
          .reset()
          .validate(newVisitor, visitorValidationSchema());
      }
    );

    reaction(
      () => toJS(this.newComment),
      (newComment) => {
        this.newCommentValidation = validation
          .reset()
          .validate(newComment, commentValidationSchema(this, newComment));
      }
    );
  }

  @observable isDeletingComment = false;
  @action deleteComment(comment_id) {
    this.isDeletingComment = true;
    return this.getAgent()
      .Blog.deleteComment(comment_id)
      .then(
        action((data) => {
          if (data.ErrorMessage) throw new Error(data.ErrorMessage);
          return data;
        })
      )
      .finally(
        action((data) => {
          this.isDeletingComment = false;
          return data;
        })
      );
  }

  @observable newComment = {};
  @observable newCommentValidation = { errors: {} };
  @action saveNewComment({ blog_id }) {
    return saveDateHelper({
      store: this,
      agentKey: 'Blog',
      key: 'NewComment',
      validation: validation,
      validationSchema: commentValidationSchema(this, this.newComment),
      modelToValidate: this.newComment,
      data: { id: blog_id, ...this.newComment },
    })
      .then(
        action(() => {
          this.newComment = {};
        })
      )
      .then(
        action(() => {
          this.newCommentValidation = { errors: {} };
        })
      );
  }

  @computed get homeBlogPosts() {
    return this.blogPosts.filter((a) => a.ShowInHomePage);
  }

  @observable isLoadingBlogPosts = false;
  @observable hasLoadedBlogPosts = false;
  @observable blogPosts = {
    BlogPosts: [],
    Categories: [],
  };
  @action loadBlogPosts({ page, size, query, categoryId }) {
    return loadDataHelper({
      store: this,
      agentKey: 'Blog',
      key: 'BlogPosts',
      params: { page, size, categoryId, query },
    });
  }

  @observable isLoadingBlogPostPage = false;
  @observable hasLoadedBlogPostPage = false;
  @observable blogPostPage = {
    BlogPost: {},
  };
  @action loadBlogPostPage(blogId) {
    return loadDataHelper({
      store: this,
      agentKey: 'Blog',
      key: 'BlogPostPage',
      params: blogId,
    });
  }

  @observable isLoadingBannerBlogPosts = false;
  @observable hasLoadedBannerBlogPosts = false;
  @observable bannerBlogPosts = [];
  @action loadBannerBlogPosts() {
    return loadDataHelper({
      store: this,
      agentKey: 'Blog',
      key: 'BannerBlogPosts',
    });
  }
}
export default BlogStore;
