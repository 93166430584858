import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/ar';
import 'moment/locale/ja';
import 'moment/locale/it';
import 'moment/locale/sv';
import timeAgoEs from 'timeago.js/lib/lang/es';
import timeAgoIt from 'timeago.js/lib/lang/it';
import timeAgoFr from 'timeago.js/lib/lang/fr';
import timeAgoPt from 'timeago.js/lib/lang/pt_BR';
import timeAgoDe from 'timeago.js/lib/lang/de';
import timeAgoPl from 'timeago.js/lib/lang/pl';
import timeAgoSv from 'timeago.js/lib/lang/sv';
import timeAgoJa from 'timeago.js/lib/lang/ja';
import timeAgoAr from 'timeago.js/lib/lang/ar';
import { register } from 'timeago.js';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
const timeAgoLocales = {
  es: timeAgoEs,
  it: timeAgoIt,
  fr: timeAgoFr,
  de: timeAgoDe,
  pt: timeAgoPt,
  pl: timeAgoPl,
  sv: timeAgoSv,
  ja: timeAgoJa,
  ar: timeAgoAr,
};
export const t = () => i18n.t;

i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  .use(LanguageDetector)
  .use(initReactI18next)
  .on('languageChanged', function (lng) {
    if (lng) {
      var langShort = lng.split('-')[0].toLocaleLowerCase();
      register(langShort, timeAgoLocales[langShort]);
      moment.locale(lng);
    }
  })
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    load: 'languageOnly',
    loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
    saveMissing: false,
    keySeparator: false,
    nsSeparator: false,
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
      ],
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        if (value instanceof Date) return moment(value).format(format);
        return value;
      },
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
