import React, {useMemo} from 'react';
import {LinkableLinkRecordFragment, useDatoSiteLogoQuery} from 'graphql/generated/graphql';
import {NavbarItem, NavbarItems, NavbarItemsProps} from './NavbarItems';
import {RunwayEastLogo} from '../RunwayEastLogo';
import {RunwayEastButton} from '../button/RunwayEastButton';
import {BasketIcon} from '../icon/BasketIcon';
import {RightIcon} from '../icon/RightIcon';
import {colors} from '../theme';
import {RunwayEastLink} from '../link/RunwayEastLink';
import {DatoButtonLink} from '../link/DatoLink';
import { Data } from '@react-google-maps/api';

export interface MediumNavbarProps extends NavbarItemsProps {
  dashboardHref: string;
  isBackgroundTransparent?: boolean;
  loginHref?: string;
  currentRoute?: string;
  numberOfBasketItems: number;
  checkoutRoute: string;
  loggedIn: boolean;
  bookViewingHref: string;
  cta: LinkableLinkRecordFragment;
}

export const MediumNavbar: React.FC<MediumNavbarProps> = React.memo(
  ({
    items,
    isBackgroundTransparent,
    loginHref,
    numberOfBasketItems,
    checkoutRoute,
    dashboardHref,
    loggedIn,
    bookViewingHref,
    cta,
  }) => {
    const navbarClassNames = useMemo((): string => {
      const classNames = ['rwe-navbar'];

      if (isBackgroundTransparent) {
        classNames.push('rwe-navbar--transparent');
      }

      return classNames.join(' ');
    }, [isBackgroundTransparent]);
    const {data} = useDatoSiteLogoQuery();
    return (
      <div className={navbarClassNames}>
        <div className="rwe-navbar__content">
          <RunwayEastLink href="/">
            <div className="rwe-navbar__logo">
              <RunwayEastLogo width={194} href={data?.siteLogo?.headerImage?.url}/>
            </div>
          </RunwayEastLink>
          <div className="rwe-navbar__navigation">
            <NavbarItems prefetch items={items} />
          </div>
          <div className="rwe-navbar__right-section">
            <div className="phone-num__wrapper">
              <a href="tel:02031379953">
              <svg xmlns="http://www.w3.org/2000/svg" className='phone-num__img' fill="#000000" height="17px" width="17px" version="1.1" id="Layer_1" viewBox="0 0 511.639 511.639" xmlSpace="preserve">
                <g>
                  <g>
                    <path d="M504.982,376.258h-0.021c-19.435-36.715-81.472-73.813-88.725-78.059c-14.4-8.213-29.696-10.731-42.987-6.997 c-10.411,2.88-18.944,9.301-24.768,18.624c-8.107,9.664-18.155,21.035-20.309,22.933c-16.512,11.221-26.944,10.133-41.28-4.224 L183.083,224.748c-14.336-14.357-15.403-24.768-4.757-40.533c2.411-2.859,13.824-12.949,23.488-21.056 c9.323-5.824,15.723-14.357,18.624-24.768c3.691-13.333,1.195-28.587-7.125-43.221c-4.117-7.019-41.216-69.077-77.952-88.512 C113.153-5.076,86.315-1.044,68.566,16.727L45.633,39.639C4.203,81.068-46.229,169.644,81.43,297.324L214.315,430.21 c61.141,61.141,113.301,81.429,155.627,81.429c46.059,0,80.448-24.043,102.037-45.632l22.912-22.912 C512.662,425.324,516.715,398.466,504.982,376.258z"/>
                  </g>
                </g>
              </svg>
              0203 1379 953</a>
            </div>
            <div className="rwe-navbar__right-section-cta-container">
              {numberOfBasketItems ? (
                <div>
                  <RunwayEastLink href={checkoutRoute}>
                    <BasketIcon items={numberOfBasketItems} />
                  </RunwayEastLink>
                </div>
              ) : null}
            </div>
            <div className="rwe-navbar__right-section-cta-container">
              {loggedIn ? (
                <div>
                  <RunwayEastButton
                    variant="primary"
                    href={dashboardHref}
                    RightIconComponent={
                      <RightIcon color={colors.primary.white} />
                    }
                  >
                    Go to Dashboard
                  </RunwayEastButton>
                </div>
              ) : null}
            </div>
            <div className="rwe-navbar__right-section-cta-container">
              {!loggedIn ? (
                <div>
                  <DatoButtonLink data={cta} />
                </div>
              ) : null}
            </div>
            <div className="rwe-navbar__right-section-cta-container">
              {!loggedIn ? (
                <div>
                  <NavbarItem text="Login" prefetch href={loginHref} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  },
);
