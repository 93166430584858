import {LOCATION_CHOICE_COOKIE_NAME} from 'env/stores/authStore';
import React, {useCallback} from 'react';
import {AppStore, AuthStore} from 'ui/components/withAppWrapper';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export interface LocationItemProps {
  location: AppStore['business'];
  currentLocation: AppStore['business'];
  authStore: AuthStore;
}

export const LocationItem = React.memo(
  ({currentLocation, location, authStore}: LocationItemProps) => {
    const locationIsCurrent = location.Id === currentLocation.Id;

    const changeLocation = useCallback(() => {
      cookies.set(LOCATION_CHOICE_COOKIE_NAME, location.Id, {
        secure: true,
        sameSite: 'none',
        domain: authStore.registrableDomain,
        path: '/',
      });

      window.location.reload();
    }, [authStore.registrableDomain, location.Id]);

    return (
      /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
      <span
        className="dropdown-item"
        onClick={changeLocation}
        style={{cursor: 'pointer'}}
      >
        <span className={locationIsCurrent ? 'text-gray-900' : undefined}>
          {location.Name}
        </span>
        {locationIsCurrent ? (
          <i className="icon-check fs-24 mr-0 ml-12 text-gray-900" />
        ) : null}
      </span>
    );
  },
);
