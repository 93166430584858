import React from 'react';
import {MenuItem, MenuItemProps} from './MenuItem';

export interface MenuProps {
  items: MenuItemProps[];
}

export const Menu = React.memo(({items}: MenuProps) => (
  <>
    {items.map((item) => (
      <MenuItem {...item} />
    ))}
  </>
));
