import { observable, action, reaction, toJS } from 'mobx';
import { loadDataHelper, saveDateHelper } from './storeHelpers';
import _ from 'lodash';

import StoreBase from './storeBase';
import { commentValidationSchema } from './validationSchemas/commentValidationSchema';
import { validationContext } from 'validx';
const validation = validationContext();

class EventsStore extends StoreBase {
  constructor({ data, host, cookieString } = {}) {
    super({ data, host, cookieString });
    reaction(
      () => toJS(this.newComment),
      (newComment) => {
        this.newCommentValidation = validation
          .reset()
          .validate(newComment, commentValidationSchema(this, newComment));
      }
    );

    reaction(
      () => this.eventTicketsPage.Event.EventProducts,
      (products) => {
        products.forEach((product) => {
          product.Attendees = product.Attendees || [];
        });
      }
    );
  }

  @observable newComment = {};
  @observable newCommentValidation = { errors: {} };
  @action saveNewComment({ event_id }) {
    return saveDateHelper({
      store: this,
      agentKey: 'Events',
      key: 'NewComment',
      validation: validation,
      validationSchema: commentValidationSchema(this, this.newComment),
      modelToValidate: this.newComment,
      data: { id: event_id, ...this.newComment },
    })
      .then(
        action(() => {
          this.newComment = {};
        })
      )
      .then(
        action(() => {
          this.newCommentValidation = { errors: {} };
        })
      );
  }

  @observable isDeletingComment = false;
  @action deleteComment(comment_id) {
    this.isDeletingComment = true;
    return this.getAgent()
      .Events.deleteComment(comment_id)
      .then(
        action((data) => {
          if (data.ErrorMessage) throw new Error(data.ErrorMessage);
          return data;
        })
      )
      .finally(
        action((data) => {
          this.isDeletingComment = false;
          return data;
        })
      );
  }

  @observable isCancellingTicket = false;
  @action cancelTicket(ticketId) {
    this.isCancellingTicket = true;
    return this.getAgent()
      .Events.cancelTicket(ticketId)
      .then(
        action((data) => {
          if (data.ErrorMessage) throw new Error(data.ErrorMessage);
          return data;
        })
      )
      .finally(
        action((data) => {
          this.isCancellingTicket = false;
          return data;
        })
      );
  }

  @observable isSendingTicket = false;
  @action sendTicket(ticketId) {
    this.isSendingTicket = true;
    return this.getAgent()
      .Events.sendTicket(ticketId)
      .then(
        action((data) => {
          if (data.ErrorMessage) throw new Error(data.ErrorMessage);
          return data;
        })
      )
      .finally(
        action((data) => {
          this.isSendingTicket = false;
          return data;
        })
      );
  }

  @observable isLoadingEventsPage = false;
  @observable hasLoadedEventsPage = false;
  @observable eventsPage = {
    Categories: [],
    CalendarEvents: [],
  };
  @action loadEventsPage(params) {
    return loadDataHelper({
      store: this,
      agentKey: 'Events',
      key: 'EventsPage',
      params,
    });
  }

  @observable isLoadingEventsCalendar = false;
  @observable hasLoadedEventsCalendar = false;
  @observable eventsCalendar = [];
  @action loadEventsCalendar({ start, end }) {
    return loadDataHelper({
      store: this,
      agentKey: 'Events',
      key: 'EventsCalendar',
      params: {
        start,
        end,
      },
    });
  }

  @observable isLoadingUpcomingEvents = false;
  @observable hasLoadedUpcomingEvents = false;
  @observable upcomingEvents = [];
  @action loadUpcomingEvents() {
    return loadDataHelper({
      store: this,
      agentKey: 'Events',
      key: 'UpcomingEvents',
      path: 'CalendarEvents',
    });
  }

  @observable isLoadingMyEvents = false;
  @observable hasLoadedMyEvents = false;
  @observable myEvents = {
    UpcomingEvents: [],
    PastEvents: [],
  };
  @observable myPastEvents = [];
  @action loadMyEvents() {
    return loadDataHelper({
      store: this,
      agentKey: 'Events',
      key: 'MyEvents',
    }).then(
      action((data) => {
        return data;
      })
    );
  }

  @observable isLoadingEventPage = false;
  @observable hasLoadedEventPage = false;
  @observable eventPage = {
    Event: {},
  };
  @action loadEventPage(event_id) {
    return loadDataHelper({
      store: this,
      agentKey: 'Events',
      key: 'EventPage',
      params: event_id,
    });
  }

  @observable isLoadingEventTicketsPage = false;
  @observable hasLoadedEventTicketsPage = false;
  @observable eventTicketsPage = {
    Event: {
      EventProducts: [],
    },
  };
  @action loadEventTicketsPage(event_id) {
    return loadDataHelper({
      store: this,
      agentKey: 'Events',
      key: 'EventTicketsPage',
      params: event_id,
    });
  }
}
export default EventsStore;
