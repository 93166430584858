import { observable, action, autorun, computed, reaction, toJS } from 'mobx';
import { loadDataHelper } from './storeHelpers';
import StoreBase from './storeBase';
import Agent from 'env/agent';
import { routes } from 'env/routes';
import { isServer } from 'env/ssr/ServerSideRenderManager';
import Cookies from 'universal-cookie';
const NAVIGATION_STATE_COOKIE_NAME = 'nexudus.state.navigation.docked';
const cookies = new Cookies();
class AppStore extends StoreBase {
  constructor({ data, host, cookieString } = {}) {
    super({ data, host, cookieString });

    const persistNavigationStatus = () => {
      window.localStorage.setItem(NAVIGATION_STATE_COOKIE_NAME, this.mainMenuDocked ? '1' : '0');
    };

    reaction(
      () => this.mainMenuDocked,
      () => persistNavigationStatus()
    );

    if (!isServer()) {
      // Get menu dock status from state cookie or default it based
      // on window width
      let defaultDockedValue = window.innerWidth > 1024;
      const cookieValue = window.localStorage.getItem(NAVIGATION_STATE_COOKIE_NAME);
      if (cookieValue)
        defaultDockedValue = cookieValue == '1';

      this.toggleMainMenuDocked(defaultDockedValue);
      this.toggleMainMenuExpanded(defaultDockedValue);

      window.updateSignalRConnectionStatus = (status) => {
        console.log('SIGNALR: connection status changed to', status);
        this.updateConnectionStatus(status);
      };
    }
  }

  @observable showNotices = true;
  @action setShowNotices(value) {
    this.showNotices = value;
  }

  @observable signalRConnectionStatus = 'disconnected';
  @action updateConnectionStatus(status) {
    this.signalRConnectionStatus = status;
  }
  @computed get isSignalRConnected() {
    return this.signalRConnectionStatus == 'connected';
  }

  @observable isLoadingLanguages = false;
  @observable hasLoadedLanguages = false;
  @observable languages = [];
  @action loadLanguages() {
    return loadDataHelper({
      store: this,
      agentKey: 'Business',
      key: 'Languages',
    });
  }

  @observable isLoadingNotifications = false;
  @observable hasLoadedNotifications = false;
  @observable notifications = [];
  @action loadNotifications() {
    return loadDataHelper({
      store: this,
      agentKey: 'Auth',
      key: 'Notifications',
    }).then(
      action((notifications) => {
        const t = global.t;

        const canSeeNotification = (not) => {
          if (
            !this.configuration['Members.ViewInvoices'] &&
            not.NotificationType == 'coworkerinvoice'
          )
            return false;

          if (
            !this.configuration['PublicWebSite.CommunityBoard'] &&
            not.NotificationType == 'community-thread'
          )
            return false;

          if (
            !this.configuration['PublicWebSite.CommunityBoard'] &&
            not.NotificationType == 'community-message'
          )
            return false;
          if (
            !this.configuration['PublicWebSite.Events'] &&
            not.NotificationType == 'community-event'
          )
            return false;

          if (
            !this.configuration['PublicWebSite.Blog'] &&
            not.NotificationType == 'blog'
          )
            return false;
          return true;
        };

        notifications.forEach((not) => {
          not.id = not.EntityId;
          switch (not.NotificationType) {
            case 'community-thread':
              not.icon = 'icon-comment';
              not.title = global.t(`New conversation from {{FullName}}`, {
                FullName: not.Message,
              });
              not.url = {
                href: routes.thread_by_id(),
                as: routes.thread_by_id({ Id: not.EntityId, Subject: 'read' }),
              };
              break;
            case 'course':
              not.icon = 'icon-whiteboard-2';
              not.title = global.t(`New course: {{Title}}`, {
                Title: not.Message,
              });
              not.url = {
                href: routes.course_by_id(),
                as: routes.course_by_id({
                  Id: not.EntityId,
                  Title: not.Message,
                }),
              };
              break;
            case 'thread-like':
              not.icon = 'icon-likes';
              not.title = global.t(`{{FullName}} liked your message`, {
                FullName: not.Message.split('||')[0],
              });
              not.url = {
                href: routes.thread_by_id(),
                as: routes.thread_by_id({
                  Id: not.Message.split('||')[1],
                  Subject: 'read',
                }),
              };
              break;
            case 'reply-like':
              not.icon = 'icon-likes';
              not.title = global.t(`{{FullName}} liked your reply`, {
                FullName: not.Message.split('||')[0],
              });
              not.url = {
                href: routes.thread_by_id(),
                as: routes.thread_by_id({
                  Id: not.Message.split('||')[1],
                  Subject: 'read',
                }),
              };
              break;
            case 'community-message':
              not.title = global.t(`New reply from {{FullName}}`, {
                FullName: not.Message,
              });
              not.url = {
                href: routes.thread_by_id(),
                as:
                  routes.thread_by_id({ Id: not.EntityId, Subject: 'read' }) +
                  '#comments-list',
              };
              break;
            case 'coworkerinvoice':
              not.icon = 'icon-invoice';
              not.title = global.t(`New invoice: {{InvoiceNumber}}`, {
                InvoiceNumber: not.Message,
              });
              not.url = {
                href: routes.invoices,
              };
              break;
            case 'community-event':
              not.icon = 'icon-ticket';
              not.title = global.t(`New event: {{Name}}`, {
                Name: not.Message,
              });
              not.url = {
                href: routes.event_by_id(),
                as: routes.event_by_id({ Id: not.EntityId, Name: not.Message }),
              };
              break;
            case 'blog':
              not.icon = 'icon-text-quote';
              not.title = global.t(`New article: {{Name}}`, {
                Name: not.Message,
              });
              not.url = {
                href: routes.post_by_id(),
                as: routes.post_by_id({ Id: not.EntityId, Title: not.Message }),
              };
              break;
            default:
              not.url = {
                href: routes.home,
              };
              break;
          }
        });
        this.notifications = notifications.filter(canSeeNotification);
        return notifications;
      })
    );
  }

  @action dismissNotification(entityId) {
    this.notifications = this.notifications.filter((not) => not.id != entityId);
    return this.getAgent().Auth.DismissNotifications(entityId);
  }

  @action addNotification(data) {
    if (this.notifications.find((n) => n.id == data.id)) return;
    this.notifications.push(data);
  }

  @action clearNotifications() {
    this.notifications = [];
    this.getAgent().Auth.clearNotifications();
  }

  @observable isLoadingLegalStatus = false;
  @observable hasLoadedLegalStatus = false;
  @observable legalStatus = null;
  @action loadLegalStatus() {
    return loadDataHelper({
      store: this,
      agentKey: 'Business',
      key: 'LegalStatus',
    });
  }

  @observable isLoadingVisitorTerms = false;
  @observable hasLoadedVisitorTerms = false;
  @observable visitorTerms = [];
  @action loadVisitorTerms(visitorGuid) {
    return loadDataHelper({
      store: this,
      agentKey: 'Business',
      key: 'VisitorTerms',
      params: visitorGuid,
    });
  }

  @observable isLoadingVisitorTermsAccept = false;
  @observable hasLoadedVisitorTermsAccept = false;
  @observable visitorTermsAccept = [];
  @action loadVisitorTermsAccept(visitorGuid) {
    return loadDataHelper({
      store: this,
      agentKey: 'Business',
      key: 'VisitorTermsAccept',
      params: visitorGuid,
    });
  }

  @observable isLoadingAcceptTerms = false;
  @observable hasLoadedAcceptTerms = false;
  @observable acceptTerms = null;
  @action loadAcceptTerms() {
    return loadDataHelper({
      store: this,
      agentKey: 'Business',
      key: 'AcceptTerms',
    }).then(
      action(() => {
        this.legalStatus = null;
      })
    );
  }

  @observable isLoadingHomePage = false;
  @observable hasLoadedHomePage = false;
  @observable homePage = {};
  @action loadHomePage() {
    return loadDataHelper({
      store: this,
      agentKey: 'Business',
      key: 'HomePage',
    });
  }

  @computed get businessesTree() {
    const tree = [];
    const lookup = {};
    // Initialize lookup table with each array item's id as key and
    // its children initialized to an empty array
    this.businesses.forEach((o) => {
      lookup[o.Id] = o;
      lookup[o.Id].businesses = [];
    });
    this.businesses.forEach((o) => {
      // If the item has a parent we do following:
      // 1. access it in constant time now that we have a lookup table
      // 2. since children is preconfigured, we simply push the item
      if (o.RootBusinessId != null) {
        lookup[o.RootBusinessId]?.businesses
          ? lookup[o.RootBusinessId]?.businesses.push(o)
          : tree.push(o);
      } else {
        // no o.parent so this is a "root at the top level of our tree
        tree.push(o);
      }
    });
    return tree;
  }

  @observable isLoadingBusinesses = false;
  @observable hasLoadedBusinesses = false;
  @observable businesses = [];
  @action loadBusinesses(includeRoot) {
    return loadDataHelper({
      store: this,
      agentKey: 'Business',
      key: 'Businesses',
      params: { includeRoot },
    });
  }

  @observable isLoadingNetworks = false;
  @observable hasLoadedNetworks = false;
  @observable networks = [];
  @action loadNetworks() {
    return loadDataHelper({
      store: this,
      agentKey: 'Business',
      key: 'Networks',
    });
  }

  @observable isLoadingBusiness = false;
  @observable hasLoadedBusiness = false;
  @observable business = null;
  @action loadBusiness() {
    return loadDataHelper({
      store: this,
      agentKey: 'Business',
      key: 'Business',
    });
  }

  @observable isLoadingCustomPages = false;
  @observable hasLoadedCustomPages = false;
  @observable customPages = [];
  @action setCustomPages(customPages) {
    this.customPages = customPages;
  }
  @action loadCustomPages() {
    return loadDataHelper({
      store: this,
      agentKey: 'Business',
      key: 'CustomPages',
    });
  }

  @computed get publicCustomPages() {
    return this.customPages.filter((x) => x.Visibility != 'Private');
  }

  @observable isLoadingCustomPage = false;
  @observable hasLoadedCustomPage = false;
  @observable customPage = null;
  @action loadCustomPage({ name, password, customController, customAction }) {
    this.customPage = null;
    return loadDataHelper({
      store: this,
      agentKey: 'Business',
      key: 'CustomPage',
      params: {
        name,
        password,
        customController,
        customAction,
      },
    });
  }

  @observable showReferralModal = false;
  @observable isLoadingReferralDiscounts = false;
  @observable hasLoadedReferralDiscounts = false;
  @observable referralDiscounts = [];
  @action loadReferralDiscounts() {
    return loadDataHelper({
      store: this,
      agentKey: 'Business',
      key: 'ReferralDiscounts',
    });
  }

  @observable isLoadingSendReferralInvite = false;
  @observable hasLoadedSendReferralInvite = false;
  @observable sendReferralInvite = null;
  @action loadSendReferralInvite({ discountCodeId, email }) {
    return loadDataHelper({
      store: this,
      agentKey: 'Business',
      key: 'SendReferralInvite',
      params: { discountCodeId, email },
    });
  }

  @observable isLoadingConfiguration = false;
  @observable hasLoadedConfiguration = false;
  @observable configuration = [];
  @action loadConfiguration() {
    this.isLoadingConfiguration = true;
    action(() => {
      this.isLoadingConfiguration = false;
      this.hasLoadedConfiguration = true;
    })
    // return this.getAgent().Business.getConfiguration()
    //   .then(
    //     action(async (config) => {
    //       if (!config) {
    //         throw new Error('Could not load site configuration');
    //       }
    //       this.configuration = await config.reduce((result, filter) => {
    //         result[filter.Name] = filter.Value;
    //         return result;
    //       }, {});

    //       return this.configuration;
    //     })
    //   )
    //   .finally(
    //     action(() => {
    //       this.isLoadingConfiguration = false;
    //       this.hasLoadedConfiguration = true;
    //     })
    //   );
  }

  @observable isLoadingCountries = false;
  @observable hasLoadedCountries = false;
  @observable countries = [];
  @action loadCountries() {
    return loadDataHelper({
      store: this,
      agentKey: 'Business',
      key: 'Countries',
    });
  }

  @observable isLoadingTileAction = false;
  @observable hasLoadedTileAction = false;
  @observable tileAction = [];
  @action loadTileAction(tileId) {
    return loadDataHelper({
      store: this,
      agentKey: 'Business',
      key: 'TileAction',
      params: tileId,
    });
  }

  @observable popupMessage = null;
  @observable popupSize = null;
  @action async setPopMessage(message, size) {
    this.popupMessage = message ? message + '' : null;
    this.popupSize = size ? size + '' : null;

    return new Promise((resolve, reject) => {
      autorun((reaction) => {
        if (!this.popupMessage) {
          resolve();
          reaction.dispose();
        }
      });
    });
  }

  @observable yesNoQuestion = null;
  @observable yesNoDescription = null;
  @observable yesNoAnswer = null;
  @action respondYesNoQuestion(response) {
    this.yesNoAnswer = response;
    this.yesNoQuestion = null;
    this.yesNoDescription = null;
  }

  @action setYesNotQuestion(question, description) {
    this.yesNoQuestion = question;
    this.yesNoDescription = description;
    this.yesNoAnswer = null;

    return new Promise((resolve, reject) => {
      autorun((reaction) => {
        switch (this.yesNoAnswer) {
          case true:
            resolve(true);
            reaction.dispose();
            break;
          case false:
            resolve(false);
            reaction.dispose();
            break;
          default:
            break;
        }
      });
    });
  }

  @observable mainMenuDocked = false;
  @action toggleMainMenuDocked(state) {
    if (state !== undefined) {
      this.mainMenuDocked = state;
    } else {
      this.mainMenuDocked = !this.mainMenuDocked;
    }

    this.mainMenuExpanded = this.mainMenuDocked;
  }

  @observable mainMenuExpanded = false;
  @action toggleMainMenuExpanded(state) {
    if (state !== undefined) {
      this.mainMenuExpanded = state;
    } else {
      this.mainMenuExpanded = !this.mainMenuExpanded;
    }
  }

  @observable isLoadingProfileTags = false;
  @observable hasLoadedProfileTags = false;
  /**
   * OBSOLETE, you can now just call profileTags as those come in the settings.
   */
  @action loadProfileTags() {
    return this.profileTags;
  }

  @computed get profileTags() {
    try {
      const tags = this.configuration['Directory.ProfileTags'];
      if (tags?.length > 0) return tags.split(',');
      else return [];
    } catch {
      return [];
    }
  }

  @computed get coworkingDashboard() {
    return this.configuration['Nexudus.ProductVersion'] == 'coworking';
  }
  @computed get corporateDashboard() {
    return this.configuration['Nexudus.ProductVersion'] == 'workplaces';
  }
  @computed get virtualDashboard() {
    return this.configuration['Nexudus.ProductVersion'] == 'communities';
  }
}

export default AppStore;
