import React from 'react';
import {colors} from '../theme';

interface CloseIconProps {
  color?: any;
  width?: number;
  height?: number;
}

export const CloseIcon = React.memo(
  ({
    color = colors.primary.charcoalBlack,
    width = 24,
    height = 24,
  }: CloseIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22 4.01429L19.9857 2L12 9.98571L4.01429 2L2 4.01429L9.98571 12L2 19.9857L4.01429 22L12 14.0143L19.9857 22L22 19.9857L14.0143 12L22 4.01429Z" />
    </svg>
  ),
);
