import {useHubspotForm} from 'hooks/use-hubspot-form';
import React, {useCallback, useMemo, useState} from 'react';
import {colors} from '../theme';
import {
  TextInputWithButtonOnRight,
  TextInputWithButtonOnRightProps,
} from './TextInputWithButtonOnRight';

const HUBSPOT_NEWSLETTER_FORM_CONFIG = {
  formGuid: process.env.HUBSPOT_NEWSLETTER_SIGNUP_FORM_ID as string,
  portalId: process.env.HUBSPOT_ID as string,
  fields: ['email'],
};

type OmittedTextInputWithButtonOnRightPropNames =
  | 'value'
  | 'onValueChange'
  | 'hasError'
  | 'bottomText'
  | 'buttonProps';

export interface NewsletterSignUpInputProps
  extends Omit<
    TextInputWithButtonOnRightProps,
    OmittedTextInputWithButtonOnRightPropNames
  > {
  buttonVariant?: TextInputWithButtonOnRightProps['buttonProps']['variant'];
  buttonText?: TextInputWithButtonOnRightProps['buttonProps']['children'];
}

export const NewsletterSignUpInput = React.memo<NewsletterSignUpInputProps>(
  ({
    backgroundColor = colors.primary.white,
    textColor = colors.primary.charcoalBlack,
    placeholder = 'Enter email...',
    buttonText = 'Sign up',
    buttonVariant = 'primary',
  }) => {
    const [value, setValue] = useState('');

    const hubspotNewsletterSignupForm = useHubspotForm(
      HUBSPOT_NEWSLETTER_FORM_CONFIG,
    );

    const handleClick = useCallback(
      () =>
        hubspotNewsletterSignupForm.submit({
          fields: [{name: 'email', value}],
          legalConsentOptions: {
            consent: {
              consentToProcess: true,
              text: 'I agree to receive other communications from Runway East.',
            },
          },
        }),
      [hubspotNewsletterSignupForm, value],
    );

    const buttonProps = useMemo(
      () => ({
        variant: buttonVariant,
        children: buttonText,
        onClick: handleClick,
      }),
      [buttonText, buttonVariant, handleClick],
    );

    return (
      <TextInputWithButtonOnRight
        value={value}
        onValueChange={setValue}
        placeholder={placeholder}
        textColor={textColor}
        backgroundColor={backgroundColor}
        hasError={hubspotNewsletterSignupForm.lastFormResponseHasError}
        bottomText={hubspotNewsletterSignupForm.lastFormResponseMessage}
        buttonProps={buttonProps}
      />
    );
  },
);
