import React, { useEffect, useState, useRef } from 'react';
import { observer, inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import i18n from 'env/i18n';

const LanguageBar = withCustomComponent('LanguageBar')(
	inject('appStore')(
		observer(({ router, appStore, classes, componentName }) => {
			const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
			const { languages } = appStore;

			const prevLanguageRef = useRef();
			const prevLanguage = prevLanguageRef.current;

			useEffect(() => {
				if(!appStore.hasLoadedLanguages) appStore.loadLanguages();
			}, [])

			useEffect(() => {
				prevLanguageRef.current = currentLanguage;

				if(prevLanguage && currentLanguage != prevLanguage) {
					window.location.href = `?lng=${currentLanguage}`;
				}
			}, [currentLanguage])

			return (
        <select
          class={`custom-select ${classes}`}
          id="language"
          value={currentLanguage}
          onChange={(ev) => setCurrentLanguage(ev.target.value)}
        >
          {languages.sort(sortAlphabetically).map((l) => (
            <option value={l.Culture.toLowerCase()}>{l.Name}</option>
          ))}
        </select>
			);
		}
		)
	)
);

export default LanguageBar;

const sortAlphabetically = (a, b) => {
  if (a.Name < b.Name) {
    return -1;
  }
  if (a.Name > b.Name) {
    return 1;
  }

  return 0;
}
